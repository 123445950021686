import classNames from 'classnames';
import React from 'react';

import { renderChildren_v1_0_0 } from '../render-components';
import { convertListItemToCheckoutItemSiteData_v1_0_0 } from '../../../site-data/retrieval';
import { getInferredComponentDefaultType_v1_0_0 } from '../../compiler/components';
import { getComponentClassName_v1_0_0 } from '../render-components';
import { renderChildComponentSiteDataPropertyValue_v1_0_0 } from "../render-components";
import { getLinkTarget } from '../../../../../../../../lib/text-utils';
import { RuntimeWimlComponentProps_v1 } from "app/wiml/versions/v1/types";
import NextLink from 'next/link';

import { loadStripe } from '@stripe/stripe-js';
import { fetcher } from '../../../../../../../../lib/data-utils';
// uses convetino from firebase.ts but we know it'll only be in next or web.
const stripePk = process.env.STRIPE_PUBLIC_KEY || process.env.REACT_APP_STRIPE_PUBLIC_KEY || process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY;

export function Link_v1_0_0(props: RuntimeWimlComponentProps_v1) {
    const componentClassName = getComponentClassName_v1_0_0(props);
    const urlJSONValue = renderChildComponentSiteDataPropertyValue_v1_0_0(props, 'url', getInferredComponentDefaultType_v1_0_0('Link')) || props.url;

    // todo replace with next link
    // https://nextjs.org/docs/api-reference/next/link
    const retVal = _renderLink_v1_0_0(props, componentClassName, urlJSONValue);

    return retVal
}

export function _renderLink_v1_0_0(props: RuntimeWimlComponentProps_v1_0_0, componentClassName: string, urlJSONValue: string | Record<string, any>) {
    let retVal = null;
    const renderedChildren = renderChildren_v1_0_0(props.children);
    const className = classNames(componentClassName, {
        btn: props.button != null,
        'btn-primary': props.button === 'primary',
        'btn-secondary': props.button === 'secondary',
    });
    if (urlJSONValue) {
        if (typeof urlJSONValue === 'string') {
            const urlString = urlJSONValue;
            const target = getLinkTarget(urlString || '');

            // update: this logic will hide it now
            // todo handle this case where url is empty. what should we do? for now we can hide via css
            // https://app.clickup.com/t/863gpbwjz
            /*
            a:not([href]),
            a[href=""] {
                display: none;
            }
            */
            if (target) {
                retVal = (
                    <a href={urlString} target={target} className={className}>
                        {renderedChildren}
                    </a>
                );
            } else {
                retVal = (
                    <NextLink href={urlString}>
                        <a className={className}>
                            {renderedChildren}
                        </a>
                    </NextLink>
                );
            }
        }
        else {
            const checkout = (urlJSONValue as { checkout: string; })?.checkout;
            if (checkout) {
                // this must be a list item ,if not, error
                if (!props.dataRetrievalListId || !props.dataRetrievalListItemId) throw new Error('Checkout Link component must be used inside a List component');
                // todo - consider this a new type - e.g. CheckoutData (something generic - has no idea about list items etc)
                const listItemCheckoutData = convertListItemToCheckoutItemSiteData_v1_0_0(props.wimlData, props.dataRetrievalListId, props.dataRetrievalListItemId, props.extra.websiteData.ecommerce.accountActive);

                const extra = { disabled: !listItemCheckoutData.valid };

                retVal = (
                    <button className={className} {...extra} onClick={() => redirectToCheckoutProvider(props.extra.websiteData.id, listItemCheckoutData)}>
                        {renderedChildren}
                    </button>
                );
            }
        }
    }
    return retVal;
}

async function redirectToCheckoutProvider(websiteId: string, listItemCheckoutData: any) {
    const cancelUrl = window.location;
    // v3 was at '/order/thank-you/' but we do not support nested pages yet in v4
    const successUrl = window.location.origin + '/thank-you/' + listItemCheckoutData.meta.location.listItemId;

    const res = await fetcher(`/api/v4/ecommerce/charge/?website_id=${websiteId}&list_id=${listItemCheckoutData.meta.location.listId}&list_item_id=${listItemCheckoutData.meta.location.listItemId}&cancel_url=${cancelUrl}&success_url=${successUrl}`, undefined, { getJson: true });

    // console.log({ res })
    const stripe = await loadStripe(stripePk, { stripeAccount: res.account.id });

    const sessionId = res.session.id;
    stripe.redirectToCheckout({ sessionId });
}
Link_v1_0_0.inputProps = {
    url: {
        type: 'url',
        label: 'Link URL',
        description: 'The url of the Link',
    },
};
