// v1.2 move to approrpiate locations, but don't change types.
// todo v1.2 add test and move to renderer

export const listItemComponentCheckoutRules_v1_3_0 = {
    heading__title: { content: true },
    image__featured: { url: true },
    number__price: { number: true },
    text__confirmation_page: { content: true },
    text__confirmation_email: { content: true },
};
