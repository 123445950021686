import * as objectUtils from '../../../../../../../lib/object-utils';
import { _isTextNode_v1_0_0, _isListNode_v1_0_0, _isPageNode_v1_0_0, getNodeAttr_v1_0_0, getWimlPageNodeCollectionFromNode_v1_0_0 } from '../../../v1.0.0/theme-data/compiler/nodes';
import { WimlPageNodeCollection_v1_0_0, WimlNode_v1_0_0 } from '../../../v1.0.0/theme-data/compiler/types';
import { ThemeDataPageComponentCollection_v1, ThemeDataPageListComponent_v1, ThemeDataPageChildComponent_v1, ThemeDataPageWithComponents_v1, ThemeDataPageChildComponentListInstanceChildComponent_v1 } from "../../../types";
import { RuntimeWimlComponentCollection_v1_0_0 } from '../../../v1.0.0/theme-data/renderer/types';
import { _getThemeDataComponentId_v1_2_0, formatNodeKey_v1_2_0 } from './components';
import { getListIdFromNodeAttrs_v1_2_0, processListQualifiedNodeChildren_v1_2_0 } from './nodes';
import { _getPageUrl_v1_0_0 } from '../../../v1.0.0/theme-data/compiler/page-components';
import { children } from 'cheerio/lib/api/traversing';

export function _getThemeDataPageChildComponentCollectionFromNode_v1_2_0(runtimeComponents: RuntimeWimlComponentCollection_v1_0_0, node: WimlNode_v1_0_0, parentListId: string = '', parentListInstanceComponentId: string = ''): ThemeDataPageChildComponent_v1[] {

    // todo v1.2 it's probably easier to construct id's with a bfs, or provide a `parent` node to get the stuff.
    // but even that latter approach would require a recursive approach to get the parents full path, whereas bfs would provide it by default.
    if (!_isTextNode_v1_0_0(node)) {
        // console.group();
        BeginLog(node, parentListId, parentListInstanceComponentId);
        const abc = processListQualifiedNodeChildren_v1_2_0(runtimeComponents, node, parentListId, parentListInstanceComponentId, true, _getThemeDataPageChildComponentCollectionFromNode_v1_2_0);

        const isList = _isListNode_v1_0_0(node);
        const lookup = runtimeComponents[node.tag];

        if ((isList || lookup?.inputProps) && !_isPageNode_v1_0_0(node)) {
            const component = createPageChildComponent_v1_2_0(node, parentListId, parentListInstanceComponentId);

            if (!parentListInstanceComponentId || isList) {
                EndLog(node, parentListId, parentListInstanceComponentId);
                // console.groupEnd();


                if (isList) {
                    // component.test = 'test';
                    const meta = getThemeDataListInstanceMeta_v1_2_0(component, abc);
                    if (meta) {
                        component.meta = meta;
                    }
                }


                return [...abc, component];

            } else {
                // todo v1.2 use create component function
                // todo v1.2 rename to themeDataPageChildComponentListInstanceComponent

                const listItemComponent: ThemeDataPageChildComponentListInstanceChildComponent_v1 = { ...component, isListInstanceComponent: true, listId: parentListId, parentListInstanceComponentId };
                // todo should this be renamed to listItemComponentId? ListComponent are those on a page, but items within a list are listItemComponents?
                // no, listComponentId refers to the list id itself, not the list item component id

                EndLog(node, parentListId, parentListInstanceComponentId);
                // console.groupEnd();



                return [...abc, listItemComponent];
            }
        } else {
            return abc;
        }
    } else {
        return []
    }
}

function BeginLog(node: WimlNode_v1_0_0, parentListId: string, parentListComponentId: string) {
    return;
    // const beginString = `Beginning processing of NODE:${node.tag} KEY:${node.attr?.key}     LISTID:${options.listId} parentListId:${parentListId} LISTCOMPONENTID:${options.listComponentId} parentListComponentId: ${parentListComponentId}`;
    // if (options.listId != parentListId || options.listComponentId != parentListComponentId) {
    //     console.warn(beginString);
    // } else {
    //     console.log(beginString);
    // }
}


function EndLog(node: WimlNode_v1_0_0, parentListId: string, parentListComponentId: string) {
    return;
    // const endString = `End processing of NODE:${node.tag} KEY:${node.attr?.key}   LISTID:${options.listId} parentListId:${parentListId} LISTCOMPONENTID:${options.listComponentId} parentListComponentId: ${parentListComponentId}`;
    // if (options.listId != parentListId || options.listComponentId != parentListComponentId) {
    //     console.warn(endString);
    // } else {
    //     console.log(endString);
    // }
}

export function getThemeDataPageComponentCollectionFromNode_v1_2_0(runtimeComponents: RuntimeWimlComponentCollection_v1_0_0, jsonNode: WimlNode_v1_0_0) {
    const nodesByPage = getWimlPageNodeCollectionFromNode_v1_0_0(jsonNode);

    const themeDataPageCollection = getThemeDataPageComponentCollectionFromNodeCollection_v1_2_0(runtimeComponents, nodesByPage);

    const retVal = themeDataPageCollection;

    return retVal;
}

export function getThemeDataPageComponentCollectionFromNodeCollection_v1_2_0(runtimeComponents: RuntimeWimlComponentCollection_v1_0_0, nodesByPage: WimlPageNodeCollection_v1_0_0) {
    const pageItems = Object.keys(nodesByPage).reduce((acc, key) => {
        const page = nodesByPage[key];
        const themeDataPageChildComponentCollection = _getThemeDataPageChildComponentCollectionFromNode_v1_2_0(runtimeComponents, page);

        const listItemComponents = themeDataPageChildComponentCollection
            .filter((c) => c.isListInstanceComponent)
            .map((c) => {
                // remove the fqlsid from id
                // handle Nested List
                const retVal = { ...c, id: c.id.split('.').slice(-1)[0] };
                return retVal;
            });
        const nonListItemComponents = themeDataPageChildComponentCollection
            .filter((c) => !c.isListInstanceComponent)
            .map((c) => {
                const isList = c.type === "List";
                if (isList) {
                    // renamve listComponentId to listInstanceComponentId
                    const currentListItemComponentsArray = listItemComponents.filter((lc) => lc.parentListInstanceComponentId === c.id);
                    const currentListItemComponentsObject = objectUtils.convertArrayToObject(currentListItemComponentsArray);
                    const retVal: ThemeDataPageListComponent_v1 = { ...c, components: { ids: Object.keys(currentListItemComponentsObject) } };
                    return retVal;
                } else {
                    const retVal = c;
                    return retVal;
                }
            });

        const pageComponentsObject = objectUtils.convertArrayToObject(nonListItemComponents, "id");

        // use lodash to titleize the name
        const name = key;//_.startCase(key);
        const pageId = _getThemeDataComponentId_v1_2_0({
            type: page.tag,
            key: getNodeAttr_v1_0_0(page).key,
        });
        const components = {
            ids: Object.keys(pageComponentsObject),
            items: pageComponentsObject,
        };
        const url = _getPageUrl_v1_0_0(page);
        const retVal = { ...acc, [pageId]: { name, components, url } };
        return retVal;
    }, {} as Record<string, ThemeDataPageWithComponents_v1>);

    const retVal = {
        ids: Object.keys(pageItems),
        items: pageItems,
    } as ThemeDataPageComponentCollection_v1;

    return retVal;
}

export function createPageChildComponent_v1_2_0(node: WimlNode_v1_0_0, parentListId: string, parentListInstanceComponentId: string): ThemeDataPageChildComponent_v1 {
    const isList = _isListNode_v1_0_0(node);

    const currentListId = _isListNode_v1_0_0(node) ? getListIdFromNodeAttrs_v1_2_0(node) : parentListId;

    const { key, ...themeProps } = getNodeAttr_v1_0_0(node);
    const type = node.tag;

    const componentId = _getThemeDataComponentId_v1_2_0({
        type,
        key,
        parentListInstanceComponentId,
    }, true);
    return {
        type: node.tag,
        themeProps,
        ...(isList && { listId: currentListId.startsWith('list__') ? currentListId : `list__${currentListId}` }),
        ...(key && { key: formatNodeKey_v1_2_0(key) }),
        id: componentId,
    };
}


export const requiredGalleryComponentTypes_v1_2_0 = ['image'];
const validGalleryComponentTypes_v1_2_0 = ['column', 'link', 'row'];
export function getThemeDataListInstanceMeta_v1_2_0(component: ThemeDataPageChildComponent_v1, children: ThemeDataPageChildComponent_v1[]) {

    const retVal = { galleryView: false };

    // const listId: string = websiteData.getPageChildListInstanceComponentThemeData(pageDefinitionId, listInstanceComponentId).listId;
    // const ids: string[] = websiteData.getPageChildComponentThemeData(pageDefinitionId, listInstanceComponentId).components.ids;

    // const camlizedListId = humps.camelize(listId);
    // const listThemeData = websiteData.getListThemeData(camlizedListId);
    // const idsToComponents = ids.map((id) => {
    //     const component = listThemeData.components.items[id];
    //     return component;
    // });
    const requiredGalleryComponents = children.filter((component) => {
        const isRequiredGalleryComponent = requiredGalleryComponentTypes_v1_2_0.includes(component.type.toLowerCase());
        return isRequiredGalleryComponent;
    });
    if (requiredGalleryComponents.length > 0) {
        const remainingComponents = children.filter((component) => {
            const isRequiredGalleryComponent = requiredGalleryComponentTypes_v1_2_0.includes(component.type.toLowerCase());
            return !isRequiredGalleryComponent;
        });
        if (remainingComponents.length > 0) {
            const isAllRemainingComponentsValidGalleryComponents = remainingComponents.every((component) => {
                const isValidGalleryComponent = validGalleryComponentTypes_v1_2_0.includes(component.type.toLowerCase());
                return isValidGalleryComponent;
            });
            if (isAllRemainingComponentsValidGalleryComponents) {
                retVal.galleryView = true;
            }
        } else {
            retVal.galleryView = true;
        }
    }

    return retVal;
}
