import React from 'react';
import { RuntimeWimlComponentProps_v1_0_0 } from '../../../../v1.0.0/theme-data/compiler/types';
import { renderChildComponentSiteDataPropertyValue_v1_2_0 } from "../render-components";
import InnerHTML from 'dangerously-set-html-content';
import { Embed_v1_0_0 } from '../../../../v1.0.0/theme-data/renderer/components/embed';

export function Embed_v1_2_0(props: RuntimeWimlComponentProps_v1_0_0) {
    let retVal = null;

    let content = renderChildComponentSiteDataPropertyValue_v1_2_0(props, 'content');

    if (content) {
        const element = <InnerHTML html={content} />;
        retVal = element;
    }
    return retVal;
}
Embed_v1_2_0.inputProps = Embed_v1_0_0.inputProps;
