import { MarkupToReactConverter_v1 } from "./theme-data-v1";
import { IWiml_v1, WimlVer, WimlImplClass_v1, ThemeData_v1, SiteData_v1, IWimlImpl_v1, SearchQuery_v1 } from "./types";
import { WimlImpl_v1_0_0 } from "./v1.0.0/wiml-v1-0-0";
import { WimlImpl_v1_1_0 } from "./v1.1.0/wiml-v1-1-0";
import { WimlImpl_v1_2_0 } from "./v1.2.0/wiml-v1-2-0";
import { WimlImpl_v1_3_0 } from "./v1.3.0/wiml-v1-3-0";
import { WimlImpl_v1_4_0 } from "./v1.4.0/wiml-v1-4-0";

export class Wiml_v1 implements IWiml_v1 {
    wimlClassLookup_v1: Record<WimlVer, WimlImplClass_v1> = {
        "1.0.0": WimlImpl_v1_0_0,
        "1.1.0": WimlImpl_v1_1_0,
        "1.2.0": WimlImpl_v1_2_0,
        "1.3.0": WimlImpl_v1_3_0,
        "1.4.0": WimlImpl_v1_4_0,
    };

    themeData: ThemeData_v1;
    siteData: SiteData_v1;

    constructor(data?: IWiml_v1) {
        if (data) {
            this.themeData = data.themeData;
            this.siteData = data.siteData;
        } else {
            this.themeData = new ThemeData_v1();
            this.siteData = new SiteData_v1();
        }
    }

    private getWimlClass() {
        if (!this.themeData.meta) {
            this.themeData;
        }
        const wimlVer = this.themeData.meta.version;
        const versionSpecificWimlClassType = this.wimlClassLookup_v1[wimlVer];

        const retVal: IWimlImpl_v1 = new versionSpecificWimlClassType(this);
        return retVal;
    }

    updateMarkup(value: string) {
        const converter = new MarkupToReactConverter_v1();
        this.themeData = converter.convertMarkupToThemeData_v1(value);
    }

    createThemeDataPageComponent(pageKey: string) {
        return this.getWimlClass().createThemeDataPageComponent(pageKey);
    }

    createThemeDataPageChildComponent(pageKey: string, componentType: string, componentKey: string, themeProps: Record<string, any>, position: string, parentComponentType: string, parentComponentKey: string, content: string) {
        return this.getWimlClass().createThemeDataPageChildComponent(pageKey, componentType, componentKey, themeProps, position, parentComponentType, parentComponentKey, content);
    }

    addThemeDataPageChildComponentContent(pageKey: string, position: string, parentComponentType: string, parentComponentKey: string, content: string) {
        this.getWimlClass().addThemeDataPageChildComponentContent(pageKey, position, parentComponentType, parentComponentKey, content);
    }

    moveThemeDataPageChildComponent(pageId: string, componentId: string, position: string | number) {
        return this.getWimlClass().moveThemeDataPageChildComponent(pageId, componentId, position);
    }
    replaceStyleImportStatement(newImport: string) {
        return this.getWimlClass().replaceStyleImportStatement(newImport);
    }

    replaceStyleCssVars(newCssVars: string) {
        return this.getWimlClass().replaceStyleCssVars(newCssVars);
    }

    updatePageChildComponentProperty(pageId: string, componentId: string, propertyId: string, propertyExpression: string) {
        this.getWimlClass().updatePageChildComponentProperty(pageId, componentId, propertyId, propertyExpression, this);
    }

    createNewListItem(listItemId: string, listId: string) {
        return this.getWimlClass().createNewListItem(listItemId, listId);
    }

    getListComponentThemeData(listId: string) {
        return this.getWimlClass().getListComponentThemeData(listId);
    }

    getPageComponentThemeData(pageId: string) {
        return this.getWimlClass().getPageComponentThemeData(pageId);
    }

    getPageChildComponentThemeData(pageId: string, componentId: string) {
        return this.getWimlClass().getPageChildComponentThemeData(pageId, componentId);
    }

    getPageChildListInstanceComponentThemeData(pageId: string, listInstanceComponentId: string) {
        return this.getWimlClass().getPageChildListInstanceComponentThemeData(pageId, listInstanceComponentId);
    }

    getPageChildListInstanceChildComponentThemeData(pageId: string, listInstanceComponentId: string, componentId: string) {
        return this.getWimlClass().getPageChildListInstanceChildComponentThemeData(pageId, listInstanceComponentId, componentId);
    }

    updateListItemComponentProperty(listId: string, listItemId: string, componentId: string, propertyId: string, propertyExpression: string) {
        this.getWimlClass().updateListItemComponentProperty(listId, listItemId, componentId, propertyId, propertyExpression);
    }

    deleteListItem(listId: string, listItemId: string) {
        this.getWimlClass().deleteListItem(listId, listItemId);
    }

    updateRelationship(listId: string, listItemId: string, relationshipListId: string, relationshipListItemId: string) {
        this.getWimlClass().updateRelationship(listId, listItemId, relationshipListId, relationshipListItemId);
    }

    deleteRelationship(listId: string, listItemId: string, relationshipListId: string, relationshipListItemId: string) {
        this.getWimlClass().deleteRelationship(listId, listItemId, relationshipListId, relationshipListItemId);
    }

    searchSiteData(query: SearchQuery_v1) {
        return this.getWimlClass().searchSiteData(query);
    }
}
