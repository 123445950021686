import { _cleanseJsonNodes, _validateLayoutNode, _validateTopLevelNodes, _validateWimlDocument, handleResult, validateWiml } from '../../../v1.0.0/theme-data/compiler/nodes';
import { _getPageDefinitionNodeCollectionFromNode_v1_0_0 } from '../../../v1.0.0/theme-data/compiler/nodes';
import { WimlNode_v1_1_0, WimlRootNode_v1_1_0 } from './types';
import { flow, } from 'fp-ts/lib/function';
import cheerio from 'cheerio';
import { fromPredicate, right, isLeft, chain } from 'fp-ts/lib/Either';


function _convertHtmlStringToJson(wimlString: string) {
    // todo make point-free
    const trimmed = wimlString.trim();
    // remove ending characters after </WIML> close tag
    // todo remove this when vscode formatter is fixed prettifier xml?
    // const endingCleaned = trimmed.replace(/<\/WIML>.*/m, '</WIML>');
    // todo use d.ts or ts for html2json
    // const document = html2json(trimmed) as unknown as Html2JsonNode
    const document = cheerioToWimlNode_v1_1_0(trimmed);
    _validateWimlDocument(document);

    const wimlNode = document.child[0];

    return wimlNode;
}


function convertCheerioToWimlNode_v1_1_0(node: GenericElement, html: string): WimlNode_v1_1_0 {
    if (isRootNode(node)) {
        const children = node.children.map(child => convertCheerioToWimlNode_v1_1_0(child, html));
        return {
            node: 'root',
            child: children,
            // startIndex, endIndex, lineNumber aren't really used until v1.2
            startIndex: node.startIndex,
            endIndex: node.endIndex,
            lineNumber: getNodeLineNumber_v1_1_0(node, html)
        };
    } else if (isTagNode(node)) {
        cheerio(node)
        const children = node.children.map(child => convertCheerioToWimlNode_v1_1_0(child, html));

        return {
            tag: node.tagName,
            node: 'element',
            attr: node.attribs,
            child: children,
            startIndex: node.startIndex,
            endIndex: node.endIndex,
            lineNumber: getNodeLineNumber_v1_1_0(node, html)
        };
    } else if (isTextNode(node)) {
        return {
            text: node.data,
            node: 'text',
            startIndex: node.startIndex,
            endIndex: node.endIndex,
            lineNumber: getNodeLineNumber_v1_1_0(node, html)
        }
    }
}



export function cheerioToWimlNode_v1_1_0(html: string): WimlNode_v1_1_0 {
    const rootNode = convertHtmlToCheerio_v1_1_0(html);
    return convertCheerioToWimlNode_v1_1_0(rootNode, html);
}



export function convertHtmlToCheerio_v1_1_0(html: string) {
    const $ = cheerio.load(html, { recognizeSelfClosing: false, xmlMode: true, withStartIndices: true, withEndIndices: true, lowerCaseTags: false });
    const rootNode = $.root()[0] as cheerio.TagElement;
    return rootNode;
}

export function __convertHtmlToCheerio_v1_1_0(html: string) {
    const $ = cheerio.load(html, { xmlMode: true, withStartIndices: true, withEndIndices: true, lowerCaseTags: false, decodeEntities: false });
    const rootNode = $;
    return rootNode;
}

export function convertWimlToNodes_v1_1_0(wimlString: string, validVersions: string[]): WimlRootNode_v1_1_0 {
    const wimlJsonRootNode = wimlJsonRootNodePipeline(validVersions)(wimlString);
    const value = handleResult(wimlJsonRootNode);

    const rootNode = {
        ...value,
        version: 'v1.1.0'
    } as WimlRootNode_v1_1_0;

    return rootNode;
}



const wimlJsonRootNodePipeline = (validVersions: string[]) => flow(
    _convertHtmlStringToJson,
    _cleanseJsonNodes,
    validateWiml(validVersions, `wiml must be version (${validVersions.join(', ')}).`),
);

import _ from 'lodash';
import { WimlRootNode_v1 } from '../../types';






type GenericElement = Omit<cheerio.Element, 'type'> & {
    type: string;
};
type RootElement = GenericElement & {
    type: "root";
    children: cheerio.Element[];
}

function isRootNode(node: GenericElement): node is RootElement {
    return node.type === 'root';
}

function isTagNode(node: GenericElement): node is cheerio.TagElement {
    return node.type === 'tag';

}

function isTextNode(node: GenericElement): node is cheerio.TextElement {
    return node.type === 'text';
}
export function getNodeLineNumber_v1_1_0(node: GenericElement, html: string): number {
    // https://stackoverflow.com/questions/46315336/how-to-get-node-line-number-in-cheerio-js
    const start = node.startIndex;
    const lineNumber = html.slice(0, start).split('\n').length;
    return lineNumber;
}

