import { useForm } from "react-hook-form";
import { CustomInput } from 'reactstrap';
import { Form, FormGroup, Label, Input, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from "react-redux";
import { updateCustomPageAbout, updateCustomPageAssets, updateCustomPageMeta, updateCustomPageSchedule, updateCustomPageNavigation, removeProductFromCustomPage, addProductToCustomPage, removeGalleryFromCustomPage, addGalleryToCustomPage } from '@wip/common/event-store/website';
import { ReactstrapInputBlurChangeDetector, RichTextEditor } from '../../form/input';
import * as dateUtils from '@wip/common/lib/date-utils';
import { saveFile } from '@wip/common/event-store/website';
import { useMemo } from "react";


const CustomPageDetail = (props) => {
    const dispatch = useDispatch();

    function uploadImage(file) {
        const workPromise = dispatch(saveFile(file)).then(action => action.payload);

        return workPromise;
    }

    const richTextConfig = useMemo(() => {
        const retVal = { onImageUpload: uploadImage };

        return retVal;
    }, [dispatch]);

    const publishDate = props.customPage.schedule.publishDate;
    const publishDateFormat = publishDate ? dateUtils.dateYYYYMMDD(new Date(publishDate)) : null;
    const schedule = { ...props.customPage.schedule, publishDate: publishDateFormat };
    const abc = { ...props.customPage, schedule };
    const { register, formState: { errors } } = useForm({
        mode: 'onBlur',
        defaultValues: abc
    });

    const { ref: titleRef, ...titleRest } = register('about.title', { required: true });
    const { ref: pageOrderRef, ...pageOrderRest } = register('meta.order');

    // todo - this is duplicated throughout the app -- combine.
    const handleChangeEvent = (event, id, key, value) => {

        // for some reason isValid is always true even when not…
        if (errors && Object.keys(errors).length) {
            console.log('Skipping due to errors:', errors);
        } else {
            if (event) {
                id = event.target.dataset.id;
                key = event.target.name;

                if (event.target.type == 'checkbox') {
                    value = event.target.checked;

                } else {
                    value = event.target.value;
                }
            }

            if (!id) throw new Error('Handle change event `id` is missing.')
            if (!key) throw new Error('Handle change event `key` is missing.')
            if (value == undefined) throw new Error('Handle change event `value` must be set to a value or null.')

            const splitKeys = key.split('.');
            const path = splitKeys[0];
            const newKey = splitKeys[1];

            if (path == 'about') {
                dispatch(updateCustomPageAbout({ id, key: newKey, value }));
            } else if (path == 'meta') {
                dispatch(updateCustomPageMeta({ id, key: newKey, value }));
            } else if (path == 'schedule') {
                dispatch(updateCustomPageSchedule({ id, key: newKey, value }));
            } else if (path == 'navigation') {
                dispatch(updateCustomPageNavigation({ id, key: newKey, value }));
            } else if (path == 'products') {
                if (value) {
                    dispatch(addProductToCustomPage({ id, productId: newKey }));
                } else {
                    dispatch(removeProductFromCustomPage({ id, productId: newKey }));
                }
            }
        }
    };


    const handleCheckChangeEvent = (event, id, key, value) => {
        // for some reason isValid is always true even when not…
        if (errors && Object.keys(errors).length) {
            console.log('Skipping due to errors:', errors);
        } else {

            if (event) {
                id = event.target.id;
                key = event.target.name;

                value = event.target.checked;
            }

            if (!id) throw new Error('Handle change event `id` is missing.')
            if (!key) throw new Error('Handle change event `key` is missing.')
            if (value == undefined) throw new Error('Handle change event `value` must be set to a value or null.')

            const splitKeys = key.split('.');
            const path = splitKeys[0];
            const newKey = splitKeys[1];

            if (path == 'products') {
                if (value) {
                    dispatch(addProductToCustomPage({ id: props.customPage.id, productId: newKey }));
                } else {
                    dispatch(removeProductFromCustomPage({ id: props.customPage.id, productId: newKey }));
                }
            } else if (path == 'galleries') {
                if (value) {
                    dispatch(addGalleryToCustomPage({ id: props.customPage.id, galleryId: newKey }));
                } else {
                    dispatch(removeGalleryFromCustomPage({ id: props.customPage.id, galleryId: newKey }));
                }
            }
        }
    };

    const handlePublishDateChangeEvent = async (e) => {
        publishDateRest.onChange && await publishDateRest.onChange(e);
        handleChangeEvent(e);
    };

    const onCoverPhotoChange = e => {
        const id = e.target.dataset.id;
        const coverPhotoFile = e.target.files[0];
        // dispatch async thunks are promises
        // https://redux-toolkit.js.org/api/createAsyncThunk#unwrapping-result-actions
        dispatch(saveFile(coverPhotoFile)).then(action => {
            const downloadUrl = action.payload;

            dispatch(updateCustomPageAssets({ id, key: 'coverPhoto', value: downloadUrl }));
            e.target.value = "";
        });
    };

    // todo - this is duplicated throughout the app -- combine.
    const handleRichEditorChangeEvent = (id, key, value) => {
        // for some reason isValid is always true even when not…
        if (errors && Object.keys(errors).length) {
            console.log('Skipping due to errors:', errors);
        } else {
            if (!id) throw new Error('Handle change event `id` is missing.')
            if (!key) throw new Error('Handle change event `key` is missing.')
            if (value == undefined) throw new Error('Handle change event `value` must be set to a value or null.')

            const splitKeys = key.split('.');
            const path = splitKeys[0];
            const newKey = splitKeys[1];

            if (path == 'about') {
                dispatch(updateCustomPageAbout({ id, key: newKey, value }));
            }
        }
    };

    const galleryOptions = props.websiteData.galleries.map(g => {
        const galleryIsChecked = !!props.customPage.galleries.find(cpGallery => cpGallery == g.id);
        // .bind(this, null, props.customPage.id, `galleries.${g.id}`, galleryIsChecked)
        return (
            <CustomInput defaultChecked={galleryIsChecked} type="checkbox" key={g.id} id={g.id} label={g.about.title} name={`galleries.${g.id}`} onChange={handleCheckChangeEvent} />
        );
    });

    const productOptions = props.websiteData.products.map(p =>
        (<CustomInput defaultChecked={props.customPage.products.find(cpProduct => cpProduct == p.id)} type="checkbox" label={p.about.title} key={p.id} id={p.id} name={`products.${p.id}`} onChange={handleCheckChangeEvent} />)
    );

    const onCoverPhotoRemove = e => {
        const id = e.target.dataset.id;
        dispatch(updateCustomPageAssets({ id, key: 'coverPhoto', value: null }));
    };

    const bookViewElelement = (
        <Form>
            <FormGroup check>
                <Label check>
                    <Input type="checkbox" defaultChecked={props.customPage.schedule.draft} name="schedule.draft" id="schedule.draft" data-id={props.customPage.id} onChange={handleChangeEvent} /> {' '}
                    Draft?
                </Label>
            </FormGroup>
            <FormGroup check>
                <Label check>
                    <Input type="checkbox" defaultChecked={props.customPage.navigation.displayInNav} name="navigation.displayInNav" data-id={props.customPage.id} onChange={handleChangeEvent} /> {' '}
                    Display in Nav Menu?
                </Label>
            </FormGroup>
            <FormGroup>
                <Label for="about.title">Page Name</Label>
                <ReactstrapInputBlurChangeDetector id="about.title" type="text" data-id={props.customPage.id} {...titleRest} innerRef={titleRef} onDiff={handleChangeEvent} />
            </FormGroup>
            <FormGroup>
                <Label for="meta.order">Page Order</Label>
                <ReactstrapInputBlurChangeDetector id="meta.order" type="number" data-id={props.customPage.id} {...pageOrderRest} innerRef={pageOrderRef} onDiff={handleChangeEvent} />
            </FormGroup>
            <FormGroup>
                <Label for="assets.coverPhoto">Cover Photo</Label>
                <Input id="assets.coverPhoto" type="file" accept="image/*" data-id={props.customPage.id} onChange={onCoverPhotoChange} />
                {
                    props.customPage.assets.coverPhoto &&
                    <Button outline color="primary" data-key='profileImage' data-id={props.customPage.id} onClick={onCoverPhotoRemove}>
                        <FontAwesomeIcon icon={faTrash} /> Remove
                    </Button>
                }
                <img src={props.customPage.assets.coverPhoto} className="img-fluid shadow mt-4" />
            </FormGroup>
            <FormGroup>
                <Label for="reviews.content">Page Content</Label>
                <RichTextEditor defaultValue={props.customPage.about.content} onDiff={handleRichEditorChangeEvent.bind(null, props.customPage.id, 'about.content')} {...richTextConfig} />
            </FormGroup>
            {
                // !! is required - here's why: https://stackoverflow.com/questions/53048037/react-showing-0-instead-of-nothing-with-short-circuit-conditional-component
                !!props.websiteData.galleries.length &&
                <FormGroup>
                    <Label for="exampleCheckbox">Galleries to Display on Page</Label>
                    <div>
                        {galleryOptions}
                    </div>
                </FormGroup>
            }
            {
                props.websiteData.ecommerce.accountActive &&
                <FormGroup>
                    <Label for="exampleCheckbox">Products to Display on Page</Label>
                    <div>
                        {productOptions}
                    </div>
                </FormGroup>
            }
        </Form>);

    return bookViewElelement;
}

export default CustomPageDetail;
