import cheerio from 'cheerio';
import { convertStringToSemVer } from '../../../../lib/text-utils';
import { WimlVer } from './types';
import { wimlComponents_v1_0_0 } from './v1.0.0/theme-data/renderer/wiml-components-v1.0.0';
import { wimlComponents_v1_1_0 } from './v1.1.0/theme-data/renderer/wiml-components-v1.1.0';
import { wimlComponents_v1_2_0 } from './v1.2.0/theme-data/renderer/wiml-components-v1.2.0';
import { wimlComponents_v1_3_0 } from './v1.3.0/theme-data/renderer/wiml-components-v1.3.0';
import { wimlComponents_v1_4_0 } from './v1.4.0/theme-data/renderer/wiml-components-v1.4.0';
import { RuntimeWimlComponentCollection_v1_0_0 } from './v1.0.0/theme-data/renderer/types';

export function getWimlVersion_v1(wimlString: string) {
    const $ = cheerio.load(wimlString, { xmlMode: true });
    const root = $('WIML');
    const version = root.attr('version');

    // todo validate only one root node
    const semVer = convertStringToSemVerString_v1(version);

    return semVer;
}

export function convertStringToSemVerString_v1(version: string): WimlVer {
    // handle default or empty minor and patch
    const {
        major, minor, patch,
    } = convertStringToSemVer(version);

    return `${major}.${minor}.${patch}` as WimlVer;
}

export function getComponentLookup_v1(wimlVersion: string, componentType: string) {
    return wimlComponentVersionMetaLookup_v1[wimlVersion][componentType];
}

export const wimlComponentVersionMetaLookup_v1 = {
    "1.0.0": wimlComponents_v1_0_0,
    "1.1.0": wimlComponents_v1_1_0,
    "1.2.0": wimlComponents_v1_2_0,
    "1.3.0": wimlComponents_v1_3_0,
    "1.4.0": wimlComponents_v1_4_0,
} as Record<string, RuntimeWimlComponentCollection_v1_0_0>;

