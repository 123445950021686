import { RuntimeWimlComponentProps_v1 } from "app/wiml/versions/v1/types";
import React from "react";
import { getComponentClassName_v1_2_0 } from "../../compiler/components";
import { V1_2_0_ComponentWrapper } from "../utils/component-wrapper";
import { getInferredComponentDefaultType_v1_0_0 } from "../../../../v1.0.0/theme-data/compiler/components";
import { _getHrClassNames_v1_2_0 } from "../styles/hr";
// unit in pixels, not rem

const COMPONENT_TYPE = getInferredComponentDefaultType_v1_0_0('Hr');

export function Hr_v1_2_0(props: RuntimeWimlComponentProps_v1) {
    const hrClassName = _getHrClassNames_v1_2_0(props);
    const className = getComponentClassName_v1_2_0(props, hrClassName);
    const retVal = (
        <div className={className}>
            <V1_2_0_ComponentWrapper {...props}>
                <hr />
            </V1_2_0_ComponentWrapper>
        </div >
    );

    return retVal;
}

