import humps from 'humps';
import React from 'react';
import { ThemeDataReactComponentOptions_v1_1_0, ComponentThemeData_v1_1_0, WimlNode_v1_1_0 } from '../compiler/types';
import { RuntimeWimlComponentProps_v1 } from "../../../types";
import { _isPageDefinitionNode_v1_0_0, _isPageNode_v1_0_0, getNodeKey_v1_0_0 } from '../../../v1.0.0/theme-data/compiler/nodes';
import { convertPropsToReactFormat_v1_0_0 } from '../../../v1.0.0/theme-data/renderer/render-components';

//todo find way to not copy so much
export function convertThemeDataToReact_v1_1_0(themeData: ComponentThemeData_v1_1_0) {
    const wimlRoot = themeData.rootNode;
    const layoutNode = wimlRoot.child.find((node) => node.tag == 'Layout');

    const retVal = wimlRoot.child
        .filter((node) => node != layoutNode)
        .reduce((accum, node) => {
            accum[humps.camelize(getNodeKey_v1_0_0(node))] = convertJsonNodeToReact_v1_1_0(node, { layout: layoutNode });
            return accum;
        }, {} as Record<string, React.ReactNode>);

    return retVal;
}


// DFS iterative https://medium.com/@jpoechill/iterative-bfs-and-dfs-in-javascript-
// https://blog.bitsrc.io/depth-first-search-of-a-binary-tree-in-javascript-874701d8210a
export function convertJsonNodeToReact_v1_1_0(node: WimlNode_v1_1_0, options: ThemeDataReactComponentOptions_v1_1_0): React.ReactNode {
    let retVal = null;

    if (node.node == 'text') {
        retVal = node.text;
    } else {
        const { layout, ...optionsRest } = options;

        const isPageNode = _isPageNode_v1_0_0(node);
        if (isPageNode) {
            optionsRest.pageOccurrenceId = getNodeKey_v1_0_0(node);
        }

        if (_isPageDefinitionNode_v1_0_0(node)) {
            // if top level page component, set the pageDefinitionId
            // if page node or header/footer
            // set optoiins
            optionsRest.pageDefinitionId = getNodeKey_v1_0_0(node);
        } else {
            if (optionsRest.pageDefinitionId) {
                node.attr = { ...node.attr, pageDefinitionId: optionsRest.pageDefinitionId };
            }

            if (optionsRest.pageOccurrenceId) {
                node.attr = { ...node.attr, pageOccurrenceId: optionsRest.pageOccurrenceId };
            }
        }

        if (layout) {
            // replace <CurrentPage /> child with node
            const layoutChildren = layout.child.map((childNode) => (childNode.tag == 'CurrentPage' ? node : childNode));
            node = { ...layout, child: layoutChildren };
        }

        // do not use this - because the types are minified at build time so they are not the same as the string
        // e.g. List -> At (just a random example)
        // e.g. Header -> Li (just a random example)
        // const component = _getReactComponent(node);
        const component = node.tag;

        const children = node.child;

        // strip key from node.attr
        const { key, ...rest } = node.attr || {};
        const restHumps = humps.camelizeKeys(rest) as RuntimeWimlComponentProps_v1;
        // convert any props that need to be in react format e.g. class -> className
        const reactProps = convertPropsToReactFormat_v1_0_0(restHumps);
        const componentProps = {
            ...reactProps,
            ...(key && { componentKey: key }),
            meta: {
                startIndex: node.startIndex,
                endIndex: node.endIndex,
                // note lineNumber was going to be introduced in v1.2 but because startIndex is in 1.1, i added lineNumber in v1.1. But current 1.1 sites would need to be re-compiled to get the lineNumber
                lineNumber: node.lineNumber,
            }
        };
        if (children) {
            retVal = React.createElement(component, componentProps, ...children.map((c) => convertJsonNodeToReact_v1_1_0(c, optionsRest)));
        } else {
            retVal = React.createElement(component, componentProps);
        }
    }

    return retVal;
}
