import React from 'react';

import { getInferredComponentDefaultType_v1_0_0 } from '../../compiler/components';
import { renderChildComponentSiteDataPropertyValue_v1_0_0 } from "../render-components";
import { RuntimeWimlComponentProps_v1 } from "app/wiml/versions/v1/types";




// todo remove this - ensure no one uses it.
export function Button_v1_0_0(props: RuntimeWimlComponentProps_v1) {
    let retVal = null;
    const content = renderChildComponentSiteDataPropertyValue_v1_0_0(props, 'content', getInferredComponentDefaultType_v1_0_0('Button')) as string;

    if (content) {
        const element = <div className="btn" dangerouslySetInnerHTML={{ __html: content }} />;
        retVal = element;
    }
    return retVal;
}
Button_v1_0_0.inputProps = {
    content: {
        type: 'medium_text',
        label: 'Content',
        description: 'The content of the button',
    },
};
