import React from 'react';

import { getInferredComponentDefaultType_v1_0_0, } from '../../../../v1.0.0/theme-data/compiler/components';
import { renderChildComponentSiteDataPropertyValue_v1_0_0 } from "../../../../v1.0.0/theme-data/renderer/render-components";
// todo any dependenccies should be pulled into this folder so as to never cause breakings changes 
import * as textUtils from '../../../../../../../../lib/text-utils';
import InnerHTML from 'dangerously-set-html-content';
import { RuntimeWimlComponentProps_v1 } from "app/wiml/versions/v1/types";
import { Text_v1_0_0 } from '../../../../v1.0.0/theme-data/renderer/components/text';
import { getComponentClassName_v1_1_0 } from '../../compiler/components';


export function Text_v1_1_0(props: RuntimeWimlComponentProps_v1) {
    let retVal = null;
    const className = getComponentClassName_v1_1_0(props);

    let content = renderChildComponentSiteDataPropertyValue_v1_0_0(props, 'content', getInferredComponentDefaultType_v1_0_0('Text')) as string;

    if (content) {
        if (props.limit) {
            content = textUtils.truncate_v2(content, props.limit);
        }

        if (/<script/mig.test(content)) {
            // todo consider using useEffect onload change state and render InnerHtml if need be, this way it's still rendered on server but then client side it cna change and render, giving good seo.
            retVal = <div className={className}><InnerHTML html={content} /></div>;
        } else {
            retVal = <div className={className} dangerouslySetInnerHTML={{ __html: content }} />;
        }
    }
    return retVal;
}
Text_v1_1_0.inputProps = Text_v1_0_0.inputProps;
