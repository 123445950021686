import {
    faDiscord,
    faFacebook,
    faInstagram,
    faLinkedin,
    faPinterest,
    faTiktok,
    faTumblr,
    faTwitter,
    faYoutube,
    faGoodreads,
    IconName,
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import { getInferredComponentDefaultType_v1_0_0 } from '../../compiler/components';
import { renderChildComponentSiteDataPropertyValue_v1_0_0 } from "../render-components";
import { RuntimeWimlComponentProps_v1 } from "app/wiml/versions/v1/types";
import { faClapper } from '../../../../../../../../lib/icons/fa-clapper';
import { faReam } from '../../../../../../../../lib/icons/fa-ream';
import { faBookbub } from '../../../../../../../../lib/icons/fa-bookbub';
import { faThreads } from '../../../../../../../../lib/icons/fa-threads';
import { faScbwi } from '../../../../../../../../lib/icons/fa-scbwi';

export const iconOptions_v1_0_0 = [
    { key: 'facebook', value: 'Facebook', icon: faFacebook },
    { key: 'twitter', value: 'Twitter', icon: faTwitter },
    { key: 'instagram', value: 'Instagram', icon: faInstagram },
    { key: 'linkedin', value: 'LinkedIn', icon: faLinkedin },
    { key: 'youtube', value: 'YouTube', icon: faYoutube },
    { key: 'pinterest', value: 'Pinterest', icon: faPinterest },
    { key: 'tiktok', value: 'TikTok', icon: faTiktok },
    { key: 'discord', value: 'Discord', icon: faDiscord },
    { key: 'tumblr', value: 'Tumblr', icon: faTumblr },
    { key: 'clapper', value: 'Clapper', icon: faClapper },
    { key: 'ream', value: 'Ream', icon: faReam },
    { key: 'Goodreads', value: 'Goodreads', icon: faGoodreads },
    { key: 'Threads', value: 'Threads', icon: faThreads },
    { key: 'Scbwi', value: 'Scbwi', icon: faScbwi },
    { key: 'bookbub', value: 'BookBub', icon: faBookbub },
].sort((a, b) => a.value.localeCompare(b.value));

export function Icon_v1_0_0(props: RuntimeWimlComponentProps_v1) {
    let retVal = null;

    const iconType = renderChildComponentSiteDataPropertyValue_v1_0_0(props, 'type', getInferredComponentDefaultType_v1_0_0('Icon'));
    if (iconType) {
        const icon = iconOptions_v1_0_0.find((option) => option.key === iconType);

        retVal = <FontAwesomeIcon icon={icon.icon as any as IconName} />;
    }

    return retVal;
}
Icon_v1_0_0.inputProps = {
    type: {
        type: 'select',
        label: 'Icon',
        description: 'The type of the icon',
        options: iconOptions_v1_0_0,
    },
};
