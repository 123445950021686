
import { getListItemSiteData_v1_0_0 } from '../../v1.0.0/site-data/retrieval';
import { IWiml_v1 } from '../../types';
import { JSONValue } from '../../../../../../lib/object-types';

export function getChildComponentPropertyValueSiteData_v1_2_0<T extends JSONValue = string>(wimlData: IWiml_v1, componentId: string, propertyName: string, pageId: string, listId: string, listItemId: string) {
    const propertyValue = listId
        ? getListChildComponentPropertyValueSiteData_v1_2_0(wimlData, componentId, propertyName, listId, listItemId)
        : getPageChildComponentPropertyValueSiteData_v1_2_0(wimlData, componentId, propertyName, pageId);
    return propertyValue as T;
}

// todo - wiml should be unaware of website
export function getListChildComponentPropertyValueSiteData_v1_2_0(wimlData: IWiml_v1, componentId: string, propertyName: string, listId: string, listItemId: string) {
    if (!listId || !listItemId) throw new Error('listId and listItemId are required for getListChildComponentPropertyValueSiteData_v1_2_0');
    const listItemData = getListItemSiteData_v1_0_0(wimlData, listId, listItemId);
    const propertyValue = listItemData?.components?.items?.[componentId]?.[propertyName]?.data?.value;

    return propertyValue;
}

export function getPageChildComponentPropertyValueSiteData_v1_2_0(wimlData: IWiml_v1, componentId: string, propertyName: string, pageId: string) {
    if (!pageId) throw new Error('pageDefinitionId is required for getPageComponentPropertyValue: ' + propertyName);

    const propertyValue = wimlData.siteData?.components.items?.pages?.items?.[pageId]?.components?.items?.[componentId]?.[propertyName]?.data?.value;

    return propertyValue;
}


