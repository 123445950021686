import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { CustomInput } from 'reactstrap';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from "react-redux";
import { ReactstrapInputBlurChangeDetector, RichTextEditor } from '../../form/input';
import { saveFile, updateBookAbout, updateBookPurchase, updateBookAwards, updateBookAssets, updateBookMeta, updateBookReviews, updateBookSchedule, updateBook__Series, addProductToBook, removeProductFromBook } from '@wip/common/event-store/website';
import * as dateUtils from '@wip/common/lib/date-utils';

const BookDetail = (props) => {
    const dispatch = useDispatch();

    function uploadImage(file) {
        const workPromise = dispatch(saveFile(file)).then(action => action.payload);

        return workPromise;
    }

    const richTextConfig = useMemo(() => {
        const retVal = { onImageUpload: uploadImage };

        return retVal;
    }, [dispatch]);

    const publishDate = props.book.schedule.publishDate;
    const publishDateFormat = publishDate ? dateUtils.dateYYYYMMDD(new Date(publishDate)) : null;
    const schedule = { ...props.book.schedule, publishDate: publishDateFormat };
    const abc = { ...props.book, schedule };
    const { register, formState: { errors } } = useForm({
        mode: 'onBlur',
        defaultValues: abc
    });

    const { ref: titleRef, ...titleRest } = register('about.title', { required: true });
    const { ref: categoriesRef, ...categoriesRest } = register('about.categories');
    const { ref: seriesRef, onChange: bookSeriesIdChange, ...seriesRest } = register('series.id');
    const { ref: seriesOrderRef, ...seriesOrderRest } = register('series.order', /*todo this does not work*/{ valueAsNumber: true });
    const { ref: subheadRef, ...subheadRest } = register('about.subheading');
    const { ref: publishDateRef, ...publishDateRest } = register('schedule.publishDate');
    const { ref: amazonLinkRef, ...amazonLinkRest } = register('purchase.amazonLink');
    const { ref: barnesandnobleLinkRef, ...barnesandnobleLinkRest } = register('purchase.barnesandnobleLink');
    const { ref: bookshopLinkRef, ...bookshopLinkRest } = register('purchase.bookshopLink');
    const { ref: indieboundLinkRef, ...indieboundLinkRest } = register('purchase.indieboundLink');
    const { ref: custombookstore1NameRef, ...custombookstore1NameRest } = register('purchase.custombookstore1Name');
    const { ref: custombookstore1LinkRef, ...custombookstore1LinkRest } = register('purchase.custombookstore1Link');
    const { ref: custombookstore2NameRef, ...custombookstore2NameRest } = register('purchase.custombookstore2Name');
    const { ref: custombookstore2LinkRef, ...custombookstore2LinkRest } = register('purchase.custombookstore2Link');
    const { ref: custombookstore3NameRef, ...custombookstore3NameRest } = register('purchase.custombookstore3Name');
    const { ref: custombookstore3LinkRef, ...custombookstore3LinkRest } = register('purchase.custombookstore3Link');
    const { ref: custombookstore4NameRef, ...custombookstore4NameRest } = register('purchase.custombookstore4Name');
    const { ref: custombookstore4LinkRef, ...custombookstore4LinkRest } = register('purchase.custombookstore4Link');
    const { ref: custombookstore5NameRef, ...custombookstore5NameRest } = register('purchase.custombookstore5Name');
    const { ref: custombookstore5LinkRef, ...custombookstore5LinkRest } = register('purchase.custombookstore5Link');
    const { ref: custombookstore6NameRef, ...custombookstore6NameRest } = register('purchase.custombookstore6Name');
    const { ref: custombookstore6LinkRef, ...custombookstore6LinkRest } = register('purchase.custombookstore6Link');

    const { ref: awardsRef, ...awardsRest } = register('awards.content');

    // todo - this is duplicated throughout the app -- combine.
    const handleChangeEvent = (event, id, key, value) => {

        // for some reason isValid is always true even when not…
        if (errors && Object.keys(errors).length) {
            console.log('Skipping due to errors:', errors);
        } else {
            if (event) {
                id = event.target.dataset.id;
                key = event.target.name;

                if (event.target.type == 'checkbox') {
                    value = event.target.checked;

                } else {
                    value = event.target.value;
                }
            }

            if (!id) throw new Error('Handle change event `id` is missing.')
            if (!key) throw new Error('Handle change event `key` is missing.')
            if (value == undefined) throw new Error('Handle change event `value` must be set to a value or null.')

            const splitKeys = key.split('.');
            const path = splitKeys[0];
            const newKey = splitKeys[1];

            if (path == 'about') {
                dispatch(updateBookAbout({ id, key: newKey, value }));
            } else if (path == 'purchase') {
                dispatch(updateBookPurchase({ id, key: newKey, value }));
            } else if (path == 'awards') {
                dispatch(updateBookAwards({ id, key: newKey, value }));
            } else if (path == 'meta') {
                dispatch(updateBookMeta({ id, key: newKey, value }));
            } else if (path == 'schedule') {
                dispatch(updateBookSchedule({ id, key: newKey, value }));
            } else if (path == 'series') {
                dispatch(updateBook__Series({ id, key: newKey, value }));
            } else if (path == 'products') {
                if (value) {
                    dispatch(addProductToBook({ id, productId: newKey }));
                } else {
                    dispatch(removeProductFromBook({ id, productId: newKey }));
                }
            }
        }
    };

    const handleCheckChangeEvent = (event, id, key, value) => {
        // // for some reason isValid is always true even when not…
        // if (errors && Object.keys(errors).length) {
        //     console.log('Skipping due to errors:', errors);
        // } else {
        //     if (event) {
        //         key = event.target.name;
        //         value = event.target.value;
        //     }

        //     if (!key) throw new Error('Handle change event `key` is missing.')
        //     if (value == undefined) throw new Error('Handle change event `value` must be set to a value or null.')


        //     const splitKeys = key.split('.');
        //     const path = splitKeys[0];
        //     const newKey = splitKeys[1];

        //     if (path == 'about') {
        //         dispatch(addGalleryToAbout({ key: newKey, value }));
        //     } else if (path == 'social') {
        //         dispatch(updateSocial({ key: newKey, value }));
        //     }
        // }

        // for some reason isValid is always true even when not…
        if (errors && Object.keys(errors).length) {
            console.log('Skipping due to errors:', errors);
        } else {

            if (event) {
                key = event.target.name;
                value = event.target.checked;
            }

            if (!key) throw new Error('Handle change event `key` is missing.')
            if (value == undefined) throw new Error('Handle change event `value` must be set to a value or null.')

            const splitKeys = key.split('.');
            const path = splitKeys[0];
            const newKey = splitKeys[1];
            if (path == 'products') {
                if (value) {
                    dispatch(addProductToBook({ id: props.book.id, productId: newKey }));
                } else {
                    dispatch(removeProductFromBook({ id: props.book.id, productId: newKey }));
                }
            }
        }
    };

    const handlePublishDateChangeEvent = async (e) => {
        // todo use onChange in regsiter handler - react hook form new version has been added to project but this needs to be fixed.
        // note onChange is handled in react-hook-form 7.18
        publishDateRest.onChange && await publishDateRest.onChange(e);

        const id = e.target.dataset.id;
        const key = e.target.name;
        const value = dateUtils.parseDate(e.target.value);
        handleChangeEvent(null, id, key, value);
    };

    const onCoverPhotoChange = e => {
        const id = e.target.dataset.id;
        const coverPhotoFile = e.target.files[0];
        // dispatch async thunks are promises
        // https://redux-toolkit.js.org/api/createAsyncThunk#unwrapping-result-actions
        dispatch(saveFile(coverPhotoFile)).then(action => {
            const downloadUrl = action.payload;

            dispatch(updateBookAssets({ id, key: 'coverPhoto', value: downloadUrl }));
            e.target.value = "";
        });
    };

    const onCoverPhotoRemove = e => {
        const id = e.target.dataset.id;
        dispatch(updateBookAssets({ id, key: 'coverPhoto', value: null }));
    };


    // todo - this is duplicated throughout the app -- combine.
    const handleRichEditorChangeEvent = (id, key, value) => {
        // for some reason isValid is always true even when not…
        if (errors && Object.keys(errors).length) {
            console.log('Skipping due to errors:', errors);
        } else {
            if (!id) throw new Error('Handle change event `id` is missing.')
            if (!key) throw new Error('Handle change event `key` is missing.')
            if (value == undefined) throw new Error('Handle change event `value` must be set to a value or null.')

            const splitKeys = key.split('.');
            const path = splitKeys[0];
            const newKey = splitKeys[1];

            if (path == 'about') {
                dispatch(updateBookAbout({ id, key: newKey, value }));
            } else if (path == 'reviews') {
                dispatch(updateBookReviews({ id, key: newKey, value }));
            }
        }
    };

    // https://github.com/scottccoates/author-site-builder/blob/97d1f076526c424746c51c7f21a9858a5d57e2c8/apps/admin/src/components/manage/style.js#L19
    const handleSeriesIdChangeEvent = async (e) => {
        bookSeriesIdChange && await bookSeriesIdChange(e);
        handleChangeEvent(e);
    };

    const productOptions = props.websiteData.products.map(p =>
        (<CustomInput defaultChecked={props.book.products.find(bp => bp == p.id)} type="checkbox" key={p.id} id={p.id} name={`products.${p.id}`} label={p.about.title} onChange={handleCheckChangeEvent} />)
    );

    const bookSeriesIdOptions = props.websiteData.bookSeries.map(bs =>
        (<option key={bs.id} value={bs.id}>{bs.about.title}</option>)
    );

    const bookViewElelement = (
        <Form>
            <FormGroup check>
                <Label check>
                    <Input type="checkbox" defaultChecked={props.book.schedule.draft} name="schedule.draft" id="schedule.draft" data-id={props.book.id} onChange={handleChangeEvent} /> {' '}
                    Draft?
                </Label>
            </FormGroup>
            <FormGroup>
                <Label for="about.title">Title</Label>
                <ReactstrapInputBlurChangeDetector id="about.title" invalid={!!errors.firstName} type="text" data-id={props.book.id} {...titleRest} innerRef={titleRef} onDiff={handleChangeEvent} />
            </FormGroup>
            <FormGroup>
                <Label for="series.id">Series</Label>
                <Input type="select" innerRef={seriesRef} data-id={props.book.id} onChange={handleSeriesIdChangeEvent} {...seriesRest}>
                    <option value=''></option>
                    {bookSeriesIdOptions}
                </Input>
            </FormGroup>
            <FormGroup>
                <Label for="series.order">Book Order in Series (Optional. Books are ordered by release date by default.)</Label>
                <ReactstrapInputBlurChangeDetector id="series.order" type="number" data-id={props.book.id} {...seriesOrderRest} innerRef={seriesOrderRef} onDiff={handleChangeEvent} />
            </FormGroup>
            <FormGroup>
                <Label for="about.subheading">Sub Heading</Label>
                <ReactstrapInputBlurChangeDetector id="about.subheading" type="text" data-id={props.book.id} {...subheadRest} innerRef={subheadRef} onDiff={handleChangeEvent} />
            </FormGroup>
            <FormGroup>
                <Label for="schedule.publishDate">Publish Date</Label>
                <ReactstrapInputBlurChangeDetector id="schedule.publishDate" type="date" data-id={props.book.id} {...publishDateRest} innerRef={publishDateRef} onChange={handlePublishDateChangeEvent} />
            </FormGroup>
            <FormGroup>
                <Label for="purchase.amazonLink">Amazon Link</Label>
                <ReactstrapInputBlurChangeDetector id="purchase.amazonLink" type="url" data-id={props.book.id} {...amazonLinkRest} innerRef={amazonLinkRef} onDiff={handleChangeEvent} />
            </FormGroup>
            <FormGroup>
                <Label for="purchase.barnesandnobleLink">Barnes &amp; Noble Link</Label>
                <ReactstrapInputBlurChangeDetector id="purchase.barnesandnobleLink" type="url" data-id={props.book.id} {...barnesandnobleLinkRest} innerRef={barnesandnobleLinkRef} onDiff={handleChangeEvent} />
            </FormGroup>
            <FormGroup>
                <Label for="purchase.bookshopLink">Bookshop.org Link</Label>
                <ReactstrapInputBlurChangeDetector id="purchase.bookshopLink" type="url" data-id={props.book.id} {...bookshopLinkRest} innerRef={bookshopLinkRef} onDiff={handleChangeEvent} />
            </FormGroup>
            <FormGroup>
                <Label for="purchase.indieboundLink">IndieBound Link</Label>
                <ReactstrapInputBlurChangeDetector id="purchase.indieboundLink" type="url" data-id={props.book.id} {...indieboundLinkRest} innerRef={indieboundLinkRef} onDiff={handleChangeEvent} />
            </FormGroup>
            <FormGroup>
                <Label for="purchase.custombookstore1Name">Custom Book Store #1: Name</Label>
                <ReactstrapInputBlurChangeDetector id="purchase.custombookstore1Name" type="url" data-id={props.book.id} {...custombookstore1NameRest} innerRef={custombookstore1NameRef} onDiff={handleChangeEvent} />
            </FormGroup>
            <FormGroup>
                <Label for="purchase.custombookstore1Link">Custom Book Store #1: Link</Label>
                <ReactstrapInputBlurChangeDetector id="purchase.custombookstore1Link" type="url" data-id={props.book.id} {...custombookstore1LinkRest} innerRef={custombookstore1LinkRef} onDiff={handleChangeEvent} />
            </FormGroup>
            <FormGroup>
                <Label for="purchase.custombookstore2Name">Custom Book Store #2: Name</Label>
                <ReactstrapInputBlurChangeDetector id="purchase.custombookstore2Name" type="url" data-id={props.book.id} {...custombookstore2NameRest} innerRef={custombookstore2NameRef} onDiff={handleChangeEvent} />
            </FormGroup>
            <FormGroup>
                <Label for="purchase.custombookstore2Link">Custom Book Store #2: Link</Label>
                <ReactstrapInputBlurChangeDetector id="purchase.custombookstore2Link" type="url" data-id={props.book.id} {...custombookstore2LinkRest} innerRef={custombookstore2LinkRef} onDiff={handleChangeEvent} />
            </FormGroup>
            <FormGroup>
                <Label for="purchase.custombookstore3Name">Custom Book Store #3: Name</Label>
                <ReactstrapInputBlurChangeDetector id="purchase.custombookstore3Name" type="url" data-id={props.book.id} {...custombookstore3NameRest} innerRef={custombookstore3NameRef} onDiff={handleChangeEvent} />
            </FormGroup>
            <FormGroup>
                <Label for="purchase.custombookstore3Link">Custom Book Store #3: Link</Label>
                <ReactstrapInputBlurChangeDetector id="purchase.custombookstore3Link" type="url" data-id={props.book.id} {...custombookstore3LinkRest} innerRef={custombookstore3LinkRef} onDiff={handleChangeEvent} />
            </FormGroup>
            <FormGroup>
                <Label for="purchase.custombookstore4Name">Custom Book Store #4: Name</Label>
                <ReactstrapInputBlurChangeDetector id="purchase.custombookstore4Name" type="url" data-id={props.book.id} {...custombookstore4NameRest} innerRef={custombookstore4NameRef} onDiff={handleChangeEvent} />
            </FormGroup>
            <FormGroup>
                <Label for="purchase.custombookstore4Link">Custom Book Store #4: Link</Label>
                <ReactstrapInputBlurChangeDetector id="purchase.custombookstore4Link" type="url" data-id={props.book.id} {...custombookstore4LinkRest} innerRef={custombookstore4LinkRef} onDiff={handleChangeEvent} />
            </FormGroup>
            <FormGroup>
                <Label for="purchase.custombookstore3Name">Custom Book Store #5: Name</Label>
                <ReactstrapInputBlurChangeDetector id="purchase.custombookstore5Name" type="url" data-id={props.book.id} {...custombookstore5NameRest} innerRef={custombookstore5NameRef} onDiff={handleChangeEvent} />
            </FormGroup>
            <FormGroup>
                <Label for="purchase.custombookstore5Link">Custom Book Store #5: Link</Label>
                <ReactstrapInputBlurChangeDetector id="purchase.custombookstore5Link" type="url" data-id={props.book.id} {...custombookstore5LinkRest} innerRef={custombookstore5LinkRef} onDiff={handleChangeEvent} />
            </FormGroup>
            <FormGroup>
                <Label for="purchase.custombookstore6Name">Custom Book Store #6: Name</Label>
                <ReactstrapInputBlurChangeDetector id="purchase.custombookstore6Name" type="url" data-id={props.book.id} {...custombookstore6NameRest} innerRef={custombookstore6NameRef} onDiff={handleChangeEvent} />
            </FormGroup>
            <FormGroup>
                <Label for="purchase.custombookstore6Link">Custom Book Store #6: Link</Label>
                <ReactstrapInputBlurChangeDetector id="purchase.custombookstore6Link" type="url" data-id={props.book.id} {...custombookstore6LinkRest} innerRef={custombookstore6LinkRef} onDiff={handleChangeEvent} />
            </FormGroup>
            <FormGroup>
                <Label for="awards.content">Book Awards / Recognition (one per line)</Label>
                <ReactstrapInputBlurChangeDetector id="awards.content" type="textarea" data-id={props.book.id} {...awardsRest} innerRef={awardsRef} onDiff={handleChangeEvent} />
            </FormGroup>
            <FormGroup>
                <Label for="assets.coverPhoto">Cover Photo</Label>
                <Input id="assets.coverPhoto" type="file" accept="image/*" data-id={props.book.id} onChange={onCoverPhotoChange} />
                {
                    props.book.assets.coverPhoto &&
                    <Button outline color="primary" data-id={props.book.id} onClick={onCoverPhotoRemove}>
                        <FontAwesomeIcon icon={faTrash} /> Remove
                    </Button>
                }
                <img src={props.book.assets.coverPhoto} className="img-fluid shadow mt-4" />
            </FormGroup>
            <FormGroup>
                <Label for="about.description">Book Description</Label>
                <RichTextEditor defaultValue={props.book.about.description} onDiff={handleRichEditorChangeEvent.bind(null, props.book.id, 'about.description')} {...richTextConfig} />
            </FormGroup>
            <FormGroup>
                <Label for="reviews.content">Book Reviews</Label>
                <RichTextEditor defaultValue={props.book.reviews.content} onDiff={handleRichEditorChangeEvent.bind(null, props.book.id, 'reviews.content')} {...richTextConfig} />
            </FormGroup>
            <FormGroup>
                <Label for="about.moreInfo">Additional Information</Label>
                <RichTextEditor defaultValue={props.book.about.moreInfo} onDiff={handleRichEditorChangeEvent.bind(null, props.book.id, 'about.moreInfo')} {...richTextConfig} />
            </FormGroup>
            {
                props.websiteData.ecommerce.accountActive &&
                <FormGroup>
                    <Label for="exampleCheckbox">Products to Display on Page</Label>
                    <div>
                        {productOptions}
                    </div>
                </FormGroup>
            }
            <FormGroup>
                <Label for="about.categories">Book Categories (one per line)</Label>
                <ReactstrapInputBlurChangeDetector id="about.categories" type="textarea" data-id={props.book.id} {...categoriesRest} innerRef={categoriesRef} onDiff={handleChangeEvent} />
            </FormGroup>
        </Form>);

    return bookViewElelement;
}

export default BookDetail;
