import React from 'react';

import { getInferredComponentDefaultType_v1_0_0, } from '../../../../v1.0.0/theme-data/compiler/components';
// todo any dependenccies should be pulled into this folder so as to never cause breakings changes 
import * as textUtils from '../../../../../../../../lib/text-utils';
import InnerHTML from 'dangerously-set-html-content';
import { RuntimeWimlComponentProps_v1 } from "app/wiml/versions/v1/types";
import { getComponentClassName_v1_2_0 } from '../../compiler/components';
import { Text_v1_1_0 } from '../../../../v1.1.0/theme-data/renderer/components/text';
import { V1_2_0_ComponentWrapper } from '../utils/component-wrapper';
import { renderChildComponentSiteDataPropertyValue_v1_2_0 } from "../render-components";

const COMPONENT_TYPE = getInferredComponentDefaultType_v1_0_0('Text');

export function Text_v1_2_0(props: RuntimeWimlComponentProps_v1) {
    const [hasInnerHtml, setHasInnerHtml] = React.useState(false);

    let retVal = null;
    const className = getComponentClassName_v1_2_0(props, 'wiml-text');

    let content = renderChildComponentSiteDataPropertyValue_v1_2_0(props, 'content') || props.children;

    // useEffect onload change state and render InnerHtml if need be, this way it's still rendered on server but then client side it cna change and render, giving good seo.
    React.useEffect(() => {
        if (/<script/mig.test(content)) {
            setHasInnerHtml(true);
        }
    }, [content]);

    if (content) {
        if (props.limit) {
            content = textUtils.truncate_v2(content, props.limit);
        }

        let innerContent = null;

        if (hasInnerHtml) {
            innerContent = <InnerHTML html={content} />;
        } else {
            innerContent = <div dangerouslySetInnerHTML={{ __html: content }} />;
        }

        // .wiml-text p:last-child:not(:only-child) {
        //     margin-bottom: initial;
        // }
        retVal =
            <div className={className}>
                <V1_2_0_ComponentWrapper {...props}>
                    {innerContent}
                </V1_2_0_ComponentWrapper>
                <style jsx global>{`
                `}</style>
            </div>;
    }

    return retVal;
}

Text_v1_2_0.inputProps = Text_v1_1_0.inputProps;
