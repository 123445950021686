import { serialize } from "../../../../lib/react-serializer";
import { getWimlVersion_v1 } from "./helpers";
import { IMarkupToReactConverter_v1, ThemeDataToReactConverterClass_v1, ThemeData_v1, WimlVer } from "./types";
import { ConvertThemeDataToReact_v1_0_0 } from "./v1.0.0/theme-data";
import { ConvertThemeDataToReact_v1_1_0 } from "./v1.1.0/theme-data";
import { ConvertThemeDataToReact_v1_2_0 } from "./v1.2.0/theme-data";
import { ConvertThemeDataToReact_v1_3_0 } from "./v1.3.0/theme-data";
import { ConvertThemeDataToReact_v1_4_0 } from "./v1.4.0/theme-data";

// todo move each class to own folder. make this file more of a factory
// then, do the same with the wiml classes - put each ver in own folder (probably in the index.ts file).
// reduce circular refs 
// run npx madge --circular --extensions ts,tsx,js,jsx .
// in the root dir

export class MarkupToReactConverter_v1 implements IMarkupToReactConverter_v1 {
    converterClassLookup_v1: Record<WimlVer, ThemeDataToReactConverterClass_v1> = {
        "1.0.0": ConvertThemeDataToReact_v1_0_0,
        "1.1.0": ConvertThemeDataToReact_v1_1_0,
        "1.2.0": ConvertThemeDataToReact_v1_2_0,
        "1.3.0": ConvertThemeDataToReact_v1_3_0,
        "1.4.0": ConvertThemeDataToReact_v1_4_0,
    };

    convertMarkupToThemeData_v1(wimlString: string): ThemeData_v1 {
        const version = getWimlVersion_v1(wimlString);
        const versionSpecificConverterType = this.converterClassLookup_v1[version];
        const versionSpecificConverter = new versionSpecificConverterType();
        // todo return type should be component theme data
        const componentThemeData = versionSpecificConverter.convertMarkupToComponentThemeData_v1(wimlString);
        // todo return type should be react/render theme data
        const renderThemeData = versionSpecificConverter.convertComponentThemeDataToRenderThemeData_v1(componentThemeData);


        const retVal = new ThemeData_v1();
        retVal.markup = wimlString;

        const pages = componentThemeData.pages;
        retVal.components.items.pages = pages;

        const lists = componentThemeData.lists;
        retVal.components.items.lists = lists;

        const rootNodeJson = JSON.stringify(componentThemeData.rootNode); // stringify so we can save in firebase as a string
        retVal.components.items.rootNodeJson = rootNodeJson;

        retVal.components.ids = Object.keys(retVal.components.items);

        const payload = Object.entries(renderThemeData).reduce((accum, [k, v]) => {
            // todo pass in list of types so we do not rely on build-time types to have consistent name. E.g. <Page/> becomes <It/> in the build
            // _reactLookupComponentsByType use this table so we can remove webpack build config
            const serializedPage = serialize(v);
            accum[k] = serializedPage;
            return accum;
        }, {});

        retVal.reactComponents.items = retVal.reactComponents.items || { pages: { ids: [], items: {} } };
        retVal.reactComponents.ids = Object.keys(retVal.reactComponents.items);
        retVal.reactComponents.items.pages.items = payload;
        retVal.reactComponents.items.pages.ids = Object.keys(payload); //.map((k, i) => ({ id: k, order: i }));

        const wimlVersion = componentThemeData.version;

        retVal.meta = {
            ...retVal.meta,
            version: wimlVersion,
        };

        return retVal;
    }
}

