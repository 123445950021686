import React, { useEffect, useState } from "react";
import { RuntimeWimlComponentProps_v1 } from "../../../../types";
import { getComponentClassName_v1_2_0 } from "../../../../v1.2.0/theme-data/compiler/components";
import { _getHrClassNames_v1_2_0 } from "app/wiml/versions/v1/v1.2.0/theme-data/renderer/styles/hr";
import { V1_2_0_ComponentWrapper } from "../../../../v1.2.0/theme-data/renderer/utils/component-wrapper";
import { Spinner } from 'reactstrap';
import { SearchQuery_v1, SearchResultCollection_v1 } from "app/wiml/versions/v1/types";

export function SearchResults_v1_4_0(props: RuntimeWimlComponentProps_v1) {
    const className = getComponentClassName_v1_2_0(props, 'search-results');
    // consider making {{props.currentPage.request.extraQuery.query}} as site data.
    const queryStr = props.currentPage.request.extraQuery.query as string;
    let query: SearchQuery_v1 | undefined = undefined;
    if (queryStr) {
        const tempQuery = JSON.parse(queryStr) as Record<string, any>;
        query = {
            rules: tempQuery.rules,
            searchTerm: tempQuery.search_term,
        };
        // http://test3.authorsite.com:3000/search?query={%22search%22:%22hello%22,%22rules%22:{%22location%22:{%22components%22:{%22ids%22:[%22lists%22],%22items%22:{%22lists%22:{%22ids%22:[%22list__blog%22],%22items%22:{%22list__blog%22:{%22components%22:{%22ids%22:[%22heading__title%22,%22text__post%22],%22items%22:{%22heading__title%22:{%22content%22:true},%22text__post%22:{%22content%22:true}}}}}}}}}},%22search_term%22:%22Eryn%22}
    }

    let retVal = null;

    const [state, setState] = useState({
        isLoaded: false,
        data: {},
    });
    const handleStateChange = (name: string, value: any) => {
        setState(prevState => ({ ...prevState, [name]: value }));
    };
    useEffect(() => {
        let res: { results: SearchResultCollection_v1 };
        if (query?.searchTerm && query?.rules.location.components.ids.length) {
            const fetchData = () => {
                try {
                    res = props.wimlData.searchSiteData(query);
                } catch (e) {
                    const rethrow = new Error('Error searching websites: ' + e);
                    rethrow.stack = e.stack;
                    throw rethrow;
                }

                handleStateChange('data', res);
                handleStateChange('isLoaded', true);

            };

            setTimeout(fetchData, 200);
        }
    }, []);
    const resultsConfig = {
        display: {
            rules: {
                ids: [] as string[],
                items: {} as {
                    [key: string]: {
                        icon: {
                            url: string,
                        }
                    }
                }
            }
        }
    }

    if (query?.searchTerm && query?.rules.location.components.ids.length) {
        if (!state.isLoading && state.data.results?.ids?.length) {
            const data = state.data;
            const resultsElements = data.results.ids.map((id: string) => {
                const item = data.results.items[id];
                // https://getbootstrap.com/docs/4.6/components/list-group/
                return (
                    <a href={item.url} key={id} className='list-group-item list-group-item-action search-results-item'>
                        <h4 className='list-group-item-heading result-heading'>{item.title}</h4>
                        <p className='list-group-item-text result-text'>{item.description}</p>

                    </a>
                );
            });

            const count = data.results.ids.length;
            const countText = count === 1 ? 'result' : 'results';
            retVal = (
                <div className={className}>
                    <V1_2_0_ComponentWrapper {...props}>
                        <div className='search-results-count'>
                            {count} {countText}
                        </div>
                        <div className="list-group list-group-flush search-results-list">
                            {resultsElements}
                        </div>
                    </V1_2_0_ComponentWrapper>
                </div >
            );
        }
        else {
            retVal = (
                <div>
                    <h4 className="text-center">
                        Loading Results… &nbsp;
                        <Spinner
                            as="span"
                            animation="border"
                            role="status"
                            aria-hidden="true"
                        />
                    </h4>

                </div>
            );
        }
    }
    return retVal;
}
// SearchResults_v1_4_0.inputProps = {
//     content: {
//         type: 'medium_text',
//         label: 'Content',
//         description: 'The content of the heading',
//     },
// };
