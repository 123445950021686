import React from 'react';

import { renderChildren_v1_0_0 } from '../../../../v1.0.0/theme-data/renderer/render-components';
import { RuntimeWimlComponentProps_v1 } from "app/wiml/versions/v1/types";
import { getComponentClassName_v1_1_0 } from '../../compiler/components';
import { Container_v1_0_0 } from '../../../../v1.0.0/theme-data/renderer/components/container';
import { getInferredComponentDefaultType_v1_0_0 } from '../../../../v1.0.0/theme-data/compiler/components';
import { renderChildComponentSiteDataPropertyValue_v1_0_0 } from "../../../../v1.0.0/theme-data/renderer/render-components";

export function Container_v1_1_0(props: RuntimeWimlComponentProps_v1) {

    const { wrapperClassName, containerClass, } = getContainerClassNames_v1_1_0(props);
    const bgUrl = renderChildComponentSiteDataPropertyValue_v1_0_0(props, 'bgUrl', getInferredComponentDefaultType_v1_0_0('Container')) || props.bgUrl;
    const bgUrlRules = bgUrl ? `background-image: url('${bgUrl}');
background-repeat: no-repeat;
background-size: cover;
background-position: center;` : '';

    const bgColor = renderChildComponentSiteDataPropertyValue_v1_0_0(props, 'bgColor', getInferredComponentDefaultType_v1_0_0('Container')) || props.bgColor;
    const bgColorFormatted = bgColor ? bgColor.startsWith('--') ? `var(${bgColor})` : bgColor : null;
    const bgColorRules = bgColorFormatted ? `background-color: ${bgColorFormatted};` : '';

    const wrapperClassNameWithUserClass = getComponentClassName_v1_1_0(props, wrapperClassName, bgUrl && 'has-bg-img', bgColor && 'has-bg-color');
    const renderedChildren = renderChildren_v1_0_0(props.children);

    return (
        <section className={wrapperClassNameWithUserClass}>
            <div className={containerClass}>
                {renderedChildren}
            </div>
            <style jsx>{`
                    section.has-bg-color {
                        ${bgColorRules}
                    }
                    section.has-bg-img {
                        ${bgUrlRules}
                    }
                `}</style>
        </section>
    );
}
Container_v1_1_0.inputProps = {
    ...Container_v1_0_0.inputProps,
    bgColor: {
        type: 'short_text',
        label: 'Background Color',
        description: 'The background color of the container',
    },
};

export function getContainerClassNames_v1_1_0(props: RuntimeWimlComponentProps_v1) {
    let wrapperClassName;
    let containerClass;

    if (props.stretch != null) {
        wrapperClassName = 'wiml-full-width-container-wrapper';
        containerClass = 'container-fluid';
    } else if (props.narrow != null) {
        wrapperClassName = 'wiml-narrow-container-wrapper';
        containerClass = 'container';
    } else {
        wrapperClassName = 'wiml-full-width-container-wrapper';
        containerClass = 'container';
    }
    return { wrapperClassName, containerClass, };
}

