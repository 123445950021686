import Sidebar_v1_3_0 from '../v1.3.0/sidebar-v1.3.0';
import Preview_v1_0_0 from '../v1.0.0/preview-v1.0.0';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { fetchThemes_v2 } from '@wip/common/event-store/themes-v2';
import { findNodeInTree_v1_3_0, findNodeParentByType_v1_3_0, getComponentTypeAndKeyFromId_v1_3_0 } from '../v1.3.0/wiml-editor-v1.3.0';
import { _getThemeDataComponentId_v1_2_0 } from '@wip/common/app/wiml/versions/v1/v1.2.0/theme-data/compiler/components';
import { OnboardWimlManage_v1_3_0 } from '../v1.3.0/onboarding-v1.3.0';
import WimlEditor_v1_4_0 from './wiml-editor-v1.4.0';
const acceptableNonExistingComponentIds = ['spacer', 'hr'];
export function WimlManage_v1_4_0(props) {
    const dispatch = useDispatch();
    const [themesLoaded, setThemesLoaded] = useState(false);
    useEffect(() => {
        // dispatch async thunks are promises
        // https://redux-toolkit.js.org/api/createAsyncThunk#unwrapping-result-actions
        dispatch(fetchThemes_v2({ includeContainerSnippets: true })).then(action => {
            // note: this does not handle errors gracefully, it swallows them. listen to the store for errors / loading, not state bound to this component.
            // dispatch(fetchWebsiteData(slug));
            setThemesLoaded(true);
        });
    }, [dispatch]);

    if (!themesLoaded) return <div>Loading Themes...</div>;

    // todo pull from v1.2 so we get onboarding
    const onPreviewChange = (previewData) => {
        let containerId = null;

        const shouldIgnore = acceptableNonExistingComponentIds.includes(previewData.componentId) ||
            acceptableNonExistingComponentIds.some(id => previewData.componentId.startsWith(`${id}__`));
        if (!shouldIgnore) {
            const rootNode = JSON.parse(props.websiteData.style.wiml.themeData.components.items.rootNodeJson);
            const { type: pageType, key: pageKey } = getComponentTypeAndKeyFromId_v1_3_0(previewData.pageDefinitionId);

            if (previewData.listItemId) {
                const component = props.websiteData.getPageChildListInstanceChildComponentThemeData(previewData.pageDefinitionId, previewData.listInstanceComponentId, previewData.componentId);
                const componentType = component.type;
                const componentKey = component.key;

                const pageNode = findNodeInTree_v1_3_0(rootNode, pageType, pageKey);
                const previewNode = findNodeInTree_v1_3_0(pageNode, componentType, componentKey);
                const previewParentContainerNode = findNodeParentByType_v1_3_0(pageNode, previewNode, 'Container');

                if (previewParentContainerNode) {
                    const componentId = _getThemeDataComponentId_v1_2_0({ type: previewParentContainerNode.tag, key: previewParentContainerNode.attr?.key });
                    containerId = componentId;
                }
            } else {
                // const { type: componentType, key: componentKey } = getComponentTypeAndKeyFromId_v1_3_0(previewData.componentId);
                const component = props.websiteData.getPageChildComponentThemeData(previewData.pageDefinitionId, previewData.componentId);
                const componentType = component.type;
                const componentKey = component.key;

                const pageNode = findNodeInTree_v1_3_0(rootNode, pageType, pageKey);
                const previewNode = findNodeInTree_v1_3_0(pageNode, componentType, componentKey);
                const previewParentContainerNode = findNodeParentByType_v1_3_0(pageNode, previewNode, 'Container');

                if (previewParentContainerNode) {
                    const componentId = _getThemeDataComponentId_v1_2_0({ type: previewParentContainerNode.tag, key: previewParentContainerNode.attr?.key });
                    containerId = componentId;
                }
            }
        }

        if (previewData.listItemId) {
            // this controls the 'view options' dropdown in the list editor - e.g. all components or just the page-specific ones.
            const stateChanges = {
                selectedTab: previewData.pageDefinitionId,
                listId: previewData.listId,
                listItemId: previewData.listItemId,
                listInstanceComponentId: previewData.listInstanceComponentId,
                componentId: previewData.componentId,
                containerId,
                meta: previewData.meta,
            };

            props.onChange(stateChanges);
        } else {
            const stateChanges = {
                selectedTab: previewData.pageDefinitionId,
                listId: null,
                listItemId: null,
                listInstanceComponentId: null,
                componentId: previewData.componentId,
                containerId,
                meta: previewData.meta,
            };

            props.onChange(stateChanges);
        }
    };

    let onboardingElement = null;
    if (props.websiteData.onboard?.status && props.websiteData.onboard.status !== 'completed') {
        // if (props.websiteData.meta.flags?.v1Holdsteady0) {
        //     onboardingElement = <OnboardWimlManage_v1_holdsteady_0 {...props} />;
        // } else {
        onboardingElement = <OnboardWimlManage_v1_3_0 {...props} />;
        // }
    }

    return <>
        {onboardingElement}
        <Sidebar_v1_3_0  {...props} wimlEditor={WimlEditor_v1_4_0} />
        <Preview_v1_0_0 {...props} onPreviewChange={onPreviewChange} />
    </>;
}
