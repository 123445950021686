import React from 'react';
import { useState, } from "react";
import {
    Collapse,
    Navbar,
    NavbarToggler,
    Nav,
    NavItem,
    UncontrolledDropdown,
    DropdownItem,
    DropdownToggle,
    DropdownMenu
} from 'reactstrap';
import Link from 'next/link';
import { breakpoints_v1_1_0 } from "../utils/breakpoints";
import { getInferredComponentDefaultType_v1_0_0 } from "../../../../v1.0.0/theme-data/compiler/components";
import { renderChildComponentSiteDataPropertyValue_v1_0_0 } from "../../../../v1.0.0/theme-data/renderer/render-components";
import { getComponentClassName_v1_1_0 } from '../../compiler/components';
import Navigation_v1_0_0 from '../../../../v1.0.0/theme-data/renderer/components/navigation';
const COMPONENT_TYPE = getInferredComponentDefaultType_v1_0_0("Navigation");

export function Navigation_v1_1_0(props) {
    let retVal = null;

    let expand = 'md';
    if (props.expand != null) {
        if (props.expand == 'never') {
            expand = undefined;
        } else {
            expand = props.expand;
        }
    }

    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => {
        // todo replace media queries with https://www.npmjs.com/package/react-responsive
        let mediaQuery = undefined;

        // todo reverse this
        if (expand == 'sm') {
            mediaQuery = '(min-width: 576px)';
        } else if (expand == 'md') {
            mediaQuery = '(min-width: 768px)';
        } else if (expand == 'lg') {
            mediaQuery = '(min-width: 992px)';
        } else if (expand == 'xl') {
            mediaQuery = '(min-width: 1200px)';
        }

        const mediaQueryList = window.matchMedia(mediaQuery);

        if (mediaQuery == undefined || mediaQueryList.matches == false) {
            setIsOpen(!isOpen);
        }
    };

    const navItems = renderChildComponentSiteDataPropertyValue_v1_0_0(props, 'items', COMPONENT_TYPE);

    const className = getComponentClassName_v1_1_0(props, 'navigation');
    props.circle != null && 'circle rounded-circle', props.square != null && 'square'

    let brightness = 'light';
    if (props.dark != null) {
        brightness = 'dark';
    }
    const brightnessProps = { [brightness]: true };

    const expandProps = { expand };


    if (navItems) {
        retVal = (
            <section className={className}>
                <Navbar className="justify-content-center" {...brightnessProps} {...expandProps}>
                    <NavbarToggler onClick={toggle} />
                    <Collapse isOpen={isOpen} navbar>
                        <Nav navbar className="align-items-center">
                            <NavList {...props} navItems={navItems} onClick={toggle} />
                        </Nav>
                    </Collapse>
                </Navbar>
            </section>
        );
    }

    return retVal;
}

function NavList(props) {
    // todo make me not global
    return (
        <>
            <NavListItems {...props} />
            <style jsx global>{`
                html body nav.navbar .dropdown.nav-item .dropdown-item {
                    border: transparent;
                    background-color: var(--global-bg-color);
                    color: var(--global-text-color);
                }

                html body nav.navbar .dropdown.nav-item .dropdown-menu {
                    background-color: var(--global-bg-color);
                }
                .navigation button.navbar-toggler {
                    border: transparent;
                    background-color: var(--global-button-bg-color);
                    color: var(--global-button-color-text);
                }

                .navigation .dropdown-item:hover {
                    background-color: initial;
                }

                /* In most cases, the nav links are not accented */
                .navbar-light .navbar-nav .nav-link, .navbar-dark .navbar-nav .nav-link,
                .navbar-light .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:hover,
                .navbar-light .navbar-nav .nav-link:focus, .navbar-dark .navbar-nav .nav-link:focus {
                    color: var(--global-color-text-accent);
                }

                .nav-item .nav-link img {
                    max-height: 6rem;
                }

                nav.navbar .nav-item {
                    font-family: var(--global-text-sub-heading-font);
                    padding-left: 1rem;
                    padding-right: 0;
                }
                
                // todo consider using var(--global....)
                .nav-wrapper .nav-item {
                    background-color: #fff;
                }

                /* dropdowns sit too low without this */
                .nav-wrapper .dropdown.nav-item {
                    padding: .5rem;
                }

                .nav-wrapper .dropdown.nav-item > a.nav-link {
                    padding: 0;
                }

                // todo reverse this
                @media ${breakpoints_v1_1_0.md} { 
                    .nav-wrapper .nav-item {
                        background-color: initial;
                    }
                }
            `}</style>
        </>
    );
}
function NavListItems(props) {
    let retVal = null;

    const onClick = props.onClick;
    const navItems = props.navItems;

    if (navItems) {
        const navItemsArray = JSON.parse(navItems);

        // map navItems to DropdownItem
        // if no children, it is a regular DropdownItem
        // else it is a UncontrolledDropdown
        const navItemComponents = navItemsArray.map((navItem, index) => {
            let navItemComponent;
            const navItemKey = navItem.text;
            const navItemValue = navItem.url;
            const navItemLabel = navItem.text;
            const navItemImage = navItem.image;
            const navItemAttrs = navItem.attrs;
            const navItemChildren = navItem.children;

            if (navItemChildren) {
                const navItemChildrenComponents = navItemChildren.map((navItemChild, index) => {
                    const navItemChildKey = navItemChild.text;
                    const navItemChildValue = navItemChild.url;
                    const navItemChildLabel = navItemChild.text;
                    const navItemChildImage = navItemChild.image;
                    const navItemChildAttrs = navItemChild.attrs;

                    const retVal = getNavItem(navItemChildKey, navItemChildValue, navItemChildLabel, navItemChildImage, navItemChildAttrs, true, onClick);

                    return retVal;
                });

                navItemComponent = (
                    <UncontrolledDropdown nav key={navItemKey}>
                        <DropdownToggle nav caret>
                            {navItemLabel}
                        </DropdownToggle>
                        <DropdownMenu>
                            {navItemChildrenComponents}
                        </DropdownMenu>
                    </UncontrolledDropdown>
                );

            } else {
                navItemComponent = getNavItem(navItemKey, navItemValue, navItemLabel, navItemImage, navItemAttrs, false, onClick);
            }
            return navItemComponent;
        });

        retVal = navItemComponents;
    }

    return retVal
}

function getNavItem(navItemKey, navItemValue, navItemLabel, navItemImage, navItemAttrs, isChild, onClick) {
    // DropdownItem is rendered as a button, NavItem is rendered as a li
    const Tag = isChild ? DropdownItem : NavItem;
    // conditionally add btn btn-primary class if navItemAttrs.primary or navItemAttrs.secondary is true
    const navItemClasses = ['nav-link'];
    if (navItemAttrs?.primary) {
        navItemClasses.push('btn', 'btn-primary');
    } else if (navItemAttrs?.secondary) {
        navItemClasses.push('btn', 'btn-secondary');
    }

    let navItemImageComponent = null;

    if (navItemImage) {
        navItemImageComponent = (
            <img src={navItemImage} />
        );
    }

    const navItemClassName = navItemClasses.join(' ');

    const retVal = (
        <Tag key={navItemKey}>
            <Link href={navItemValue}>
                <a className={navItemClassName} onClick={onClick}>
                    {navItemLabel}
                    {navItemImageComponent}
                </a>
            </Link>
        </Tag>
    );

    return retVal;
}

Navigation_v1_1_0.inputProps = Navigation_v1_0_0.inputProps;
