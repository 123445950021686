import React, { useCallback, useState } from "react";
import { RuntimeWimlComponentProps_v1 } from "../../../../types";
import { getComponentClassName_v1_2_0 } from "../../../../v1.2.0/theme-data/compiler/components";
import { _getHrClassNames_v1_2_0 } from "app/wiml/versions/v1/v1.2.0/theme-data/renderer/styles/hr";
import { SearchQuery_v1 } from "app/wiml/versions/v1/types";
import { Form, FormGroup, Label, Spinner, InputGroup, InputGroupAddon, Input, Button } from 'reactstrap';
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faSpinner, faTimes, faTrash } from "@fortawesome/free-solid-svg-icons";
import debounce from 'lodash/debounce';
import { V1_2_0_ComponentWrapper } from "../../../../v1.2.0/theme-data/renderer/utils/component-wrapper";


export function SearchInput_v1_4_0(props: RuntimeWimlComponentProps_v1) {
    // In component:

    // if we decide to do SPA single page search instead of full page reload,
    // do not use nextjs here - ideally use props.currentPage.router or something
    // but others, e.g. search results, would have to listen to that same router somehow e.g. https://nextjs.org/docs/pages/api-reference/functions/use-router#routerevents
    // import { useRouter } from 'next/router'
    // const router = useRouter();

    const className = getComponentClassName_v1_2_0(props, 'search-input');
    const [isSearching, setIsSearching] = useState(false);

    const defaultValues = {
        searchTerm: '',
    };

    // consider making {{props.currentPage.request.extraQuery.query}} as site data.
    const queryStr = props.currentPage.request.extraQuery.query as string;

    if (queryStr) {
        const tempQuery = JSON.parse(queryStr) as Record<string, any>;
        defaultValues.searchTerm = tempQuery.search_term;
        // http://test3.authorsite.com:3000/search?query={%22search%22:%22hello%22,%22rules%22:{%22location%22:{%22components%22:{%22ids%22:[%22lists%22],%22items%22:{%22lists%22:{%22ids%22:[%22list__blog%22],%22items%22:{%22list__blog%22:{%22components%22:{%22ids%22:[%22heading__title%22,%22text__post%22],%22items%22:{%22heading__title%22:{%22content%22:true},%22text__post%22:{%22content%22:true}}}}}}}}}},%22search_term%22:%22Eryn%22}
    }

    const onSearch: Function = null;//props.onSearch;

    const { register, handleSubmit, watch, setValue } = useForm({ defaultValues });
    const searchTerm = watch('searchTerm');

    // Create our debounced search function that will only fire after 300ms of no typing
    const debouncedSearch = useCallback(
        debounce(async (term) => {
            if (!term) {
                setIsSearching(false);
                return;
            }

            try {
                if (onSearch) {
                    await onSearch(term);
                }
            } finally {
                setIsSearching(false);
            }
        }, 800),
        []
    );

    const rules = { "location": { "components": { "ids": ["lists"], "items": { "lists": { "ids": ["list__blog"], "items": { "list__blog": { "components": { "ids": ["heading__title", "text__post"], "items": { "heading__title": { "content": true }, "text__post": { "content": true } } } } } } } } } };
    const onSubmit = (data: { searchTerm: string; }) => {
        // Use Next.js router to navigate programmatically 
        const searchParams = new URLSearchParams();
        const query = {
            search_term: data.searchTerm,
            rules,
        };
        searchParams.set('query', JSON.stringify(query));
        // router.replace(`/search?${searchParams.toString()}`);
        window.location.replace(`/search?query=${encodeURIComponent(JSON.stringify(query))}`);
    };

    let retVal = null;

    const [state, setState] = useState({
        isLoading: false,
        data: {},
    });

    const handleInputChange = (e) => {
        const value = e.target.value;
        if (value) {
            setIsSearching(true);
        } else {
            setIsSearching(false);
        }
        debouncedSearch(value);
    };

    const handleClearSearch = async () => {
        setValue('searchTerm', '');
        setIsSearching(false);
        if (onSearch) {
            await onSearch('');
        }
    };
    // note onChange is handled in react-hook-form 7.18
    const { ref: searchTermRef, ...searchTermRest } = register('searchTerm', { required: true, onChange: handleInputChange });

    let searchIconWrapper = null;
    if (isSearching) {
        searchIconWrapper = (
            <div className="search-term-icon-wrapper">
                <FontAwesomeIcon icon={faSpinner} spin className="text-muted" />
            </div>
        );
    } else if (searchTerm) {
        searchIconWrapper = (
            <div
                className="search-term-icon-wrapper"
                onClick={handleClearSearch}
                style={{ cursor: 'pointer' }}>
                <FontAwesomeIcon icon={faTimes} className="text-muted" />
            </div>
        );
    } else {
        searchIconWrapper = (
            <div className="search-term-icon-wrapper">
                <FontAwesomeIcon icon={faSearch} className="text-muted" />
            </div>
        );
    }

    retVal = (
        <div className={className}>
            <V1_2_0_ComponentWrapper {...props}>
                <Form onSubmit={handleSubmit(onSubmit)} className="wiml-contact-form">
                    <FormGroup>
                        <InputGroup>
                            <Input type="text" name="search-term" id="search-term" className="form-control py-2 pr-4" {...searchTermRest} innerRef={searchTermRef} />
                            {searchIconWrapper}
                        </InputGroup>
                    </FormGroup>
                </Form>
            </V1_2_0_ComponentWrapper>
            <style jsx global>{`
                .search-term-icon-wrapper {
                    position: absolute;
                    right: 15px;
                    top: 50%;
                    transform: translateY(-50%);
                    z-index: 10;
                }
`}</style>
        </div >
    );
    return retVal;
}
// SearchResults_v1_4_0.inputProps = {
//     content: {
//         type: 'medium_text',
//         label: 'Content',
//         description: 'The content of the heading',
//     },
// };
