import InnerHTML from 'dangerously-set-html-content';
import React from 'react';

import { getInferredComponentDefaultType_v1_0_0 } from '../../compiler/components';
import { renderChildComponentSiteDataPropertyValue_v1_0_0 } from "../render-components";
import { RuntimeWimlComponentProps_v1_0_0 } from '../../../../../../../app/wiml/versions/v1/v1.0.0/theme-data/compiler/types';


export function Embed_v1_0_0(props: RuntimeWimlComponentProps_v1_0_0) {
    let retVal = null;

    let content = renderChildComponentSiteDataPropertyValue_v1_0_0(props, 'content', getInferredComponentDefaultType_v1_0_0('Embed')) as string;

    if (content) {
        const element = <InnerHTML html={content} />;
        retVal = element;
    }
    return retVal;
}
Embed_v1_0_0.inputProps = {
    content: {
        type: 'medium_text',
        label: 'Content',
        description: 'The content of the embed block',
    },
};
