/// <reference types="styled-jsx" />
//  https://stackoverflow.com/questions/66011598/styled-jsx-typescript-error-after-migrating-to-monorepo-structure-property-jsx

import React from 'react';

import { renderChildren_v1_0_0 } from '../render-components';
import { getInferredComponentDefaultType_v1_0_0 } from '../../compiler/components';
import { getComponentClassName_v1_0_0 } from '../render-components';
import { renderChildComponentSiteDataPropertyValue_v1_0_0 } from "../render-components";
import { RuntimeWimlComponentProps_v1 } from "app/wiml/versions/v1/types";

export function Container_v1_0_0(props: RuntimeWimlComponentProps_v1) {
    const containerClass = props.fluid == null ? 'container' : 'container-fluid';

    const bgUrl = renderChildComponentSiteDataPropertyValue_v1_0_0(props, 'bgUrl', getInferredComponentDefaultType_v1_0_0('Container'));
    const className = getComponentClassName_v1_0_0(props, containerClass, bgUrl && 'has-bg-img');
    const renderedChildren = renderChildren_v1_0_0(props.children);

    return (
        <div className={className}>{renderedChildren}
            <style jsx>{`
                div.has-bg-img {
                    background-image: url('${bgUrl}');
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: center;
                }
            `}</style>
        </div>
    );
}

Container_v1_0_0.inputProps = {
    bgUrl: {
        type: 'image',
        label: 'Background Image URL',
        description: 'The url of the bg image',
    }
};
