import { useEffect, useRef } from 'react'
import { useDispatch } from "react-redux";
import {
  Button,
  Nav,
  NavItem,
  NavLink
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';

import { saveWebsiteData, clearPendingChanges } from '@wip/common/event-store/website';
import { useAuth } from "@wip/common/lib/auth";
import WimlEditor_v1_0_0 from './wiml-editor-v1.0.0';
import Settings_v1_0_0 from './settings-v1.0.0';
import _ from 'lodash';
import { convertObjectToArray } from "@wip/common/lib/object-utils";
import { Resizable } from "re-resizable";

export default function Sidebar_v1_0_0(props) {
  return __Sidebar_v1_0_0(props, WimlEditor_v1_0_0, Settings_v1_0_0);
}

export function __Sidebar_v1_0_0(props, EditorComponent, SettingsComponent, tabListExtraComponents) {
  const sidebarTabs = getSidebarTabs(props, EditorComponent, SettingsComponent);

  const sideBar = (
    <aside className="d-flex flex-row">
      <SidebarTabsListWrapper sidebarTabs={sidebarTabs}  {...props} tabListExtraComponents={tabListExtraComponents} />
      <ResizableSidebar>
        <SidebarEditor {...props} sidebarTabs={sidebarTabs} EditorComponent={EditorComponent} SettingsComponent={SettingsComponent} />
      </ResizableSidebar>
      <style jsx>{`
          aside {
            font-size: 14px;
          }
        `}</style>
    </aside>
  );

  return sideBar;
}

function getSidebarTabs(props, EditorComponent, SettingsComponent, extraComponents) {
  const pageIds = props.websiteData.style.wiml.themeData.components.items.pages.ids;
  const pages = props.websiteData.style.wiml.themeData.components.items.pages.items;
  const pagesArray = convertObjectToArray(pages || { startHere: { name: "Start Here" } });
  const sortedPagesArray = _.sortBy(pagesArray, p => pageIds.indexOf(p.id));
  const ignoredTabs = ['book', 'event', 'post'];
  const isAdmin = props.userData.roles.includes('admin');

  const sidebarTabs = sortedPagesArray
    .filter(pc => isAdmin || !ignoredTabs.includes(pc.id))
    .map(pc => {
      return [pc.id, pc.name, EditorComponent, faEllipsisH];
    });
  sidebarTabs.push([SettingsComponent.sidebarTab?.id || 'settings', SettingsComponent.sidebarTab?.name || 'Settings', SettingsComponent, faEllipsisH]);
  return sidebarTabs;
}

function SidebarTabsListWrapper(props) {
  return (
    <section className="sidebar-nav border-right">
      <Nav vertical tabs>
        <SidebarTabList {...props} sidebarTabs={props.sidebarTabs} />
        {props.tabListExtraComponents}
      </Nav>
      <style jsx>{`
        .sidebar-nav {
          width: 100px;
          border-color: ${props.themeConfig.borderColor};
          height: auto;
          overflow-y: scroll;
        }

        .sidebar-nav :global(.nav-tabs) {
          position: relative; /* necessary for onboarding or anything floating over sidebar */
        }
        
        .sidebar-nav :global(li.nav-item) {
          min-height: 75px;
          display: flex;
          flex-direction: column;
          text-align: center;
        }

        .sidebar-nav :global(a.nav-link) {
          padding: 0;
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
      `}</style>
    </section>
  );
}

export function ResizableSidebar(props) {
  // https://github.com/bokuweb/re-resizable/issues/727
  // Create refs

  const scrollOffsetX = useRef(0);
  const scrollOffsetY = useRef(0);

  return (
    <Resizable
      enable={{ right: true }}
      handleWrapperClass="sidebar-resize-handle"
      handleStyles={{ right: { background: 'darkgrey' } }}
      defaultSize={{
        width: 260,
      }}
      minWidth={160}
      maxWidth={960}
      onResizeStart={(e, direction, ref) => {
        scrollOffsetX.current = ref.scrollLeft || 0
        scrollOffsetY.current = ref.scrollTop || 0
      }}
      onResize={(e, direction, ref, d) => {
        ref.scrollTo(scrollOffsetX.current, scrollOffsetY.current)
      }}
      className="sidebar-editor-wrapper border-right">
      {props.children}
      <style jsx>{`
          :global(.sidebar-editor-wrapper) {
            overflow-y: scroll;
            overflow-x: hidden;
            position: relative !important;
            transform: none !important;
          }
      `}</style>
    </Resizable>
  );
}
function SidebarTabList(props) {
  const sidebarTabComponents = props.sidebarTabs.map(tab => (
    <NavItem key={tab[0]}>
      <NavLink
        /*href={`/${tab[0]}/`}*/
        href='#'
        active={tab[0] == props.selectedTab}
        className={`sidebar-tab-${tab[0]}`}
        onClick={() => {
          props.onChange('listId', null);
          props.onChange('listItemId', null);
          props.onChange('listInstanceComponentId', null);

          props.onChange('relationshipAListId', null);
          props.onChange('relationshipAListItemId', null);
          props.onChange('relationshipBListId', null);
          props.onChange('relationshipBListItemId', null);
          props.onChange('relationshipBIsNew', null);

          props.onChange('selectedTab', tab[0]);
          // todo - somethings are not pages e.g. settings - should probably   filter these
          props.onChange('pageId', tab[0]);
        }}
      >
        <FontAwesomeIcon icon={tab[3]} />
        {titleCaseName(tab[1])}
      </NavLink>
    </NavItem>
  ));

  return sidebarTabComponents;
}

function SidebarEditor(props) {
  const EditorComponent = props.EditorComponent;
  const dispatch = useDispatch();
  const { user, } = useAuth();

  function cancel(e) {
    if (confirm('Are you sure you want to cancel your changes?')) {
      dispatch(clearPendingChanges({ isCancelled: true }))
        // https://stackoverflow.com/questions/63439021/handling-errors-with-redux-toolkit 
        // there is NO CATCH for thunks
        .then(action => {
          if (action.error) {
            console.error(error);
          } else {
            window.location.reload();
          }
        });
    }
  }

  function save(e) {
    // const comment = prompt("Leave a note about the changes you've made.");
    const date = new Date().toISOString();

    const comment = `${user.email} saved website on ${date}.`;

    if (comment) {
      // dispatch(commitPendingChanges());
      dispatch(saveWebsiteData(comment))
        .then(action => {
          if (action.error) {
            throw new Error(action.payload);
          } else {
            dispatch(clearPendingChanges({ isCancelled: false }))
              .then(() => {
                // alert('Your changes have been saved.');
                // todo - make the ui reflect new state
                // e.g. website history should be updated.
                props.onSave && props.onSave();
              });
          }
        }).catch(error => {
          alert('There was an error saving your changes.');
          console.error(error);
        });
    }
  }

  const { sidebarTabs, selectedTab } = props;

  const defaultTab = [null, "start Here", EditorComponent, faEllipsisH];

  const sidebarTabObj = sidebarTabs.find(tab => tab[0] == selectedTab) || defaultTab;

  if (!sidebarTabObj) throw new Error(`editor for tab ${selectedTab} doesn't exist.`);

  const SidebarEditorElement = sidebarTabObj[2];
  const pendingChanges = props.pendingChanges;
  // https://stackoverflow.com/questions/35153599/reactjs-get-height-of-an-element
  const ref = useRef(null)

  useEffect(() => {
    // this is how we keep the resizer to be as tall as it needs to be available through the entire height of the admin sidebar.
    // to account for images, wait for dom to be loaded and ready.

    // wait for dom ready and images loaded
    // https://stackoverflow.com/questions/39993676/code-inside-domcontentloaded-event-not-working
    // window.addEventListener('DOMContentLoaded', () => {
    //   setHeight(ref.current.clientHeight);
    // });

    // wait for dom ready and images loaded
    const timeoutId = setTimeout(() => {
      const resizeHandle = document.querySelector('.sidebar-resize-handle > div');
      if (resizeHandle) {
        const height = ref.current.clientHeight
        resizeHandle.style.height = `${height}px`;
      }
    }, 1000);

    return () => {
      clearTimeout(timeoutId);
    };
  }) // NOTE: do NOT add dependencies here, this is a side effect that should run every render

  return (
    <>
      <div className="p-3 pb-5" ref={ref}>
        <h3>{sidebarTabObj[1]}</h3>
        <div className="sidebar-editor mt-2">
          <SidebarEditorElement {...props} />
        </div>
      </div>
      <div className="sidebar-editor-controls d-flex">
        <Button className="flex-grow-1 rounded-0" disabled={pendingChanges.length == 0} color={pendingChanges.length == 0 ? "secondary" : "danger"} onClick={cancel}>cancel</Button>
        <Button className="flex-grow-1 rounded-0" disabled={pendingChanges.length == 0} color={pendingChanges.length == 0 ? "secondary" : "primary"} onClick={save}>save</Button>
      </div>
      <style jsx>{`
        .sidebar-editor-controls {
          /* TODO this should be sticky but I do'nt know if it's supported yet:
          https://stackoverflow.com/questions/34252589/position-fixed-100-of-parent
          https://stackoverflow.com/questions/5873565/set-width-of-a-position-fixed-div-relative-to-parent-div
          position: sticky;
          */ 
          position: fixed;
          bottom: 0;
          width: inherit;
          z-index: 1;
        }

        .preview-wrapper :global(.preview-header) {
          background-color: ${props.themeConfig.backgroundPrimaryColor};
          height: 40px;
        }

        .preview-wrapper :global(.preview-body) {
          padding:0;
        }
    `}</style>
    </>
  );
}

const titleCaseName = (name) => {
  return _.startCase(name);
}
