import { Table, Progress } from 'reactstrap';
import { getData, getDataSuccess } from '@wip/common/event-store/user';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-svg-core/styles.css';
import { Provider } from 'react-redux'
import { createBrowserHistory } from 'history';
import { useEffect, useState } from "react";
import Manage from './manage';
import WebsiteGenerator from './landing-page/website-generator-from-image';
import CreateUser from './user/create';
import CreateWebsite from './website/create';
import SearchWebsites from './website/search';
import HqManage from './hq/index';
import store from '../domain/event-store';
import Login from './account/login';
import Logout from './account/logout';
import fbClient, { firebase } from '@wip/common/lib/firebase';
import { getClient as getUserClient } from "@wip/common/domain/user-service";
import { getClient as getWebsiteClient } from "@wip/common/domain/website-service";
import { getClient as getAnalyticsClient } from "@wip/common/domain/analytics-service";
import { useDispatch } from "react-redux";
import moment from 'moment';
import { fetcher } from "@wip/common/lib/data-utils";

// DO NOT import BrowserRouter (as per tutorial). that caused router to not actually do anything.
// see here: https://stackoverflow.com/questions/63554233/react-router-v5-history-push-changes-the-address-bar-but-does-not-change-the
// https://github.com/ReactTraining/react-router/issues/4059#issuecomment-254437084
// this is incredibly common but not our problem: https://stackoverflow.com/questions/62449663/react-router-with-custom-history-not-working
import {
  Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import { AuthProvider, useAuth } from '@wip/common/lib/auth';
import { AnalyticsProvider, getClient as getTracker, useAnalytics } from '@wip/common/lib/analytics-utils';
import { daysDiff } from '@wip/common/lib/date-utils';

const apiUrl = process.env.REACT_APP_API_URL;

export const history = createBrowserHistory();

const themeConfig = {
  primaryColor: "red",
  primaryFontFamily: "Lato",
  mutedColor: "#a8b7cf",
  backgroundPrimaryColor: "#fcfcff",
  backgroundSecondaryColor: "#eff4fa",
  backgroundThirdColor: "#d6dbe4",
  buttonPrimaryColor: "#28a745",
  borderColor: "#e4e9f1",
};

// todo provide these via context provider
const websiteApi = getWebsiteClient(fbClient);
const userApi = getUserClient(fbClient);
const analyticsApi = getAnalyticsClient(fbClient);
// TODO FIX THIS BY MAKING THIS MODULE IMPLEMENT GETCLIENT AS WELL.
// MOVE TO A CLASS OR PUT THIS CONDITINALS IN THE LIB/FIREBASE-CLIENT 
const segmentWriteKey = process.env.REACT_APP_SEGMENT_WRITE_KEY;
const tracker = getTracker(segmentWriteKey);

function withReduxProvider(Component) {
  return function withReduxProvider(props) {
    return (
      <Provider store={store}>
        <Component {...props} />
      </Provider>
    );
  };
}

function App() {
  const props = { themeConfig };
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getData());
  }, []);

  const storeUserData = async user => {
    const impersonationDataStr = localStorage.getItem('wip_impersonation') || '{}';
    const impersonationData = JSON.parse(impersonationDataStr);
    const impersonationUserId = impersonationData.impersonator?.userId;

    const impersonation = {
      isImpersonating: !!impersonationUserId,
      impersonator: impersonationData.impersonator,
    }

    const userRoles = [];
    const userIdToken = await user.getIdTokenResult();
    const isAdmin = !!userIdToken.claims['admin'];
    if (isAdmin) {
      userRoles.push('admin');
    }

    //photoURL gets decamlized to photo_u_r_l
    const { photoURL: photoUrl, ...providerData } = user.providerData[0];

    let userType = 'standard';
    if (isAdmin) {
      userType = 'admin';
    } else if (/^test/i.test(providerData.displayName)) {
      userType = 'demo';
    }

    const auth = {
      // idToken: user.za, // user.getIdToken() is recommended but because we listen for onIdTokenChanged, we always have the latest token.
      idToken: userIdToken.token,
      resfreshToken: user.refreshToken
    };

    const userData = { ...providerData, photoUrl, uid: user.uid, roles: userRoles, userType, impersonation, auth };

    dispatch(getDataSuccess(userData));
  };

  const appElement = (
    <AuthProvider onLogin={storeUserData}>
      <AnalyticsProvider tracker={tracker}>
        <Router history={history}>
          <Switch>
            <ProtectedRoute path="/manage/:slug" component={Manage} {...props} firebase={firebase} />

            {/* deprecated */}
            <ProtectedRoute path="/admin/users/create" roleRequired="admin" component={CreateUserDeprecated} {...props} />
            {/* end deprecated */}

            <ProtectedRoute path="/admin/user/create" roleRequired="admin" component={CreateUser} {...props} />
            <ProtectedRoute path="/admin/website/create" roleRequired="admin" component={CreateWebsite} {...props} />
            <ProtectedRoute path="/admin/website/search" roleRequired="admin" component={SearchWebsites} {...props} />
            <ProtectedRoute path="/admin/manage" roleRequired="admin" component={HqManage} {...props} />
            <ProtectedRoute path="/admin" roleRequired="admin" component={Admin} {...props} />

            {/* <ProtectedRoute path="/generator" component={WebsiteGenerator} {...props} /> */}

            <Route path="/generator" render={(routeProps) => <WebsiteGenerator {...routeProps} {...props} firebase={firebase} />} />

            {/* This is how to pass in custom props with `router props` https://v5.reactrouter.com/web/api/Route/route-props & https://stackoverflow.com/questions/27864720/react-router-pass-props-to-handler-component*/}
            <Route path="/login" render={(routeProps) => <Login {...routeProps} {...props} firebase={firebase} />} />
            <Route path="/logout" render={(routeProps) => <Logout {...routeProps} {...props} firebase={firebase} />} />

            {/* this must be on the bottom */}
            <ProtectedRoute path="/" component={Home} {...props} />
          </Switch>
        </Router>
      </AnalyticsProvider>
    </AuthProvider >
  );

  return appElement;
}

const AppWithRedux = withReduxProvider(App);
export default AppWithRedux;
// export default App;

function Home(props) {
  const currentEnv = process.env.NODE_ENV;

  const { tracker } = useAnalytics();
  const { user } = useAuth();

  const [state, setState] = useState({
    websiteSlugs: [],
    isLoaded: false
  });

  useEffect(async () => {
    if (currentEnv == 'production') {
      const isAdmin = user?.roles?.includes('admin');

      if (isAdmin) {
        return;
      }

      tracker.page();
    }
  }, [currentEnv, user]);

  const handleStateChange = (name, value) => {
    setState(prevState => ({ ...prevState, [name]: value }));
  };

  useEffect(async () => {
    const userInfo = await userApi.getData(user.uid);
    if (userInfo) {
      const websiteIds = userInfo.websites

      const getWebsiteWork = websiteIds.map(id => websiteApi.getData(id, 'latest'));
      const websites = await Promise.all(getWebsiteWork);

      if (websites.length == 0) {
        handleStateChange('isLoaded', true);
      } else if (websites.length == 1) {
        const queryParams = props.location.search;
        const returnTo = `/manage/${websites[0].meta.slug}${queryParams}`;
        props.history.replace(returnTo);
      } else {
        const websiteSlugs = websites.map(w => w.meta.slug);

        handleStateChange('websiteSlugs', websiteSlugs);
        handleStateChange('isLoaded', true);
      }
    } else {
      // ending slash - do not have ending slash or CORS error - will cause redirect from /user/ to /user (no ending slash) == cors error.
      // todo move endpoint from zapier to user
      const newUserApiUrl = `${apiUrl}/user`;

      // api uses snake_case
      const POSTWebhookData = {
        user_id: user.uid,
        display_name: user.displayName,
        email: user.email,
      };

      const localStorageThemeId = localStorage.getItem('new_website_data__website_generator_theme_id');
      const localStorageThemeWiml = localStorage.getItem('new_website_data__website_generator_wiml');

      if (localStorageThemeId && localStorageThemeWiml) {
        POSTWebhookData.theme_id = localStorageThemeId;
        POSTWebhookData.wiml = localStorageThemeWiml;
      }

      const POSTOptions = {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        headers: { "Content-Type": "application/json", "Authorization": 'fill me in with users token' },
        body: JSON.stringify(POSTWebhookData)
      };

      const res = await fetcher(newUserApiUrl, POSTOptions, { getJson: false });
      window.location.reload();
    }

  }, []);

  const websiteLinks = state.websiteSlugs.map(slug =>
    <li key={slug}>
      <Link to={`/manage/${slug}/`}>{slug}</Link>
    </li>
  );

  return (
    <nav className="d-flex flex-column align-items-center">
      <h1>My Websites</h1>
      <ul>
        {/* <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          <Link to="/about">About</Link>
        </li>
        <li>
          <Link to="/users">Users</Link>
        </li> */}
        {!state.isLoaded && 'Please wait, do not close or refresh your browser. Loading…'}
        {state.isLoaded && ((websiteLinks?.length && websiteLinks) || "You don't have any websites yet.")}
      </ul>
    </nav>
  );
}

function Admin() {
  // const { user } = useAuth();

  const [state, setState] = useState({
    data: {},
    isLoaded: false
  });

  const handleStateChange = (name, value) => {
    setState(prevState => ({ ...prevState, [name]: value }));
  };

  useEffect(async () => {
    const dashboardData = await analyticsApi.getDashboardData();

    handleStateChange('data', dashboardData);
    handleStateChange('isLoaded', true);
  }, []);

  // const websiteLinks = state.websiteSlugs.map(slug =>
  //   <li key={slug}>
  //     <Link to={`/manage/${slug}/`}>{slug}</Link>
  //   </li>
  // );
  let adminReports;

  if (state.isLoaded) {
    const trialsInProgress = state.data.trialsInProgress;

    const today = new Date();

    const progressRows = trialsInProgress.map((trial, i) => {
      const { createdDate } = trial;
      const daysDiffValue = daysDiff(today, createdDate);
      const daysRemaining = 14 - daysDiffValue;

      let className;
      if (daysRemaining <= 0) {
        className = "table-danger";

      } else if (daysRemaining <= 7) {
        className = "table-warning";
      }

      return (
        <tr key={trial.id} className={className}>
          <th scope="row">{i + 1}</th>
          <td>{trial.name}</td>
          <td><Progress value={trial.progress} /></td>
          <td>{daysRemaining}</td>
        </tr>
      );
    });

    const progressTable = (
      <div>
        <h2 className="text-center">Trials in Progress</h2>
        <Table>
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Progress</th>
              <th>Days Left <small className="text-muted">14-day trial.</small></th>
            </tr>
          </thead>
          <tbody>
            {progressRows}
          </tbody>
        </Table>
      </div>
    );

    const supportMessages = state.data.supportMessages;

    const supportMessagesRows = supportMessages.map((supportMessage, i) => {
      // const { date } = supportMessage;
      // const daysDiffValue = daysDiff(today, date);
      // const daysRemaining = 14 - daysDiffValue;

      let className;
      // if (daysRemaining <= 0) {
      //   className = "table-danger";

      // } else if (daysRemaining <= 7) {
      //   className = "table-warning";
      // }

      return (
        <tr key={supportMessage.id} className={className}>
          <th scope="row">{i + 1}</th>
          <td>{supportMessage.name || supportMessage.email}</td>
          <td>{moment(supportMessage.date).fromNow()}</td>
          <td><a href={supportMessage.link} target="_blank">{supportMessage.channel}</a></td>
          <td><u title={supportMessage.message}>{supportMessage.subject}</u></td>
        </tr>
      );
    });

    const supportMessagesTable = (
      <div>
        <h2 className="text-center">Support Comms</h2>
        <Table>
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Date</th>
              <th>Channel</th>
              <th>Subject / Message</th>
            </tr>
          </thead>
          <tbody>
            {supportMessagesRows}
          </tbody>
        </Table>
      </div>
    );

    adminReports = (
      <div className="row">
        <div className="col-lg">
          {progressTable}
        </div>
        <div className="col-lg">
          {supportMessagesTable}
        </div>
        <style jsx>{`
          .col-lg {
            max-height: 50vh;
            overflow: scroll;
          }
    `}</style>
      </div>
    );

  } else {
    adminReports = (
      <h3>Loading…</h3>
    );
  }

  return (
    <div className="d-flex flex-column align-items-center">
      <h1>📛 Admin Dashboard 📛</h1>
      <div className="container-fluid mt-5">
        {adminReports}
        <EmbeddedProductReport />
        <EmbeddedTenantsReport />
      </div>
    </div>
  );

  function EmbeddedProductReport(props) {
    const previewUrl = 'https://app.posthog.com/shared_dashboard/_N-oBBa7B3kvemEqlaesCNNsSHf1UQ';

    return <div className="row my-5">
      <div className="col-lg">
        <div className="embed-responsive embed-responsive-1by1">
          <iframe className="embed-responsive-item" src={previewUrl} />
        </div>
      </div>
    </div>;
  }
}

function EmbeddedTenantsReport(props) {
  const previewUrl = 'https://app.posthog.com/shared_dashboard/30gFlDUB6ytNrYNb14E4fwp2uHUDJw';

  return <div className="row my-5">
    <div className="col-lg">
      <div className="embed-responsive embed-responsive-1by1">
        <iframe className="embed-responsive-item" src={previewUrl} />
      </div>
    </div>
  </div>;
}

function About() {
  return <h2>About</h2>;
}

// https://github.com/auth0/auth0-react/blob/master/EXAMPLES.md#1-protecting-a-route-in-a-react-router-dom-app
const ProtectedRoute = ({ component, roleRequired = null, ...args }) => {
  const WrappedComponent = withAuthenticationRequired(component, {
    onRedirecting: () => "resuming session…",
    roleRequired,
  });

  const retVal = (
    <Route render={(routeProps) => <WrappedComponent {...routeProps} {...args} />} />
  );

  return retVal;
};

// much of this was copied from auth0 lib
// node_modules/@auth0/auth0-react/src/with-authentication-required.tsx
function withAuthenticationRequired(Component, options) {
  return function WithAuthenticationRequired(props) {
    const { isAuthenticated, isLoaded, user } = useAuth();

    const {
      returnTo = defaultReturnTo,
      onRedirecting = defaultOnRedirecting,
      loginOptions = {},
      roleRequired = null,
    } = options;


    useEffect(async () => {
      let isAuthorized = false;

      if (isLoaded) {
        if (isAuthenticated) {
          if (roleRequired) {
            const userIdToken = await user.getIdTokenResult();
            const roleSatisifed = !!userIdToken.claims[roleRequired];

            isAuthorized = roleSatisifed;
          } else {
            isAuthorized = true;
          }
        }

        if (!isAuthorized) {
          const opts = {
            ...loginOptions,
            appState: {
              ...loginOptions.appState,
              returnTo: typeof returnTo === 'function' ? returnTo() : returnTo,
            },
          };

          history.push('/login', opts)
        }
      }

    }, [history, isAuthenticated, loginOptions, returnTo]);

    // TODO THIS DOESN'T WORK BECAUSE THE ROUTES USE .BIND TO BIND THEMECONFIG PROPS
    return isAuthenticated ? <Component {...props} /> : onRedirecting();
  };
}

const defaultReturnTo = () => `${window.location.pathname}${window.location.search}`;

const defaultOnRedirecting = () => <></>;

function CreateUserDeprecated(props) {
  return <>
    <h1 className="text-danger">You are visiting a deprecated page</h1>
    <p><a href="/admin/user/create/">Please bookmark and visit <pre style={{ display: "inline" }}>/user/create</pre> instead.</a></p>
    <br />
    <br />
    <br />
    <CreateUser {...props} />
  </>;
}
