import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { Form, FormGroup, Label } from 'reactstrap';
import { useDispatch } from "react-redux";
import { ReactstrapInputBlurChangeDetector, RichTextEditor } from '../../form/input';
import { saveFile, updateBookSeriesAbout, updateBookSeriesMeta, } from '@wip/common/event-store/website';
import * as dateUtils from '@wip/common/lib/date-utils';
import BookList from "./book-list";

const BookSeriesDetail = (props) => {
    const dispatch = useDispatch();

    function uploadImage(file) {
        const workPromise = dispatch(saveFile(file)).then(action => action.payload);

        return workPromise;
    }

    const richTextConfig = useMemo(() => {
        const retVal = { onImageUpload: uploadImage };

        return retVal;
    }, [dispatch]);

    const { register, formState: { errors } } = useForm({
        mode: 'onBlur',
        defaultValues: props.bookSeries
    });

    const { ref: titleRef, ...titleRest } = register('about.title', { required: true });
    const { ref: categoriesRef, ...categoriesRest } = register('about.categories');
    const { ref: orderRef, ...orderRest } = register('meta.order', /*todo this does not work*/{ valueAsNumber: true });
    const { ref: subheadRef, ...subheadRest } = register('about.subheading');
    const { ref: publishDateRef, ...publishDateRest } = register('schedule.publishDate');
    const { ref: amazonLinkRef, ...amazonLinkRest } = register('purchase.amazonLink');
    const { ref: barnesandnobleLinkRef, ...barnesandnobleLinkRest } = register('purchase.barnesandnobleLink');
    const { ref: bookshopLinkRef, ...bookshopLinkRest } = register('purchase.bookshopLink');
    const { ref: indieboundLinkRef, ...indieboundLinkRest } = register('purchase.indieboundLink');
    const { ref: custombookstore1NameRef, ...custombookstore1NameRest } = register('purchase.custombookstore1Name');
    const { ref: custombookstore1LinkRef, ...custombookstore1LinkRest } = register('purchase.custombookstore1Link');
    const { ref: custombookstore2NameRef, ...custombookstore2NameRest } = register('purchase.custombookstore2Name');
    const { ref: custombookstore2LinkRef, ...custombookstore2LinkRest } = register('purchase.custombookstore2Link');
    const { ref: custombookstore3NameRef, ...custombookstore3NameRest } = register('purchase.custombookstore3Name');
    const { ref: custombookstore3LinkRef, ...custombookstore3LinkRest } = register('purchase.custombookstore3Link');
    const { ref: custombookstore4NameRef, ...custombookstore4NameRest } = register('purchase.custombookstore4Name');
    const { ref: custombookstore4LinkRef, ...custombookstore4LinkRest } = register('purchase.custombookstore4Link');
    const { ref: custombookstore5NameRef, ...custombookstore5NameRest } = register('purchase.custombookstore5Name');
    const { ref: custombookstore5LinkRef, ...custombookstore5LinkRest } = register('purchase.custombookstore5Link');
    const { ref: custombookstore6NameRef, ...custombookstore6NameRest } = register('purchase.custombookstore6Name');
    const { ref: custombookstore6LinkRef, ...custombookstore6LinkRest } = register('purchase.custombookstore6Link');

    const { ref: awardsRef, ...awardsRest } = register('awards.content');

    // todo - this is duplicated throughout the app -- combine.
    const handleChangeEvent = (event, id, key, value) => {

        // for some reason isValid is always true even when not…
        if (errors && Object.keys(errors).length) {
            console.log('Skipping due to errors:', errors);
        } else {
            if (event) {
                id = event.target.dataset.id;
                key = event.target.name;

                if (event.target.type == 'checkbox') {
                    value = event.target.checked;

                } else {
                    value = event.target.value;
                }
            }

            if (!id) throw new Error('Handle change event `id` is missing.')
            if (!key) throw new Error('Handle change event `key` is missing.')
            if (value == undefined) throw new Error('Handle change event `value` must be set to a value or null.')

            const splitKeys = key.split('.');
            const path = splitKeys[0];
            const newKey = splitKeys[1];

            if (path == 'about') {
                dispatch(updateBookSeriesAbout({ id, key: newKey, value }));
            } else if (path == 'meta') {
                dispatch(updateBookSeriesMeta({ id, key: newKey, value }));
            }
        }
    };

    const handleCheckChangeEvent = (event, id, key, value) => {
        // // for some reason isValid is always true even when not…
        // if (errors && Object.keys(errors).length) {
        //     console.log('Skipping due to errors:', errors);
        // } else {
        //     if (event) {
        //         key = event.target.name;
        //         value = event.target.value;
        //     }

        //     if (!key) throw new Error('Handle change event `key` is missing.')
        //     if (value == undefined) throw new Error('Handle change event `value` must be set to a value or null.')


        //     const splitKeys = key.split('.');
        //     const path = splitKeys[0];
        //     const newKey = splitKeys[1];

        //     if (path == 'about') {
        //         dispatch(addGalleryToAbout({ key: newKey, value }));
        //     } else if (path == 'social') {
        //         dispatch(updateSocial({ key: newKey, value }));
        //     }
        // }

        // for some reason isValid is always true even when not…
        if (errors && Object.keys(errors).length) {
            console.log('Skipping due to errors:', errors);
        } else {

            if (event) {
                key = event.target.name;
                value = event.target.checked;
            }

            if (!key) throw new Error('Handle change event `key` is missing.')
            if (value == undefined) throw new Error('Handle change event `value` must be set to a value or null.')

            const splitKeys = key.split('.');
            const path = splitKeys[0];
            const newKey = splitKeys[1];
            if (path == 'products') {
                if (value) {
                    dispatch(addProductToBook({ id: props.book.id, productId: newKey }));
                } else {
                    dispatch(removeProductFromBook({ id: props.book.id, productId: newKey }));
                }
            }
        }
    };

    const handlePublishDateChangeEvent = async (e) => {
        publishDateRest.onChange && await publishDateRest.onChange(e);

        const id = e.target.dataset.id;
        const key = e.target.name;
        const value = dateUtils.parseDate(e.target.value);
        handleChangeEvent(null, id, key, value);
    };

    const onCoverPhotoChange = e => {
        const id = e.target.dataset.id;
        const coverPhotoFile = e.target.files[0];
        // dispatch async thunks are promises
        // https://redux-toolkit.js.org/api/createAsyncThunk#unwrapping-result-actions
        dispatch(saveFile(coverPhotoFile)).then(action => {
            const downloadUrl = action.payload;

            dispatch(updateBookAssets({ id, key: 'coverPhoto', value: downloadUrl }));
            e.target.value = "";
        });
    };

    const onCoverPhotoRemove = e => {
        const id = e.target.dataset.id;
        dispatch(updateBookAssets({ id, key: 'coverPhoto', value: null }));
    };


    // todo - this is duplicated throughout the app -- combine.
    const handleRichEditorChangeEvent = (id, key, value) => {
        // for some reason isValid is always true even when not…
        if (errors && Object.keys(errors).length) {
            console.log('Skipping due to errors:', errors);
        } else {
            if (!id) throw new Error('Handle change event `id` is missing.')
            if (!key) throw new Error('Handle change event `key` is missing.')
            if (value == undefined) throw new Error('Handle change event `value` must be set to a value or null.')

            const splitKeys = key.split('.');
            const path = splitKeys[0];
            const newKey = splitKeys[1];

            if (path == 'about') {
                dispatch(updateBookSeriesAbout({ id, key: newKey, value }));
            }
        }
    };

    const booksInSeries = props.websiteData.books.filter(b => b.series.id == props.bookSeries.id);

    const bookViewElelement = (
        <Form>
            <FormGroup>
                <Label for="about.title">Title</Label>
                <ReactstrapInputBlurChangeDetector id="about.title" invalid={!!errors.firstName} type="text" data-id={props.bookSeries.id} {...titleRest} innerRef={titleRef} onDiff={handleChangeEvent} />
            </FormGroup>
            <FormGroup>
                <Label for="meta.order">Series Order</Label>
                <ReactstrapInputBlurChangeDetector id="meta.order" type="number" data-id={props.bookSeries.id} {...orderRest} innerRef={orderRef} onDiff={handleChangeEvent} />
            </FormGroup>
            <FormGroup>
                <Label for="about.subheading">Sub Heading</Label>
                <ReactstrapInputBlurChangeDetector id="about.subheading" type="text" data-id={props.bookSeries.id} {...subheadRest} innerRef={subheadRef} onDiff={handleChangeEvent} />
            </FormGroup>
            <FormGroup>
                <Label for="about.description">Book Series Description</Label>
                <RichTextEditor defaultValue={props.bookSeries.about.description} onDiff={handleRichEditorChangeEvent.bind(null, props.bookSeries.id, 'about.description')} {...richTextConfig} />
            </FormGroup>
            <BookList books={booksInSeries} onSelect={props.onBookSelect} {...props} />
        </Form>
    );

    return bookViewElelement;
}

export default BookSeriesDetail;
