import _ from 'lodash';

import * as objectUtils from '../lib/object-utils';
import * as textUtils from '../lib/text-utils';
import { getFirebaseKeyV3 } from '../lib/firebase';
import { DataClass } from '../lib/object-utils';
import Book from './book';
import BookSeries from './book-series';
import CustomPage from './custom-page';
import Event from './event';
import Gallery from './gallery';
import GalleryItem from './gallery-item';
import LandingPage from './landing-page';
import NavigationGroup from './navigation-group';
import NavigationGroupItem from './navigation-group-item';
import Post from './post';
import Product from './product';
import Slide from './slide';

import { _createNewListItem_v1_0_0, _doListItemComponentPropertyUpdate_v1_0_0, _doListItemDelete_v1_0_0, _doListItemRelationshipDelete_v1_0_0, _doListItemRelationshipUpdate_v1_0_0, _doPageChildComponentPropertyUpdate_v1_0_0, _getListComponentPropertyValueFromExpression_v1_0_0, _getPageChildComponentPropertyValueFromExpression_v1_0_0, _getPropertyValueFromExpression_v1_0_0 } from '../app/wiml/versions/v1/v1.0.0/site-data/modification';
import { IWiml_v1, SearchQuery_v1 } from "app/wiml/versions/v1/types";
import { Wiml_v1 } from "../app/wiml/versions/v1/wiml-v1";


export const WEBSITE_PREFIXES = {
    LIST_ITEM_PREFIX: 'li',
    WEBSITE_PREFIX: 'site',
};

class Style extends DataClass {
    wiml: Wiml_v1;

    constructor(data: { wiml: IWiml_v1 }) {
        super(data);
        this.wiml = new Wiml_v1(data.wiml);
    }
};

export default class Website extends DataClass {
    id: any;
    contact: any;
    style: Style;
    social: any;
    meta: any;
    about: any;
    customHtml: any;
    assets: any;
    ecommerce: any;
    onboard: any;
    navigation: any;
    bookSeries: any;
    books: any;
    posts: any;
    events: any;
    customPages: any;
    landingPages: any;
    slides: any;
    slides2: any;
    products: any;
    galleries: any;
    navigationGroups: any;

    // #region Website
    constructor(websiteId: any, data: any) {
        super(data);
        // todo - logical or assignment causing syntax error
        // this.about ||= {};
        // https://stackoverflow.com/questions/51137950/using-next-js-with-yarn-workspaces

        this.id = websiteId;

        this.contact = this.contact || {};
        this.style = new Style(this.style || { wiml: null });

        this.social = this.social || {};
        this.meta = this.meta || {};
        const createdDateString = this.meta.createdDate;
        if (createdDateString) {
            this.meta.createdDate = new Date(createdDateString);
        }
        this.about = this.about || { firstName: null, galleries: [] };
        this.customHtml = this.customHtml || { pages: {} };
        this.assets = this.assets || {};
        this.ecommerce = this.ecommerce || {};
        this.onboard = this.onboard || {};

        // todo: move navigationGroups into navigation
        this.navigation = this.navigation || {};

        if (this.meta.appVersion != 'v4') {
            this.bookSeries = (this.bookSeries || [])
                .map((b: { [x: string]: any; id: any; }) => {
                    const { id, ...rest } = b;
                    const bookSeries = new BookSeries(id, rest);
                    return bookSeries;
                });
            this._sortBookSeries();

            this.books = (this.books || [])
                .map((b: { [x: string]: any; id: any; }) => {
                    const { id, ...rest } = b;
                    const book = new Book(id, rest);
                    return book;
                });
            this._sortBooks();

            this.posts = (this.posts || [])
                .map((p: { [x: string]: any; id: any; }) => {
                    const { id, ...rest } = p;
                    const post = new Post(id, rest);
                    return post;
                });
            this._sortPosts();

            this.events = (this.events || [])
                .map((e: { [x: string]: any; id: any; }) => {
                    const { id, ...rest } = e;
                    const event = new Event(id, rest);
                    return event;
                });
            // .sort((a, b) => b.schedule.date ? b.schedule.date - a.schedule.date : -1);
            this._sortEvents();

            this.customPages = (this.customPages || [])
                .map((cp: { [x: string]: any; id: any; }) => {
                    const { id, ...rest } = cp;
                    const customPage = new CustomPage(id, rest);
                    return customPage;
                });
            // .sort((a, b) => a.meta.order ? a.meta.order - b.meta.order : -1);
            this._sortCustomPages();

            this.landingPages = (this.landingPages || [])
                .map((cp: { [x: string]: any; id: any; }) => {
                    const { id, ...rest } = cp;
                    const landingPage = new LandingPage(id, rest);
                    return landingPage;
                });
            // .sort((a, b) => a.meta.order ? a.meta.order - b.meta.order : -1);
            this._sortLandingPages();

            this.slides = (this.slides || [])
                .map((s: { [x: string]: any; id: any; }) => {
                    const { id, ...rest } = s;
                    const slide = new Slide(id, rest);
                    return slide;
                });
            // .sort((a, b) => a.meta.order ? a.meta.order - b.meta.order : -1);
            this._sortSlides();

            this.slides2 = (this.slides2 || [])
                .map((s: { [x: string]: any; id: any; }) => {
                    const { id, ...rest } = s;
                    const slide = new Slide(id, rest);
                    return slide;
                });
            // .sort((a, b) => a.meta.order ? a.meta.order - b.meta.order : -1);
            this._sortSlides2();

            this.products = (this.products || [])
                .map((p: { [x: string]: any; id: any; }) => {
                    const { id, ...rest } = p;
                    const slide = new Product(id, rest);
                    return slide;
                });
            this._sortProducts();

            this.galleries = (this.galleries || [])
                .map((p: { [x: string]: any; id: any; }) => {
                    const { id, ...rest } = p;
                    const gallery = new Gallery(id, rest);
                    return gallery;
                });
            // .sort((a, b) => a.meta.order ? a.meta.order - b.meta.order : -1);
            this._sortGalleries();

            this.navigationGroups = (this.navigationGroups || [])
                .map((p: { [x: string]: any; id: any; }) => {
                    const { id, ...rest } = p;
                    const pg = new NavigationGroup(id, rest);
                    return pg;
                });
            // .sort((a, b) => a.meta.order ? a.meta.order - b.meta.order : -1);
            this._sortNavigationGroups();
        }
    }

    static createNewWebsite(websiteId: string, { websiteType, slug, source, firstName, lastName, email, bookCategory, inviteCode, wimlThemeId, wimlMarkup }: { websiteType: string, slug: any; source: string; firstName: any; lastName: any; email: any; bookCategory: any; inviteCode: any; wimlThemeId: any; wimlMarkup: any; }) {
        const themeId = wimlThemeId;
        // const themeId = getThemeFromCategory_v3(bookCategory);

        let purchased = false;
        const code = inviteCode && inviteCode.toLowerCase();
        if (code == '4ever') {
            purchased = true
        }

        // let websiteType = 'standard';
        // if (/^test/i.test(firstName)) {
        //     websiteType = 'demo';
        // }

        const data = {
            meta: {
                slug,
                purchased,
                createdDate: new Date(),
                websiteType,
                appVersion: 'v4', //todo remove 'v',
                ...(source && { source }),
            },
            about: {
                firstName,
                lastName,
                bio: textUtils.generatePlaceholderText(2),
            },
            contact: {
                email,
                content: "Say hello! I'd love to hear from you. Drop me an email using the contact form below."
            },
            style: {
                ...new Style({ wiml: null }),
                baseThemeId: themeId,
            },
            assets: {
                profileImage: headshotPhoto,
            },
            onboard: {
                status: 'not_started',
            },
            books: [
                {
                    id: getFirebaseKeyV3(),
                    about: {
                        title: "My Book vol 1",
                        subheading: "Adventure Awaits",
                        description: textUtils.generatePlaceholderText(),
                        moreInfo: textUtils.generatePlaceholderText(2),
                    },
                    reviews: {
                        content: textUtils.generatePlaceholderText(1),
                    },
                    purchase: {
                        indieboundLink: 'https://www.indiebound.org/',
                    },
                    assets: {
                        coverPhoto: bookCoverPhoto,
                    },
                }
            ],
        };

        const website = new Website(websiteId, data);
        website.updateStyle('wiml', wimlMarkup);
        return website;
    }

    updateAbout(key: string, value: any) {
        this.about[key] = value;
    }

    updateAssets(key: string, value: any) {
        this.assets[key] = value;
    }

    updateStyle(key: string, value: any) {
        if (key == 'wiml') {
            this._updateWiml(value);
        } else {
            this.style[key] = value;
        }
    }

    _updateWiml(value: string) {
        this.style.wiml.updateMarkup(value);
    }

    createPageComponent(pageKey: string) {
        return this.style.wiml.createThemeDataPageComponent(pageKey);
    }

    createPageChildComponent(pageKey: string, componentType: string, componentKey: string, themeProps: Record<string, any>, position?: string, parentComponentType?: string, parentComponentKey?: string, content?: string,) {
        return this.style.wiml.createThemeDataPageChildComponent(pageKey, componentType, componentKey, themeProps, position, parentComponentType, parentComponentKey, content);
    }

    addPageChildComponentContent(pageKey: string, componentType: string, componentKey: string, themeProps: Record<string, any>, position?: string, parentComponentType?: string, parentComponentKey?: string, content?: string,) {
        return this.style.wiml.addThemeDataPageChildComponentContent(pageKey, componentType, componentKey, themeProps, position, parentComponentType, parentComponentKey, content);
    }

    movePageChildComponent(pageId: string, componentId: string, position: string | number) {
        return this.style.wiml.moveThemeDataPageChildComponent(pageId, componentId, position);
    }

    replaceStyleImportStatement(newImport: string) {
        return this.style.wiml.replaceStyleImportStatement(newImport);
    }

    replaceStyleCssVars(newCssVars: string) {
        return this.style.wiml.replaceStyleCssVars(newCssVars);
    }

    updatePageChildComponentProperty(pageId: string, componentId: string, propertyId: string, propertyExpression: string) {
        this.style.wiml.updatePageChildComponentProperty(pageId, componentId, propertyId, propertyExpression);
    }

    getListComponentThemeData(listId: string) {
        return this.style.wiml.getListComponentThemeData(listId);
    }

    getPageComponentThemeData(pageId: string) {
        return this.style.wiml.getPageComponentThemeData(pageId);
    }

    getPageChildComponentThemeData(pageId: string, componentId: string) {
        const bbbbb = this.style.wiml.getPageChildComponentThemeData(pageId, componentId);
        return bbbbb;
    }

    getPageChildListInstanceComponentThemeData(pageId: string, listInstanceComponentId: string) {
        return this.style.wiml.getPageChildListInstanceComponentThemeData(pageId, listInstanceComponentId);
    }

    getPageChildListInstanceChildComponentThemeData(pageId: string, listInstanceComponentId: string, componentId: string) {
        return this.style.wiml.getPageChildListInstanceChildComponentThemeData(pageId, listInstanceComponentId, componentId);
    }

    updateListItemComponentProperty(listId: string, listItemId: string, componentId: string, propertyId: string, propertyExpression: string) {
        this.style.wiml.updateListItemComponentProperty(listId, listItemId, componentId, propertyId, propertyExpression);
    }

    deleteListItem(listId: string, listItemId: string) {
        this.style.wiml.deleteListItem(listId, listItemId);
    }

    createNewListItem(listItemId: string, listId: string) {
        const newItem = this.style.wiml.createNewListItem(listItemId, listId);

        return newItem;
    }

    updateRelationship(listId: string, listItemId: string, relationshipListId: string, relationshipListItemId: string) {
        this.style.wiml.updateRelationship(listId, listItemId, relationshipListId, relationshipListItemId);
    }

    deleteRelationship(listId: string, listItemId: string, relationshipListId: string, relationshipListItemId: string) {
        this.style.wiml.deleteRelationship(listId, listItemId, relationshipListId, relationshipListItemId);
    }

    searchSiteData(query: SearchQuery_v1) {
        return this.style.wiml.searchSiteData(query);
    }

    updateContact(key: string, value: any) {
        this.contact[key] = value;
    }

    updateCustomHtmlPage(key: string, value: any) {
        this.customHtml.pages[key] = value;
    }

    updateEcommerce(key: string, value: any) {
        this.ecommerce[key] = value;
    }

    updateOnboard(key: string, value: any) {
        this.onboard[key] = value;
    }
    _getOnboardStatusIndex() {
        return _getOnboardStatusIndex(this.onboard.status);
    }
    onboardStatusIsOnOrAfter(statusInQuestion: string) {
        return _getOnboardStatusIndex(this.onboard.status) >= _getOnboardStatusIndex(statusInQuestion);
    }
    onboardAdvance(fromStatus: string) {
        handleOnboardEvent(this.onboard.status, 'NEXT', { website: this, fromStatus });
    }

    updateSocial(key: string, value: any) {
        this.social[key] = value;
    }

    addGalleryToAbout(galleryId: any) {
        this.about.galleries.push(galleryId);
    }

    removeGalleryFromAbout(galleryId: any) {
        this.about.galleries = this.about.galleries.filter((gId: any) => gId != galleryId);
    }

    updateNavigation(key: string, value: any) {
        this.navigation[key] = value;
    }

    get navigationBlogTagsCollection() {
        const retVal = (this.navigation.blogTags || "")
            .split("\n")
            .filter(Boolean)
            .map((t: string) => t.trim())
            .map((t: any) => ({ key: textUtils.convertToSlug(t), value: t }));

        return retVal;
    }

    // ideally we'd have an event listener for changes to site that would reflect progress %
    get onboardProgress() {
        const index = _getOnboardStatusIndex(this.onboard.status);
        return (index) / Object.keys(statusList).length / 2 * 100;
    }
    // #endregion Website

    // #region Books
    createBook(bookId: any, data: { about: { title: string; }; }) {
        if (!data) {
            const title = 'New Book';

            data = {
                about: { title },
            }
        }

        const book = new Book(bookId, { ...data });

        // NOTE: most objects have a `meta.order` prop. Although books support that, they are ordered by release_date by default.
        this.books.push(book);

        const x = this._sortBooks();
        const b = x;
    }

    deleteBook(bookId: string) {
        this.books = this.books.filter((b: { id: any; }) => b.id !== bookId);

        // remove customhtml - book
        delete this.customHtml.pages[bookId];

        this._sortBooks();
    }

    updateBookAbout(bookId: any, key: string, value: any) {
        const book = this.books.find((b: { id: any; }) => b.id == bookId);

        book.about[key] = value;
    }

    updateBookPurchase(bookId: any, key: string, value: any) {
        const book = this.books.find((b: { id: any; }) => b.id == bookId);

        book.purchase[key] = value;
    }

    updateBookAwards(bookId: any, key: string, value: any) {
        const book = this.books.find((b: { id: any; }) => b.id == bookId);

        book.awards[key] = value;
    }

    updateBookAssets(bookId: any, key: string, value: any) {
        const book = this.books.find((b: { id: any; }) => b.id == bookId);

        book.assets[key] = value;
    }

    updateBookMeta(bookId: any, key: string, value: any) {
        const book = this.books.find((b: { id: any; }) => b.id == bookId);

        book.meta[key] = value;

        this._sortBooks();
    }

    updateBookReviews(bookId: any, key: string, value: any) {
        const book = this.books.find((b: { id: any; }) => b.id == bookId);

        book.reviews[key] = value;
    }

    updateBookSchedule(bookId: any, key: string, value: any) {
        const book = this.books.find((b: { id: any; }) => b.id == bookId);
        if (key.toLocaleLowerCase().endsWith('date') && value) {
            value = new Date(value);
        }
        book.schedule[key] = value;

        this._sortBooks();
    }

    updateBook__Series(bookId: any, key: string, value: any) {
        const book = this.books.find((b: { id: any; }) => b.id == bookId);

        book.series[key] = value;

        this._sortBooks();
    }

    addProductToBook(bookId: any, productId: any) {
        const book = this.books.find((b: { id: any; }) => b.id == bookId);

        book.products.push(productId);
    }

    removeProductFromBook(bookId: any, productId: any) {
        const book = this.books.find((b: { id: any; }) => b.id == bookId);

        book.products = book.products.filter((p: any) => p != productId);
    }

    // _sortBooks() {
    //     const seriesDict = objectUtils.convertArrayToObject(this.bookSeries);

    //     this.books = _
    //         .chain(this.books)
    //         .sortBy(this.books, b => {
    //             let retVal = 0

    //             const series = seriesDict[b.series.id];
    //             if (series) {
    //                 retVal = series.meta.order;
    //             }

    //             return retVal;
    //         })
    //         .sortBy(this.books, b => {
    //             const retVal = b.series.order;

    //             return retVal;
    //         })
    //         .value();
    // }

    _sortBooks() {
        // not some users like anita and laurie force book order without series, but does provide a series order in the admin
        // this is a hack
        // it has been changed to a date desc instead, like events, by default.
        // note- this will change how featuredBook works now, as well as anything that depends on index[0] of books
        const seriesDict = objectUtils.convertArrayToObject(this.bookSeries);
        const sortedBooks = _
            .chain(this.books)
            // https://stackoverflow.com/questions/24111535/how-can-i-use-lodash-underscore-to-sort-by-multiple-nested-fields#comment44299290_24112128
            // multiple order by fields must be an array of functions, not a single array of values within 1 function
            .sortBy([
                b => {
                    let seriesOrder = 0

                    const series = seriesDict[b.series.id];
                    if (series) {
                        seriesOrder = series.meta.order;
                    }
                    return seriesOrder;
                },
                b => {
                    const bookOrderInSeries = b.series?.order !== undefined ? parseInt(b.series?.order) : -b.schedule?.publishDate?.getTime() || 0;
                    // const bookOrderInSeries = parseInt(b.series?.order) || b.schedule?.publishDate?.getTime() || 0;
                    return bookOrderInSeries;
                }
            ])
            .value();
        this.books = sortedBooks;
        return sortedBooks;
    }

    // #endregion Books



    // #region bookSeries
    createBookSeries(bookId: any, data: { about: { title: string; }; meta: { order: number; }; }) {
        if (!data) {
            const title = 'New Book Series';

            const maxBookSeriesOrder = this.bookSeries.length ? Math.max(...this.bookSeries.map((bs: { meta: { order: any; }; }) => bs.meta.order)) : 0;
            const order = maxBookSeriesOrder + 1;

            data = {
                about: { title },
                meta: { order },
            }
        }

        const book = new BookSeries(bookId, { ...data });

        // NOTE: most objects have a `meta.order` prop. Although bookSeries support that, they are ordered by release_date by default.
        this.bookSeries.push(book);

        this._sortBookSeries();
    }

    deleteBookSeries(bookSeriesId: string) {
        this.bookSeries = this.bookSeries.filter((b: { id: any; }) => b.id !== bookSeriesId);

        // remove customhtml - book
        delete this.customHtml.pages[bookSeriesId];

        // find each book connected to this series, remove series
        this.books.filter((b: { series: { id: any; }; }) => b.series.id == bookSeriesId).forEach((b: { id: any; }) => {
            this.updateBook__Series(b.id, 'id', null);
            this.updateBook__Series(b.id, 'order', null);
        });

        this._sortBookSeries();
    }

    updateBookSeriesAbout(bookSeriesId: any, key: string, value: any) {
        const bookSeries = this.bookSeries.find((b: { id: any; }) => b.id == bookSeriesId);

        bookSeries.about[key] = value;
    }

    updateBookSeriesMeta(bookSeriesId: any, key: string, value: any) {
        const bookSeries = this.bookSeries.find((b: { id: any; }) => b.id == bookSeriesId);

        bookSeries.meta[key] = value;

        this._sortBookSeries();
    }

    _sortBookSeries() {
        this.bookSeries = _.orderBy(this.bookSeries, ['meta.order'], ['asc']);
    }

    // #endregion BookSeries

    // #region Posts
    createPost(postId: any, data: { about: { title: string; }; }) {
        if (!data) {
            const title = 'New Blog Post';

            data = {
                about: { title },
            }
        }

        const post = new Post(postId, { ...data });

        this.posts.push(post);

        this._sortPosts();
    }

    deletePost(postId: any) {
        this.posts = this.posts.filter((p: { id: any; }) => p.id !== postId);

        this._sortPosts();
    }

    updatePostAbout(postId: any, key: string, value: any) {
        const post = this.posts.find((p: { id: any; }) => p.id == postId);

        post.about[key] = value;
    }

    updatePostAssets(postId: any, key: string, value: any) {
        const post = this.posts.find((p: { id: any; }) => p.id == postId);

        post.assets[key] = value;
    }

    updatePostSchedule(postId: any, key: string, value: any) {
        const post = this.posts.find((p: { id: any; }) => p.id == postId);

        post.schedule[key] = value;

        this._sortPosts();
    }

    _sortPosts() {
        this.posts = _.orderBy(this.posts, ['schedule.publishDate'], ['desc']);
    }

    // #endregion Posts

    // #region Events
    createEvent(eventId: any, data: { about: { title: string; }; }) {
        if (!data) {
            const title = 'New Event';

            data = {
                about: { title },
            }
        }

        const event = new Event(eventId, { ...data });

        // NOTE: most objects have a `meta.order` prop. Although events support that, they are ordered by release_date by default.
        this.events.push(event);

        this._sortEvents();
    }

    deleteEvent(eventId: any) {
        this.events = this.events.filter((e: { id: any; }) => e.id !== eventId);

        this._sortEvents();
    }

    updateEventAbout(eventId: any, key: string, value: any) {
        const event = this.events.find((e: { id: any; }) => e.id == eventId);

        event.about[key] = value;
    }

    updateEventLocation(eventId: any, key: string, value: any) {
        const event = this.events.find((e: { id: any; }) => e.id == eventId);

        event.location[key] = value;
    }

    updateEventSchedule(eventId: any, key: string, value: any) {
        const event = this.events.find((e: { id: any; }) => e.id == eventId);

        event.schedule[key] = value;

        this._sortEvents();
    }

    _sortEvents() {
        this.events = _.orderBy(this.events, ['schedule.date'], ['desc']);
    }

    // #endregion Events

    // #region CustomPages
    createCustomPage(customPageId: any, data: { about: { title: string; }; meta: { order: number; }; }) {
        if (!data) {
            const title = 'New Custom Page';

            const maxCustomPageOrder = this.customPages.length ? Math.max(...this.customPages.map((cp: { meta: { order: any; }; }) => cp.meta.order)) : 0;
            const order = maxCustomPageOrder + 1;

            data = {
                about: { title },
                meta: { order },
            }
        }

        const customPage = new CustomPage(customPageId, { ...data });

        this.customPages.push(customPage);

        this._sortCustomPages();
    }

    deleteCustomPage(customPageId: string) {
        this.customPages = this.customPages.filter((cp: { id: any; }) => cp.id !== customPageId);

        // remove nav group -> nav item -> customPages
        this.navigationGroups.forEach((ng: { items: any[]; id: any; }) => {
            ng.items
                .filter((ngi: { page: { id: any; }; }) => {
                    return ngi.page.id == customPageId;
                })
                .forEach((ngi: { id: any; }) => {
                    this.deleteNavigationGroupItem(ngi.id, ng.id);
                });
        });

        // remove customhtml - custom pg
        delete this.customHtml.pages[customPageId];

        this._sortCustomPages();
    }

    updateCustomPageAbout(customPageId: any, key: string, value: any) {
        const customPage = this.customPages.find((cp: { id: any; }) => cp.id == customPageId);

        customPage.about[key] = value;
    }

    updateCustomPagePurchase(customPageId: any, key: string, value: any) {
        const customPage = this.customPages.find((cp: { id: any; }) => cp.id == customPageId);

        customPage.purchase[key] = value;
    }

    updateCustomPageAwards(customPageId: any, key: string, value: any) {
        const customPage = this.customPages.find((cp: { id: any; }) => cp.id == customPageId);

        customPage.awards[key] = value;
    }

    updateCustomPageAssets(customPageId: any, key: string, value: any) {
        const customPage = this.customPages.find((cp: { id: any; }) => cp.id == customPageId);

        customPage.assets[key] = value;
    }

    updateCustomPageMeta(customPageId: any, key: string, value: any) {
        const customPage = this.customPages.find((cp: { id: any; }) => cp.id == customPageId);

        customPage.meta[key] = value;

        this._sortCustomPages();
    }

    updateCustomPageReviews(customPageId: any, key: string, value: any) {
        const customPage = this.customPages.find((cp: { id: any; }) => cp.id == customPageId);

        customPage.reviews[key] = value;
    }

    updateCustomPageSchedule(customPageId: any, key: string, value: any) {
        const customPage = this.customPages.find((cp: { id: any; }) => cp.id == customPageId);

        customPage.schedule[key] = value;

        this._sortCustomPages();
    }

    updateCustomPageNavigation(customPageId: any, key: string, value: any) {
        const customPage = this.customPages.find((lp: { id: any; }) => lp.id == customPageId);

        customPage.navigation[key] = value;

        this._sortCustomPages();
    }

    addProductToCustomPage(customPageId: any, productId: any) {
        const customPage = this.customPages.find((cp: { id: any; }) => cp.id == customPageId);

        customPage.products.push(productId);
    }

    removeProductFromCustomPage(customPageId: any, productId: any) {
        const customPage = this.customPages.find((cp: { id: any; }) => cp.id == customPageId);

        customPage.products = customPage.products.filter((p: any) => p != productId);
    }

    addGalleryToCustomPage(customPageId: any, galleryId: any) {
        const customPage = this.customPages.find((cp: { id: any; }) => cp.id == customPageId);

        customPage.galleries.push(galleryId);
    }

    removeGalleryFromCustomPage(customPageId: any, galleryId: any) {
        const customPage = this.customPages.find((cp: { id: any; }) => cp.id == customPageId);

        customPage.galleries = customPage.galleries.filter((p: any) => p != galleryId);
    }

    _sortCustomPages() {
        this.customPages = _.orderBy(this.customPages, ['meta.order'], ['asc']);
    }

    // #endregion CustomPages

    // #region LandingPages
    createLandingPage(landingPageId: any, data: { about: { title: string; }; meta: { order: number; }; }) {
        if (!data) {
            const title = 'New Landing Page';

            const maxLandingPageOrder = this.landingPages.length ? Math.max(...this.landingPages.map((cp: { meta: { order: any; }; }) => cp.meta.order)) : 0;
            const order = maxLandingPageOrder + 1;

            data = {
                about: { title },
                meta: { order },
            }
        }

        const landingPage = new LandingPage(landingPageId, { ...data });

        this.landingPages.push(landingPage);

        this._sortLandingPages();
    }

    deleteLandingPage(landingPageId: string) {
        this.landingPages = this.landingPages.filter((lp: { id: any; }) => lp.id !== landingPageId);

        // remove nav group -> nav item -> landing pg
        this.navigationGroups.forEach((ng: { items: any[]; id: any; }) => {
            ng.items
                .filter((ngi: { page: { id: any; }; }) => {
                    return ngi.page.id == landingPageId;
                })
                .forEach((ngi: { id: any; }) => {
                    this.deleteNavigationGroupItem(ngi.id, ng.id);
                });
        });

        // remove customhtml - landing pg
        delete this.customHtml.pages[landingPageId];

        this._sortLandingPages();
    }

    updateLandingPageAbout(landingPageId: any, key: string, value: any) {
        const landingPage = this.landingPages.find((lp: { id: any; }) => lp.id == landingPageId);

        landingPage.about[key] = value;
    }

    updateLandingPagePurchase(landingPageId: any, key: string, value: any) {
        const landingPage = this.landingPages.find((lp: { id: any; }) => lp.id == landingPageId);

        landingPage.purchase[key] = value;
    }

    updateLandingPageAwards(landingPageId: any, key: string, value: any) {
        const landingPage = this.landingPages.find((lp: { id: any; }) => lp.id == landingPageId);

        landingPage.awards[key] = value;
    }

    updateLandingPageAssets(landingPageId: any, key: string, value: any) {
        const landingPage = this.landingPages.find((lp: { id: any; }) => lp.id == landingPageId);

        landingPage.assets[key] = value;
    }

    updateLandingPageMeta(landingPageId: any, key: string, value: any) {
        const landingPage = this.landingPages.find((lp: { id: any; }) => lp.id == landingPageId);

        landingPage.meta[key] = value;

        this._sortLandingPages();
    }

    updateLandingPageReviews(landingPageId: any, key: string, value: any) {
        const landingPage = this.landingPages.find((lp: { id: any; }) => lp.id == landingPageId);

        landingPage.reviews[key] = value;
    }

    updateLandingPageSchedule(landingPageId: any, key: string, value: any) {
        const landingPage = this.landingPages.find((lp: { id: any; }) => lp.id == landingPageId);

        landingPage.schedule[key] = value;

        this._sortLandingPages();
    }

    updateLandingPageNavigation(landingPageId: any, key: string, value: any) {
        const landingPage = this.landingPages.find((lp: { id: any; }) => lp.id == landingPageId);

        landingPage.navigation[key] = value;

        this._sortLandingPages();
    }

    updateLandingPageForm(landingPageId: any, key: string, value: any) {
        const landingPage = this.landingPages.find((lp: { id: any; }) => lp.id == landingPageId);

        landingPage.form[key] = value;

        this._sortLandingPages();
    }

    addProductToLandingPage(landingPageId: any, productId: any) {
        const landingPage = this.landingPages.find((lp: { id: any; }) => lp.id == landingPageId);

        landingPage.products.push(productId);
    }

    removeProductFromLandingPage(landingPageId: any, productId: any) {
        const landingPage = this.landingPages.find((lp: { id: any; }) => lp.id == landingPageId);

        landingPage.products = landingPage.products.filter((p: any) => p != productId);
    }

    addGalleryToLandingPage(landingPageId: any, galleryId: any) {
        const landingPage = this.landingPages.find((lp: { id: any; }) => lp.id == landingPageId);

        landingPage.galleries.push(galleryId);
    }

    removeGalleryFromLandingPage(landingPageId: any, galleryId: any) {
        const landingPage = this.landingPages.find((lp: { id: any; }) => lp.id == landingPageId);

        landingPage.galleries = landingPage.galleries.filter((g: any) => g != galleryId);
    }

    _sortLandingPages() {
        this.landingPages = _.orderBy(this.landingPages, ['meta.order'], ['asc']);
    }

    // #endregion LandingPages

    // #region Slides
    createSlide(slideId: any, data: { about: { title: string; }; meta: { order: number; }; }) {
        if (!data) {
            const title = 'New Slide';

            const maxSlideOrder = this.slides.length ? Math.max(...this.slides.map((s: { meta: { order: any; }; }) => s.meta.order)) : 0;
            const order = maxSlideOrder + 1;

            data = {
                about: { title },
                meta: { order }
            }
        }

        const slide = new Slide(slideId, { ...data });

        this.slides.push(slide);

        this._sortSlides();
    }

    deleteSlide(slideId: any) {
        this.slides = this.slides.filter((s: { id: any; }) => s.id !== slideId);

        this._sortSlides();
    }

    updateSlideAbout(slideId: any, key: string, value: any) {
        const slide = this.slides.find((s: { id: any; }) => s.id == slideId);

        slide.about[key] = value;
    }

    updateSlideAssets(slideId: any, key: string, value: any) {
        const slide = this.slides.find((s: { id: any; }) => s.id == slideId);

        slide.assets[key] = value;
    }

    updateSlideMeta(slideId: any, key: string, value: any) {
        const slide = this.slides.find((s: { id: any; }) => s.id == slideId);

        slide.meta[key] = value;

        this._sortSlides();
    }

    updateSlideNavigation(slideId: any, key: string, value: any) {
        const slide = this.slides.find((s: { id: any; }) => s.id == slideId);

        slide.navigation[key] = value;
    }

    _sortSlides() {
        this.slides = _.orderBy(this.slides, ['meta.order'], ['asc']);
    }

    // #endregion Slides

    // #region Slides2
    createSlide2(slideId: any, data: { about: { title: string; }; meta: { order: number; }; }) {
        if (!data) {
            const title = 'New Slide';

            const maxSlideOrder = this.slides2.length ? Math.max(...this.slides2.map((s: { meta: { order: any; }; }) => s.meta.order)) : 0;
            const order = maxSlideOrder + 1;

            data = {
                about: { title },
                meta: { order }
            }
        }

        const slide = new Slide(slideId, { ...data });

        this.slides2.push(slide);

        this._sortSlides2();
    }

    deleteSlide2(slideId: any) {
        this.slides2 = this.slides2.filter((s: { id: any; }) => s.id !== slideId);

        this._sortSlides2();
    }

    updateSlide2About(slideId: any, key: string, value: any) {
        const slide = this.slides2.find((s: { id: any; }) => s.id == slideId);

        slide.about[key] = value;
    }

    updateSlide2Assets(slideId: any, key: string, value: any) {
        const slide = this.slides2.find((s: { id: any; }) => s.id == slideId);

        slide.assets[key] = value;
    }

    updateSlide2Meta(slideId: any, key: string, value: any) {
        const slide = this.slides2.find((s: { id: any; }) => s.id == slideId);

        slide.meta[key] = value;

        this._sortSlides2();
    }

    updateSlide2Navigation(slideId: any, key: string, value: any) {
        const slide = this.slides2.find((s: { id: any; }) => s.id == slideId);

        slide.navigation[key] = value;
    }

    _sortSlides2() {
        this.slides2 = _.orderBy(this.slides2, ['meta.order'], ['asc']);
    }

    // #endregion Slides2

    // #region Products
    createProduct(productId: any, data: { about: { title: string; type: string; }; price: { currency: string; }; meta: { order: number; }; }) {
        if (!data) {
            const title = 'New Product';
            const type = 'digital';
            const currency = 'USD';

            const maxProductOrder = this.products.length ? Math.max(...this.products.map((p: { meta: { order: any; }; }) => p.meta.order)) : 0;
            const order = maxProductOrder + 1;

            data = {
                about: { title, type },
                price: { currency },
                meta: { order },
            }
        }

        const product = new Product(productId, { ...data });

        this.products.push(product);

        this._sortProducts();
    }

    deleteProduct(productId: string) {
        this.products = this.products.filter((p: { id: any; }) => p.id !== productId);

        // remove book -> products
        this.books.forEach((b: { id: any; }) => {
            this.removeProductFromBook(b.id, productId);
        });

        // remove landing pages -> products
        this.landingPages.forEach((lp: { id: any; }) => {
            this.removeProductFromLandingPage(lp.id, productId);
        });

        // remove custom pages -> products
        this.customPages.forEach((cp: { id: any; }) => {
            this.removeProductFromCustomPage(cp.id, productId);
        });

        // remove customhtml - product
        delete this.customHtml.pages[productId];

        this._sortProducts();
    }

    updateProductAbout(productId: any, key: string, value: any) {
        const product = this.products.find((p: { id: any; }) => p.id == productId);

        product.about[key] = value;
    }

    updateProductAssets(productId: any, key: string, value: any) {
        const product = this.products.find((p: { id: any; }) => p.id == productId);

        product.assets[key] = value;
    }

    updateProductAttribute(productId: any, key: string, value: any) {
        const product = this.products.find((p: { id: any; }) => p.id == productId);

        product.attribute[key] = value;
    }

    updateProductMeta(productId: any, key: string, value: any) {
        const product = this.products.find((p: { id: any; }) => p.id == productId);

        product.meta[key] = value;

        this._sortProducts();
    }

    updateProductConfirmation(productId: any, key: string, value: any) {
        const product = this.products.find((p: { id: any; }) => p.id == productId);

        product.confirmation[key] = value;
    }

    updateProductSocial(productId: any, key: string, value: any) {
        const product = this.products.find((p: { id: any; }) => p.id == productId);

        product.social[key] = value;
    }

    updateProductStripe(productId: any, key: string, value: any) {
        const product = this.products.find((p: { id: any; }) => p.id == productId);

        product.stripe[key] = value;
    }

    updateProductSubscription(productId: any, key: string, value: any) {
        const product = this.products.find((p: { id: any; }) => p.id == productId);

        product.subscription[key] = value;
    }

    updateProductPrice(productId: any, key: string, value: string) {
        const product = this.products.find((p: { id: any; }) => p.id == productId);

        // ts type of scalar but not object
        let retVal: any = value;

        if (key == 'total') {
            const total = parseInt(value) * 100;
            retVal = total;
        }

        product.price[key] = retVal;
    }

    _sortProducts() {
        this.products = _.orderBy(this.products, ['meta.order'], ['asc']);
    }

    // #endregion Products

    // #region Galleries`
    createGallery(galleryId: any, data: { about: { title: string; }; meta: { order: number; }; }) {
        if (!data) {
            const title = 'New Gallery';

            const maxGalleryOrder = this.galleries.length ? Math.max(...this.galleries.map((g: { meta: { order: any; }; }) => g.meta.order)) : 0;
            const order = maxGalleryOrder + 1;

            data = {
                about: { title },
                meta: { order },
            }
        }

        const gallery = new Gallery(galleryId, { ...data });

        // NOTE: most objects have a `meta.order` prop. Although galleries support that, they are ordered by release_date by default.
        this.galleries.push(gallery);

        this._sortGalleries();
    }

    deleteGallery(galleryId: any) {
        this.galleries = this.galleries.filter((g: { id: any; }) => g.id !== galleryId);

        // remove about -> galleries
        this.removeGalleryFromAbout(galleryId);

        // remove landing pages -> galleries
        this.landingPages.forEach((lp: { id: any; }) => {
            this.removeGalleryFromLandingPage(lp.id, galleryId);
        });

        // remove custom pages -> galleries
        this.customPages.forEach((cp: { id: any; }) => {
            this.removeGalleryFromCustomPage(cp.id, galleryId);
        });

        this._sortGalleries();
    }

    updateGalleryAbout(galleryId: any, key: string, value: any) {
        const gallery = this.galleries.find((g: { id: any; }) => g.id == galleryId);

        gallery.about[key] = value;
    }

    updateGalleryAssets(galleryId: any, key: string, value: any) {
        const gallery = this.galleries.find((g: { id: any; }) => g.id == galleryId);

        gallery.assets[key] = value;
    }

    updateGalleryMeta(galleryId: any, key: string, value: any) {
        const gallery = this.galleries.find((g: { id: any; }) => g.id == galleryId);

        gallery.meta[key] = value;

        this._sortGalleries();
    }

    _sortGalleries() {
        this.galleries = _.orderBy(this.galleries, ['meta.order'], ['asc']);
    }
    // #endregion Galleries

    // #region GalleryItems
    createGalleryItem(galleryItemId: any, galleryId: any, data: Record<string, any>) {
        const gallery = this.galleries.find((g: { id: any; }) => g.id == galleryId);

        if (!data) {
            const maxGalleryItemOrder = gallery.items.length ? Math.max(...gallery.items.map((gi: { meta: { order: any; }; }) => gi.meta.order)) : 0;
            const order = maxGalleryItemOrder + 1;

            const title = 'New Gallery Item';

            data = {
                about: { title },
                meta: { order },
            }
        }

        const galleryItem = new GalleryItem(galleryItemId, { ...data });

        gallery.addItem(galleryItem);
    }

    deleteGalleryItem(galleryItemId: any, galleryId: any) {
        const gallery = this.galleries.find((g: { id: any; }) => g.id == galleryId);

        gallery.removeItem(galleryItemId);
    }

    updateGalleryItemAbout(galleryItemId: any, galleryId: any, key: any, value: any) {
        const gallery = this.galleries.find((g: { id: any; }) => g.id == galleryId);

        gallery.updateItemAbout(galleryItemId, key, value);
    }

    updateGalleryItemAssets(galleryItemId: any, galleryId: any, key: any, value: any) {
        const gallery = this.galleries.find((g: { id: any; }) => g.id == galleryId);

        gallery.updateItemAssets(galleryItemId, key, value);
    }

    updateGalleryItemMeta(galleryItemId: any, galleryId: any, key: any, value: any) {
        const gallery = this.galleries.find((g: { id: any; }) => g.id == galleryId);

        gallery.updateItemMeta(galleryItemId, key, value);
    }

    // #endregion GalleryItems

    // #region NavigationGroups
    createNavigationGroup(navigationGroupId: any, data: { about: { title: string; }; meta: { order: number; }; }) {
        if (!data) {
            const title = 'New Navigation Group';

            const maxNavigationGroupOrder = this.navigationGroups.length ? Math.max(...this.navigationGroups.map((ng: { meta: { order: any; }; }) => ng.meta.order)) : 0;
            const order = maxNavigationGroupOrder + 1;

            data = {
                about: { title },
                meta: { order },
            }
        }

        const navigationGroup = new NavigationGroup(navigationGroupId, { ...data });

        this.navigationGroups.push(navigationGroup);

        this._sortNavigationGroups();
    }

    deleteNavigationGroup(navigationGroupId: any) {
        this.navigationGroups = this.navigationGroups.filter((ng: { id: any; }) => ng.id !== navigationGroupId);

        this._sortNavigationGroups();
    }

    updateNavigationGroupAbout(navigationGroupId: any, key: string, value: any) {
        const navigationGroup = this.navigationGroups.find((ng: { id: any; }) => ng.id == navigationGroupId);

        navigationGroup.about[key] = value;
    }

    updateNavigationGroupAssets(navigationGroupId: any, key: string, value: any) {
        const navigationGroup = this.navigationGroups.find((ng: { id: any; }) => ng.id == navigationGroupId);

        navigationGroup.assets[key] = value;
    }

    updateNavigationGroupMeta(navigationGroupId: any, key: string, value: any) {
        const navigationGroup = this.navigationGroups.find((ng: { id: any; }) => ng.id == navigationGroupId);

        navigationGroup.meta[key] = value;

        this._sortNavigationGroups();
    }

    _sortNavigationGroups() {
        this.navigationGroups = _.orderBy(this.navigationGroups, ['meta.order'], ['asc']);
    }

    // #endregion NavigationGroups

    // #region NavigationGroupItems
    createNavigationGroupItem(navigationGroupItemId: any, navigationGroupId: any, data: { meta: { order: number; }; page: { id: string; }; }) {
        const navigationGroup = this.navigationGroups.find((ng: { id: any; }) => ng.id == navigationGroupId);

        if (!data) {
            const pageId = '_about';

            const maxNavigationGroupItemOrder = navigationGroup.items.length ? Math.max(...navigationGroup.items.map((gi: { meta: { order: any; }; }) => gi.meta.order)) : 0;
            const order = maxNavigationGroupItemOrder + 1;

            data = {
                meta: { order },
                page: { id: pageId },
            }
        }

        const navigationGroupItem = new NavigationGroupItem(navigationGroupItemId, { ...data });

        navigationGroup.addItem(navigationGroupItem);
    }

    deleteNavigationGroupItem(navigationGroupItemId: any, navigationGroupId: any) {
        const navigationGroup = this.navigationGroups.find((ng: { id: any; }) => ng.id == navigationGroupId);

        navigationGroup.removeItem(navigationGroupItemId);
    }

    updateNavigationGroupItemAbout(navigationGroupItemId: any, navigationGroupId: any, key: any, value: any) {
        const navigationGroup = this.navigationGroups.find((ng: { id: any; }) => ng.id == navigationGroupId);

        navigationGroup.updateItemAbout(navigationGroupItemId, key, value);
    }

    updateNavigationGroupItemAssets(navigationGroupItemId: any, navigationGroupId: any, key: any, value: any) {
        const navigationGroup = this.navigationGroups.find((ng: { id: any; }) => ng.id == navigationGroupId);

        navigationGroup.updateItemAssets(navigationGroupItemId, key, value);
    }

    updateNavigationGroupItemMeta(navigationGroupItemId: any, navigationGroupId: any, key: any, value: any) {
        const navigationGroup = this.navigationGroups.find((ng: { id: any; }) => ng.id == navigationGroupId);

        navigationGroup.updateItemMeta(navigationGroupItemId, key, value);
    }

    updateNavigationGroupItemPage(navigationGroupItemId: any, navigationGroupId: any, key: any, value: any) {
        const navigationGroup = this.navigationGroups.find((ng: { id: any; }) => ng.id == navigationGroupId);

        navigationGroup.updateItemPage(navigationGroupItemId, key, value);
    }

    // #endregion NavigationGroupItems

    // #region Props
    get featuredBook() {
        const retVal = /*todo: emove this .find statement - we don't use it and no one uses it*/this.books.find((b: { meta: { featured: any; }; }) => b.meta.featured) || this.activeBooks[0];

        return retVal;
    }

    get activeBooks() {
        const retVal = this.books.filter((b: { schedule: { draft: any; }; }) => !b.schedule.draft);

        return retVal;
    }

    get activeEvents() {
        const retVal = this.events.filter((e: { schedule: { draft: any; }; }) => !e.schedule.draft);

        return retVal;
    }

    get activePosts() {
        const retVal = this.posts.filter((p: { schedule: { draft: any; }; }) => !p.schedule.draft);

        return retVal;
    }

    get activeCustomPages() {
        const retVal = this.customPages.filter((cp: { schedule: { draft: any; }; }) => !cp.schedule.draft);

        return retVal;
    }

    // todo rename ActiveCustomPages to PublishedCustomPages
    get activeAndViewableCustomPages() {
        const retVal = this.activeCustomPages.filter((cp: { navigation: { displayInNav: any; }; }) => cp.navigation.displayInNav);

        return retVal;
    }

    get activeLandingPages() {
        const retVal = this.landingPages.filter((lp: { schedule: { draft: any; }; }) => !lp.schedule.draft);

        return retVal;
    }

    get activeProducts() {
        const retVal = this.products.filter((p: { schedule: { draft: any; }; }) => !p.schedule.draft);

        return retVal;
    }
    // #endregion Props
}

function getThemeFromCategory_v3(bookCategory: any) {
    let themeId = 'fanning-the-pages:default';

    switch (bookCategory) {
        case "kidlit": {
            themeId = 'later-alligator:white';
            break;
        }
        case 'literary': {
            themeId = 'fanning-the-pages:default';
            break;
        }
        case "educational": {
            themeId = 'cottage-love:default';
            break;
        }
        case 'fantasy': {
            themeId = 'mountains-in-the-clouds:default';
            break;
        }
        case "scifi": {
            themeId = 'future:dark';
            break;
        }
        case "mystery": {
            themeId = 'bleak-wood:dark';
            break;
        }
        case 'horror': {
            themeId = 'rustic-writer:default';
            break;
        }
        case 'thriller': {
            themeId = 'rustic-writer:default';
            break;
        }
        case 'contemporary': {
            themeId = 'poppies:default';
            break;
        }
        case 'historical': {
            themeId = 'merlot-stripes:default';
            break;
        }
        case 'romance': {
            themeId = 'lilac-watercolor:dark';
            break;
        }
        case 'nonfiction': {
            themeId = 'fanning-the-pages:default';
            break;
        }
    }

    return themeId;
}

export const headshotPhoto = 'https://firebasestorage.googleapis.com/v0/b/wild-ink-pages-prod.appspot.com/o/user-content%2F-M_T1d3Wsx3Cux6dMWNm%2FAuthor%20Photo%20300x300%20(4)_1630383601435.png?alt=media&token=fb9aaf87-20de-4ef9-9c24-820dccb909f3';
export const bookCoverPhoto = 'https://firebasestorage.googleapis.com/v0/b/wild-ink-pages-prod.appspot.com/o/user-content%2Fsite_DLKkweDDgZ%2FBook%20Cover%20Coming%20Soon%20%282%29_1718424672691.jpg?alt=media&token=6a2718bc-4a58-4130-9195-88c3dc46f2a8';
export const bookCoverPhotoWidth = 457;
export const bookCoverPhotoHeight = 729;

function prepareMediumTextValue(value: any) {
    // replace newlines with <br>
    // https://stackoverflow.com/questions/784539/how-do-i-replace-all-line-breaks-in-a-string-with-br-elements
    const replaced = textUtils.convertNewLineToBr(value);

    return replaced;
}

function _evaluateExpression(expression: string, options: any) {
    const current_item = options.currentListItem;
    const current_page = options.currentPage;

    const expr = parser.parse(expression);

    const funcs = {
        slugify: slugify,
        checkout: checkoutLinkUrl,
    };

    let val;
    try {
        val = expr.evaluate({ current_item: current_item, current_page: current_page, ...funcs });
    } catch (e) {
        // if (/not found in list/i.test(e) == false) {
        //     throw e;
        // }
        val = null;
    }
    return val;
}

export function _getVariablePlaceholders(expression: string) {
    const regex = /{{(.*?)}}/g;
    const matches = [];
    let match: string[];
    while (match = regex.exec(expression)) {
        matches.push(match[1]);
    }
    return matches;
}

function checkoutLinkUrl(listItem, platform) {
    const retVal = `{{checkout(current_item, "stripe")}}`;

    return retVal;
}

type StateTarget = {
    target: string;
    guard?: (context: onboardingContext) => boolean;
};

// status list
// todo consider a tree or FSM
type State = {
    index: number;
    onEnter?: (context: onboardingContext) => void;
    onExit?: (context: onboardingContext) => void;
    on?: Record<string, StateTarget>;
}

const statusList: Record<string, State> = {
    not_started: {
        index: 0,
        on: {
            NEXT: {
                target: 'initial_bio_started',
                guard: (context) => {
                    return context.fromStatus == 'not_started';
                }
            },
        },
    },
    initial_bio_started: {
        index: 1,
        onEnter: (context) => {
            context.website.updateOnboard('status', 'initial_bio_started');
        },
        on: {
            NEXT: {
                target: 'initial_bio_completed',
                guard: (context) => {
                    return context.fromStatus == 'initial_bio_started';
                }
            },
        },
    },
    initial_bio_completed: {
        index: 2,
        onEnter: (context) => {
            context.website.updateOnboard('status', 'initial_bio_completed');
        },
        on: {
            NEXT: {
                target: 'initial_book_started',
                guard: (context) => {
                    return context.fromStatus == 'initial_bio_completed';
                }
            },
        },
    },
    initial_book_started: {
        index: 3,
        onEnter: (context) => {
            context.website.updateOnboard('status', 'initial_book_started');
        },
        on: {
            NEXT: {
                target: 'initial_book_completed',
                guard: (context) => {
                    return context.fromStatus == 'initial_book_started';
                }
            },
        },
    },
    initial_book_completed: {
        index: 4,
        onEnter: (context) => {
            context.website.updateOnboard('status', 'initial_book_completed');
        },
        on: {
            NEXT: {
                target: 'initial_theme_started',
                guard: (context) => {
                    return context.fromStatus == 'initial_book_completed';
                }
            },
        },
    },
    initial_theme_started: {
        index: 5,
        onEnter: (context) => {
            context.website.updateOnboard('status', 'initial_theme_started');
        },
        on: {
            NEXT: {
                target: 'initial_theme_completed',
                guard: (context) => {
                    return context.fromStatus == 'initial_theme_started';
                }
            },
        },
    },
    initial_theme_completed: {
        index: 6,
        onEnter: (context) => {
            context.website.updateOnboard('status', 'initial_theme_completed');
        },
        on: {
            NEXT: {
                target: 'initial_sidebar_started',
                guard: (context) => {
                    return context.fromStatus == 'initial_theme_completed';
                }
            },
        },
    },
    initial_sidebar_started: {
        index: 7,
        onEnter: (context) => {
            context.website.updateOnboard('status', 'initial_sidebar_started');
        },
        on: {
            NEXT: {
                target: 'initial_sidebar_completed',
                guard: (context) => {
                    return context.fromStatus == 'initial_sidebar_started';
                }
            },
        },
    },
    initial_sidebar_completed: {
        index: 8,
        onEnter: (context) => {
            context.website.updateOnboard('status', 'initial_sidebar_completed');
        },
        on: {
            NEXT: {
                target: 'completed',
                guard: (context) => {
                    return context.fromStatus == 'initial_sidebar_completed';
                }
            },
        },
    },
    completed: {
        index: 9,
        onEnter: (context) => {
            context.website.updateOnboard('status', 'completed');
        },
    },
};

export function _getOnboardStatusIndex(key: string) {
    return statusList[key].index;
}

type onboardingContext = {
    website: Website;
    fromStatus: string;
};

export function handleOnboardEvent(currentState: string, event: string, context: onboardingContext) {
    const state = statusList[currentState];
    if (state.on && event in state.on) {
        if (state.onExit) {
            state.onExit(context);
        }
        currentState = state.on[event].target;
        const guard = state.on[event].guard;
        if (guard == null || guard?.(context)) {
            const newState = statusList[currentState];
            if (newState.onEnter) {
                newState.onEnter(context);
            }
        }
    }
};
