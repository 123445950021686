import React, { useRef, useEffect, useCallback } from 'react';
import { Image_v1_0_0 } from '../../../../v1.0.0/theme-data/renderer/components/image';
import { getComponentClassName_v1_2_0 } from "../../compiler/components";
import { getInferredComponentDefaultType_v1_0_0 } from '../../../../v1.0.0/theme-data/compiler/components';
import { renderChildComponentSiteDataPropertyValue_v1_0_0 } from "../../../../v1.0.0/theme-data/renderer/render-components";
import { RuntimeWimlComponentProps_v1 } from "app/wiml/versions/v1/types";
import { V1_2_0_ComponentWrapper } from '../utils/component-wrapper';
import _ from 'lodash';
import { renderChildComponentSiteDataPropertyValue_v1_2_0 } from "../render-components";
import { _getMaxHeightClassNames_v1_2_0, _getMaxWidthClassNames_v1_2_0 } from '../styles/dimensions';

const COMPONENT_TYPE = getInferredComponentDefaultType_v1_0_0('Image');

// todo v1.4 make props consistent - is img src or url?
export function Image_v1_2_0(props: RuntimeWimlComponentProps_v1) {
    // note: this debouncer must be scoped to the instance - not the module, or only the first instance will work
    // todo v1.2 memoize with useCallback
    const resizeDebouncer = _.debounce((resizeFunc) => {
        resizeFunc();
    }, 500);
    const imgRef = useRef<HTMLImageElement>(null);
    const minWidthImgIdRef = useRef<string>(null);
    const minHeightImgIdRef = useRef<string>(null);
    const targetImgLoadedRef = useRef<boolean>(false);
    let retVal = null;

    // d-inline-block is for debugger box
    // rouded-circle is bootstrap
    const openGallery = props.openGallery;
    // todo add filter to getComponentClassName so that we can skip things like borders on the parent and only appy to child
    const outerClassName = getComponentClassName_v1_2_0(props, 'wiml-img');

    const maxWidthClassName = _getMaxWidthClassNames_v1_2_0(props);
    const maxHeightClassName = _getMaxHeightClassNames_v1_2_0(props);
    const innerClassName = [maxWidthClassName, maxHeightClassName, props.circle != null && 'circle rounded-circle', props.square != null && 'square', props.shadow != null && 'shadow', 'wim-inner-img'].filter(Boolean).join(' ');

    const url = renderChildComponentSiteDataPropertyValue_v1_2_0(props, 'url') || props.src;

    if (url) {
        const alt = renderChildComponentSiteDataPropertyValue_v1_2_0(props, 'alt');

        /*
        // why is className in both parent and child?
// when using attrs like max-height, and not height explicitly, the img height 100% will not work. percentage for height needs a reference point (explicit height on parent).
// so if parent: max-height 400px
// child img: height 100%, that 100% will not adhere to parent but to img itself. if parent is explicitly set to height: 400px then it will work.

// These combinations WORK:
parent: height: 400px
child: height: 100%

parent: width: 100%
child: width: 100%

// These DO NOT work (percent needs reference):
parent: max-height: 400px
child: height: 100%

parent: max-width: 100%
child: width: 100%

// This DOES work (explicit values):
parent: max-height: 400px
child: max-height: 400px
        The key principle: percentage values need an explicit reference point from the parent. Using constraints like max-height doesn't provide that reference point, you need explicit height or width values for percentage calculations to work.
        
        */
        retVal = <div className={outerClassName}>
            <V1_2_0_ComponentWrapper {...props} >
                <img ref={imgRef} src={url} alt={alt} className={innerClassName} onClick={openGallery?.bind(null, url)} />
            </V1_2_0_ComponentWrapper>
        </div>
    }

    // useEffect(() => {
    //     if (props.dataRetrievalListItemId == imgIdRef.current) {
    //         const listItemId = props.dataRetrievalListItemId;
    //         const handleResize = () => {
    //             if (imgRef.current && imgRef.current.complete) {
    //                 props.onTargetImageLoad(imgRef.current);

    //             }
    //         };
    //         imgRef.current.onload = () => {
    //             props.onTargetImageLoad(imgRef.current);
    //             window.addEventListener('resize', handleResize);
    //             window.addEventListener('orientationchange', handleResize);

    //             return () => {
    //                 window.removeEventListener('resize', handleResize);
    //                 window.removeEventListener('orientationchange', handleResize);
    //             };
    //         }
    //     }

    // }, [props.dataRetrievalListItemId, imgIdRef.current]);

    useEffect(() => {
        if (url) {
            if (props.dataRetrievalListItemId) {
                if (props.minImageWidthRatioIds) {
                    const listItemId = props.dataRetrievalListItemId;
                    const minImageWidthRatioIds = props.minImageWidthRatioIds;
                    if (minImageWidthRatioIds[0] == listItemId) {
                        minWidthImgIdRef.current = listItemId;
                    }
                }

                if (props.minImageHeightRatioIds) {
                    const listItemId = props.dataRetrievalListItemId;
                    const minImageHeightRatioIds = props.minImageHeightRatioIds;
                    if (minImageHeightRatioIds[0] == listItemId) {
                        minHeightImgIdRef.current = listItemId;
                    }
                }
            }

            if (!targetImgLoadedRef.current) {
                if (minWidthImgIdRef.current && minHeightImgIdRef.current) {
                    broadcastTargetImageLoaded(['minWidthImg', 'minHeightImg']);
                } else if (minWidthImgIdRef.current) {
                    broadcastTargetImageLoaded('minWidthImg');
                } else if (minHeightImgIdRef.current) {
                    broadcastTargetImageLoaded('minHeightImg');
                }
            }
        }
    }, [props.dataRetrievalListItemId, props.minImageWidthRatioIds, props.minImageHeightRatioIds, url]);

    return retVal;

    function broadcastTargetImageLoaded(key: string | string[]) {
        const keyArray = Array.isArray(key) ? key : [key];
        const listItemId = props.dataRetrievalListItemId;

        const imageRef = imgRef.current;
        if (imageRef) {
            const additionalProps = keyArray.reduce((acc, key) => {
                acc[key] = imageRef;
                return acc;
            }, {} as Record<string, HTMLImageElement>);

            const doBroadcast = () => {
                // const doBroadcast = _.debounce(() => {
                props.onTargetImageLoad({ listItemId, ...additionalProps });
                // }, 5000);
            }
            if (imageRef.complete) {
                doBroadcast();
            } else {
                imageRef.onload = () => {
                    doBroadcast();
                };
            }

            const debounceBroadcast = () => {
                resizeDebouncer(doBroadcast);
            };

            const resizeHandler = () => {

                debounceBroadcast();
            }

            // window.addEventListener('resize', debounceBroadcast);

            targetImgLoadedRef.current = true;

            // Cleanup on unmount
            return () => {
                // window.removeEventListener('resize', debounceBroadcast);
            };
        } else {
            throw new Error('Image ref is null');
        }
    }
}
Image_v1_2_0.inputProps = {
    ...Image_v1_0_0.inputProps,
    width: {
        type: 'number',
        label: 'Width (px)',
        description: 'The width of the image',
        readonly: true, // note this doesn't actuallly do anything, refer to getComponentProperties_v1 in apps/admin/src/components/manage/wiml.js
    },
    height: {
        type: 'number',
        label: 'Height (px)',
        description: 'The height of the image',
        readonly: true,
    },
}
