import React from 'react';
import { getNodeKey_v1_0_0 } from '../compiler/nodes';
import { WimlNode_v1_0_0 } from '../compiler/types';
import { RuntimeWimlComponentProps_v1, RuntimeWimlComponenttPropKeys_v1, ThemeDataListRenderOptions_v1, ThemeDataReactComponentOptions } from "../../../types";
import { ComponentThemeData_v1_0_0 } from "../compiler/types";
import { ReactComponentProps_v1_0_0 } from "./types";
import { _isPageNode_v1_0_0, _isPageDefinitionNode_v1_0_0 } from '../compiler/nodes';


export function renderChildren_v1_0_0(children: React.ReactNode) {
    // this allows us to put html entites and things right in the wiml. e.g. scripts, &nbsp;, etc.
    const renderedChildren = React.Children.map(children, (child) => {
        let retVal = child;

        const isStringReactNode = typeof child === "string";
        if (isStringReactNode) {
            retVal = <span dangerouslySetInnerHTML={{ __html: child }} />;
        }

        return retVal;
    });

    return renderedChildren;
}


// todo move to own file?
export function convertThemeDataToReact_v1_0_0(themeData: ComponentThemeData_v1_0_0) {
    // todo why are we using wiml nodes at this point, just use theme data components
    // because the compiler doesn't maintain tree structure, it just maintains a list of components
    // also, v1.1 stores the line numbers in renderer, but not compiler
    const wimlRoot = themeData.rootNode;
    const layoutNode = wimlRoot.child.find((node) => node.tag == 'Layout');

    const retVal = wimlRoot.child
        .filter((node) => node != layoutNode)
        .reduce((accum, node) => {
            accum[humps.camelize(getNodeKey_v1_0_0(node))] = convertJsonNodeToReact_v1_0_0(node, { layout: layoutNode });
            return accum;
        }, {} as Record<string, React.ReactNode>);

    return retVal;
}


// DFS iterative https://medium.com/@jpoechill/iterative-bfs-and-dfs-in-javascript-
// https://blog.bitsrc.io/depth-first-search-of-a-binary-tree-in-javascript-874701d8210a
export function convertJsonNodeToReact_v1_0_0(node: WimlNode_v1_0_0, options: ThemeDataReactComponentOptions): React.ReactNode {
    let retVal = null;

    if (node.node == 'text') {
        retVal = node.text;
    } else {
        const { layout, ...optionsRest } = options;

        const isPageNode = _isPageNode_v1_0_0(node);
        if (isPageNode) {
            optionsRest.pageOccurrenceId = getNodeKey_v1_0_0(node);
        }

        if (_isPageDefinitionNode_v1_0_0(node)) {
            // if top level page component, set the pageDefinitionId
            // if page node or header/footer
            // set optoiins
            optionsRest.pageDefinitionId = getNodeKey_v1_0_0(node);
        } else {
            if (optionsRest.pageDefinitionId) {
                node.attr = { ...node.attr, pageDefinitionId: optionsRest.pageDefinitionId };
            }

            if (optionsRest.pageOccurrenceId) {
                node.attr = { ...node.attr, pageOccurrenceId: optionsRest.pageOccurrenceId };
            }
        }

        if (layout) {
            // replace <CurrentPage /> child with node
            const layoutChildren = layout.child.map((childNode) => (childNode.tag == 'CurrentPage' ? node : childNode));
            node = { ...layout, child: layoutChildren };
        }

        // do not use this - because the types are minified at build time so they are not the same as the string
        // e.g. List -> At (just a random example)
        // e.g. Header -> Li (just a random example)
        // const component = _getReactComponent(node);
        const component = node.tag;

        const children = node.child;

        // strip key from node.attr
        const { key, ...rest } = node.attr || {};
        const restHumps = humps.camelizeKeys(rest) as RuntimeWimlComponentProps_v1;
        // convert any props that need to be in react format e.g. class -> className
        const reactProps = convertPropsToReactFormat_v1_0_0(restHumps);
        const componentProps = {
            ...reactProps,
            ...(key && { componentKey: key }),
            // todo v1.2 ...{ componentKey: key, id: _getComponentId_v1_0_0({ key: key || '', type: component }) },
        };

        if (children) {
            retVal = React.createElement(component, componentProps, ...children.map((c) => convertJsonNodeToReact_v1_0_0(c, optionsRest)));
        } else {
            retVal = React.createElement(component, componentProps);
        }
    }

    return retVal;
}
export const convertPropsToReactFormat_v1_0_0 = (props: RuntimeWimlComponentProps_v1) => {
    const retVal: ReactComponentProps_v1_0_0 = {};
    Object.keys(props).forEach((key: RuntimeWimlComponenttPropKeys_v1) => {
        const value = props[key];
        if (key == 'class') {
            retVal.className = value;
        } else {
            retVal[key] = value;
        }
    });
    return retVal;
};


import { ThemeDataPageListComponentsTopLevelCloneProps_v1 } from "../../../types";
import { isValidNonFragmentElement_v1_0_0 } from '../compiler/nodes';
import { ComponentThemeData_v1 } from '../../../types';
import { _getComponentId_v1_0_0 } from '../compiler/components';
import humps from "humps";
import _ from 'lodash';


export const cloneReactChildren_v1_0_0 = (children: React.ReactNode, topLevelProps: ThemeDataPageListComponentsTopLevelCloneProps_v1, propsForAllChildren: ThemeDataListRenderOptions_v1): React.ReactNode => {
    const count = React.Children.count(children);
    if (count == 0) {
        return null;
    } else if (count == 1) {
        return _cloneChild(children, topLevelProps, propsForAllChildren);
    } else {
        const retVal = React.Children.map(children, (child) => _cloneChild(child, topLevelProps, propsForAllChildren));
        return retVal;
    }
};

function _cloneChild(child: React.ReactNode, topLevelProps: ThemeDataPageListComponentsTopLevelCloneProps_v1, propsForAllChildren: ThemeDataListRenderOptions_v1) {
    if (isValidNonFragmentElement_v1_0_0(child)) {
        // todo - this data-list-id is not rendered in any wiml component.
        // update - now in row and col
        const combinedTopLevelProps = topLevelProps
            ? { ...topLevelProps, className: `${child.props.className || ''} ${topLevelProps.className || ''}`.trim() }
            : null;
        const cloned = React.cloneElement(
            child,
            {
                ...child.props,
                ...propsForAllChildren,
                ...combinedTopLevelProps,
            },
            // child.props.children &&
            cloneReactChildren_v1_0_0(child.props.children, null, propsForAllChildren)
            // React.Children.map(child.props.children, (innerChild) => cloneReactChildren_v1_0_0(innerChild, null, propsForAllChildren)
        );
        return cloned;
    } else {
        const cloned = child;
        return cloned;
    }
}

export function isListChildComponentType_v1_0_0(props: RuntimeWimlComponentProps_v1) {
    return props.dataRetrievalListId;
}
export function getComponentClassName_v1_0_0(props: RuntimeWimlComponentProps_v1, ...defaultClasses: string[]) {
    // const alignClass = _getAlignClass(props);
    const classNames = [props.className, ...defaultClasses /* , alignClass */];
    const className = classNames.filter(Boolean).join(' ');
    return className;
}
export function getComponentHtmlAttrs_v1_0_0(props: RuntimeWimlComponentProps_v1) {
    // the id should incorporate the page and list key to get unique id.
    const retVal = {} as Record<string, string>;
    if (props.topLevelListComponentKey) {
        // todo - investigate why this convention was used. update: this convent typically allows for single click to copy/paste / periods not so easily.
        const htmlPreparedTopLevelComponentKey = props.topLevelListComponentKey.replace(/\./g, "__");
        const listComponentKeyQualifiedListItemId = `${htmlPreparedTopLevelComponentKey}__${props.dataRetrievalListItemId}`;
        // this represents the actual `id` attribute on an html element
        retVal.id = listComponentKeyQualifiedListItemId;
    }
    return retVal;
}
import { getListChildComponentPropertyValueSiteData_v1_0_0, getPageChildComponentPropertyValueSiteData_v1_0_0 } from '../../site-data/retrieval';
import { JSONArray, JSONObject, JSONValue } from '../../../../../../../lib/object-types';

// todo - wiml should be unaware of websiteData
export function renderChildComponentSiteDataPropertyValue_v1_0_0(props: RuntimeWimlComponentProps_v1, propertyName: string, componentType: string) {
    let retVal = isListChildComponentType_v1_0_0(props)
        ? getListChildComponentPropertyValueSiteData_v1_0_0(props.wimlData, props.componentKey, propertyName, componentType, props.dataRetrievalListId, props.dataRetrievalListItemId)
        : getPageChildComponentPropertyValueSiteData_v1_0_0(props.wimlData, props.componentKey, propertyName, componentType, props.pageDefinitionId);

    retVal = postProcessPropertyValue_v1_0_0(retVal);
    return retVal;
}
export function postProcessPropertyValue_v1_0_0(retVal: JSONValue) {
    if (typeof retVal == 'string') {
        if (retVal.startsWith?.("{{checkout")) {
            retVal = { checkout: "stripe" };
        }
    }
    return retVal;
}
