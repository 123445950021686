import { Parser } from 'expr-eval';
import { slugify } from '../../../../../../lib/text-utils';
import { getComponentLookup_v1 } from '../../helpers';
import * as objectUtils from '../../../../../../lib/object-utils';
import _ from 'lodash';
import humps from 'humps';
import { _getListChildComponentThemeData_v1_0_0, _getListChildComponentsThemeData_v1_0_0 } from '../theme-data/compiler/retrieval';
import { IWiml_v1 } from '../../types';
export const parser = new Parser();

export function _getVariablePlaceholders_v1_0_0(expression: string) {
    const regex = /{{(.*?)}}/g;
    const matches = [];
    let match: string[];
    while (match = regex.exec(expression)) {
        matches.push(match[1]);
    }
    return matches;
}


export function _evaluateExpression_v1_0_0(expression: string, options: any) {
    const current_item = options.currentListItem;
    const current_page = options.currentPage;
    const pages = humps.decamelizeKeys(options.pages, { separator: '_' });
    const lists = humps.decamelizeKeys(options.lists, { separator: '_' });
    const expr = parser.parse(expression);

    const funcs = {
        slugify: slugify,
        checkout: checkoutLinkUrl,
    };

    let val;
    try {
        val = expr.evaluate({ current_item: current_item, current_page: current_page, pages, lists, ...funcs });
    } catch (e) {
        // if (/not found in list/i.test(e) == false) {
        //     throw e;
        // }
        val = null;
    }
    return val;
}


function checkoutLinkUrl(listItem: any, platform: string) {
    const retVal = `{{checkout(current_item, "stripe")}}`;

    return retVal;
} export function _validatePropertyExpression_v1_0_0(property: { type: any; }, propertyExpression: string) {
    const propertyType = property.type;

    if (propertyExpression) {
        switch (propertyType) {
            case 'json': {
                JSON.parse(propertyExpression);
                break;
            }
            case 'date': {
                // throw error if propertyExpression is not a valid date
                const date = new Date(propertyExpression);
                if (date.toString() === 'Invalid Date') {
                    throw new Error(`Invalid date: ${propertyExpression}`);
                }
                break;
            }
        }
    }
}
export function _getPropertyValueFromExpression_v1_0_0(componentType: any, property: any, propertyExpression: string, options: any) {
    _validatePropertyExpression_v1_0_0(property, propertyExpression);

    // loop over _getVariablePlaceholders and replace with the value of the variable and return the new string
    const variablePlaceholders = _getVariablePlaceholders_v1_0_0(propertyExpression);
    let newPropertyExpression = propertyExpression;
    if (componentType != 'List') {
        for (let i = 0; i < variablePlaceholders.length; i++) {
            const variablePlaceholder = variablePlaceholders[i];
            const variableValue = _evaluateExpression_v1_0_0(variablePlaceholder, options);
            newPropertyExpression = newPropertyExpression.replace(`{{${variablePlaceholder}}}`, variableValue);
        }
    }

    const propertyValue = newPropertyExpression;
    return propertyValue;
}

export function _getPageChildComponentPropertyValueFromExpression_v1_0_0(pageId: string, componentId: string, propertyId: string, propertyExpression: string, wimlData: IWiml_v1) {
    const pageComponent = wimlData.themeData?.components.items?.pages?.items?.[pageId]?.components?.items?.[componentId];
    // todo store the type in the list item, or the list itself, so we can use it here
    if (!pageComponent) throw new Error(`pageComponent ${pageComponent} not found in pageId ${pageId}`);
    const componentType = pageComponent.type;

    const wimlVersion = wimlData.themeData.meta?.version || "1.0.0";

    const lookupType = getComponentLookup_v1(wimlVersion, componentType);
    const property = lookupType.inputProps[propertyId];
    const currentPage = wimlData.siteData?.components.items?.pages?.items?.[pageId];

    const propertyValue = _getPropertyValueFromExpression_v1_0_0(componentType, property, propertyExpression, { currentPage, pages: wimlData.siteData?.components?.items?.pages, lists: wimlData.siteData?.components?.items?.lists });
    return propertyValue;
}

export function _doPageChildComponentPropertyUpdate_v1_0_0(pageId: string, componentId: string, propertyId: string, propertyExpression: string, wimlData: IWiml_v1) {
    try {
        const newValue = _getPageChildComponentPropertyValueFromExpression_v1_0_0(pageId, componentId, propertyId, propertyExpression, wimlData);

        wimlData.siteData ||= { components: { ids: [], items: { pages: { ids: [], items: {} }, lists: { ids: [], items: {} } } } };
        wimlData.siteData.components.items ||= { pages: { ids: [], items: {} }, lists: { ids: [], items: {} } };
        const components = wimlData.siteData.components.items;

        components.pages ||= { ids: [], items: {} };
        components.pages.items[pageId] ||= { components: { ids: [], items: {} } };
        components.pages.items[pageId].components.items[componentId] ||= {};
        components.pages.items[pageId].components.items[componentId][propertyId] ||= { data: { value: undefined, expression: undefined } };
        components.pages.items[pageId].components.items[componentId][propertyId].data.value = newValue;
        components.pages.items[pageId].components.items[componentId][propertyId].data.expression = propertyExpression;
    } catch (e) {
        if (/not found in page/i.test(e) == false) {
            throw e;
        }
    }
}

export function updatePageChildComponentProperty_v1_0_0(pageId: string, componentId: string, propertyId: string, propertyExpression: string, wimlData: IWiml_v1) {
    _doPageChildComponentPropertyUpdate_v1_0_0(pageId, componentId, propertyId, propertyExpression, wimlData);

    // look at updateListComponentProperty and do the same thing but for page
    const otherComponentsInThisPage = wimlData.siteData?.components.items?.pages?.items?.[pageId]?.components?.items || {};
    for (const [otherComponentId, otherComponent] of Object.entries(otherComponentsInThisPage)) {
        if (otherComponentId != componentId) {
            for (const [propertyId, property] of Object.entries(otherComponent)) {
                const propertyExpression = property.data.expression;
                _doPageChildComponentPropertyUpdate_v1_0_0(pageId, otherComponentId, propertyId, propertyExpression, wimlData);
            }
        }
    }

    const otherPages = wimlData.siteData?.components.items?.pages?.items || {};
    for (const [otherPageId, otherPage] of Object.entries(otherPages)) {
        if (otherPageId != pageId) {
            for (const [otherComponentId, otherComponent] of Object.entries(otherPage.components?.items || {})) {
                for (const [propertyId, property] of Object.entries(otherComponent)) {
                    const propertyExpression = property.data.expression;
                    _doPageChildComponentPropertyUpdate_v1_0_0(otherPageId, otherComponentId, propertyId, propertyExpression, wimlData);
                }
            }
        }
    }

    const otherLists = wimlData.siteData?.components.items?.lists?.items || {};
    for (const [listId, list] of Object.entries(otherLists)) {
        for (const [listItemId, listItem] of Object.entries(list.items.items)) {
            for (const [listComponentId, listComponent] of Object.entries(listItem.components?.items || {})) {
                for (const [propertyId, property] of Object.entries(listComponent)) {
                    const propertyExpression = property.data.expression;
                    _doListItemComponentPropertyUpdate_v1_0_0(listId, listItemId, listComponentId, propertyId, propertyExpression, wimlData);
                }
            }
        }
    }

    wimlData.siteData.components.ids = Object.keys(wimlData.siteData.components.items);
    wimlData.siteData.components.items.pages.ids = Object.keys(wimlData.siteData?.components.items?.pages?.items || {});

    wimlData.siteData.components.items.pages.items[pageId].components.ids = Object.keys(wimlData.siteData.components.items.pages.items[pageId].components.items);
}

export function _getListComponentPropertyValueFromExpression_v1_0_0(listId: string, listItemId: string, componentId: string, propertyId: string, propertyExpression: string, wimlData: IWiml_v1) {
    const listComponent = _getListChildComponentThemeData_v1_0_0(listId, componentId, wimlData);
    // todo store the type in the list item, or the list itself, so we can use it here
    if (!listComponent) throw new Error(`listComponentId ${componentId} not found in listId ${listId}`);
    const componentType = listComponent.type;

    const wimlVersion = wimlData.themeData.meta?.version || "1.0.0";

    const lookupType = getComponentLookup_v1(wimlVersion, componentType);
    const property = lookupType.inputProps[propertyId];

    const currentListItem = wimlData.siteData?.components.items?.lists?.items?.[listId]?.items?.items?.[listItemId];

    const propertyValue = _getPropertyValueFromExpression_v1_0_0(componentType, property, propertyExpression, { currentListItem, pages: wimlData.siteData?.components?.items?.pages, lists: wimlData.siteData?.components?.items?.lists });
    return propertyValue;
}

export function _createNewListItem_v1_0_0(listId: string, wimlData: IWiml_v1) {
    const components = {
        ids: [],
        items: {},
    };

    // set default values. if there is a heading component, set the link url to slugify of the heading text
    const listComponents = _getListChildComponentsThemeData_v1_0_0(listId, wimlData);
    const listComponentsArray = objectUtils.convertObjectToArray(listComponents);
    const linkComponent = listComponentsArray.find(c => c.type === 'Link' && c.id === 'link');

    if (linkComponent) {
        let urlPath;
        // todo fix in v1.2.0 the list__ prefix
        // get this 1.3.0 code out of here asap
        if ((wimlData.themeData.meta.version == '1.3.0' || wimlData.themeData.meta.version == '1.4.0')) {
            let slugifyPath;
            listId = listId.replace('list__', '');
            if (listId == 'blog') {
                urlPath = 'post';
                slugifyPath = '{{slugify(current_item.components.items.heading__title.content.data.value)}}';
            } else if (listId == 'books') {
                urlPath = 'book';
                slugifyPath = '{{slugify(current_item.components.items.heading__title.content.data.value)}}';
            } else if (listId == 'events') {
                urlPath = 'event';
                slugifyPath = '{{slugify(current_item.components.items.heading__title.content.data.value)}}';
            } else {
                const singularListId = listId.replace(/s$/, '').replace(/ies$/, 'y');
                urlPath = singularListId;
                slugifyPath = '{{slugify(current_item.components.items.heading__title.content.data.value)}}';
            }

            components.items[linkComponent.id] = {
                url: {
                    data: {
                        expression: `/${urlPath}/${slugifyPath}`,

                    }
                }
            };
        } else if (wimlData.themeData.meta.version == '1.2.0') {
            let slugifyPath;
            listId = listId.replace('list__', '');
            if (listId == 'socialMedia') {

            } else {
                if (listId == 'blog') {
                    urlPath = 'post';
                } else {
                    const singularListId = listId.replace(/s$/, '').replace(/ies$/, 'y');
                    urlPath = singularListId;
                }

                components.items[linkComponent.id] = {
                    url: {
                        data: {
                            expression: `/${urlPath}/{{slugify(current_item.components.items.heading.content.data.value)}}`,

                        }
                    }
                };
            }
        } else {
            if (listId == 'socialMedia') {

            } else {
                if (listId == 'blog') {
                    urlPath = 'post';
                } else {
                    const singularListId = listId.replace(/s$/, '').replace(/ies$/, 'y');
                    urlPath = singularListId;
                }

                components.items[linkComponent.id] = {
                    url: {
                        data: {
                            expression: `/${urlPath}/{{slugify(current_item.components.items.heading.content.data.value)}}`,

                        }
                    }
                };
            }
        }
        components.ids.push(linkComponent.id);
    }
    const retVal = ({
        components,
        relationships: { lists: { ids: [], items: {} } },
        meta: {
            createdDate: new Date(),
            editedDate: new Date()
        }
    });

    return retVal;
}


export function createNewListItem_v1_0_0(listItemId: string, listId: string, wimlData: IWiml_v1) {
    // use https://lodash.com/docs/4.17.15#defaultsDeep
    // use defaultsDeep to assign
    wimlData.siteData ||= { components: { ids: [], items: { pages: { ids: [], items: {} }, lists: { ids: [], items: {} } } } };
    wimlData.siteData.components.items.lists ||= { ids: [], items: {} };
    wimlData.siteData.components.items.lists.items[listId] ||= { ids: [], items: { ids: [], items: {} } };

    // todo this is causing a bug when adding new slides in aarons blank site. some reason "slides" exists but items is undefined. 👇
    wimlData.siteData.components.items.lists.items[listId].items ||= { ids: [], items: {} };

    const newItem = _createNewListItem_v1_0_0(listId, wimlData);

    // todo resort met order for each list item in list
    // todo this is causing a bug when adding new slides in aarons blank site. some reason "slides" exists but items is undefined.
    wimlData.siteData.components.items.lists.items[listId].items.items[listItemId] = newItem;
    wimlData.siteData.components.items.lists.items[listId].items.ids = Object.keys(wimlData.siteData.components.items.lists.items[listId].items.items);

    return newItem;
}

export function _doListItemComponentPropertyUpdate_v1_0_0(listId: string, listItemId: string, componentId: string, propertyId: string, propertyExpression: string, wimlData: IWiml_v1) {
    // todo consider using dot.assign here
    try {
        const newValue = _getListComponentPropertyValueFromExpression_v1_0_0(listId, listItemId, componentId, propertyId, propertyExpression, wimlData);

        wimlData.siteData ||= { components: { ids: [], items: { pages: { ids: [], items: {} }, lists: { ids: [], items: {} } } } };
        wimlData.siteData.components.items ||= { pages: { ids: [], items: {} }, lists: { ids: [], items: {} } };
        const components = wimlData.siteData.components.items;

        components.lists ||= { ids: [], items: {} };
        components.lists.items[listId] ||= { ids: [], items: { ids: [], items: {} } };
        components.lists.items[listId].items.items ||= {};
        components.lists.items[listId].items.items[listItemId] ||= _createNewListItem_v1_0_0(listId, wimlData);
        components.lists.items[listId].items.items[listItemId].meta.editedDate = new Date();
        components.lists.items[listId].items.items[listItemId].components ||= { ids: [], items: {} };
        components.lists.items[listId].items.items[listItemId].components.items[componentId] ||= {};
        components.lists.items[listId].items.items[listItemId].components.items[componentId][propertyId] ||= { data: { value: undefined, expression: undefined } };
        components.lists.items[listId].items.items[listItemId].components.items[componentId][propertyId].data.value = newValue;
        components.lists.items[listId].items.items[listItemId].components.items[componentId][propertyId].data.expression = propertyExpression;
        components.lists.items[listId].items.items[listItemId].components.ids = Object.keys(components.lists.items[listId].items.items[listItemId].components.items);
    }
    catch (e) {
        if (/not found in list/i.test(e) == false) {
            throw e; // todo explain why this case would occur and when it is ok
        }
    }
}

export function updateListItemComponentProperty_v1_0_0(listId: string, listItemId: string, componentId: string, propertyId: string, propertyExpression: string, wimlData: IWiml_v1) {
    _doListItemComponentPropertyUpdate_v1_0_0(listId, listItemId, componentId, propertyId, propertyExpression, wimlData);
    const otherComponentsInThisListItem = (wimlData.siteData?.components.items?.lists?.items?.[listId]?.items?.items?.[listItemId]?.components?.items) || {};
    for (const [otherComponentId, otherComponent] of Object.entries(otherComponentsInThisListItem)) {
        if (otherComponentId != componentId) {
            for (const [propertyId, property] of Object.entries(otherComponent)) {
                const propertyExpression = property.data.expression;
                _doListItemComponentPropertyUpdate_v1_0_0(listId, listItemId, otherComponentId, propertyId, propertyExpression, wimlData);
            }
        }
    }

    const otherListItemsInList = wimlData.siteData?.components.items?.lists?.items?.[listId]?.items?.items || {};
    for (const [otherListItemId, otherListItem] of Object.entries(otherListItemsInList)) {
        if (otherListItemId != listItemId) {
            for (const [otherComponentId, otherComponent] of Object.entries(otherListItem.components?.items || {})) {
                for (const [propertyId, property] of Object.entries(otherComponent)) {
                    const propertyExpression = property.data.expression;
                    _doListItemComponentPropertyUpdate_v1_0_0(listId, otherListItemId, otherComponentId, propertyId, propertyExpression, wimlData);
                }
            }
        }
    }

    const otherLists = wimlData.siteData?.components.items?.lists?.items || {};
    for (const [otherListId, otherList] of Object.entries(otherLists)) {
        if (otherListId != listId) {
            for (const [otherListItemId, otherListItem] of Object.entries(otherList.items.items)) {
                for (const [otherComponentId, otherComponent] of Object.entries(otherListItem.components?.items || {})) {
                    for (const [propertyId, property] of Object.entries(otherComponent)) {
                        const propertyExpression = property.data.expression;
                        _doListItemComponentPropertyUpdate_v1_0_0(otherListId, otherListItemId, otherComponentId, propertyId, propertyExpression, wimlData);
                    }
                }
            }
        }
    }

    const allPages = wimlData.siteData?.components.items?.pages?.items || {};
    for (const [pageId, page] of Object.entries(allPages)) {
        const pageComponents = page.components.items;
        for (const [pageComponentId, pageComponent] of Object.entries(pageComponents)) {
            for (const [propertyId, property] of Object.entries(pageComponent)) {
                const propertyExpression = property.data.expression;
                _doPageChildComponentPropertyUpdate_v1_0_0(pageId, pageComponentId, propertyId, propertyExpression, wimlData);
            }
        }
    }

    wimlData.siteData.components.ids = Object.keys(wimlData.siteData.components.items);

    // it's possible this call has added a new list
    wimlData.siteData.components.items.lists.ids = Object.keys(wimlData.siteData?.components.items?.lists?.items);//.map((k, i) => ({ id: k, order: i }));

    wimlData.siteData.components.items.lists.items[listId].ids = Object.keys(wimlData.siteData?.components.items?.lists?.items?.[listId]?.items || {});
    wimlData.siteData.components.items.lists.items[listId].items.ids = Object.keys(wimlData.siteData?.components.items?.lists?.items?.[listId]?.items?.items || {});
    // now identify any dependencies in the graph and update their values
    // const dependencies = wimlData.siteData.lists.items[listId].items[listItemId].components[listComponentId][propertyId].data.dependencies;
    // if (dependencies) {
    //     for (const dependency of dependencies) {
    //         const dependencyValue = this._getListComponentPropertyValueFromExpression(listId, listItemId, dependency.componentId, dependency.propertyId, dependency.expression);
    //         this.updateListComponentProperty(listId, listItemId, dependency.componentId, dependency.propertyId, dependency.expression);
    //     }
    // }
}

export function _doListItemRelationshipUpdate_v1_0_0(listId: string, listItemId: string, relationshipListId: string, relationshipListItemId: string, wimlData: IWiml_v1) {
    wimlData.siteData.components.items ||= { pages: { ids: [], items: {} }, lists: { ids: [], items: {} } };
    const components = wimlData.siteData.components.items;

    if (!components?.lists?.items[listId]?.items?.items?.[listItemId]) throw new Error(`list item ${listItemId} not found in list ${listId}`);
    if (!components?.lists?.items[relationshipListId]?.items?.items?.[relationshipListItemId]) throw new Error(`list item ${relationshipListItemId} not found in list ${relationshipListId}`);

    components.lists.items[listId].items.items[listItemId].meta.editedDate = new Date();
    components.lists.items[listId].items.items[listItemId].relationships ||= { lists: { ids: [], items: {} } };
    components.lists.items[listId].items.items[listItemId].relationships.lists.items[relationshipListId] ||= { ids: [], items: {} };
    components.lists.items[listId].items.items[listItemId].relationships.lists.items[relationshipListId].items[relationshipListItemId] ||= { data: {} };
    components.lists.items[listId].items.items[listItemId].relationships.lists.items[relationshipListId].items[relationshipListItemId].data.createdDate = new Date().toDateString();
    components.lists.items[listId].items.items[listItemId].relationships.lists.items[relationshipListId].ids = Object.keys(components.lists.items[listId].items.items[listItemId].relationships.lists.items[relationshipListId].items);
    components.lists.items[listId].items.items[listItemId].relationships.lists.ids = Object.keys(components.lists.items[listId].items.items[listItemId].relationships.lists.items);
}

export function updateRelationship_v1_0_0(listId: string, listItemId: string, relationshipListId: string, relationshipListItemId: string, wimlData: IWiml_v1) {
    _doListItemRelationshipUpdate_v1_0_0(listId, listItemId, relationshipListId, relationshipListItemId, wimlData);
    _doListItemRelationshipUpdate_v1_0_0(relationshipListId, relationshipListItemId, listId, listItemId, wimlData);
}

export function deleteRelationship_v1_0_0(listId: string, listItemId: string, relationshipListId: string, relationshipListItemId: string, wimlData: IWiml_v1) {
    _doListItemRelationshipDelete_v1_0_0(listId, listItemId, relationshipListId, relationshipListItemId, wimlData);
    _doListItemRelationshipDelete_v1_0_0(relationshipListId, relationshipListItemId, listId, listItemId, wimlData);
}

export function _doListItemRelationshipDelete_v1_0_0(listId: string, listItemId: string, relationshipListId: string, relationshipListItemId: string, wimlData: IWiml_v1) {
    wimlData.siteData.components.items ||= { pages: { ids: [], items: {} }, lists: { ids: [], items: {} } };
    const components = wimlData.siteData.components.items;

    if (!components?.lists?.items[listId]?.items?.items?.[listItemId]) throw new Error(`list item ${listItemId} not found in list ${listId}`);
    if (!components?.lists?.items[relationshipListId]?.items?.items?.[relationshipListItemId]) throw new Error(`list item ${relationshipListItemId} not found in list ${relationshipListId}`);

    delete components.lists.items[listId].items.items[listItemId].relationships.lists.items[relationshipListId].items[relationshipListItemId];
    components.lists.items[listId].items.items[listItemId].relationships.lists.items[relationshipListId].ids = Object.keys(components.lists.items[listId].items.items[listItemId].relationships.lists.items[relationshipListId].items);
}

export function _doListItemDelete_v1_0_0(listId: string, listItemId: string, wimlData: IWiml_v1) {
    const listItemsObj = wimlData.siteData?.components?.items?.lists?.items[listId]?.items?.items;

    if (listItemsObj) {
        wimlData.siteData.components.items.lists.items[listId].items.items = _.omit(listItemsObj, listItemId);
        wimlData.siteData.components.items.lists.items[listId].items.ids = Object.keys(wimlData.siteData.components.items.lists.items[listId].items.items);
    }
}

// todo - if list item is deleted, remove anyone who references it
export function deleteListItem_v1_0_0(listId: string, listItemId: string, wimlData: IWiml_v1) {
    const listsRelatedToThisItem = wimlData.siteData?.components.items?.lists?.items?.[listId]?.items?.items?.[listItemId]?.relationships?.lists;
    if (listsRelatedToThisItem) {
        for (const [relationshipListId, relationshipList] of Object.entries(listsRelatedToThisItem.items)) {
            for (const relationshipListItemId of relationshipList.ids) {
                _doListItemRelationshipDelete_v1_0_0(relationshipListId, relationshipListItemId, listId, listItemId, wimlData);
            }
        }
    }

    _doListItemDelete_v1_0_0(listId, listItemId, wimlData);

    wimlData.siteData.components.ids = Object.keys(wimlData.siteData.components.items);

    // it's possible this call has added a new list
    wimlData.siteData.components.items.lists.ids = Object.keys(wimlData.siteData?.components.items?.lists?.items);//.map((k, i) => ({ id: k, order: i }));
    wimlData.siteData.components.items.lists.items[listId].ids = Object.keys(wimlData.siteData?.components.items?.lists?.items?.[listId]?.items || {});
}
