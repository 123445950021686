import { CardHeader, UncontrolledCollapse, Card, CardBody, Collapse, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledButtonDropdown } from 'reactstrap';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as dateUtils from '@wip/common/lib/date-utils';
import * as objectUtils from '@wip/common/lib/object-utils';
import { _isElementNode_v1_0_0 } from '@wip/common/app/wiml/versions/v1/v1.0.0/theme-data/compiler/nodes';
import { _getThemeDataComponentId_v1_2_0 } from '@wip/common/app/wiml/versions/v1/v1.2.0/theme-data/compiler/components';
import { wimlComponents_v1_3_0 } from '@wip/common/app/wiml/versions/v1/v1.3.0/theme-data/renderer/wiml-components-v1.3.0';
import { sortListItems } from '@wip/common/domain/list-sort';
import { createNewListItem, deleteListItem, deleteRelationship, saveFile, updateListItemComponentProperty, updatePageChildComponentProperty, updateRelationship, updateStyle, createPageChildComponent, movePageChildComponent, } from '@wip/common/event-store/website';
import { move } from "@wip/common/lib/array-utils";
import { dateYYYYMMDD } from '@wip/common/lib/date-utils';
import { convertArrayToObject, convertObjectToArray } from "@wip/common/lib/object-utils";
import imageCompression from 'browser-image-compression';
import humps from 'humps';
import _ from 'lodash';
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-markup";
import "prismjs/themes/prism.css";
import 'prismjs/components/prism-css'; // import order matters
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { DragDropContext } from "react-beautiful-dnd";
import { useForm } from "react-hook-form";
import NewWindow from 'react-new-window';
import { useDispatch } from "react-redux";
import { Button, CustomInput, Form, FormGroup, Input, Label } from 'reactstrap';
import { ReactstrapInputBlurChangeDetector, RichTextEditor } from '../../../../../../form/input';
import WimlProducts from '../v1.0.0/wiml-products-v1.0.0';
import { Droppable, Draggable } from "react-beautiful-dnd";
import { resetDebouncer_v1_0_0 } from '../v1.0.0/wiml-editor-v1.0.0';
import useScript from 'react-script-hook';
import { cssGlobalVariables_v1_2_0 } from '@wip/common/app/wiml/versions/v1/v1.2.0/theme-data/renderer/utils/constants';
import SimpleEditor from 'react-simple-code-editor';
import { JsonEditor } from 'json-edit-react'

// todo make this  and the rest of the admin wiml-version-specific
// v1.3.0
export const DEFAULT_ADMIN_LIST_LABEL_KEY_v1_3_0 = 'heading__title.content.data.value';

// create a debounce function that accepts a function to run and a delay
const componentIdScrollerDebouncer = _.debounce((scrollFunc) => {
    scrollFunc();
}, 250);
const defaultSelectionOption = <option value="" />;
const viewListOptions = [
    { key: "components:page-specific", value: "Show page-specific components (default)" },
    { key: "components:all", value: "Show all components" },
];
const WimlEditor = (props) => {
    const wimlEditorWindow = useRef(null);
    const componentsMetaLookup = wimlComponents_v1_3_0;
    const wimlVersion = props.websiteData.style.wiml.themeData.meta?.version || "1.0.0";
    const dispatch = useDispatch();

    const handleDeleteListItem = () => {
        dispatch(deleteListItem({ listId: props.listId, listItemId: props.listItemId }));
        deselectListItem();
    };

    const deselectListItemRelationship = () => {
        props.onChange('relationshipAListId', null);
        props.onChange('relationshipAListItemId', null);
        props.onChange('relationshipBListId', null);
        props.onChange('relationshipBListItemId', null);
    };


    const handleDeleteListItemRelationship = () => {
        dispatch(deleteRelationship({
            listId: props.relationshipAListId, listItemId: props.relationshipAListItemId,
            relationshipListId: props.relationshipBListId, relationshipListItemId: props.relationshipBListItemId
        }));
        deselectListItemRelationship();
    };

    const deselectListItem = () => {
        props.onChange('listId', null);
        props.onChange('listItemId', null);
    };

    let defaultValues;

    const relationshipAListId = props.relationshipAListId;
    const relationshipAListItemId = props.relationshipAListItemId;
    const relationshipBListId = props.relationshipBListId;
    const relationshipBListItemId = props.relationshipBListItemId;
    const relationshipBIsNew = props.relationshipBIsNew;

    const selectedTab = props.selectedTab;
    const pageId = props.selectedTab;
    const listId = props.listId;
    const listItemId = props.listItemId;
    const componentId = props.componentId;
    const containerId = props.containerId;
    const goToLine = !!props.meta?.goToLine;
    const lineNumber = props.meta?.lineNumber;

    if (listId) {
        defaultValues = props.websiteData.style.wiml.siteData?.components?.items?.lists?.items?.[listId]?.items?.items?.[listItemId]?.components?.items;
    } else {
        defaultValues = props.websiteData.style.wiml.siteData?.components?.items?.pages?.items?.[pageId]?.components?.items;
    }

    const toggleContainer = useCallback((containerId) => {
        props.onChange('containerId', props.containerId === containerId ? null : containerId);
    }, [props.onChange, props.containerId]);

    function uploadImage(file) {
        const options = {
            maxSizeMB: .5, // this should probably match the firebase rules
            maxWidthOrHeight: 1500,
            useWebWorker: true
        };

        const workPromise = imageCompression(file, options)
            .then(compressedFile => dispatch(saveFile(compressedFile)));

        return workPromise;
    }

    const richTextConfig = useMemo(() => {
        const retVal = { onImageUpload: file => uploadImage(file).then(action => action.payload) };

        return retVal;
    }, [dispatch]);

    // useEffect(() => {
    //     // https://app.clickup.com/25740756/docs/rhhem-14790/rhhem-5390
    //     resetDebouncer(reset, defaultValues);
    // }, [reset, defaultValues]);


    // xmllint.js is a single 6mb file. it does not work with cra and webpack. 
    // i have found it easiest to do this, but ideally we'd use webpack and put xmllint into its own chunk.
    // but when i do that, the validateXML function crashses the browser tab.
    // window.validateXML is passed as a dep to xsd auto completer
    const [loading, error] = useScript({
        src: `${process.env.PUBLIC_URL}/xmllint.js`,
        // checkForExisting: true
    });


    useEffect(() => {
        if (goToLine && lineNumber) {
            // debugger;
            // const domId = 'container_editor_line_number__' + lineNumber;
            // componentIdScrollerDebouncer(scrollDomById(domId, wimlEditorWindow.current?.document));
            // goToLine handle via wiml-boy. monaco does not render every line (optmiization) so this doesn't work
        }
        else if (componentId) {
            const decamelizeComponentId = humps.decamelize(componentId, { separator: '-' });
            componentIdScrollerDebouncer(scrollDomById(decamelizeComponentId));
        }

    }, [componentId, selectedTab, listItemId, goToLine, lineNumber]);

    const scrollDomById = useCallback((domId, docToSearch = window.document) => {
        return () => {
            // don't rely on 
            // const componentInDom = window[componentId];
            const componentInDom = docToSearch.getElementById(domId);
            // document.querySelector('[data-wiml-component-id="your-component-id"]');
            if (componentInDom) {
                componentInDom.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
            } else {
                console.warn(`Could not find component ${domId} in DOM`);
            }
        };
    }, []);


    const { register, reset, formState } = useForm({
        mode: 'onBlur', //todo is it possible to make ONLY onBlur and exclude onChange?
        defaultValues: defaultValues,
        // shouldUnregister: true, --> DOES NOT help with reset problem - see resetDebouncer
    });

    useEffect(() => {
        resetDebouncer_v1_0_0(reset);
    }, [reset, selectedTab, listItemId]);

    const errors = formState.errors;
    // kind of annoying - https://github.com/react-hook-form/react-hook-form/issues/4414
    //regsiter with default values, then update the default values
    // const { ref: wimlMarkupRef, ...wimlMarkupRest } = register('style.wiml.themeData.markup', { required: true, value: props.websiteData.style.wiml.themeData.markup });
    // todo - this is duplicated throughout the app -- combine.
    const handleChangeEvent = (event, key, value) => {
        // for some reason isValid is always true even when not…
        if (errors && Object.keys(errors).length) {
            console.log('Skipping due to errors:', errors);
        } else {
            if (event) {
                key = event.target.name;
                value = event.target.value;
            }

            if (!key) throw new Error('Handle change event `key` is missing.')
            if (value == undefined) throw new Error('Handle change event `value` must be set to a value or null.')


            const splitKeys = key.split('.');
            const path = splitKeys[0];
            const newKey = splitKeys[1];

            if (path == 'style') {
                dispatch(updateStyle({ key: newKey, value }));
            }

        }
    };

    const handleRichTextEditorChangeEvent = ({ pageId, listId, listItemId }, componentId, propertyId, propertyValue) => {
        if (errors && Object.keys(errors).length) {
            console.log('Skipping due to errors:', errors);
        } else {
            dispatchUpdateComponentProperty({ pageId, listId, listItemId }, componentId, propertyId, propertyValue);
        }
    }
    // todo  v1.3.0 handle old stuff below
    const handleDragEnd = (result) => {
        const draggableId = result.draggableId;
        if (draggableId.startsWith('container__')) {
            // const position = result.source.index > result.destination.index ? "above" : "below";
            const position = result.destination.index;
            dispatch(movePageChildComponent({ pageId, componentId: draggableId, position }));
        } else {
            // this assumes we're dragging into the same list. if we change this, you must use draggableId
            const { destination, source, draggableId } = result;
            if (!destination) return;
            if (destination.droppableId == source.droppableId && destination.index == source.index) return;

            const soucePageQualifiedListId = source.droppableId.split('.');
            const sourcePageId = soucePageQualifiedListId[0];
            if (!sourcePageId) throw new Error('sourcePageId is missing');
            const sourceListId = soucePageQualifiedListId[1];
            if (!sourceListId) throw new Error('sourceListId is missing');

            const destinationPageQualifiedListInstanceComponentId = destination.droppableId.split('.');
            const destinationPageId = destinationPageQualifiedListInstanceComponentId[0];
            if (!destinationPageId) throw new Error('destinationPageId is missing');
            const destinationListId = destinationPageQualifiedListInstanceComponentId[1];
            if (!destinationListId) throw new Error('destinationListId is missing');
            const destinationListInstanceComponentId = destinationPageQualifiedListInstanceComponentId.slice(2).join('.');
            if (!destinationListInstanceComponentId) throw new Error('destinationListInstanceComponentId is missing');

            const listItemId = draggableId;

            // handle existing sort order
            const destinationListComponent = props.websiteData.style.wiml.siteData.components.items.pages?.items?.[destinationPageId]?.components?.items?.[destinationListInstanceComponentId];
            const sortExpression = destinationListComponent?.sort?.data?.value;

            // const listItems = props.websiteData.style.wiml.siteData.components.items.lists.items[sourceListId].items.items;
            const listItems = props.websiteData.style.wiml.siteData.components.items.lists.items[destinationListId].items.items;
            const listItemsArr = objectUtils.convertObjectToArray(listItems);
            let sortedItemsArr = listItemsArr;
            if (sortExpression) {
                if (!sortExpression.startsWith("{{manualOrder")) {
                    throw new Error("Manual order is not supported when sorting is enabled.")
                }
                sortedItemsArr = sortListItems(sortExpression, listItemsArr);
            }

            const newSortedItems = move(sortedItemsArr, source.index, destination.index);
            const newOrderedIds = newSortedItems.map((x, index) => ({ [x.id]: index }));
            const newExpression = newOrderedIds.reduce((accum, next) => {
                const objId = Object.keys(next)[0];
                accum[objId] = next[objId];
                return accum;
            }, {})
            const newSortExpression = `{{manualOrder(current_item.id,${JSON.stringify(newExpression)})}}`;

            dispatchUpdateComponentProperty({ pageId: destinationPageId }, destinationListInstanceComponentId, 'sort', newSortExpression);
        }
    };
    const handleNavigationPropertyChangeEvent = (name, pageId, listId, listItemId,) => (jsonValue) => {
        const cleansedValue = jsonValue.map(x => {
            const { children, ...rest } = x;
            if (children?.length) {
                return x;
            } else {
                return rest;
            }
        });
        const cleansedValueString = JSON.stringify(cleansedValue);
        const event = {
            target: {
                name,
                value: cleansedValueString,
                dataset: { pageId, listId, listItemId }
            }
        };
        handlePropertyChangeEvent(event);
    }
    const handlePropertyChangeEvent = (event) => {

        // todo - look for onChange and run it e.g. event-detail.js
        /*
            src/components/manage/event-detail.js
           const handleEventDateChangeEvent = async (e) => {
            dateRest.onChange && await dateRest.onChange(e);
     
            const id = e.target.dataset.id;
            const key = e.target.name;
            const value = dateUtils.parseDate(e.target.value);
            handleChangeEvent(null, id, key, value);
          };
     */


        if (errors && Object.keys(errors).length) {
            console.log('Skipping due to errors:', errors);
        } else {
            const pageId = event.target.dataset.pageId;
            const listId = event.target.dataset.listId;
            const listItemId = event.target.dataset.listItemId;

            const name = event.target.name;

            let propertyValue;
            if (event.target.type == 'checkbox') {
                propertyValue = event.target.checked;
            }
            else if (event.target.type == 'number') {
                // todo put this logic in domain layer
                propertyValue = event.target.value ? parseFloat(event.target.value) : null;
            }
            else if (event.target.type == 'date') {
                // todo put this logic in domain layer
                // keep the date as a string, but convert it to a date object for timezone
                propertyValue = event.target.value ? dateUtils.parseDate(event.target.value).toISOString() : null;
                // console.log({ propertyValue })
            }
            else {
                propertyValue = event.target.value;
            }

            const splitKeys = name.split('.');
            // const componentId = splitKeys[0];
            // const propertyId = splitKeys[1];
            // if splitKeys[startinIndex] starts with "list", then iterate or recur until it doesn't
            const findIndex = splitKeys.findIndex(key => !key.startsWith('list')) - 1;
            const startIndex = findIndex !== -1 ? findIndex : 0;

            const componentId = splitKeys.slice(0, startIndex + 1).join('.');
            const propertyId = splitKeys[startIndex + 1];

            dispatchUpdateComponentProperty({ pageId, listId, listItemId }, componentId, propertyId, propertyValue);
        }
    };

    const dispatchUpdateComponentProperty = ({ pageId, listId, listItemId }, componentId, propertyId, propertyValue) => {
        if (!pageId && (!listId || !listItemId)) throw new Error('Handle change event `pageId` or `listId` must be set to a value or null.')
        if (!componentId) throw new Error('Handle change `componentKey` is missing.')
        if (!propertyId) throw new Error('Handle change `propertyId` is missing.')
        if (typeof propertyValue == 'undefined') throw new Error('Handle change event `value` must be set to a value or null.')

        let retVal;

        if (pageId) {
            retVal = dispatch(updatePageChildComponentProperty({ pageId, componentId, propertyId, propertyValue }));
        } else if (listId) {
            retVal = dispatch(updateListItemComponentProperty({ listId, listItemId, componentId, propertyId, propertyValue }));
        }

        return retVal;
    };

    const dispatchCreateNewListItem = (listId, listInstanceComponentId) => {
        const retVal = dispatch(createNewListItem({ listId }));

        props.onChange('listId', listId);
        props.onChange('listItemId', retVal.payload.id);
        props.onChange('listInstanceComponentId', listInstanceComponentId);

        return retVal;
    };

    const onImageAdd = event => {
        if (errors && Object.keys(errors).length) {
            console.log('Skipping due to errors:', errors);
        } else {
            const pageId = event.target.dataset.pageId;
            const listId = event.target.dataset.listId;
            const listItemId = event.target.dataset.listItemId;

            const name = event.target.name;
            const imageFile = event.target.files[0];

            const splitKeys = name.split('.');
            const path = splitKeys[0];
            const propertyId = splitKeys[1]; // url prop

            // dispatch async thunks are promises
            // https://redux-toolkit.js.org/api/createAsyncThunk#unwrapping-result-actions
            // todo v1.2 handle when image is just pasted or url is provided
            uploadImage(imageFile).then(action => {
                const downloadUrl = action.payload;
                const value = downloadUrl;
                dispatchUpdateComponentProperty({ pageId, listId, listItemId }, path, propertyId, value);
            });
        }
    };


    const onImageLoad = e => {
        const img = e.target;

        const name = img.dataset.name;

        const splitKeys = name.split('.');
        const path = splitKeys[0];

        if (path.startsWith('image')) {
            const actualWidth = img.naturalWidth;
            const actualHeight = img.naturalHeight;

            if (actualWidth && actualHeight) {
                const pageId = img.dataset.pageId;
                const listId = img.dataset.listId;
                const listItemId = img.dataset.listItemId;


                const siteDataRequestProps = {
                    componentId: path,
                    dataRetrievalListItemId: listItemId,
                    dataRetrievalListId: listId,
                    pageDefinitionId: pageId,
                    wimlData: props.websiteData.style.wiml,
                }

                // in case not loading
                const currentWidth = renderChildComponentSiteDataPropertyValue_v1_2_0(siteDataRequestProps, 'width');
                if (actualWidth != currentWidth) {
                    dispatchUpdateComponentProperty({ pageId, listId, listItemId }, path, 'width', actualWidth);
                }

                const currentHeight = renderChildComponentSiteDataPropertyValue_v1_2_0(siteDataRequestProps, 'height');
                if (actualHeight != currentHeight) {
                    dispatchUpdateComponentProperty({ pageId, listId, listItemId }, path, 'height', actualHeight);
                }
            }
        }
    };

    const onImageRemove = event => {
        if (errors && Object.keys(errors).length) {
            console.log('Skipping due to errors:', errors);
        } else {
            const pageId = event.target.dataset.pageId;
            const listId = event.target.dataset.listId;
            const listItemId = event.target.dataset.listItemId;

            const name = event.target.name;

            const splitKeys = name.split('.');
            const path = splitKeys[0];
            const propertyId = splitKeys[1]; // url prop

            dispatchUpdateComponentProperty({ pageId, listId, listItemId }, path, propertyId, null);
        }
    };

    const handleRelationshipChangeEvent = (event) => {
        if (errors && Object.keys(errors).length) {
            console.log('Skipping due to errors:', errors);
        } else {
            const eventValue = event.target.value;
            if (event.target.dataset.relationshipBListId != null) {
                props.onChange('relationshipBListId', eventValue);
                props.onChange('relationshipBListItemId', null);
            } else if (event.target.dataset.relationshipBListItemId != null) {
                // it's possible they set this to empty, so don't dispatch an update
                if (eventValue) {
                    props.onChange('relationshipBListItemId', eventValue);
                    dispatchUpdateRelationship(relationshipAListId, relationshipAListItemId, relationshipBListId, eventValue);
                }
            } else {
                throw new Error('Handle change event `relationshipBListId` or `relationshipBListItemId` must be set to a value or null.')
            }
        }
    };

    const dispatchUpdateRelationship = (relationshipAListId, relationshipAListItemId, relationshipBListId, relationshipBListItemId) => {
        if (!relationshipAListId || !relationshipAListItemId || !relationshipBListId || !relationshipBListItemId) throw new Error('Handle change event `pageId` or `listId` must be set to a value or null.')

        let retVal;

        retVal = dispatch(updateRelationship({ listId: relationshipAListId, listItemId: relationshipAListItemId, relationshipListId: relationshipBListId, relationshipListItemId: relationshipBListItemId }));

        return retVal;
    };

    let componentsToEdit = null;
    let addComponents = null;
    const newContainerKeyRef = useRef(null);

    if (relationshipAListId) {
        // const typeListOptions = property.options.map((option) => {
        //     return (
        //         <option key={option.key} value={option.key}>
        //             {option.value}
        //         </option>
        //     );
        // });\

        // todo merge w/ defaultValues prop
        const { ref: listIdAInputRef, ...listIdAInputRest } = register("relationshipAListId", { value: relationshipAListId });
        const { ref: listItemIdAInputRef, ...listItemIdAInputRest } = register("relationshipAListItemId", { value: relationshipAListItemId });
        const { ref: listIdBInputRef, ...listIdBInputRest } = register("relationshipBListId", { value: relationshipBListId });
        const { ref: listItemIdBInputRef, ...listItemIdBInputRest } = register("relationshipBListItemId", { value: relationshipBListItemId });
        const listIdBOptions = props.websiteData.style.wiml.siteData?.components?.items?.lists?.ids;

        const relationshipBListIdOptions = listIdBOptions.map((option) => {
            return (
                <option key={option} value={option}>
                    {option}
                </option>
            );
        });

        const relationshipBListItemIdOptions = convertObjectToArray(props.websiteData.style.wiml.siteData?.components?.items?.lists?.items?.[relationshipBListId]?.items?.items).map((option) => {
            return (
                <option key={option.id} value={option.id}>
                    {option.components?.items?.heading?.content?.data?.value || option.id}
                </option>
            );
        });
        // const relationshiptDataId = { "data-relationship-a-list-id": listId, "data-relationship-a-list-item-id": listItemId };
        const deleteButton = <Button className='ml-3' color="danger" onClick={handleDeleteListItemRelationship}>
            delete
        </Button>;

        componentsToEdit = (
            <>
                <h4>List Item A</h4>
                <Input type="select" innerRef={listIdAInputRef} {...listIdAInputRest} disabled>
                    <option value={relationshipAListId}>
                        {relationshipAListId}
                    </option>
                </Input>
                <Input type="select" innerRef={listItemIdAInputRef} {...listItemIdAInputRest} disabled>
                    <option value={relationshipAListItemId}>
                        {relationshipAListItemId}
                    </option>
                </Input>
                <h4>List Item B</h4>
                <Input type="select" innerRef={listIdBInputRef} {...listIdBInputRest} onChange={handleRelationshipChangeEvent} disabled={!relationshipBIsNew} data-relationship-b-list-id>
                    {defaultSelectionOption}
                    {relationshipBListIdOptions}
                </Input>
                <Input type="select" innerRef={listItemIdBInputRef} {...listItemIdBInputRest} onChange={handleRelationshipChangeEvent} disabled={!relationshipBIsNew} data-relationship-b-list-item-id>
                    {defaultSelectionOption}
                    {relationshipBListItemIdOptions}
                </Input>
                <Button color="primary" onClick={deselectListItemRelationship}>
                    back
                </Button>
                {props.relationshipBIsNew ? null : deleteButton}
            </>
        );
    }
    else if (listId) {
        const listItemComponents = props.websiteData.style.wiml.themeData.components.items.lists?.items?.[listId]?.components;
        if (!listItemComponents) {
            const siteDataListItem = props.websiteData.style.wiml.siteData.components.items.lists.items[listId].items.items[listItemId];
            if (!siteDataListItem) throw new Error(`No list item found for list ${listId} and list item ${listItemId}`);
            console.warn(`No components found for list ${listId}`);
            ({ componentsToEdit, addComponents } = defaultWimlEditor(props, pageId, getPageChildContainerComponents_v1_3_0, componentsMetaLookup, getAddNewContainerComponents_v1_3_0));
        } else {
            const listItemComponentsReact = getListItemComponents(listId, listItemId, listItemComponents, props.selectedTab, props.listInstanceComponentId, props.listItemViewFormatKey, props.websiteData);
            const relationshipItemComponentsReact = getListItemRelationshipComponents(listId, listItemId,);

            componentsToEdit = (
                <>
                    <div className='mb-5'>
                        <Button color="primary" onClick={deselectListItem}>
                            back
                        </Button>
                        <Button className='ml-3' color="danger" onClick={handleDeleteListItem}>
                            delete
                        </Button>
                    </div>
                    {/* <div>{JSON.stringify(itemData, null, 2)}</div> */}
                    {listItemComponentsReact}
                    {relationshipItemComponentsReact}
                </>
            );
        }
    } else {
        ({ componentsToEdit, addComponents } = defaultWimlEditor(props, pageId, getPageChildContainerComponents_v1_3_0, componentsMetaLookup, getAddNewContainerComponents_v1_3_0));
    }
    const isAdmin = props.userData.roles.includes('admin');
    let wimlInput = null

    if (isAdmin) {
        const listId = props.listId;
        const listItemId = props.listItemId;

        let listItemElem = null;

        if (listId) {

            const selectListItemViewFormat = (e) => {
                props.onChange('listItemViewFormatKey', e.target.value);
            };

            const viewListOptionElems = viewListOptions.map((option) => {
                return (
                    <option key={option.key} value={option.key}>
                        {option.value}
                    </option>
                );
            });

            const listItemViewFormatInputComponent = (
                <Input type="select" value={props.listItemViewFormatKey} onChange={selectListItemViewFormat}>
                    {viewListOptionElems}
                </Input>
            );


            listItemElem = (
                <div className="my-2">
                    <h6>List: {listId}</h6>
                    <h6>List Item: {listItemId}</h6>
                    {listItemViewFormatInputComponent}
                </div>
            );
        }

        const clicker = (e) => {
            e?.preventDefault();
            wimlEditorWindow.current = null;
            props.onChange('pop', !props.pop);
        }

        const opener = (window) => {

            wimlEditorWindow.current = window;
        }


        // wimlInput = <WimlBoy listItemElem={listItemElem} handleChangeEvent={handleChangeEvent} clicker={clicker} {...props} />;

        const bb = props.pop ? <NewWindow onOpen={opener} onUnload={clicker} features={{ width: 800, height: 600 }}><WimlBoy listItemElem={listItemElem} onDiff={handleChangeEvent} clicker={clicker} goToLine={goToLine} lineNumber={lineNumber} {...props} /></NewWindow> : <><WimlBoy listItemElem={listItemElem} onDiff={handleChangeEvent} clicker={clicker} goToLine={goToLine} lineNumber={lineNumber} {...props} /></>;
        wimlInput = <>
            {/* <pre>{JSON.stringify(props.websiteData.style.wiml.themeData.markup, null, 2)}</pre> */}
            {bb}
        </>

    }

    let extraComponents = null;
    if (pageId == 'page__products') {
        extraComponents = <WimlProducts {...props} />;
    }

    let groupedComponents = null;

    if (loading) {
        return <div>loading...</div>
    } else if (error) {
        return <div>Error: {error.message}</div>
    }
    // separating forms because we call `reset` which will reset everything in the form, and we don't want to reset the wiml input
    // the wiml. when they were both in the same form, the `reset` call would cause this to lose its value
    return (
        <DragDropContext onDragEnd={handleDragEnd}>
            <Form>
                {wimlInput}
            </Form>
            <Form>
                {groupedComponents}
            </Form>
            <Form>
                {extraComponents}
                {componentsToEdit}
                {addComponents}
            </Form>
        </DragDropContext>
    );

    // if not new wiml needed, new version of wip.
    function getPageChildContainerComponents_v1_3_0(props, pageId, components, componentsMetaLookup) {
        // find all containers - they should be top-level only
        const containerComponents = components
            ?.ids
            .map((componentId) => {
                const component = { ...components.items[componentId], id: componentId };
                return component;
            })
            .filter((component) => component.type == 'Container');

        const containerAndChildrenComponents = containerComponents.map((containerComponent, index) => {
            const nodeChildren = getPageChildNodeChildren_v1_3_0(props, pageId, containerComponent.type, containerComponent.key);
            const nodeChildrenComponentItemsArray = nodeChildren.map((node) => {
                const componentId = _getThemeDataComponentId_v1_2_0({ type: node.tag, key: node.attr?.key });
                if (components.items[componentId]) {
                    const component = { ...components.items[componentId], id: componentId };
                    return component;
                } else {
                    if (node.tag == 'List') {
                        // write regex to find potential nested list. it would be in this format
                        // const nestedListRegex = new RegExp(`list\\s+\\.${componentId}`);
                        const componentsArray = objectUtils.convertObjectToArray(components.items);
                        // console.log({ componentsArray })
                        // const foundId = componentsArray.find((component) => nestedListRegex.test(component.id));
                        const found = componentsArray.find(comp => comp.type == 'List' && unFormatNodeKey_v1_3_0(comp.key) == node.attr.key);
                        if (found) {
                            const component = { ...found, id: found.id };
                            return component;
                        } else {
                            return null;
                        }
                    } else {
                        return null;
                    }
                }
            }).filter(Boolean);
            const nodeChildrenComponentItemsObject = convertArrayToObject(nodeChildrenComponentItemsArray, 'id');
            const { id: containerId, ...containerItem } = containerComponent;
            const containerAndInnerComponentItemsObject = {
                [containerId]: containerItem,
                ...nodeChildrenComponentItemsObject,
            };
            const containerAndChildrenComponentIds = Object.keys(containerAndInnerComponentItemsObject);
            const containerAndChildrenComponents = { items: containerAndInnerComponentItemsObject, ids: containerAndChildrenComponentIds };

            const componentsArray = containerAndChildrenComponents?.ids?.map((componentId) => {
                // todo fix because id should be in the component
                const component = { ...containerAndChildrenComponents.items[componentId], id: componentId };
                if (component.themeProps?.adminDisplay != 'none') {
                    const properties = getComponentProperties_v1_2_0(props, component, { pageId }, componentsMetaLookup);

                    return getComponentFormGroup(props, component, properties);
                }
            }).filter(Boolean);

            /*
            dnd goes
            DragDropContext
                -> Droppable
                    -> Draggable
            */
            const retVal = (
                <Draggable key={containerId} draggableId={containerId} index={index} disableInteractiveElementBlocking>
                    {(provided) => (
                        <Card id={"container-" + containerId}>
                            <div ref={provided.innerRef} {...provided.draggableProps}>
                                <CardHeader  {...provided.dragHandleProps} onClick={toggleContainer.bind(null, containerId)}>Container: {containerId}</CardHeader>
                                <Collapse isOpen={containerComponents.length == 1 || props.containerId === containerId}>
                                    <CardBody>
                                        {componentsArray}
                                    </CardBody>
                                </Collapse>
                            </div>
                        </Card>
                    )}
                </Draggable>
            );

            return retVal;
        });
        // return containerAndChildrenComponents;
        // look into https://stackoverflow.com/questions/52814011/nested-drag-and-drop-with-react-beautiful-dnd
        const droppableRegion = <Droppable droppableId={props.selectedTab}>
            {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                    {containerAndChildrenComponents}
                    {provided.placeholder}
                </div>
            )}
        </Droppable>;

        return droppableRegion;
    }

    // if not new wiml needed, new version of wip.
    function getAddNewContainerComponents_v1_3_0(props, pageId, components, componentsMetaLookup) {
        const isAdmin = props.userData.roles.includes('admin');
        if (!isAdmin) return null;

        const handleNewContainerClick = templateId => async e => {
            e.preventDefault();

            let key = null;
            let content = null;

            try {
                const pageKey = unFormatNodeKey_v1_3_0(getKeyFromId_v1_3_0(pageId));

                if (templateId == 'products-container-1') {
                    key = 'products'
                    content = getTagChildContents_v1_3_0('Container', productsPageCheatSheet);
                } else if (templateId == 'product-container-1') {
                    key = 'product'
                    content = getTagChildContents_v1_3_0('Container', productTemplate);
                } else if (templateId == 'product-reviews-container-1') {
                    key = 'product-reviews'
                    content = getTagChildContents_v1_3_0('Container', productReviewsTemplate);
                } else if (templateId == 'promotions-container-1') {
                    key = 'promotions'
                    content = getTagChildContents_v1_3_0('Container', promotionsTemplate);
                } else if (templateId == 'all-books-container-1') {
                    key = 'all-books-gallery'
                    content = getTagChildContents_v1_3_0('Container', allBooksTemplate);
                } else if (templateId == 'about-author-container-1') {
                    key = 'about'
                    content = getTagChildContents_v1_3_0('Container', authorTemplate);
                } else if (templateId == 'quote-container-1') {
                    key = 'quote'
                    content = getTagChildContents_v1_3_0('Container', quoteTemplate);
                } else if (templateId == 'social-proof-container-1') {
                    key = 'social-proof'
                    content = getTagChildContents_v1_3_0('Container', socialProofTemplate);
                } else if (templateId == 'image-gallery-container-1') {
                    key = 'image-gallery'
                    content = getTagChildContents_v1_3_0('Container', imageGalleryTemplate);
                } else if (templateId == 'books-page-container-1') {
                    key = 'all-books-list'
                    content = getTagChildContents_v1_3_0('Container', booksPageTemplate);
                } else if (templateId == 'book-page-container-1') {
                    key = 'individual-books-list'
                    content = getTagChildContents_v1_3_0('Container', bookPageTemplate);
                } else if (templateId == 'blog-page-container-1') {
                    key = 'blog-list'
                    content = getTagChildContents_v1_3_0('Container', blogPageTemplate);
                } else if (templateId == 'post-page-container-1') {
                    key = 'post'
                    content = getTagChildContents_v1_3_0('Container', postPageTemplate);
                } else if (templateId == 'events-page-container-1') {
                    key = 'events-list'
                    content = getTagChildContents_v1_3_0('Container', eventsPageTemplate);
                } else if (templateId == 'event-page-container-1') {
                    key = 'event'
                    content = getTagChildContents_v1_3_0('Container', eventPageTemplate);
                } else {
                    const inputKey = window.prompt('Enter template key, e.g. books or help-categories.');
                    key = inputKey;
                    // key = formatNodeKey_v1_2_0(pageKey);
                    if (!key) return;
                }
                dispatch(createPageChildComponent({ pageKey, componentType: 'Container', componentKey: key, position: 'bottom', content }));
                // newContainerKeyRef.current.value = '';
            } catch (e) {
                alert("Error: " + e);
                const rethrow = new Error('Error searching websites: ' + e);
                rethrow.stack = e.stack;
                throw rethrow;
            }
        };
        const retVal = (
            <div className="mt-5">
                <h4>Add new container</h4>
                <Form >
                    {/* <FormGroup>
                        <Label for="section-key">Container key: remember to use <code>this-format, e.g. books, help-categories</code>.</Label>
                        <Input pattern='[a-z]+(-[a-z]+)*' id="section-key" type="text" innerRef={newContainerKeyRef} required autoComplete="off" />
                    </FormGroup> */}

                    <UncontrolledButtonDropdown  >
                        <Button id="caret" color="info" onClick={handleNewContainerClick(null)}>
                            Create empty container
                        </Button>
                        <DropdownToggle caret color="info" />
                        <DropdownMenu>
                            <DropdownItem header>Templates</DropdownItem>
                            <DropdownItem onClick={handleNewContainerClick('about-author-container-1')}><span className='text-muted'>About author</span></DropdownItem>
                            <DropdownItem onClick={handleNewContainerClick('all-books-container-1')}><span className='text-muted'>All books (gallery)</span></DropdownItem>
                            <DropdownItem onClick={handleNewContainerClick('books-page-container-1')}><span className='text-muted'>All books (list)</span></DropdownItem>
                            <DropdownItem onClick={handleNewContainerClick('blog-page-container-1')}><span className='text-muted'>Blog list page</span></DropdownItem>
                            <DropdownItem onClick={handleNewContainerClick('events-page-container-1')}><span className='text-muted'>Events page (list)</span></DropdownItem>
                            <DropdownItem onClick={handleNewContainerClick('event-page-container-1')}><span className='text-muted'>Event page (individual)</span></DropdownItem>
                            <DropdownItem onClick={handleNewContainerClick('image-gallery-container-1')}><span className='text-muted'>Image Gallery</span></DropdownItem>
                            <DropdownItem onClick={handleNewContainerClick('post-page-container-1')}><span className='text-muted'>Post page (individual blog)</span></DropdownItem>
                            <DropdownItem onClick={handleNewContainerClick('products-container-1')}><span className='text-muted'>Products</span></DropdownItem>
                            <DropdownItem onClick={handleNewContainerClick('product-container-1')}><span className='text-muted'>Product (individual)</span></DropdownItem>
                            <DropdownItem onClick={handleNewContainerClick('product-reviews-container-1')}><span className='text-muted'>Product review</span></DropdownItem>
                            <DropdownItem onClick={handleNewContainerClick('promotions-container-1')}><span className='text-muted'>Promotions (slider)</span></DropdownItem>
                            <DropdownItem onClick={handleNewContainerClick('quote-container-1')}><span className='text-muted'>Quote</span></DropdownItem>
                            <DropdownItem onClick={handleNewContainerClick('book-page-container-1')}><span className='text-muted'>Single book (list)</span></DropdownItem>
                            <DropdownItem onClick={handleNewContainerClick('social-proof-container-1')}><span className='text-muted'>Social Proof (Praise)</span></DropdownItem>
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                </Form>
            </div>
        )

        return retVal;
    }


    function getListItemComponents(listId, listItemId, components, pageId, listInstanceComponentId, listItemViewFormatKey, websiteData) {
        if (!listId) throw new Error('listId is required')
        if (!listItemId) throw new Error('listItemId is required');
        if (!components) throw new Error('components is required');
        if (!pageId) throw new Error('pageId is required');
        if (!listInstanceComponentId) throw new Error('listInstanceComponentId is required');
        if (!listItemViewFormatKey) throw new Error('listItemViewFormatKey is required');
        if (!websiteData) throw new Error('websiteData is required');

        let retrievalIds;
        if (listItemViewFormatKey === 'components:page-specific') {
            let newPageId = pageId;
            if (newPageId == 'books' && websiteData.style.wiml.themeData.components.items.pages.ids.includes('book')) {
                newPageId = 'book';
            } else if (newPageId == 'blog' && websiteData.style.wiml.themeData.components.items.pages.ids.includes('post')) {
                newPageId = 'post';
            }
            retrievalIds = websiteData.style.wiml.themeData.components.items.pages.items[newPageId]?.components?.items?.[listInstanceComponentId]?.components?.ids;
        } else if (listItemViewFormatKey === 'components:all') {
            retrievalIds = components.ids;
        } else {
            throw new Error(`listItemViewFormatKey ${listItemViewFormatKey} is not supported`);
        }

        const componentsArray = retrievalIds.map((componentId) => {
            const component = { ...components.items[componentId], id: componentId };
            if (component.themeProps?.adminDisplay != 'none') {
                const properties = getComponentProperties_v1_2_0(props, component, { listId, listItemId }, componentsMetaLookup);
                return getComponentFormGroup(props, component, properties);
            }
        }).filter(Boolean);

        const retVal = componentsArray;
        return retVal;
    }

    function getListItemRelationshipComponents(listId, listItemId,) {
        let listItemRelationships = null;

        const relatedLists = props.websiteData.style.wiml.siteData.components.items.lists?.items?.[listId]?.items.items?.[listItemId]?.relationships?.lists?.items;

        let itemComponents = null;

        if (relatedLists) {
            const relatedListsArray = convertObjectToArray(relatedLists);
            itemComponents = relatedListsArray.map((relatedList) => {
                // const listItemComponent = (
                //     <div key={item.id}><ListItemRelationship item={item} component={component} onSelectListItemRelationship={selectListItemRelationship} /></div>
                // );
                const relatedListItemsArray = convertObjectToArray(relatedList.items);
                const listItemRelationshipComponents = relatedListItemsArray.map((relatedListItem) => {
                    // const labelValue = dot.pick(labelKey, item.components?.items) || `<i title=${labelKey}>EMPTY</i>`;
                    const selectListItemRelationship = () => {
                        props.onChange('relationshipAListId', listId);
                        props.onChange('relationshipAListItemId', listItemId);
                        props.onChange('relationshipBListId', relatedList.id);
                        props.onChange('relationshipBListItemId', relatedListItem.id);
                        props.onChange('relationshipBIsNew', false);
                    };
                    const listItemRelationshipComponent = (
                        <div key={relatedList.id + '_' + relatedListItem.id} >
                            <ListItemRelationship relatedListId={relatedList.id} relatedListItemId={relatedListItem.id} onClick={selectListItemRelationship} />
                        </div>

                    );

                    return listItemRelationshipComponent;
                });


                return listItemRelationshipComponents;
            });
        }

        const addNew = () => {
            props.onChange('relationshipAListId', listId);
            props.onChange('relationshipAListItemId', listItemId);
            props.onChange('relationshipBIsNew', true);
        };

        listItemRelationships = (
            <>
                <h6>Relationships</h6>
                <Button color="primary" onClick={addNew}>
                    Add
                </Button>
                {itemComponents}
            </>
        );
        const retVal = (
            <>
                {listItemRelationships}
            </>
        );

        return retVal;
    }

    function getComponentProperties_v1_2_0(props, component, { pageId, listId, listItemId }, componentsMetaLookup) {
        const propertyMeta = {
            "List.filter": false,
            "List.sort": false,
            "Image.width": false,
            "Image.height": false,
        }
        const isAdmin = props.userData.roles.includes('admin');

        const componentMeta = componentsMetaLookup[component.type];
        const componentDataId = { "data-page-id": pageId, "data-list-id": listId, "data-list-item-id": listItemId };

        let inputPropComponents = null;
        const inputProps = componentMeta.inputProps;
        if (inputProps) {
            inputPropComponents = Object.keys(inputProps)
                .filter((inputKey) => {
                    if (isAdmin) return true;

                    const fqPropKey = `${component.type}.${inputKey}`;
                    const isFiltered = propertyMeta[fqPropKey] === false;
                    return !isFiltered;
                })
                .map((inputKey) => {

                    const property = inputProps[inputKey];
                    let inputPropComponent = null;

                    if (Array.isArray(property)) {
                        throw new Error('Array input props are not supported.');
                    } else {
                        let inputComponent;

                        const inputType = property.type;
                        const inputName = `${component.id}.${inputKey}.data.expression`;
                        let defaultExpression = defaultValues?.[component.id]?.[inputKey]?.data.expression;
                        const defaultValue = defaultValues?.[component.id]?.[inputKey]?.data.value;
                        if (inputType == 'date' && defaultExpression) {
                            defaultExpression = dateUtils.dateYYYYMMDD(new Date(defaultExpression));
                        }
                        const inputLabel = property.label;
                        const inputDescription = property.description;
                        // use register to set a default value

                        const { ref: formInputRef, ...formInputRest } = register(inputName, { value: defaultExpression });
                        // todo DRY THIS UP
                        switch (inputType) {
                            case 'number': {
                                inputComponent = <ReactstrapInputBlurChangeDetector type="number" step="0.01" onDiff={handlePropertyChangeEvent} innerRef={formInputRef} {...formInputRest} {...componentDataId} />;
                                break;
                            }
                            case 'select': {
                                const typeListOptions = property.options.map((option) => {
                                    return (
                                        <option key={option.key} value={option.key}>
                                            {option.value}
                                        </option>
                                    );
                                });

                                inputComponent = (
                                    <Input type="select" id={inputName} name={inputName} innerRef={formInputRef} {...formInputRest} onChange={handlePropertyChangeEvent} {...componentDataId}>
                                        {defaultSelectionOption}
                                        {typeListOptions}
                                    </Input>
                                );
                                break;
                            }
                            case 'url': {
                                inputComponent = <ReactstrapInputBlurChangeDetector type="url" onDiff={handlePropertyChangeEvent} innerRef={formInputRef} {...formInputRest} {...componentDataId} />;
                                break;
                            }
                            case 'short_text': {
                                inputComponent = <ReactstrapInputBlurChangeDetector onDiff={handlePropertyChangeEvent} innerRef={formInputRef} {...formInputRest} {...componentDataId} />;
                                break;
                            }
                            case 'date': {

                                // https://stackoverflow.com/questions/49277112/react-js-how-to-set-a-default-value-for-input-date-type

                                /**
                                 *  const eventDate = props.event.schedule.date;
        const eventDateFormat = eventDate ? dateUtils.dateYYYYMMDD(new Date(eventDate)) : null;
        const schedule = { ...props.event.schedule, date: eventDateFormat };
        const abc = { ...props.event, schedule };
        const { register, formState: { errors } } = useForm({
            mode: 'onBlur',
            defaultValues: abc
        });
        
        
        src/components/manage/event-detail.js
                                 */
                                const defaultValueDate = defaultExpression ? dateYYYYMMDD(new Date(defaultExpression)) : null;
                                inputComponent = (
                                    // very important -- onChange must come last -- see above and see src/components/manage/event-detail.js
                                    <ReactstrapInputBlurChangeDetector type="date" id={inputName} name={inputName} innerRef={formInputRef} {...formInputRest} onChange={handlePropertyChangeEvent} {...componentDataId} defaultValue={defaultValueDate} />
                                );
                                break;
                            }
                            case 'medium_text': {
                                inputComponent = <ReactstrapInputBlurChangeDetector type="textarea" rows={2} onDiff={handlePropertyChangeEvent} innerRef={formInputRef} {...formInputRest} {...componentDataId} />;
                                break;
                            }
                            case 'long_text': {
                                const onDiff = (newValue) => {
                                    return handleRichTextEditorChangeEvent({ pageId, listId, listItemId }, component.id, inputKey, newValue);
                                };
                                // Q: why does rich text get a unique key? A: https://app.clickup.com/25740756/docs/rhhem-14790/rhhem-5390
                                const uniqueKey = [component.id, inputKey, pageId, listId, listItemId].join('-');
                                // console.log({ uniqueKey })
                                inputComponent = <RichTextEditor key={uniqueKey} defaultValue={defaultExpression} onDiff={onDiff} {...richTextConfig} />;
                                break;
                            }
                            case 'json': {
                                const jsonVal = defaultExpression ? JSON.parse(defaultExpression) : null;
                                inputComponent = <InputJsonEditorWrapper onDiff={handleNavigationPropertyChangeEvent(inputName, pageId, listId, listItemId)} defaultValue={jsonVal} />;
                                // inputComponent = <ReactstrapInputBlurChangeDetector type="textarea" rows={5} onDiff={handlePropertyChangeEvent} innerRef={formInputRef} {...formInputRest} {...componentDataId} />;
                                break;
                            }
                            case 'boolean': {
                                inputComponent = <CustomInput type="switch" id={inputName} name={inputName} innerRef={formInputRef} {...formInputRest} onChange={handlePropertyChangeEvent} {...componentDataId} />;
                                break;
                            }
                            case 'list_item': {
                                throw new Error('Array input props are not supported.');
                            }
                            case 'image': {
                                const imageUrl = defaultValue;
                                inputComponent = (
                                    <>
                                        <Input name={inputName} type="file" accept="image/*" {...componentDataId} onChange={onImageAdd} /*todo: put this in global style={{ color: "transparent" }}*/ />
                                        <ReactstrapInputBlurChangeDetector type="url" onDiff={handlePropertyChangeEvent} innerRef={formInputRef} {...formInputRest} {...componentDataId} />

                                        {imageUrl &&
                                            <>
                                                <div>
                                                    <Button outline color="primary" name={inputName} onClick={onImageRemove} {...componentDataId}>
                                                        <FontAwesomeIcon icon={faTrash} /> Remove
                                                    </Button>
                                                </div>
                                                <div>
                                                    <img src={imageUrl} className="img-fluid shadow mt-4" {...componentDataId} data-name={inputName} onLoad={onImageLoad} />
                                                </div>
                                            </>}

                                    </>
                                );
                                break;
                            }
                            default: {
                                throw new Error(`Unknown input type: ${inputType}`);
                            }
                        }

                        const reactComponent = (
                            <FormGroup key={inputKey}>
                                <Label for={inputName} title={inputDescription}>{inputLabel}</Label>
                                {inputComponent}
                            </FormGroup>
                        );
                        inputPropComponent = reactComponent;
                    }

                    return inputPropComponent;
                });
        }

        let listItemComponents = null;

        const listProps = componentMeta.listProps;
        if (listProps) {
            listItemComponents = getListItemsComponents_v1_3_0(pageId, component, props, dispatchCreateNewListItem);
        }

        const retVal = (
            <>
                {inputPropComponents}
                {listItemComponents}
            </>
        );

        return retVal;
    }
}

export default WimlEditor;
const ListItemRelationship = props => {
    const relatedListId = props.relatedListId;
    const relatedListItemId = props.relatedListItemId;

    const retVal = (
        <Button color="primary" onClick={props.onClick}>
            <h6>{relatedListId} | {relatedListItemId}</h6>
        </Button>
    );
    return retVal;
};
function defaultWimlEditor(props, pageId, getPageChildContainerComponents_v1_3_0, componentsMetaLookup, getAddNewContainerComponents_v1_3_0) {
    const pages = props.websiteData.style.wiml.themeData.components.items.pages.items;
    const page = pages?.[pageId];

    const components = page?.components;
    // todo, instead of accessing page.components, access page.sections...
    // todo migrate - if no wiml needed this would be a new version of wip
    const componentsToEdit = getPageChildContainerComponents_v1_3_0(props, pageId, components, componentsMetaLookup);
    // componentsToEdit = getPageComponents_v1_3_0(pageId, components, componentsMetaLookup);
    const addComponents = getAddNewContainerComponents_v1_3_0(props, pageId, components, componentsMetaLookup);
    return { componentsToEdit, addComponents };
}

function getPageChildNodeChildren_v1_3_0(props, pageId, componentType, componentKey) {
    const rootNode = JSON.parse(props.websiteData.style.wiml.themeData.components.items.rootNodeJson);

    const { type: pageType, key: pageKey } = getComponentTypeAndKeyFromId_v1_3_0(pageId);

    const pageNode = findNodeInTree_v1_3_0(rootNode, pageType, pageKey);
    if (!pageNode)
        throw new Error(`Could not find page node for ${pageId}`);

    const foundNode = findNodeInTree_v1_3_0(pageNode, componentType, componentKey);
    if (!foundNode)
        throw new Error(`Could not find node for ${componentKey}`);

    const nodeChildren = flatNodeChildren_v1_3_0(foundNode);
    return nodeChildren;
}

export function getComponentTypeAndKeyFromId_v1_3_0(componentId) {
    let type;
    let key;
    if (componentId.startsWith('header')) {
        type = 'Header';
    } else if (componentId.startsWith('footer')) {
        type = 'Footer';
    } else if (componentId.startsWith('page__')) {
        type = 'Page';
        key = componentId.replace('page__', '');
    } else {
        const split = componentId.split('__');
        type = split[0];
        key = split[1];
    }
    return { type, key };
}

const acceptedDefaultTypes = ['Container', 'Navigation'];
function getComponentFormGroup(props, component, properties) {
    const isAdmin = props.userData.roles.includes('admin');

    let header;
    if (component.key) {
        // convert dash case to title case
        const newHeader = _.startCase(component.key.replace(/-/g, ' '));
        header = (
            <h5>{component.type} <span className="text-secondary"> {newHeader}</span></h5>
        );
    } else {
        if (acceptedDefaultTypes.includes(component.type) || isAdmin) {
            header = (
                <h5>{component.type} <span className="text-secondary"> [default]</span></h5>
            );
        } else {
            return;
        }
    }

    const componentId = component.id;
    const camlizedComponentId = humps.decamelize(componentId, { separator: '-' });

    const retVal = (
        // <div key={componentId} className="my-4" data-wiml-component-id={camlizedComponentId}>
        <div key={componentId} className="my-4" id={camlizedComponentId}>
            {/* <pre>{camlizedComponentId}</pre> */}
            {/* <pre>{JSON.stringify(component, null, 2)}</pre> */}
            {header}
            {properties}
        </div>
    );

    return retVal;
}

// import * as monaco from 'monaco-editor/esm/vs/editor/editor.api';

// import * as monaco from 'monaco-editor';

import Editor2 from "@monaco-editor/react";
// import type { editor } from 'monaco-editor'

import { XsdManager, XsdFeatures } from './src';
// // import { XsdManager, XsdFeatures } from 'monaco-xsd-code-completion/esm';
// // import { XsdManager, XsdFeatures } from 'monaco-xsd-code-completion/src';
// import * as monaco from 'monaco-editor';

// import loader from '@monaco-editor/loader';
// import XsdManager from 'monaco-xsd-code-completion/umd/main';
function WimlBoy(props) {

    // because wimlboy can be opened in a new window, react-hook-form is bound to a dom node that no longer exists.
    // if you dig, you'll see _f.ref is not in the new window popup, but rather in the original window.
    // react-hook-form is unaware the children are moved to a new dom node via portal.
    // the easiest way is to just remount the component so it's boudn to whatever window it was created in.

    const { goToLine, lineNumber } = props;

    useEffect(() => {
        if (goToLine && lineNumber) {
            editorRef.current.getDomNode().scrollIntoViewIfNeeded();
            editorRef.current.revealLineNearTop(lineNumber);
            editorRef.current.setPosition({ column: 1, lineNumber: lineNumber });
        }
    }, [goToLine, lineNumber])

    // the initial problem is that monaco on mount enclosures the old "value", and never gets updated, always causing a new "save" on blur.
    // the alternative would be to useCallback and pass the value to the editor, but that would cause a re-render on every key press.
    const valueRef = useRef();
    valueRef.current = props.websiteData.style.wiml.themeData.markup;

    const editorRef = useRef(null);

    function handleEditorMount(editor, reactMonaco) {
        editorRef.current = editor;

        const xsdManager = new XsdManager(editor);
        xsdManager.set({
            path: 'wiml.xsd',
            value: xsd1,
            namespace: 'xs',
        })

        if (!window.validateXML) throw new Error('window.validateXML is not defined. Make sure to include the validateXML script in your html file.')

        const xsdFeatures = new XsdFeatures(xsdManager, reactMonaco, editor, window.validateXML) // Initialise the xsdFeatures.
        xsdFeatures.addCompletion() // Add auto completion.
        xsdFeatures.addValidation() // Add auto validation on debounce. Can be manually triggered with doValidation.
        xsdFeatures.addGenerateAction() // Add geneate template to actions menu. Generate can be run with doGenerate.
        xsdFeatures.addReformatAction() // Add reformat code to actions menu. Can be run manually with doReformatCode.


        editor.onDidBlurEditorText(() => {
            const newVal = editor.getValue();
            if (newVal != valueRef.current) {
                const event = {
                    type: 'input',
                    target: {
                        name: 'style.wiml.themeData.markup',
                        value: newVal
                    },
                    bubbles: true,
                    cancelable: true
                };

                props.onDiff && props.onDiff(event);
            }
        });
    }

    function handleHighlightCss(code) {
        return highlight(code, languages.css)
    }


    function handleHighlightHtml(code) {
        return highlight(code, languages.html)
    }

    const websiteDataCheatSheet = props.websiteData;
    // const websiteDataCheatSheet = JSON.stringify(props.websiteData, null, 2);


    return (
        < >
            <FormGroup>
                <Label for="style.wiml">WIML</Label>
                <br />
                <Button color="primary" onClick={props.clicker}>Move WIML code</Button>
                <div className="wiml-theme-data-markup-editor-container">
                    <Editor2
                        height="100%"
                        language='html'
                        value={valueRef.current}
                        // value={xml1}
                        options={{
                            minimap: { enabled: false },
                            fontSize: 14,
                        }}
                        onMount={handleEditorMount}
                    />
                </div>
            </FormGroup>
            <div className="text-muted mb-4">
                <h6>Website: {props.websiteData.id}</h6>
                {props.listItemElem}
            </div>
            <div className="mb-4">
                <h6>Cheat Sheet</h6>
                <Card>
                    <CardHeader id="toggler-website-data">
                        Website data
                    </CardHeader>
                    <UncontrolledCollapse toggler="#toggler-website-data">
                        <CardBody>
                            <div>
                                <h4>{props.websiteData.meta.slug}'s data</h4>
                                <FormGroup >
                                    <Label size='sm' for="websiteDataFilterText" title="Website data filter text" className='mt-2 text-secondary'>Website data filter text</Label>
                                    <Input id="websiteDataFilterText" bsSize='sm' onChange={(e) => props.onChange('websiteDataFilterText', e.target.value)} />
                                </FormGroup>

                                <JsonEditor data={websiteDataCheatSheet}
                                    restrictEdit={true}
                                    restrictAdd={true}
                                    restrictDelete={true}

                                    searchText={props.websiteDataFilterText}
                                // searchFilter={true}
                                />
                            </div>
                            <div className="mt-5">
                                <h4>Checkout URL</h4>
                                <SimpleEditor
                                    highlight={handleHighlightHtml}
                                    value={`{{checkout(current_item, "stripe")}}`} />
                            </div>
                        </CardBody>
                    </UncontrolledCollapse>
                </Card>
                <Card>
                    <CardHeader id="toggler-css-variables">
                        CSS variables
                    </CardHeader>
                    <UncontrolledCollapse toggler="#toggler-css-variables">
                        <CardBody>
                            <SimpleEditor
                                highlight={handleHighlightCss}
                                value={cssGlobalVariables_v1_2_0} />
                        </CardBody>
                    </UncontrolledCollapse>
                </Card>
                <Card>
                    <CardHeader id="toggler-products">
                        Products page
                    </CardHeader>
                    <UncontrolledCollapse toggler="#toggler-products">
                        <CardBody>
                            <div>
                                <h4>Add products page</h4>
                                <SimpleEditor
                                    highlight={handleHighlightHtml}
                                    value={productsPageCheatSheet} />
                            </div>
                            <div className="mt-5">
                                <h4>Checkout URL</h4>
                                <SimpleEditor
                                    highlight={handleHighlightHtml}
                                    value={`{{checkout(current_item, "stripe")}}`} />
                            </div>
                        </CardBody>
                    </UncontrolledCollapse>
                </Card>
            </div>
            <style jsx global>{`
                .xml-lint {
    border-width: 0;
    border-style: dotted;
    border-bottom-width: 3px;
}

.xml-lint--fatal-error {
    border-color: red;
}

.xml-lint--error {
    border-color: orange;
}

.xml-lint--warning {
    border-color: blue;
}


                /* only matches if within sidebar, not if in newwindow */
                :global(.sidebar-editor) .wiml-theme-data-markup-editor-container {
                    height: 400px;
                    overflow: auto !important;
                }
            `}</style>
        </ >
    );
}


export const findNodeInTree_v1_3_0 = (node, type, key) => {
    const formattedKey = unFormatNodeKey_v1_3_0(key);
    if (_isElementNode_v1_0_0(node) && node.tag == type && node.attr?.key == formattedKey) {
        return node;
    }
    if (node.child) {
        for (const child of node.child) {
            const result = findNodeInTree_v1_3_0(child, type, formattedKey);
            if (result) {
                return result;
            }
        }
    }
    // else if (node.child) {
    //     return node.child.find(child => findNodeInTree_v1_3_0(child, type, key));
    // }
    // else {
    //     return null;
    // }
}


export const flatNodeChildren_v1_3_0 = (node, includeNode = false) => {
    if (_isElementNode_v1_0_0(node)) {
        if (!node.child) {
            return [node];
        } else {
            const children = node
                .child
                .map((child) => {
                    return flatNodeChildren_v1_3_0(child, true);
                })
                .flat()
                .filter(Boolean);

            if (includeNode) {
                return [node, ...children];
            } else {
                return children;
            }
        }
    }
}

export const findNodeParentByType_v1_3_0 = (rootNode, targetNode, parentTag) => {
    const rootNodesFlatChildren = flatNodeChildren_v1_3_0(rootNode);
    const parentNodes = rootNodesFlatChildren.filter((node) => node.tag === parentTag);
    const parentNode = parentNodes.find((node) => flatNodeChildren_v1_3_0(node).includes(targetNode));
    return parentNode;
}

import { JSONEditor } from "@json-editor/json-editor/dist/nonmin/jsoneditor";
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

// conslider not using svg above or idk. this lib will prefill the fontawesome classes
// see iconlib.
import '@fortawesome/fontawesome-free/css/all.css'
import { getListItemsComponents_v1_3_0 } from './list-items-v1.3.0';
import { getKeyFromId_v1_3_0, unFormatNodeKey_v1_3_0 } from '@wip/common/app/wiml/versions/v1/v1.3.0/theme-data/compiler/components';
import { renderChildComponentSiteDataPropertyValue_v1_2_0 } from '@wip/common/app/wiml/versions/v1/v1.2.0/theme-data/renderer/render-components';
import { wiml_v1_0_0__32 } from '@wip/common/app/wiml/tests/test-data';
import { getTagChildContents_v1_3_0 } from '@wip/common/app/wiml/versions/v1/v1.3.0/theme-data/compiler/transform';

const InputJsonEditorWrapper = (props) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);

    return <div>
        <Button color="primary" onClick={toggle}>
            Edit
        </Button>
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}></ModalHeader>
            <ModalBody>
                <InputJsonEditor {...props} />
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={toggle}>
                    Done
                </Button>
            </ModalFooter>
        </Modal>
    </div>
}

const InputJsonEditor = (props) => {

    const ref = useRef();
    // const [editor, setEditor] = useState<JSONEditor>();
    const editor = useRef();
    const initialChangeEventFired = useRef(false);
    // const editorValue = useRef();
    useEffect(() => {
        if (true) {
            if (!editor.current) {
                editor.current = new JSONEditor(ref.current, {
                    theme: "bootstrap4",
                    iconlib: "fontawesome5",
                    disable_collapse: true,
                    disable_array_delete_last_row: true,
                    disable_array_delete_all_rows: true,
                    collapsed: false,
                    startval: props.defaultValue,
                    schema: {
                        $schema: "https://json-schema.org/draft/2020-12/schema",
                        $defs: {
                            navigationItemProperties: {
                                type: "object",
                                properties: {
                                    text: {
                                        type: "string",
                                        title: "Text",
                                    },
                                    url: {
                                        type: "string",
                                        title: "URL",
                                    },
                                },
                            },
                            navigationItem: {
                                type: "object",
                                title: "Navigation",
                                headerTemplate: "{{i}} - {{self.text}}",
                                allOf: [
                                    { $ref: "#/$defs/navigationItemProperties" },
                                    {
                                        properties: {
                                            children: {
                                                type: "array",
                                                title: "Children",
                                                format: "table",
                                                items: {
                                                    $ref: "#/$defs/navigationItemProperties",
                                                    title: "Child Navigation",
                                                    headerTemplate: "{{i}} - {{self.text}}",
                                                },
                                            },
                                        },
                                    },
                                ],
                            },
                        },
                        type: "array",
                        title: "Navigation",
                        format: "table",
                        items: {
                            $ref: "#/$defs/navigationItem",
                        },
                    },
                });

                editor.current.on("change", () => {
                    if (initialChangeEventFired.current) {
                        // const newValue = JSON.stringify(editor.current.getValue());
                        // if (editorValue.current !== newValue) {
                        props.onDiff(editor.current.getValue());
                        // }
                        // editorValue.current = newValue;
                    } else {
                        initialChangeEventFired.current = true;
                    }
                });
            }

            return () => {
                // destroy editor
                if (editor.current) {
                    console.log("destroy editor");
                    // for (const callback in editor.current.callbacks) {
                    //     const callbackArray = editor.current.callbacks[callback];
                    //     for (const cb of callbackArray) {
                    //         editor.current.off(callback, cb);
                    //     }
                    // }
                    editor.current.off();
                    editor.current.destroy();
                    editor.current = null;
                }
            };
        }
    }, []);

    return <div id="jsoneditor" ref={ref} />;
};


const xsd1 = `<?xml version="1.0" encoding="UTF-8"?>
<xs:schema xmlns:xs="http://www.w3.org/2001/XMLSchema"
    targetNamespace="http://www.example.com/wiml"
    xmlns="http://www.example.com/wiml"
    xmlns:html="http://www.w3.org/1999/xhtml"
    elementFormDefault="qualified">

    <xs:element name="WIML">
        <xs:complexType>
            <xs:sequence>
                <xs:element ref="Layout" minOccurs="0" />
                <xs:element ref="Page" maxOccurs="unbounded" />
            </xs:sequence>
            <xs:attribute name="version" use="required">
                <xs:simpleType>
                    <xs:restriction base="xs:string">
                        <xs:enumeration value="1.0.0" />
                        <xs:enumeration value="1.1.0" />
                        <xs:enumeration value="1.2.0" />
                        <xs:enumeration value="1.3.0" />
                    </xs:restriction>
                </xs:simpleType>
            </xs:attribute>
        </xs:complexType>
    </xs:element>

    <xs:simpleType name="KeyType">
        <xs:restriction base="xs:string">
            <xs:pattern value="[a-z]+(-[a-z]+)*" />
        </xs:restriction>
    </xs:simpleType>

    <xs:attributeGroup name="GenericWimlComponentAttributes">
        <xs:attribute name="key" type="KeyType" />
        <xs:attribute name="admin-display" type="xs:string" />
        <xs:attribute name="class" type="xs:string" />
        <xs:attributeGroup ref="AlignGroup" />
        <xs:attributeGroup ref="DimensionsGroup" />
        <xs:attributeGroup ref="FontGroup" />
    </xs:attributeGroup>

    <xs:complexType name="GenericWimlComponentChildren" mixed="true">
        <xs:sequence>
            <xs:any processContents="lax" minOccurs="0" maxOccurs="unbounded" />
        </xs:sequence>
    </xs:complexType>

    <xs:complexType name="GenericWimlComponentType">
        <xs:complexContent>
            <xs:extension base="GenericWimlComponentChildren">
                <xs:attributeGroup ref="GenericWimlComponentAttributes" />
            </xs:extension>
        </xs:complexContent>
    </xs:complexType>

    <xs:attributeGroup name="AlignGroup">
        <xs:attribute name="align-x" type="xs:string" />
        <xs:attribute name="align-x-xs" type="xs:string" />
        <xs:attribute name="align-x-sm" type="xs:string" />
        <xs:attribute name="align-x-md" type="xs:string" />
        <xs:attribute name="align-x-lg" type="xs:string" />

        <xs:attribute name="align-y" type="xs:string" />
        <xs:attribute name="align-y-xs" type="xs:string" />
        <xs:attribute name="align-y-sm" type="xs:string" />
        <xs:attribute name="align-y-md" type="xs:string" />
        <xs:attribute name="align-y-lg" type="xs:string" />
    </xs:attributeGroup>

    <xs:attributeGroup name="AlignItemsGroup">
        <xs:attribute name="align-items-x" type="xs:string" />
        <xs:attribute name="align-items-x-xs" type="xs:string" />
        <xs:attribute name="align-items-x-sm" type="xs:string" />
        <xs:attribute name="align-items-x-md" type="xs:string" />
        <xs:attribute name="align-items-x-lg" type="xs:string" />

        <xs:attribute name="align-items-y" type="xs:string" />
        <xs:attribute name="align-items-y-xs" type="xs:string" />
        <xs:attribute name="align-items-y-sm" type="xs:string" />
        <xs:attribute name="align-items-y-md" type="xs:string" />
        <xs:attribute name="align-items-y-lg" type="xs:string" />
    </xs:attributeGroup>

    <xs:attributeGroup name="ColSizeGroup">
        <xs:attribute name="size" type="xs:string" />
        <xs:attribute name="size-xs" type="xs:string" />
        <xs:attribute name="size-sm" type="xs:string" />
        <xs:attribute name="size-md" type="xs:string" />
        <xs:attribute name="size-lg" type="xs:string" />
    </xs:attributeGroup>

    <xs:element name="Col">
        <xs:complexType>
            <xs:complexContent>
                <xs:extension base="GenericWimlComponentType">
                    <xs:attributeGroup ref="AlignItemsGroup" />
                    <xs:attributeGroup ref="ColSizeGroup" />
                </xs:extension>
            </xs:complexContent>
        </xs:complexType>
    </xs:element>

    <xs:element name="ContactForm">
    </xs:element>

    <xs:element name="Container">
        <xs:complexType>
            <xs:complexContent>
                <xs:extension base="GenericWimlComponentType">
                    <xs:attribute name="bg-color" type="xs:string" />
                    <xs:attribute name="bg-url" type="xs:string" />
                    <xs:attribute name="bg-fixed" type="xs:boolean" />
                </xs:extension>
            </xs:complexContent>
        </xs:complexType>
    </xs:element>

    <xs:element name="Date">
        <xs:complexType>
            <xs:simpleContent>
                <xs:extension base="xs:string">
                    <xs:attributeGroup ref="GenericWimlComponentAttributes" />
                </xs:extension>
            </xs:simpleContent>
        </xs:complexType>
    </xs:element>

    <xs:element name="Embed">
        <xs:complexType>
            <xs:simpleContent>
                <xs:extension base="xs:string">
                    <xs:attributeGroup ref="GenericWimlComponentAttributes" />
                </xs:extension>
            </xs:simpleContent>
        </xs:complexType>
    </xs:element>

    <xs:attributeGroup name="DimensionsGroup">
        <xs:attribute name="max-height" type="xs:string" />
        <xs:attribute name="max-height-xs" type="xs:string" />
        <xs:attribute name="max-height-sm" type="xs:string" />
        <xs:attribute name="max-height-md" type="xs:string" />
        <xs:attribute name="max-height-lg" type="xs:string" />

        <xs:attribute name="max-width" type="xs:string" />
        <xs:attribute name="max-width-xs" type="xs:string" />
        <xs:attribute name="max-width-sm" type="xs:string" />
        <xs:attribute name="max-width-md" type="xs:string" />
        <xs:attribute name="max-width-lg" type="xs:string" />
    </xs:attributeGroup>

    <xs:attributeGroup name="FontGroup">
        <xs:attribute name="font-color" type="xs:string" />
        <xs:attribute name="font-color-xs" type="xs:string" />
        <xs:attribute name="font-color-sm" type="xs:string" />
        <xs:attribute name="font-color-md" type="xs:string" />
        <xs:attribute name="font-color-lg" type="xs:string" />

        <xs:attribute name="font-size" type="xs:string" />
        <xs:attribute name="font-size-xs" type="xs:string" />
        <xs:attribute name="font-size-sm" type="xs:string" />
        <xs:attribute name="font-size-md" type="xs:string" />
        <xs:attribute name="font-size-lg" type="xs:string" />

        <xs:attribute name="font-weight" type="xs:string" />
        <xs:attribute name="font-weight-xs" type="xs:string" />
        <xs:attribute name="font-weight-sm" type="xs:string" />
        <xs:attribute name="font-weight-md" type="xs:string" />
        <xs:attribute name="font-weight-lg" type="xs:string" />
    </xs:attributeGroup>

    <xs:element name="Heading">
        <xs:complexType>
            <xs:simpleContent>
                <xs:extension base="xs:string">
                    <xs:attribute name="tag" type="xs:string" />
                    <xs:attribute name="sub" type="xs:boolean" />
                    <xs:attributeGroup ref="GenericWimlComponentAttributes" />
                </xs:extension>
            </xs:simpleContent>
        </xs:complexType>
    </xs:element>

    <xs:element name="Hr">
    </xs:element>

    <xs:element name="Icon">
        <xs:complexType>
            <xs:simpleContent>
                <xs:extension base="xs:string">
                    <xs:attributeGroup ref="GenericWimlComponentAttributes" />
                    <xs:attributeGroup ref="IconSizeGroup" />
                </xs:extension>
            </xs:simpleContent>
        </xs:complexType>
    </xs:element>

    <xs:attributeGroup name="IconSizeGroup">
        <xs:attribute name="size" type="xs:string" />
        <xs:attribute name="size-xs" type="xs:string" />
        <xs:attribute name="size-sm" type="xs:string" />
        <xs:attribute name="size-md" type="xs:string" />
        <xs:attribute name="size-lg" type="xs:string" />
    </xs:attributeGroup>

    <xs:element name="Image">
        <xs:complexType>
            <xs:simpleContent>
                <xs:extension base="xs:string">
                    <xs:attribute name="circle" type="xs:boolean" />
                    <xs:attribute name="shadow" type="xs:boolean" />
                    <xs:attribute name="src" type="xs:string" />
                    <xs:attribute name="square" type="xs:boolean" />
                    <xs:attributeGroup ref="GenericWimlComponentAttributes" />
                    <xs:attributeGroup ref="WrapGroup" />
                </xs:extension>
            </xs:simpleContent>
        </xs:complexType>
    </xs:element>

    <xs:element name="Layout">
        <xs:complexType>
            <xs:sequence>
                <xs:element ref="title" minOccurs="0" maxOccurs="1" />
                <xs:element ref="meta" minOccurs="0" maxOccurs="unbounded" />
                <xs:element ref="style" />
                <xs:element name="Header" type="GenericWimlComponentType" />
                <xs:element name="CurrentPage" type="xs:anyType" />
                <xs:element name="Footer" type="GenericWimlComponentType" />
            </xs:sequence>
        </xs:complexType>
    </xs:element>

    <xs:element name="List">
        <xs:complexType>
            <xs:complexContent>
                <xs:extension base="GenericWimlComponentType">
                    <xs:attribute name="admin-label-key" type="xs:string" />
                    <xs:attribute name="filter" type="xs:string" />
                    <xs:attribute name="limit" type="xs:integer" />
                    <xs:attribute name="list-id" type="KeyType" />
                    <xs:attribute name="slider" type="xs:boolean" />
                    <xs:attribute name="slides" type="xs:integer" />
                    <xs:attribute name="speed" type="xs:integer" />
                    <xs:attribute name="sort" type="xs:string" />
                </xs:extension>
            </xs:complexContent>
        </xs:complexType>
    </xs:element>

    <xs:element name="Link">
        <xs:complexType>
            <xs:complexContent>
                <xs:extension base="GenericWimlComponentType">
                    <xs:attribute name="button" type="xs:boolean" />
                    <xs:attribute name="url" type="xs:anyURI" />
                </xs:extension>
            </xs:complexContent>
        </xs:complexType>
    </xs:element>

    <xs:element name="meta">
        <xs:complexType>
            <xs:simpleContent>
                <xs:extension base="xs:string">
                    <xs:attribute name="charset" type="xs:string" />
                    <xs:attribute name="content" type="xs:string" use="required" />
                    <xs:attribute name="name" type="xs:string" />
                    <xs:attribute name="property" type="xs:string" />
                </xs:extension>
            </xs:simpleContent>
        </xs:complexType>
    </xs:element>

    <xs:element name="Nav">
        <xs:complexType>
            <xs:complexContent>
                <xs:extension base="GenericWimlComponentType">
                    <xs:attribute name="admin-label-key" type="xs:string" />
                    <xs:attribute name="display" type="xs:string" />
                    <xs:attribute name="icon" type="xs:string" />
                    <xs:attribute name="label" type="xs:string" />
                    <xs:attribute name="url" type="xs:anyURI" />
                </xs:extension>
            </xs:complexContent>
        </xs:complexType>
    </xs:element>

    <xs:element name="Navigation">
        <xs:complexType>
            <xs:simpleContent>
                <xs:extension base="xs:string">
                    <xs:attributeGroup ref="GenericWimlComponentAttributes" />
                </xs:extension>
            </xs:simpleContent>
        </xs:complexType>
    </xs:element>

    <xs:element name="Number">
        <xs:complexType>
            <xs:simpleContent>
                <xs:extension base="xs:string">
                    <xs:attribute name="display" type="xs:string" />
                    <xs:attributeGroup ref="GenericWimlComponentAttributes" />
                </xs:extension>
            </xs:simpleContent>
        </xs:complexType>
    </xs:element>

    <xs:element name="OrderSummary">
    </xs:element>

    <xs:element name="Page">
        <xs:complexType>
            <xs:complexContent>
                <xs:extension base="GenericWimlComponentType">
                </xs:extension>
            </xs:complexContent>
        </xs:complexType>
    </xs:element>

    <xs:element name="Row">
        <xs:complexType>
            <xs:complexContent>
                <xs:extension base="GenericWimlComponentType">
                    <xs:attributeGroup ref="AlignItemsGroup" />
                </xs:extension>
            </xs:complexContent>
        </xs:complexType>
    </xs:element>

    <xs:element name="style">
        <xs:complexType>
            <xs:simpleContent>
                <xs:extension base="xs:string">
                    <xs:attribute name="type" type="xs:string" />
                </xs:extension>
            </xs:simpleContent>
        </xs:complexType>
    </xs:element>

    <xs:element name="Spacer">
        <xs:complexType>
            <xs:attributeGroup ref="SpacerSizeGroup" />
        </xs:complexType>
    </xs:element>

    <xs:attributeGroup name="SpacerSizeGroup">
        <xs:attribute name="size-x" type="xs:string" />
        <xs:attribute name="size-x-xs" type="xs:string" />
        <xs:attribute name="size-x-sm" type="xs:string" />
        <xs:attribute name="size-x-md" type="xs:string" />
        <xs:attribute name="size-x-lg" type="xs:string" />

        <xs:attribute name="size-y" type="xs:string" />
        <xs:attribute name="size-y-xs" type="xs:string" />
        <xs:attribute name="size-y-sm" type="xs:string" />
        <xs:attribute name="size-y-md" type="xs:string" />
        <xs:attribute name="size-y-lg" type="xs:string" />
    </xs:attributeGroup>

    <xs:element name="Text">
        <xs:complexType>
            <xs:simpleContent>
                <xs:extension base="xs:string">
                    <xs:attribute name="limit" type="xs:integer" />
                    <xs:attributeGroup ref="GenericWimlComponentAttributes" />
                </xs:extension>
            </xs:simpleContent>
        </xs:complexType>
    </xs:element>

    <xs:element name="title">
        <xs:complexType>
            <xs:simpleContent>
                <xs:extension base="xs:string">
                </xs:extension>
            </xs:simpleContent>
        </xs:complexType>
    </xs:element>

    <xs:attributeGroup name="WrapGroup">
        <xs:attribute name="wrap" type="xs:string" />
        <xs:attribute name="wrap-xs" type="xs:string" />
        <xs:attribute name="wrap-sm" type="xs:string" />
        <xs:attribute name="wrap-md" type="xs:string" />
        <xs:attribute name="wrap-lg" type="xs:string" />
    </xs:attributeGroup>
</xs:schema>
`;

// todo replace this - do not depend on test data, and instead have test data consume this.
const productsPageCheatSheet = wiml_v1_0_0__32('1.3.0');



const promotionsTemplate = `<Container key="hero"  bg-url="https://firebasestorage.googleapis.com/v0/b/wild-ink-pages-prod.appspot.com/o/user-content%2Fsite_xfwhWLszaE%2FHero%20Backgrounds%20MZ%20(2)_1718038029679.jpg?alt=media&token=6b9384d2-3e69-4601-8883-cd405df10cd4"   bg-fixed>
            <Spacer size-y="1" />
            <List key="promotions" slider speed="6000" >
            <Row>
                <Col size="5">
                    <Image key="featured" align-x="center" align-x-md="center" align-y="center"/>
                </Col>
                 <Col size="6" align-y="center">
                     <Heading key="title" tag="h1" />
                    <Text key="description"/>
                    <Link button key="target">Learn More</Link>
                </Col>
            </Row>
            </List >
            <Spacer size-y="1" />
        </Container>`;

const allBooksTemplate = `<Container key="all-books"
            bg-url="https://firebasestorage.googleapis.com/v0/b/wild-ink-pages-prod.appspot.com/o/user-content%2Fsite_xfwhWLszaE%2FHero%20Backgrounds%20MZ%20(2)_1718038029679.jpg?alt=media&token=6b9384d2-3e69-4601-8883-cd405df10cd4"
            bg-fixed>
            <Spacer size-y="2" />
            <Heading key="all-books" align-x="center" tag="h1" font-weight="8">Explore All Books</Heading>
            <Spacer size-y="1" />
            <Row align-items-x="center">
                <List key="books">
                    <Col size="3">
                    <Link>
                    <Image key="cover" max-height="4" max-height-md="5" max-width-md="4" class="layered box" />
                    </Link>
                    </Col>
                </List>
            </Row>
            <Link key="all-books" button align-x="center" url="/books"> View All </Link>
            <Spacer size-y="2" />
        </Container>`;

const authorTemplate = `<Container key="author">
            <Spacer size-y="5" />
            <Heading key="about" align-x="center" tag="h1" font-weight="8">About</Heading>
            <Spacer size-y="3" />
            <Row>
                <Col size="5">
                <Image key="headshot" align-x="center" align-y="center"
                    src="https://firebasestorage.googleapis.com/v0/b/wild-ink-pages-prod.appspot.com/o/user-content%2Fsite_6gwgmK5RJt%2FAuthor%20Headshots%20500x500%20(8)_1718053712194.jpg?alt=media&token=c45cf245-2252-4dff-ad57-aaa5ba6735e8" />
                </Col>
                <Col align-y="center">
                <Text key="bio">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempo incididunt
                    ut labore et dolore magna aliqua. Tortor at auctor urna nunc. Netus et malesuada fames acurpis
                    egestas sed. Elementum tempus egestas sed sed risus. Quis ipsum suspendisse ultrices gravidadictum
                    fusce. Quisque sagittis purus sit amet volutpat consequat mauris nunc. Ipsum a arcu cursus vitae.
                    Felis eget nunc lobortis mattis aliquam faucibus purus in massa. Habitant morbi tristique senectus
                    et netus et malesuada fames ac. Imperdiet massa tincidunt nunc pulvinar sapien et ligula
                    ullamcorper. Consectetur purus ut faucibus pulvinar elementum integer enim neque.</Text>
                <Link key="about" button align-x-md="center" url="/about"> Learn More </Link>
                </Col>
            </Row>
            <Spacer size-y="5" />
        </Container>`;

const quoteTemplate = `<Container key="quote">
            <Heading sub key="quote" tag="h2"  align-x="center">Lorem ipsum dolor sit amet, consectetur
                adipiscing
                elit. Sed do eiusmod tempor incididunt ut labore et dolore magna. </Heading>
        </Container>;`

const socialProofTemplate = `<Container key="social-proof">
            <Spacer size-y="1" />
            <Heading sub key="social-proof"  tag="h1" font-weight="8" align-x="center" align-x-md="center" align-y="center" />
            <Spacer size-y="1" />
            <List key="social-proof" slider="" speed="6000">
                <Row>
                    <Col size="10" align-y="center">
                        <Heading key="title" tag="h4" font-weight="2" />
                        <Heading key="description" sub=""  tag="h6" font-weight="1" />
                    </Col>
                </Row>
            </List>
            <Spacer size-y="1" />
        </Container>`;


const productTemplate = `<Page key="product">
        <Container>
            <Spacer size-y="5" />
            <Row>
                <List key="products"
                    filter="{{slugify(current_item.components.items.heading__title.content.data.value) == current_page.request.query.product}}">
                    <Col>
                    <Heading key="title" tag="h2" />
                    <Number key="price" display="currency" />
                    <Text key="description" />
                    <Spacer size-y="1" />
                    </Col>
                </List>
            </Row>
            <Spacer size-y="5" />
        </Container>
    </Page>`;


const productReviewsTemplate =
    `      <Container>
                <Row>
             <List key="products"
                    filter="{{slugify(current_item.components.items.heading__title.content.data.value) == current_page.request.query.product}}">
                    <Col>
                    <Spacer size-y="1" />
                    <Heading key="reviews" tag="h2" font-weight="8" />
                    <Spacer size-y="1" />
                    <Text key="reviews" />
                    </Col>
                </List>
            </Row>
            </Container>`
    ;

const imageGalleryTemplate = `<Container key="gallery">
    <Row>
        <Col class="flex-row justify-content-center align-items-baseline flex-wrap">
        <List key="gallery">
            <Image class="" key="cover" max-width="4"
                src="https://firebasestorage.googleapis.com/v0/b/wild-ink-pages-prod.appspot.com/o/user-content%2Fsite_6gwgmK5RJt%2FBook%20Cover%20Coming%20Soon%20(1)_1719872033627.jpg?alt=media&token=7c7705b9-1de2-4e6b-aaaf-4907374988ac" />
        </List>
        </Col>
    </Row>
</Container>`;


const booksPageTemplate =
    `<Container>
            <Spacer size-y="5" />
            <Row>
                <List key="books" sort="{{current_item.components.items.date__published.date.data.value}}">
                    <Col size="4">
                    <Image key="cover" align-x-md="center" />
                    </Col>
                    <Col size="8" align-y="center">
                    <Heading key="title" tag="h4" align-x-md="center" /> 
                    <Text key="description" limit="500" />
                    <Link button align-x-md="center"> Learn More </Link>
                    </Col>
                </List>
            </Row>
            <Spacer size-y="1" />
        </Container>`;


const bookPageTemplate = `        <Container>
            <Spacer size-y="5" />
            <Row>
                <List key="books"
                    filter="{{slugify(current_item.components.items.heading__title.content.data.value) == current_page.request.query.book}}">
                    <Col size="4">
                    <Image key="cover" class="layered box" align-x="center"
                        src="https://firebasestorage.googleapis.com/v0/b/wild-ink-pages-prod.appspot.com/o/user-content%2Fsite_6gwgmK5RJt%2FBook%20Cover%20Coming%20Soon%20(1)_1719872033627.jpg?alt=media&token=7c7705b9-1de2-4e6b-aaaf-4907374988ac" />
                    <Spacer size-y="1" />

                    </Col>
                    <Col size="8">
                    <Heading sub key="title" tag="h1">Book Title</Heading>
                    <Date key="published" align-x="center" />
                    <Text key="description">Lorem ipsum dolor sit
                        amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
                        aliqua. Tortor at auctor urna nunc. Netus et malesuada fames ac turpis egestas sed. Elementum
                        tempus egestas sed sed risus. </Text>
                    <Row>
                        <Col>
                        <Link key="amazon" align-x="center" button> Amazon </Link>
                        </Col>
                        <Col>
                        <Link key="barnesnoble" align-x="center" button> Barnes & Noble </Link>
                        </Col>
                        <Col>
                        <Link key="bookshop" align-x="center" button> Bookshop.org </Link>
                        </Col>
                    </Row>
                    </Col>
                </List>
            </Row>
            <Row>
                <List key="books">
                    <Col size="12">
                    <Spacer size-y="1" />
                    <Heading sub key="reviews" tag="h2" font-weight="8" />
                    <Spacer size-y="1" />
                    <Text key="reviews" />
                    </Col>
                </List>
            </Row>
            <Spacer size-y="5" />
        </Container>`;


const blogPageTemplate = `<Container>
            <Spacer size-y="5" />
            <Heading key="page-title" align-x="center" tag="h1">Blog</Heading>
            <Spacer size-y="1" />
            <Row>
                <List key="blog" sort="{{current_item.components.items.date__published.date.data.value}}">
                    <Col size="4">
                    <Link>
                    <Image key="featured" align-x-md="center" max-width-md="3" />
                    </Link>
                    </Col>
                    <Col size="8" align-y="center">
                    <Heading key="title" tag="h4" align-x-md="center" /> <Text key="post" limit="250" />
                    <Link button align-x-md="center"> Read More </Link>
                    <Spacer />
                    </Col>
                </List>
            </Row>
            <Spacer size-y="5" />
        </Container>`;


const postPageTemplate = `  <Container>
            <Spacer size-y="5" />
            <Row>
                <List key="blog"
                    filter="{{slugify(current_item.components.items.heading__title.content.data.value) == current_page.request.query.post}}">
                    <Col>
                    <Heading key="title" tag="h2" />
                    <Date key="published" />
                    <Spacer size-y="1" />
                    <Image key="featured" wrap="right" max-width-md="3" /> <Text key="post" />
                    <Spacer size-y="1" />
                    </Col>
                </List>
            </Row>
        </Container>`;


const eventsPageTemplate = ` <Container>
            <Spacer size-y="5" />
            <Heading key="page-title" align-x="center" tag="h1">Events</Heading>
            <Spacer size-y="1" />
            <Heading key="upcoming-events" align-x="center" tag="h2">Upcoming Events</Heading>
            <Spacer size-y="1" />
            <Row>
                <List key="upcoming-events" list-id="events"
                    filter="{{isFuture(current_item.components.items.date__start.date.data.value)}}"
                    sort="{{reverse(current_item.components.items.date__start.date.data.value)}}">
                    <Col>
                    <Heading key="title" align-x="left" tag="h4" />
                    <Date key="start" align-x="left" />
                    <Text key="description" />
                    </Col>
                </List>
            </Row>
            <Spacer size-y="2" />
            <Heading key="past-events" align-x="center" tag="h2">Past Events</Heading>
            <Spacer size-y="1" />
            <Row>
                <List key="past-events" list-id="events"
                    filter="{{isPast(current_item.components.items.date__start.date.data.value)}}"
                    sort="{{current_item.components.items.date__start.date.data.value}}">
                    <Col>
                    <Heading key="title" align-x="left" tag="h4" />
                    <Date key="start" align-x="left" />
                    <Text key="description" />
                    </Col>
                </List>
            </Row>
            <Spacer size-y="5" />
        </Container>`;


const eventPageTemplate = `<Container>
            <Spacer size-y="5" />
            <Row>
                <List key="events"
                    filter="{{slugify(current_item.components.items.heading__title.content.data.value) == current_page.request.query.event}}">
                    <Col>
                    <Heading key="title" tag="h2" />
                    <Date key="start" />
                    <Text key="description" />
                    <Spacer size-y="1" />
                    </Col>
                </List>
            </Row>
            <Spacer size-y="5" />
        </Container>`;
