import { convertThemeDataToReact_v1_2_0 } from './renderer/render-components';
import { IThemeDataToReactConverter_v1 } from '../../types';
import { _markupToComponentThemeData_v1_2_0 } from './compiler';
import { ComponentThemeData_v1_2_0 } from './compiler/types';


export class ConvertThemeDataToReact_v1_2_0 implements IThemeDataToReactConverter_v1 {
    constructor() {
    }

    public convertComponentThemeDataToRenderThemeData_v1(themeData: ComponentThemeData_v1_2_0) {
        return convertThemeDataToReact_v1_2_0(themeData);
    }
    public convertMarkupToComponentThemeData_v1(wimlString: string) {
        return _markupToComponentThemeData_v1_2_0(wimlString);
    }
}
