import { JSONObject, JSONValue } from "../../../../lib/object-types";

export type ComponentThemeData_v1 = {
    rootNode: WimlRootNode_v1,
    pages: ThemeDataPageComponentCollection_v1,
    lists: ThemeDataListCollection_v1,
    version: WimlVersion_v1,
};

export type WimlNode_v1 = {
    text?: string;
    tag?: string; // 'div'
    node?: string; // 'element' //todo make this NON-optional
    attr?: { [key: string]: string }; // { 'data-wiml-page': 'home' }
    child?: Array<WimlNode_v1>;
};

export type WimlVersion_v1 =
    '1.0.0' |
    '1.1.0' |
    '1.2.0';
// todo make some part of this global amongst version

export type WimlRootNode_v1 = WimlNode_v1 & {
    version: WimlVersion_v1;
};

export type RenderThemeData_v1 = Record<string, React.ReactNode>;
export type ThemeDataWimlComponent_v1 = {
    type: string;
};
type UrlQueryProps_v1 = Record<string, string | string[]>;
type CurrentPage = {
    pageKey: string;
    request: {
        extraQuery: UrlQueryProps_v1;
        query: {
            [key: string]: string;
        };
    };
};

export type RuntimeWimlComponentProps_v1 = {
    display?: "currency" | "percent" | "progress";
    topLevelListComponentKey: string;
    speed?: any;
    slider?: boolean;
    colorThemeConfig: any;
    baseThemeConfig: any;
    circle: boolean;
    square: boolean;
    button: string;
    alignLg: any;
    pageDefinitionId: string;
    currentPage: CurrentPage;
    children: React.ReactNode;
    className?: string;
    componentKey?: string;
    componentId?: string; // todo remove and put in v1.2
    displayLayout?: boolean;
    fluid?: boolean;
    listId?: string;
    listItemId?: string;
    pageKey?: string;
    size?: string;
    class?: string;
    wimlData: IWiml_v1;
    dataRetrievalListId?: string;
    dataRetrievalListItemId?: string;
    [key: string]: any; // todo remove me
    extra: {
        [key: string]: any; // used for Legal component - relies on about data - remove me when possible.
    };
};

export type RuntimeWimlComponenttPropKeys_v1 = keyof RuntimeWimlComponentProps_v1;

export type ThemeDataPageListComponentsTopLevelCloneProps_v1 = {
    className?: string;
    topLevelListComponentKey: string;
};

// THEME DATA -- PAGE
export type ThemeDataPageWithComponents_v1 = {
    name: string;
    url: string;
    components: ThemeDataListComponentCollection_v1;
};
// todo v1.2 rename

export type ThemeDataPageComponent_v1 = ThemeDataWimlComponent_v1 & {
    // unique identifier for the component within the page or list
    key: string;
    themeProps?: JSONObject;
};

export type ThemeDataPageChildComponent_v1 = ThemeDataPageComponent_v1 & {
    // id is the type + key
    // e.g. listBooks
    // however if type and key are the same, it's just the key e.g. aboutAbout -> about
    id: string;
    isListInstanceComponent?: boolean;
    parentListInstanceComponentId?: string;
};

export type ThemeDataPageChildComponentListInstanceChildComponent_v1 = ThemeDataPageChildComponent_v1 & {
    listId: string;
    parentListInstanceComponentId: string;
};

export type ThemeDataPageListComponent_v1 = ThemeDataPageChildComponent_v1 & {
    // unique identifier for the component within the page or list
    components: {
        ids: string[];
    };
};

export type ThemeDataPageComponentCollection_v1 = WimlCollection_v1<ThemeDataPageWithComponents_v1>;

export type ThemeDataReactComponentOptions = {
    layout?: WimlNode_v1;
    pageDefinitionId?: string; // todo rename to componentDefinitoinId -- because "header" and "footer" (part of <Laytou>) are not pages
    pageOccurrenceId?: string;
};
// THEME DATA -- LIST

export type ThemeDataList_v1 = ThemeDataWimlComponent_v1 & {
    type: 'List';
};

export type ThemeDataListQualifiedAndMergedWithComponents_v1 = ThemeDataListQualifiedAndMerged_v1 & {
    components: ThemeDataListComponentCollection_v1;
};


export type ThemeDataListCollection_v1 = WimlCollection_v1<ThemeDataListQualifiedAndMergedWithComponents_v1>;

export type ListInstanceComponentThemeData_v1 = ThemeDataPageChildComponent_v1 & {
    listId: string;
    meta: Record<string, any>;
};
type ThemeDataListComponentCollection_v1 = WimlCollection_v1<ThemeDataPageChildComponent_v1>;


export type ThemeDataListRenderOptions_v1 = {
    dataRetrievalListId?: string;
    dataRetrievalListItemId?: string;
};

export type ThemeDataPageList_v1 = ThemeDataList_v1 & ListInstanceComponentThemeData_v1 & {
    pageOccurrenceId: string;
};

export type ThemeDataListQualifiedAndMerged_v1 = ThemeDataList_v1 & ThemeDataPageChildComponent_v1 & ListInstanceComponentThemeData_v1 & {
    pageOccurrenceIds: string[];
};

export type ThemeDataPageListQualifiedComponent_v1 = ThemeDataPageQualifiedListComponent_v1 & {
    lqId: string;
};

export type ThemeDataPageQualifiedListComponent_v1 = ThemeDataPageChildComponent_v1 & ListInstanceComponentThemeData_v1 & {};
// SITE DATA
// SITE DATA -- PAGE
type SiteDataPageComponent_v1 = {
    [property: string]: SiteDataPageComponentProperty_v1;
};
type SiteDataPageComponentProperty_v1 = {
    data: {
        expression: string;
        value: JSONValue;
    };
};
type SiteDataPageComponentCollection_v1 = WimlCollection_v1<SiteDataPageComponent_v1>;
type SiteDataPage_v1 = {
    components: SiteDataPageComponentCollection_v1;
};
type SiteDataPageCollection_v1 = WimlCollection_v1<SiteDataPage_v1>;
// SITE DATA -- LIST

export type SiteDataListItem_v1 = {
    meta: {
        editedDate: Date;
    };
    components: WimlCollection_v1<SiteDataPageComponent_v1>;
    relationships: { lists: WimlCollection_v1<ListItemRelationshipCollection_v1>; };
};
type ListItemRelationshipCollection_v1 = WimlCollection_v1<ListItemRelationship_v1>;
type SiteDataList_v1 = {
    ids: string[];
    items: WimlCollection_v1<SiteDataListItem_v1>;
};
type ListItemRelationship_v1 = {
    data: JSONObject;
};
// SiteDataList e.g. Books
// -- Ids - IGNORE ME
// -- Items aka List Items
// -- -- IDs - ids of the list items
// -- -- ITEMS 
// -- -- -- LI_123 -- SiteDataListItem
// -- -- -- -- Components
// -- -- -- -- -- IDS
// -- -- -- -- -- ITEMS
// -- -- -- -- Meta
type SiteDataListCollection_v1 = WimlCollection_v1<SiteDataList_v1>;
// todo migrate pages -> pages.items, same for lists
// rootNodeJson -> rootNode.jsonString


export class ThemeData_v1 {
    components: {
        ids: string[];
        items: {
            pages: ThemeDataPageComponentCollection_v1;
            lists: ThemeDataListCollection_v1;
            rootNodeJson: string;
        };
    };
    meta: {
        version: WimlVer; // todo make enum global
    };
    markup: string;
    reactComponents: {
        ids: string[];
        items: {
            pages: WimlCollection_v1<{ [page: string]: string; }>;
        };
    };

    constructor() {
        this.components = {
            ids: [],
            items: {
                pages: { ids: [], items: {} },
                lists: { ids: [], items: {} },
                rootNodeJson: ''
            }
        };
        this.meta = {
            version: 'v1.0.0'
        };
        this.markup = '';
        this.reactComponents = {
            ids: [],
            items: {
                pages: { ids: [], items: {} }
            }
        };
    }
}

export class SiteData_v1 {
    components: {
        ids: string[];
        items: {
            pages: SiteDataPageCollection_v1;
            lists: SiteDataListCollection_v1;
        };
    };

    constructor() {
        this.components = {
            ids: [],
            items: {
                pages: { ids: [], items: {} },
                lists: { ids: [], items: {} }
            }
        };
    }
}

export type WimlCollection_v1<T> = {
    ids: string[];
    items: { [item: string]: T; };
};

export type WimlVer = "1.0.0" | "1.1.0" | "1.2.0" | "1.3.0" | "1.4.0";

export interface IWimlImpl_v1 {
    createThemeDataPageComponent(pageKey: string): ThemeDataPageWithComponents_v1;

    createThemeDataPageChildComponent(pageKey: string, componentType: string, componentKey: string, themeProps: Record<string, any>, position: string, parentComponentType: string, parentComponentKey: string, content: string): ThemeDataPageChildComponent_v1

    addThemeDataPageChildComponentContent(pageKey: string, position: string, parentComponentType: string, parentComponentKey: string, content: string): void
    moveThemeDataPageChildComponent(pageId: string, componentId: string, position: string | number): ThemeDataPageChildComponent_v1
    replaceStyleImportStatement(newImport: string): string

    replaceStyleCssVars(newCssVars: string): string

    updatePageChildComponentProperty(pageId: string, componentId: string, propertyId: string, propertyExpression: string): void

    getListComponentThemeData(listId: string): ThemeDataListQualifiedAndMergedWithComponents_v1;

    getPageComponentThemeData(pageId: string): ThemeDataPageWithComponents_v1

    getPageChildComponentThemeData(pageId: string, componentId: string): ThemeDataPageChildComponent_v1

    getPageChildListInstanceComponentThemeData(pageId: string, listInstanceComponentId: string): ListInstanceComponentThemeData_v1

    getPageChildListInstanceChildComponentThemeData(pageId: string, listInstanceComponentId: string, componentId: string): ListInstanceComponentThemeData_v1;

    deleteListItem(listId: string, listItemId: string): void;

    createNewListItem(listItemId: string, listId: string): Record<string, any>;

    updateListItemComponentProperty(listId: string, listItemId: string, componentId: string, propertyId: string, propertyExpression: string): void

    updateRelationship(listId: string, listItemId: string, relationshipListId: string, relationshipListItemId: string): void
    deleteRelationship(listId: string, listItemId: string, relationshipListId: string, relationshipListItemId: string): void

    searchSiteData(query: SearchQuery_v1): { results: SearchResultCollection_v1; }
};

export interface IWiml_v1 extends IWimlImpl_v1 {
    themeData: ThemeData_v1;
    siteData: SiteData_v1;

    updateMarkup(value: string): void;
};

export type WimlImplClass_v1 = new (data: IWiml_v1) => IWimlImpl_v1;

export type SearchResultItem_v1 = {
    title: string;
    description: string;
    url: string;
    meta: {
        pageId?: string;
        listId?: string;
        listItemId?: string;
    };
};

export type SearchResultCollection_v1 = WimlCollection_v1<SearchResultItem_v1>;

export type Search_v1 = {
    query: {
        rules: Record<string, any>;
    };
};

export type SearchRuleLocation_v1 = {
    [component: string]: boolean;
};

export type SearchRuleLocationCollection_v1 = WimlCollection_v1<{
    components: WimlCollection_v1<SearchRuleLocation_v1>;
}>;

export type SearchQueryRule_v1 = {
    location: {
        components: WimlCollection_v1<SearchRuleLocationCollection_v1>;
    };
};

export type SearchQuery_v1 = {
    rules: SearchQueryRule_v1;
    searchTerm: string;
};

export interface IThemeDataToReactConverter_v1 {
    convertMarkupToComponentThemeData_v1: (wimlString: string) => ComponentThemeData_v1;
    convertComponentThemeDataToRenderThemeData_v1: (themeData: any) => RenderThemeData_v1;
}

export type ThemeDataToReactConverterClass_v1 = new () => IThemeDataToReactConverter_v1;

export interface IMarkupToReactConverter_v1 {
    convertMarkupToThemeData_v1: (wimlString: string) => ThemeData_v1;
}
