import humps from "humps";
import { RuntimeWimlComponentProps_v1 } from "../../../types";
import { _getAlignmentChildrenClassNames_v1_2_0, _getAlignmentClassNames_v1_2_0 } from "../renderer/styles/alignment";
import { _getBgColorClassNames_v1_2_0 } from "../renderer/styles/background";
import { _getFontColorClassNames_v1_2_0, _getFontSizeClassNames_v1_2_0, _getFontWeightClassNames_v1_2_0 } from "../renderer/styles/font";
import { ThemeDataPageComponent_v1_2_0 } from "./types";
import { _getMaxHeightClassNames_v1_2_0, _getMaxWidthClassNames_v1_2_0 } from "../renderer/styles/dimensions";
import { _getWrapClassNames_v1_2_0 } from "../renderer/styles/wrap";

export function getComponentClassName_v1_2_0(props: RuntimeWimlComponentProps_v1, ...additionalClasses: string[]) {
    const alignmentClassName = _getAlignmentClassNames_v1_2_0(props);
    const alignmentChildrenClassName = _getAlignmentChildrenClassNames_v1_2_0(props);

    const bgColorClassName = _getBgColorClassNames_v1_2_0(props);

    const fontSizeClassName = _getFontSizeClassNames_v1_2_0(props);
    const fontWeightClassName = _getFontWeightClassNames_v1_2_0(props);
    const fontColorClassName = _getFontColorClassNames_v1_2_0(props);

    const maxWidthClassName = _getMaxWidthClassNames_v1_2_0(props);
    const maxHeightClassName = _getMaxHeightClassNames_v1_2_0(props);

    const wrapClassName = _getWrapClassNames_v1_2_0(props);

    let topLevelListComponentKey = null;
    if (props.topLevelListComponentKey) {
        const htmlPreparedTopLevelComponentKey = props.topLevelListComponentKey.replace(/\./g, "__");
        const listComponentKeyQualifiedListItemId = `list_${htmlPreparedTopLevelComponentKey}`;
        topLevelListComponentKey = listComponentKeyQualifiedListItemId;
    }
    const classNames = [
        props.className,
        ...additionalClasses,

        // todo move to other collection like getPreviewClassNames
        'position-relative', // needed for debugging-box

        alignmentClassName,
        alignmentChildrenClassName,
        bgColorClassName,
        fontColorClassName,
        fontSizeClassName,
        fontWeightClassName,
        maxWidthClassName,
        maxHeightClassName,
        wrapClassName,

        topLevelListComponentKey,
    ];

    const className = classNames.filter(Boolean).join(' ');

    return className;
}

export function _getThemeDataComponentId_v1_2_0(c: ThemeDataPageComponent_v1_2_0, useParent: boolean = false) {
    let retVal: string;
    const typeSeparator = '__';
    const parentSeparator = '.';
    const pageType = 'Page';
    const listType = 'List';
    const key = formatNodeKey_v1_2_0(c.key);
    const type = formatNodeType_v1_2_0(c.type);

    if (key) {
        // retVal = lastPart;
        retVal = `${type}${typeSeparator}${key}`;
    } else {
        // todo v1.2 no, page requires key
        if (c.type === pageType) {
            const defaultKey = 'home';
            retVal = `${type}${typeSeparator}${defaultKey}`;
        } else if (c.type === listType) {
            throw new Error('Lists require key');
        } else {
            retVal = type;
        }
    }

    if (useParent && c.parentListInstanceComponentId) {
        retVal = `${c.parentListInstanceComponentId}${parentSeparator}${retVal}`;
    }

    return retVal;
}

// todo v1.2 rename formatNodeAttr
export function formatNodeKey_v1_2_0(key: string) {
    return key && key.replace(/-/g, '_');
}

export function formatNodeType_v1_2_0(key: string) {
    return humps.decamelize(key);
}
