import React from 'react';

import { getInferredComponentDefaultType_v1_0_0 } from '../../compiler/components';
import { renderChildComponentSiteDataPropertyValue_v1_0_0 } from "../render-components";
import { RuntimeWimlComponentProps_v1 } from "app/wiml/versions/v1/types";




export function Image_v1_0_0(props: RuntimeWimlComponentProps_v1) {
    const defaultClassName = '';
    let retVal = null;

    // rouded-circle is bootstrap
    const classNames = [defaultClassName, props.className, props.circle != null && 'circle rounded-circle', props.square != null && 'square'];
    const className = classNames.filter(Boolean).join(' ');

    const url = renderChildComponentSiteDataPropertyValue_v1_0_0(props, 'url', getInferredComponentDefaultType_v1_0_0('Image'));

    if (url) {
        const alt = renderChildComponentSiteDataPropertyValue_v1_0_0(props, 'alt', getInferredComponentDefaultType_v1_0_0('Image'));

        retVal = <img className={className} src={url} alt={alt} />;
    }
    return retVal;
}
Image_v1_0_0.inputProps = {
    url: {
        type: 'image',
        label: 'Image URL',
        description: 'The url of the image',
    },
    alt: {
        type: 'short_text',
        label: 'Alternate Text',
        description: 'The alternate text for the image',
    },
};
