import React from "react";
import { RuntimeWimlComponentProps_v1 } from "../../../../types";
import { renderChildren_v1_0_0 } from "../../../../v1.0.0/theme-data/renderer/render-components";
import { getComponentClassName_v1_2_0 } from "../../../../v1.2.0/theme-data/compiler/components";

function Legal(props: RuntimeWimlComponentProps_v1) {
  const name = props.extra.websiteData.onboard.name || `${props.extra.websiteData.about.firstName} ${props.extra.websiteData.about.lastName}`;
  return <div className="legal">
    <div className="colophon">
      <div className="copyright">
        © {name}. All Rights Reserved.
      </div>
      <div className="design-credits mt-1">
        Made with ❤ by{' '}
        <a href="//www.wildinkpages.com/" target="_blank">
          Wild Ink Pages
        </a>
        .
      </div>
    </div>
    <style jsx>{`
        .legal {
          z-index: 1;
          position: relative;
          text-align: center;
          padding: 0;
          user-select: none;
        }

        .colophon {
          position: relative;
          z-index: 2;
        }

        .design-credits {
          font-size: 0.8rem;
        }

        .design-credits a {
          color: inherit;
        }
      `}</style>
  </div>;
}



export function Footer_v1_3_0(props: RuntimeWimlComponentProps_v1) {
  let footer;

  const className = getComponentClassName_v1_2_0(props, 'footer');
  const renderedChildren = renderChildren_v1_0_0(props.children);

  footer = (
    <footer className={className}>
      <div>{renderedChildren}</div>
      <Legal {...props} />
      <style jsx>{`
        footer {
          background-color: var(--global-footer-bg-color);
          color: var(--global-footer-text-color);
        }
        footer a {
          color: var(--global-footer-link-color);
        }
      `}</style>
    </footer>
  );

  return footer;
}
