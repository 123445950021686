import { useForm } from "react-hook-form";
import { Form, FormGroup, Label, Input } from 'reactstrap';
import { useDispatch } from "react-redux";
import { ReactstrapInputBlurChangeDetector } from '../../form/input';
import { saveFile, updateNavigationGroupItemAbout, updateNavigationGroupItemAssets, updateNavigationGroupItemMeta, updateNavigationGroupItemPage } from '@wip/common/event-store/website';
import { getAllPagesForUser } from "@wip/common/app/page";

const NavigationGroupItemDetail = (props) => {
    const dispatch = useDispatch();

    const abc = { ...props.navigationGroupItem };
    const { register, formState: { errors } } = useForm({
        mode: 'onBlur',
        defaultValues: abc
    });

    // const { ref: pageIdRef, ...pageIdRest } = register('page.id', { required: true });
    const { ref: pageIdRef, onChange: pageIdChange, ...pageIdRest } = register('page.id');

    const { ref: orderRef, ...orderRest } = register('meta.order', { required: true });

    // todo - this is duplicated throughout the app -- combine.
    const handleChangeEvent = (event, id, key, value) => {

        // for some reason isValid is always true even when not…
        if (errors && Object.keys(errors).length) {
            console.log('Skipping due to errors:', errors);
        } else {
            if (event) {
                id = event.target.dataset.id;
                key = event.target.name;

                if (event.target.type == 'checkbox') {
                    value = event.target.checked;

                } else {
                    value = event.target.value;
                }
            }

            if (!id) throw new Error('Handle change event `id` is missing.')
            if (!key) throw new Error('Handle change event `key` is missing.')
            if (value == undefined) throw new Error('Handle change event `value` must be set to a value or null.')

            const splitKeys = key.split('.');
            const path = splitKeys[0];
            const newKey = splitKeys[1];

            if (path == 'about') {
                dispatch(updateNavigationGroupItemAbout({ navigationGroupId: props.navigationGroup.id, id, key: newKey, value }));
            } else if (path == 'meta') {
                dispatch(updateNavigationGroupItemMeta({ navigationGroupId: props.navigationGroup.id, id, key: newKey, value }));
            } else if (path == 'page') {
                dispatch(updateNavigationGroupItemPage({ navigationGroupId: props.navigationGroup.id, id, key: newKey, value }));
            } else if (path == 'awards') {
                dispatch(updateBookAwards({ id, key: newKey, value }));
            } else if (path == 'schedule') {
                dispatch(updateBookSchedule({ id, key: newKey, value }));
            } else if (path == 'products') {
                if (value) {
                    dispatch(addProductToBook({ id, productId: newKey }));
                } else {
                    dispatch(removeProductFromBook({ id, productId: newKey }));
                }
            }
        }
    };

    const onCoverPhotoChange = e => {
        const id = e.target.dataset.id;
        const coverPhotoFile = e.target.files[0];
        // dispatch async thunks are promises
        // https://redux-toolkit.js.org/api/createAsyncThunk#unwrapping-result-actions
        dispatch(saveFile(coverPhotoFile)).then(action => {
            const downloadUrl = action.payload;

            dispatch(updateNavigationGroupItemAssets({ navigationGroupId: props.navigationGroup.id, id, key: 'navigationGroupItem', value: downloadUrl }));
            e.target.value = "";
        });
    };

    // todo - this is duplicated throughout the app -- combine.
    const handleRichEditorChangeEvent = (id, key, value) => {
        // for some reason isValid is always true even when not…
        if (errors && Object.keys(errors).length) {
            console.log('Skipping due to errors:', errors);
        } else {
            if (!id) throw new Error('Handle change event `id` is missing.')
            if (!key) throw new Error('Handle change event `key` is missing.')
            if (value == undefined) throw new Error('Handle change event `value` must be set to a value or null.')

            const splitKeys = key.split('.');
            const path = splitKeys[0];
            const newKey = splitKeys[1];

            if (path == 'about') {
                dispatch(updateBookAbout({ id, key: newKey, value }));
            } else if (path == 'reviews') {
                dispatch(updateBookReviews({ id, key: newKey, value }));
            }
        }
    };

    // https://github.com/scottccoates/author-site-builder/blob/97d1f076526c424746c51c7f21a9858a5d57e2c8/apps/admin/src/components/manage/style.js#L19
    const handlePageIdChangeEvent = async (e) => {
        pageIdChange && await pageIdChange(e);
        handleChangeEvent(e);
    };

    const pages = getAllPagesForUser(props.websiteData.customPages);

    const pageIdOptions = pages.map(p =>
        (<option key={p.key} value={p.key}>{p.value}</option>)
    );

    const navigationGroupItemDetail = (
        <Form>
            {/* <FormGroup>
                <Label for="page.id">Title</Label>
                <ReactstrapInputBlurChangeDetector id="page.id" invalid={!!errors.page?.id} type="text" data-id={props.navigationGroupItem.id} {...pageIdRest} innerRef={pageIdRef} onDiff={handleChangeEvent} />
            </FormGroup> */}
            <FormGroup>
                <Label for="page.id">Page</Label>
                <Input type="select" innerRef={pageIdRef} data-id={props.navigationGroupItem.id} onChange={handlePageIdChangeEvent} {...pageIdRest}>
                    {pageIdOptions}
                </Input>
            </FormGroup>
            <FormGroup>
                <Label for="meta.order">Item Order</Label>
                <ReactstrapInputBlurChangeDetector id="meta.order" type="number" data-id={props.navigationGroupItem.id} {...orderRest} innerRef={orderRef} onDiff={handleChangeEvent} />
            </FormGroup>
            {/* <FormGroup>
                <Label for="assets.navigationGroupItem">NavigationGroup Item</Label>
                <Input id="assets.navigationGroupItem" type="file" accept="image/*" data-id={props.navigationGroupItem.id} onChange={onCoverPhotoChange} />
                <img src={props.navigationGroupItem.assets.navigationGroupItem} className="img-fluid shadow mt-4" />
            </FormGroup> */}
            {/* <FormGroup>
                <Label for="about.subheading">Sub Heading</Label>
                <ReactstrapInputBlurChangeDetector id="about.subheading" type="text" data-id={props.book.id} {...subheadRest} innerRef={subheadRef} onDiff={handleChangeEvent} />
            </FormGroup>
            <FormGroup>
                <Label for="schedule.publishDate">Publish Date</Label>
                <ReactstrapInputBlurChangeDetector id="schedule.publishDate" type="date" data-id={props.book.id} {...publishDateRest} innerRef={publishDateRef} onChange={handlePublishDateChangeEvent} />
            </FormGroup>
            <FormGroup>
                <Label for="purchase.amazonLink">Amazon Link</Label>
                <ReactstrapInputBlurChangeDetector id="purchase.amazonLink" type="url" data-id={props.book.id} {...amazonLinkRest} innerRef={amazonLinkRef} onDiff={handleChangeEvent} />
            </FormGroup>
            <FormGroup>
                <Label for="purchase.barnesandnobleLink">Barnes &amp; Noble Link</Label>
                <ReactstrapInputBlurChangeDetector id="purchase.barnesandnobleLink" type="url" data-id={props.book.id} {...barnesandnobleLinkRest} innerRef={barnesandnobleLinkRef} onDiff={handleChangeEvent} />
            </FormGroup>
            <FormGroup>
                <Label for="purchase.bookshopLink">Bookshop.org Link</Label>
                <ReactstrapInputBlurChangeDetector id="purchase.bookshopLink" type="url" data-id={props.book.id} {...bookshopLinkRest} innerRef={bookshopLinkRef} onDiff={handleChangeEvent} />
            </FormGroup>
            <FormGroup>
                <Label for="purchase.indieboundLink">IndieBound Link</Label>
                <ReactstrapInputBlurChangeDetector id="purchase.indieboundLink" type="url" data-id={props.book.id} {...indieboundLinkRest} innerRef={indieboundLinkRef} onDiff={handleChangeEvent} />
            </FormGroup>
            <FormGroup>
                <Label for="purchase.custombookstore1Name">Custom Book Store #1: Name</Label>
                <ReactstrapInputBlurChangeDetector id="purchase.custombookstore1Name" type="url" data-id={props.book.id} {...custombookstore1NameRest} innerRef={custombookstore1NameRef} onDiff={handleChangeEvent} />
            </FormGroup>
            <FormGroup>
                <Label for="purchase.custombookstore1Link">Custom Book Store #1: Link</Label>
                <ReactstrapInputBlurChangeDetector id="purchase.custombookstore1Link" type="url" data-id={props.book.id} {...custombookstore1LinkRest} innerRef={custombookstore1LinkRef} onDiff={handleChangeEvent} />
            </FormGroup>
            <FormGroup>
                <Label for="purchase.custombookstore2Name">Custom Book Store #2: Name</Label>
                <ReactstrapInputBlurChangeDetector id="purchase.custombookstore2Name" type="url" data-id={props.book.id} {...custombookstore2NameRest} innerRef={custombookstore2NameRef} onDiff={handleChangeEvent} />
            </FormGroup>
            <FormGroup>
                <Label for="purchase.custombookstore2Link">Custom Book Store #2: Link</Label>
                <ReactstrapInputBlurChangeDetector id="purchase.custombookstore2Link" type="url" data-id={props.book.id} {...custombookstore2LinkRest} innerRef={custombookstore2LinkRef} onDiff={handleChangeEvent} />
            </FormGroup>
            <FormGroup>
                <Label for="purchase.custombookstore3Name">Custom Book Store #3: Name</Label>
                <ReactstrapInputBlurChangeDetector id="purchase.custombookstore3Name" type="url" data-id={props.book.id} {...custombookstore3NameRest} innerRef={custombookstore3NameRef} onDiff={handleChangeEvent} />
            </FormGroup>
            <FormGroup>
                <Label for="purchase.custombookstore3Link">Custom Book Store #3: Link</Label>
                <ReactstrapInputBlurChangeDetector id="purchase.custombookstore3Link" type="url" data-id={props.book.id} {...custombookstore3LinkRest} innerRef={custombookstore3LinkRef} onDiff={handleChangeEvent} />
            </FormGroup>
            <FormGroup>
                <Label for="awards.content">Book Awards / Recognition</Label>
                <ReactstrapInputBlurChangeDetector id="awards.content" type="text" data-id={props.book.id} {...awardsRest} innerRef={awardsRef} onDiff={handleChangeEvent} />
            </FormGroup>
            <FormGroup>
                <Label for="assets.coverPhoto">Cover Photo</Label>
                <Input id="assets.coverPhoto" type="file" accept="image/*" data-id={props.book.id} onChange={onCoverPhotoChange} />
                <img src={props.book.assets.coverPhoto} className="img-fluid shadow mt-4" />
            </FormGroup>
            <FormGroup>
                <Label for="about.description">Book Description</Label>
                <QuillBlurChangeDetector theme="snow" modules={modules} defaultValue={props.book.about.description} onDiff={handleRichEditorChangeEvent.bind(null, props.book.id, 'about.description')} />
            </FormGroup>
            <FormGroup>
                <Label for="reviews.content">Book Reviews</Label>
                <QuillBlurChangeDetector theme="snow" modules={modules} defaultValue={props.book.reviews.content} onDiff={handleRichEditorChangeEvent.bind(null, props.book.id, 'reviews.content')} />
            </FormGroup>
            <FormGroup>
                <Label for="about.moreInfo">Additional Information</Label>
                <QuillBlurChangeDetector theme="snow" modules={modules} defaultValue={props.book.about.moreInfo} onDiff={handleRichEditorChangeEvent.bind(null, props.book.id, 'about.moreInfo')} />
            </FormGroup>
            {
                props.websiteData.ecommerce.accountActive &&
                <FormGroup>
                    <Label for="exampleCheckbox">Products to Display on Page</Label>
                    <div>
                        {productOptions}
                    </div>
                </FormGroup>
            } */}
        </Form>
    );


    return navigationGroupItemDetail;
}

export default NavigationGroupItemDetail;
