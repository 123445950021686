// todo v1.4 refer to connie on how dark(a yellow #f7e21c) is not dark. instead change the variables
// color-text -> black color
// color-primary -> light blue
// color-secondary -> darker blue
// color-accent -> red
// again refer to https://app.wildinkpages.com/manage/connie
export const cssGlobalVariables_v1_4_0 = `
/* Colors */
--global-color-text: #07031A; /* text */
--global-color-primary: #fff; /* bg */
--global-color-secondary: #f7e21c; /* heading */
--global-color-tertiary: ''; /* optionally used, e.g. in sub section of heading */
--global-color-accent: #f00; /* accent */

/* BG */
--global-bg-color: var(--global-color-primary);

/* Header */
--global-header-bg-color: var(--global-color-primary);
--global-header-text-color: var(--global-color-secondary);
--global-header-link-color: var(--global-color-secondary);

/* Footer */
--global-footer-bg-color: var(--global-color-primary);
--global-footer-text-color: var(--global-color-secondary);
--global-footer-link-color: var(--global-color-secondary);

/* Text */
--global-text-color: var(--global-color-text);
--global-text-accent-color: var(--global-color-accent);

--global-text-body-font: "Open Sans", sans-serif;
--global-text-body-color: var(--global-text-color);
--global-text-body-accent-color: var(--global-text-accent-color);

--global-text-heading-font: "Playfair Display", serif;
--global-text-heading-color: var(--global-text-color);
--global-text-heading-accent-color: var(--global-text-accent-color);

--global-text-sub-heading-font: var(--global-text-heading-font);
--global-text-sub-heading-color: var(--global-text-color);
--global-text-sub-heading-accent-color: var(--global-text-accent-color);

/* Buttons */
--global-button-bg-color: var(--global-color-accent);
--global-button-text-color: var(--global-bg-color); /* background (white) -> button (black) -> button text (white)*/

/* Icons */
--global-icon-color: var(--global-color-secondary);

/* Spacing */
--global-hr-line-color: var(--global-color-secondary);
`;

export const cssHeaderFooterStyle_v1_4_0 = `header .heading, header .sub-heading {
  color: var(--global-header-text-color);
}

footer .heading, footer .sub-heading {
  color: var(--global-footer-text-color);
}`;
