import { RuntimeWimlComponentCollection_v1_0_0 } from "../../../v1.0.0/theme-data/renderer/types";
import { wimlComponents_v1_3_0 } from "../../../v1.3.0/theme-data/renderer/wiml-components-v1.3.0";
import { SearchInput_v1_4_0 } from "./components/search-input";
import { SearchResults_v1_4_0 } from "./components/search-results";

export const wimlComponents_v1_4_0: RuntimeWimlComponentCollection_v1_0_0 = {
    ...wimlComponents_v1_3_0,
    'SearchInput': SearchInput_v1_4_0,
    'SearchResults': SearchResults_v1_4_0,
};
