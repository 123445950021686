import { useForm } from "react-hook-form";
import { CustomInput } from 'reactstrap';
import { Form, FormGroup, Label, Input } from 'reactstrap';
import { useDispatch } from "react-redux";
import { updateLandingPageAbout, updateLandingPageAssets, updateLandingPageMeta, updateLandingPageSchedule, updateLandingPageNavigation, updateLandingPageForm, removeProductFromLandingPage, addProductToLandingPage, removeGalleryFromLandingPage, addGalleryToLandingPage } from '@wip/common/event-store/website';
import { ReactstrapInputBlurChangeDetector, RichTextEditor } from '../../form/input';
import * as dateUtils from '@wip/common/lib/date-utils';
import { saveFile } from '@wip/common/event-store/website';
import { useMemo } from "react";

const LandingPageDetail = (props) => {
    const dispatch = useDispatch();

    function uploadImage(file) {
        const workPromise = dispatch(saveFile(file)).then(action => action.payload);

        return workPromise;
    }

    const richTextConfig = useMemo(() => {
        const retVal = { onImageUpload: uploadImage };

        return retVal;
    }, [dispatch]);

    const publishDate = props.landingPage.schedule.publishDate;
    const publishDateFormat = publishDate ? dateUtils.dateYYYYMMDD(new Date(publishDate)) : null;
    const schedule = { ...props.landingPage.schedule, publishDate: publishDateFormat };
    const abc = { ...props.landingPage, schedule };
    const { register, formState: { errors } } = useForm({
        mode: 'onBlur',
        defaultValues: abc
    });

    const { ref: titleRef, ...titleRest } = register('about.title', { required: true });
    const { ref: coverVideoRef, ...coverVideoRest } = register('assets.coverVideo', { required: true });
    const { ref: pageOrderRef, ...pageOrderRest } = register('meta.order');

    // todo - this is duplicated throughout the app -- combine.
    const handleChangeEvent = (event, id, key, value) => {

        // for some reason isValid is always true even when not…
        if (errors && Object.keys(errors).length) {
            console.log('Skipping due to errors:', errors);
        } else {
            if (event) {
                id = event.target.dataset.id;
                key = event.target.name;

                // todo remove this - put in handleCheckChange
                if (event.target.type == 'checkbox') {
                    value = event.target.checked;

                } else {
                    value = event.target.value;
                }
            }

            if (!id) throw new Error('Handle change event `id` is missing.')
            if (!key) throw new Error('Handle change event `key` is missing.')
            if (value == undefined) throw new Error('Handle change event `value` must be set to a value or null.')

            const splitKeys = key.split('.');
            const path = splitKeys[0];
            const newKey = splitKeys[1];

            if (path == 'about') {
                dispatch(updateLandingPageAbout({ id, key: newKey, value }));
            } else if (path == 'meta') {
                dispatch(updateLandingPageMeta({ id, key: newKey, value }));
            } else if (path == 'schedule') {
                dispatch(updateLandingPageSchedule({ id, key: newKey, value }));
            } else if (path == 'navigation') {
                dispatch(updateLandingPageNavigation({ id, key: newKey, value }));
            } else if (path == 'form') {
                dispatch(updateLandingPageForm({ id, key: newKey, value }));
            } else if (path == 'assets') {
                dispatch(updateLandingPageAssets({ id, key: newKey, value }));
            }
            // TODO MOVE THIS DOWN TO HANDLE CHECK CHANGE EVENT HANDLER
            else if (path == 'products') {
                if (value) {
                    dispatch(addProductToLandingPage({ id, productId: newKey }));
                } else {
                    dispatch(removeProductFromLandingPage({ id, productId: newKey }));
                }
            }
        }
    };


    const handleCheckChangeEvent = (event, id, key, value) => {
        // for some reason isValid is always true even when not…
        if (errors && Object.keys(errors).length) {
            console.log('Skipping due to errors:', errors);
        } else {

            if (event) {
                id = event.target.id;
                key = event.target.name;

                value = event.target.checked;
            }

            if (!id) throw new Error('Handle change event `id` is missing.')
            if (!key) throw new Error('Handle change event `key` is missing.')
            if (value == undefined) throw new Error('Handle change event `value` must be set to a value or null.')

            const splitKeys = key.split('.');
            const path = splitKeys[0];
            const newKey = splitKeys[1];

            if (path == 'products') {
                if (value) {
                    dispatch(addProductToLandingPage({ id: props.landingPage.id, productId: newKey }));
                } else {
                    dispatch(removeProductFromLandingPage({ id: props.landingPage.id, productId: newKey }));
                }
            } else if (path == 'galleries') {
                if (value) {
                    dispatch(addGalleryToLandingPage({ id: props.landingPage.id, galleryId: newKey }));
                } else {
                    dispatch(removeGalleryFromLandingPage({ id: props.landingPage.id, galleryId: newKey }));
                }
            }
        }
    };

    const handlePublishDateChangeEvent = async (e) => {
        publishDateRest.onChange && await publishDateRest.onChange(e);
        handleChangeEvent(e);
    };

    const onCoverPhotoChange = e => {
        const id = e.target.dataset.id;
        const coverPhotoFile = e.target.files[0];
        // dispatch async thunks are promises
        // https://redux-toolkit.js.org/api/createAsyncThunk#unwrapping-result-actions
        dispatch(saveFile(coverPhotoFile)).then(action => {
            const downloadUrl = action.payload;

            dispatch(updateLandingPageAssets({ id, key: 'coverPhoto', value: downloadUrl }));
            e.target.value = "";
        });
    };

    // todo - this is duplicated throughout the app -- combine.
    const handleRichEditorChangeEvent = (id, key, value) => {

        // for some reason isValid is always true even when not…
        if (errors && Object.keys(errors).length) {
            console.log('Skipping due to errors:', errors);
        } else {
            if (!id) throw new Error('Handle change event `id` is missing.')
            if (!key) throw new Error('Handle change event `key` is missing.')
            if (value == undefined) throw new Error('Handle change event `value` must be set to a value or null.')

            const splitKeys = key.split('.');
            const path = splitKeys[0];
            const newKey = splitKeys[1];

            if (path == 'about') {
                dispatch(updateLandingPageAbout({ id, key: newKey, value }));
            }
        }
    };

    const galleryOptions = props.websiteData.galleries.map(g => {
        const galleryIsChecked = !!props.landingPage.galleries.find(lpGallery => lpGallery == g.id);
        // .bind(this, null, props.landingPage.id, `galleries.${g.id}`, galleryIsChecked)
        return (
            <CustomInput defaultChecked={galleryIsChecked} type="checkbox" key={g.id} id={g.id} label={g.about.title} name={`galleries.${g.id}`} onChange={handleCheckChangeEvent} />
        );
    });

    const productOptions = props.websiteData.products.map(p =>
        (<CustomInput defaultChecked={props.landingPage.products.find(lpProduct => lpProduct == p.id)} type="checkbox" key={p.id} id={p.id} name={`products.${p.id}`} label={p.about.title} onChange={handleCheckChangeEvent} />)
    );

    const test = getit();

    const bookViewElelement = (
        <Form>
            <FormGroup check>
                <Label check>
                    <Input type="checkbox" defaultChecked={props.landingPage.schedule.draft} name="schedule.draft" data-id={props.landingPage.id} onChange={handleChangeEvent} /> {' '}
                    Draft?
                </Label>
            </FormGroup>
            <FormGroup check>
                <Label check>
                    <Input type="checkbox" defaultChecked={props.landingPage.navigation.displayInNav} name="navigation.displayInNav" data-id={props.landingPage.id} onChange={handleChangeEvent} /> {' '}
                    Display in Nav Menu?
                </Label>
            </FormGroup>
            <FormGroup check>
                <Label check>
                    <Input type="checkbox" defaultChecked={props.landingPage.form.onTop} name="form.onTop" data-id={props.landingPage.id} onChange={handleChangeEvent} /> {' '}
                    Display Buttons on Top? (buttons are always on bottom)
                </Label>
            </FormGroup>
            <FormGroup>
                <Label for="about.title">Page Name</Label>
                <ReactstrapInputBlurChangeDetector id="about.title" type="text" data-id={props.landingPage.id} {...titleRest} innerRef={titleRef} onDiff={handleChangeEvent} />
            </FormGroup>
            <FormGroup>
                <Label for="about.intro">Page Intro</Label>
                <RichTextEditor defaultValue={props.landingPage.about.intro} onDiff={handleRichEditorChangeEvent.bind(null, props.landingPage.id, 'about.intro')} {...richTextConfig} />
            </FormGroup>
            <FormGroup>
                <Label for="meta.order">Page Order</Label>
                <ReactstrapInputBlurChangeDetector id="meta.order" type="number" data-id={props.landingPage.id} {...pageOrderRest} innerRef={pageOrderRef} onDiff={handleChangeEvent} />
            </FormGroup>
            <FormGroup>
                <Label for="assets.coverPhoto">Cover Photo</Label>
                <Input id="assets.coverPhoto" type="file" accept="image/*" data-id={props.landingPage.id} onChange={onCoverPhotoChange} />
                <img src={props.landingPage.assets.coverPhoto} className="img-fluid shadow mt-4" />
            </FormGroup>
            <FormGroup>
                <Label for="about.content">Page Content</Label>
                <RichTextEditor defaultValue={props.landingPage.about.content} onDiff={handleRichEditorChangeEvent.bind(null, props.landingPage.id, 'about.content')} {...richTextConfig} />
            </FormGroup>
            <FormGroup>
                <Label for="about.bio">Bio</Label>
                <RichTextEditor defaultValue={props.landingPage.about.bio} onDiff={handleRichEditorChangeEvent.bind(null, props.landingPage.id, 'about.bio')} {...richTextConfig} />
            </FormGroup>
            <FormGroup>
                <Label for="assets.coverVideo">Video Embed Code</Label>
                <ReactstrapInputBlurChangeDetector id="assets.coverVideo" type="textarea" data-id={props.landingPage.id} {...coverVideoRest} innerRef={coverVideoRef} onDiff={handleChangeEvent} />
            </FormGroup>
            {/* {
                props.websiteData.galleries.length &&
                <FormGroup>
                    <Label for="exampleCheckbox">Galleries to Display on Page</Label>
                    <div>
                        {galleryOptions}
                    </div>
                </FormGroup>
            } */}
            {
                props.websiteData.ecommerce.accountActive &&
                <FormGroup>
                    <Label for="exampleCheckbox">Products to Display on Page</Label>
                    <div>
                        {productOptions}
                    </div>
                </FormGroup>
            }
            <FormGroup>
                <Label for="about.terms">Small Print</Label>
                <RichTextEditor defaultValue={props.landingPage.about.terms} onDiff={handleRichEditorChangeEvent.bind(null, props.landingPage.id, 'about.terms')} {...richTextConfig} />
            </FormGroup>
        </Form>);

    return bookViewElelement;
}

export default LandingPageDetail;


function getit() {
    const v1 = `<h2>Conference Speakers:</h2>
    <p><strong>[ Speaker #1 Name &amp; Bio ]</strong> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
        ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit
        esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
        officia deserunt mollit anim id est laborum.</p>
    <p><strong>[ Speaker #2 Name &amp; Bio ] </strong>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
        ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit
        esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
        officia deserunt mollit anim id est laborum.</p>
    <p><strong>[ Speaker #3 Name &amp; Bio ] </strong>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
        eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
        ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit
        esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui
        officia deserunt mollit anim id est laborum.</p>
    <h2><img
            src="https://firebasestorage.googleapis.com/v0/b/wild-ink-pages-prod.appspot.com/o/user-content%2F-MbO0HAtkIcYUIqusBSd%2FAuthor%20Photo%20200x200%20(1)_1628866700044.png?alt=media&amp;token=4d1fd361-3c68-42a6-94d0-5221d244c3aa">Hosted
        by: Dori Durbin</h2>
    <p><span style="background-color: initial;">Dori Durbin is a children's book author, illustrator, and former teacher
            with a Masters in Social Work with an emphasis on Community Services.</span></p>`;

    const v2 = `<h2>Conference Speakers:</h2>
            <p><strong>Dr. Perry Bell, Psychologist. </strong>Dr. Perry Bell specializes in the
                        treatment of children, adolescents and families. His focus includes depression, anxiety, parenting strategies,
                        behavioral challenges in children, stress, life transitions, interpersonal relationships, obsessive- compulsive
                        behavior, ADHD, developmental and social challenges related to the autism spectrum, learning challenges and time
                        management.&nbsp;</p>
            <p>Dr. Bell received his doctorate in Psychology from the Rutgers Graduate School of Applied
                        and Professional Psychology. He also holds a masters in School Psychology and a New Jersey Certification as a School
                        Psychologist from the same institution. Dr. Bell completed his undergraduate degree at Brandeis University. Prior to
                        joining CCFD, Dr. Bell worked for public and private educational and clinical institutions.</p>
            <p>Dr. Bell
                        practices from a family-systems, cognitive-behavioral orientation. This means that his focus is on the teaching and
                        acquisition of skills to manage the emotional, cognitive, behavioral and interpersonal demands of life.
                        Additionally, Dr. Bell takes into account the family as the context in which children live and the important role of
                        parents in the lives of children.</p>
            <p>Dr. Bell’s professional interest is in the field of social- emotional
                        learning (SEL) and character development or the avenues, strategies and methods by which children, parents and
                        teachers can learn the skills of emotionally intelligent and morally intelligent living.</p>
            <p>His writing,
                        curricula and research has been published in the Journal of Education and Urban Policy, the Australian Handbook of
                        School Psychology, the American Educational Research Organization as well as local newspaper outlets, state and
                        professional organizations.</p>
            <p>Outside of clinical work, Dr. Bell develops programs, trainings, consultations,
                        talks and curricula on SEL, character development and stress management for private and public schools, athletic
                        teams, parent organizations and teacher organizations.pariatur. Excepteur sint occaecat cupidatat non proident, sunt
                        in culpa qui officia deserunt mollit anim id est laborum.</p>
            <p><strong>[ Speaker #2 Name &amp; Bio ]
                        </strong>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                        dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
                        commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                        pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est
                        laborum.</p>
            <p><strong>[ Speaker #3 Name &amp; Bio ] </strong>Lorem ipsum dolor sit amet, consectetur adipiscing
                        elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
                        voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                        culpa qui officia deserunt mollit anim id est laborum.</p>
            <h2><img
                            src="https://firebasestorage.googleapis.com/v0/b/wild-ink-pages-prod.appspot.com/o/user-content%2F-MbO0HAtkIcYUIqusBSd%2FAuthor%20Photo%20200x200%20(1)_1628866700044.png?alt=media&amp;token=4d1fd361-3c68-42a6-94d0-5221d244c3aa" />Hosted
                        by: Dori Durbin</h2>
            <p>Dori Durbin is a children's book author, illustrator, and former teacher with a Masters in
                        Social Work with an emphasis on Community Services.</p>`;


    const v3 = `<h2>Conference Speakers:</h2>
            <p><strong>Dr. Perry Bell, Psychologist. </strong>Dr. Perry Bell specializes in the
                treatment of children, adolescents and families. His focus includes depression, anxiety, parenting strategies,
                behavioral challenges in children, stress, life transitions, interpersonal relationships, obsessive- compulsive
                behavior, ADHD, developmental and social challenges related to the autism spectrum, learning challenges and time
                management.&nbsp;</p>
            <p>Dr. Bell received his doctorate in Psychology from the Rutgers Graduate School of Applied
                and Professional Psychology. He also holds a masters in School Psychology and a New Jersey Certification as a School
                Psychologist from the same institution. Dr. Bell completed his undergraduate degree at Brandeis University. Prior to
                joining CCFD, Dr. Bell worked for public and private educational and clinical institutions.</p>
            <p>Dr. Bell
                practices from a family-systems, cognitive-behavioral orientation. This means that his focus is on the teaching and
                acquisition of skills to manage the emotional, cognitive, behavioral and interpersonal demands of life.
                Additionally, Dr. Bell takes into account the family as the context in which children live and the important role of
                parents in the lives of children.</p>
            <p>Dr. Bell’s professional interest is in the field of social- emotional
                learning (SEL) and character development or the avenues, strategies and methods by which children, parents and
                teachers can learn the skills of emotionally intelligent and morally intelligent living.</p>
            <p>His writing,
                curricula and research has been published in the Journal of Education and Urban Policy, the Australian Handbook of
                School Psychology, the American Educational Research Organization as well as local newspaper outlets, state and
                professional organizations.</p>
            <p>Outside of clinical work, Dr. Bell develops programs, trainings, consultations,
                talks and curricula on SEL, character development and stress management for private and public schools, athletic
                teams, parent organizations and teacher organizations.pariatur. Excepteur sint occaecat cupidatat non proident, sunt
                in culpa qui officia deserunt mollit anim id est laborum.</p>
            <p><strong>Kathy Slattengren, Parent Educator and
                    Coach. </strong>Kathy is the founder of Priceless Parenting. With over two decades of experience, Kathy
                specializes in helping parents build strong, loving relationships with their children. She has helped thousands of
                parents from across the United States to Australia through Priceless Parenting's online classes, presentations,
                coaching and books.&nbsp;</p>
            <p>Kathy holds a bachelor's degree in Computer Science and Psychology from The
                University of Minnesota and a master's degree in Education and Instructional Design from The University of
                Washington.</p>
            <p><strong>Dr. B. Glenn Wilkerson, President of th ARKGroup. &nbsp;</strong><br>
            </p>
            <p><br></p>
            
            <p>Conversation opened. 5 messages. All messages read.</p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p>
                <br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br>
            <a
                    href="https://mail.google.com/mail/u/0/" title="Skip to content">Skip to content</a><br>
            <a
                    href="https://mail.google.com/mail/u/0/" title="Using Gmail with screen readers">Using Gmail with screen
                    readers</a><br>
            </p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <figure><img
                    src="https://ssl.gstatic.com/ui/v1/icons/mail/rfr/logo_gmail_lockup_default_1x_r2.png" alt=""
                    height="40px" width="109px" /></figure>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <h2>Search</h2>
            <p><br></p>
            
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            
            <p><br></p>
            <p><br></p>
            <figure><img
                    src="https://lh3.googleusercontent.com/ogw/ADea4I6PQxvieR8cy1UtrzVmYkTTkLmAKRtHJMqe0TI=s32-c-mo" alt=""
                    height="32px" width="32px" /></figure>
            <p><br></p>
            <figure>&nbsp;</figure>
            <p><br></p>
            <p><br></p>
            <p>
                <br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p>Compose</p>
            
            <p><br></p>
            <p><br></p>
            <h2>Labels</h2>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            
            <p><br></p>
            <p><br></p>
            <p><a href="https://mail.google.com/mail/u/0/#inbox"
                    title="Inbox"><strong>Inbox</strong></a></p>
            <p>5,334</p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            
            <p><br></p>
            <p><a href="https://mail.google.com/mail/u/0/#starred" title="Starred">Starred</a></p>
            <p><br></p>
            
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><a href="https://mail.google.com/mail/u/0/#snoozed"
                    title="Snoozed">Snoozed</a></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><a
                    href="https://mail.google.com/mail/u/0/#sent" title="Sent">Sent</a></p>
            <p><br></p>
            <p><br></p>
            <p><br>
            </p>
            <p><br></p>
            <p><br></p>
            <p><a href="https://mail.google.com/mail/u/0/#drafts"
                    title="Drafts"><strong>Drafts</strong></a></p>
            <p>7</p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><a href="https://mail.google.com/mail/u/0/#label/%5BGmail%5D"
                    title="[Gmail]">[Gmail]</a></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            
            <p><a href="https://mail.google.com/mail/u/0/#label/%5BGmail%5DAll+Mail" title="[Gmail]All Mail">[Gmail]All
                    Mail</a></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><a
                    href="https://mail.google.com/mail/u/0/#label/Notes" title="Notes">Notes</a></p>
            <p><br></p>
            <p>More</p>
            
            <p>&nbsp;</p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p>Meet</p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><a href="https://meet.google.com/getalink?hs=202&amp;authuser=0" title="New
                    meeting">New meeting</a></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><a
                    href="https://mail.google.com/mail/u/0/" title="Join a meeting">Join a meeting</a></p>
            <p><br></p>
            <p><br>
            </p>
            <p>Hangouts</p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <figure><img
                    src="https://mail.google.com/mail/u/0/images/cleardot.gif" alt="Collapse" height="12px" width="12px" />
            </figure>
            <h2><strong>Hangouts</strong></h2>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <figure>&nbsp;
            </figure>
            <figure>&nbsp;</figure>
            <p><br></p>
            <p><br></p>
            <p>&nbsp;</p>
            <p><br></p>
            <p>&nbsp;</p>
            <p><br></p>
            
            <p>&nbsp;</p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p>
                <br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p>
                <br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p>More</p>
            <p><br></p>
            <p><br></p>
            
            <p>1 of 5</p>
            <p><br></p>
            <figure><img src="https://mail.google.com/mail/u/0/images/cleardot.gif" alt=""
                    height="20px" width="20px" /></figure>
            <p><br></p>
            <figure><img
                    src="https://mail.google.com/mail/u/0/images/cleardot.gif" alt="" height="20px" width="20px" /></figure>
            
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <figure><img
                    src="https://mail.google.com/mail/u/0/images/cleardot.gif" alt="Collapse all" height="20px"
                    width="20px" /></figure>
            <p><br></p>
            <figure><img
                    src="https://mail.google.com/mail/u/0/images/cleardot.gif" alt="Print all" height="20px" width="20px" />
            </figure>
            <p><br></p>
            <figure><img src="https://mail.google.com/mail/u/0/images/cleardot.gif" alt="In new window"
                    height="20px" width="20px" /></figure>
            <p><br></p>
            <p><br></p>
            <h2>Little Kids, Big Feelings Interview
            </h2>
            <p><br></p>
            <p>Inbox</p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br>
            </p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <figure>
                <img src="https://ssl.gstatic.com/ui/v1/icons/mail/profile_mask2.png" alt="" height="40px" width="40px" />
            </figure>
            <p><br></p>
            <p><br></p>
            <h3><strong>Dori Durbin</strong> &lt;artsybarncat@gmail.com&gt;</h3>
            <p><br></p>
            
            <figure><img src="https://mail.google.com/mail/u/0/images/cleardot.gif" alt="Attachments" height="20px"
                    width="20px" />Tue, Aug 24, 12:15 PM (1 day ago)</figure>
            <p><br></p>
            <p><br></p>
            <figure><img
                    src="https://mail.google.com/mail/u/0/images/cleardot.gif" alt="" height="20px" width="20px" /></figure>
            
            <p><br></p>
            <figure><img src="https://mail.google.com/mail/u/0/images/cleardot.gif" alt="" height="20px"
                    width="20px" /></figure>
            <p>to Glenn</p>
            <p><br></p>
            <figure><img
                    src="https://mail.google.com/mail/u/0/images/cleardot.gif" alt="" height="20px" width="20px" /></figure>
            
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p>&nbsp;</p>
            <p>Hi Glenn!</p>
            <p><br>
            </p>
            <p>Thank you so much for your
                time today! I am sending you everything&nbsp;you need (so far) here!</p>
            <p><br>
            </p>
            <p>I don't think I
                remembered&nbsp;to mention the speaker release&nbsp;form-- it’s a contract to allow me to share your interview— I
                have included two formats-- please read through it, sign it and shoot it back to me (you can send an image&nbsp;from
                a phone if that's better). If you have any questions or concerns, please let me know?</p>
            <p><br>
            </p>
            <p>Okay
                here's what I'd love to have before your interview:</p>
            <p><br>
            </p>
            <p>1. Headshot/photo of you</p>
            <p>2. Your
                biography</p>
            <p>3. Speaker Agreement</p>
            <p><br>
            </p>
            <p>Also.... is there anything you would like to promote
                or include for parents? For instance a link to your book, your PEP program... ARK? We will add to true emails you
                are a part of!&nbsp;</p>
            <p><br>
            </p>
            <p>Here is the Thursday, September 2nd, 11:15-12:00pm (your time) Interview
                ZOOM link as well!</p>
            <p><br>
            </p>
            <p><a
                    href="https://us04web.zoom.us/j/77676257450?pwd=SjRFMG91a09Ia3NnTEZCQ0grNDdVUT09"
                    title="https://us04web.zoom.us/j/&lt;wbr&gt;77676257450?pwd=&lt;wbr&gt;SjRFMG91a09Ia3NnTEZCQ0grNDdVUT&lt;wbr&gt;09">https://us04web.zoom.us/j/77676257450?pwd=SjRFMG91a09Ia3NnTEZCQ0grNDdVUT09</a>
            </p>
            <p><br>
            </p>
            <p>Thank you and please ask questions as they arise!</p>
            <p>Dori Durbin</p>
            <p><br>
            </p>
            <p>
                <br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p>
                <strong>2</strong> <strong>Attachments</strong></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p>&nbsp;
            </p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p>&nbsp;</p>
            <p><br></p>
            <p><br>
            </p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br>
            </p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <figure><img
                    src="https://ssl.gstatic.com/ui/v1/icons/mail/profile_mask2.png" alt="" height="40px" width="40px" />
            </figure>
            <p><br></p>
            <p><br></p>
            <h3><strong>Glenn Wilkerson</strong></h3>
            <p><br></p>
            <figure><img
                    src="https://mail.google.com/mail/u/0/images/cleardot.gif" alt="Attachments" height="20px"
                    width="20px" />Tue, Aug 24, 2:02 PM (1 day ago)</figure>
            <p><br></p>
            <p><br></p>
            <figure><img
                    src="https://mail.google.com/mail/u/0/images/cleardot.gif" alt="" height="20px" width="20px" /></figure>
            
            <p><br></p>
            <figure><img src="https://mail.google.com/mail/u/0/images/cleardot.gif" alt="" height="20px"
                    width="20px" /></figure>
            <p>to me</p>
            <p><br></p>
            <figure><img
                    src="https://mail.google.com/mail/u/0/images/cleardot.gif" alt="" height="20px" width="20px" /></figure>
            
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            
            <p><br></p>
            <p>Great visiting with you Dori!</p>
            <p><br>
            </p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            
            <figure><img
                    src="https://ci5.googleusercontent.com/proxy/hHTqu_npX5VevPtEmjmx-WkeI0XPKQuWk5JkjtUnuTPL9G4Jfg1uGu0_FX4SXkxJsgggW9iCzf-pPVcI-K7MdSlNYWaXDeMrQVBLzopISi6_HCNTkWkvVC6DNbWlZ99j9S5gS8YCYZt4pwT-51213faZvOkKTiX9d4x06Gv9fJ6HGfA47y0kt2C_idrmpvIL9ZfFBg9TFlN3QcP0hQ=s0-d-e1-ft#https://docs.google.com/uc?export=download&amp;id=1BjFe-df7pNqbXruK7nII9yOv4frunAEK&amp;revid=0B8v14GUbkMQkYStwSldXOGpYV0c2RWJJZHBEK2tjNnpxeEN3PQ"
                    alt="" height="" width="" /></figure>
            <figure><img src="" alt="" height="" width="" /></figure>
            
            <figure><img src="" alt="" height="" width="" /></figure>
            <figure><img src="" alt="" height=""
                    width="" /><br>
            </figure>
            <p>Dr. B. Glenn Wilkerson, President&nbsp;<br>
            </p>
            <p><br></p>
            <p>The
                ARKGroup&nbsp;</p>
            <p>Lone Star College--University Park</p>
            <p>20515 SH 249, CB-127</p>
            <p>Houston, Texas
                77070&nbsp;</p>
            <p>(Work) 713-471-8922 (Cell) 832-878-7113</p>
            <p><a href="http://www.thearkgroup.org/"
                    title="www.thearkgroup.org&lt;br&gt;">www.thearkgroup.org</a><br>
            </p>
            <p><br></p>
            <p><br></p>
            <figure>
                <img src="https://ssl.gstatic.com/ui/v1/icons/mail/images/cleardot.gif" alt="" height="11px" width="24px" />
            </figure>
            <p><br></p>
            <p><br></p>
            <p><strong>3</strong> <strong>Attachments</strong></p>
            <p><br></p>
            <p><br></p>
            
            <p><br></p>
            <p><br></p>
            <p>&nbsp;</p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            
            <p>&nbsp;</p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <figure><img
                    src="https://ssl.gstatic.com/ui/v1/icons/mail/profile_mask2.png" alt="" height="40px" width="40px" />
            </figure>
            <p><br></p>
            <p><br></p>
            <h3><strong>Glenn Wilkerson</strong></h3>
            <p><br></p>
            <figure><img
                    src="https://mail.google.com/mail/u/0/images/cleardot.gif" alt="Attachments" height="20px"
                    width="20px" />Tue, Aug 24, 2:07 PM (1 day ago)</figure>
            <p><br></p>
            <p><br></p>
            <figure><img
                    src="https://mail.google.com/mail/u/0/images/cleardot.gif" alt="" height="20px" width="20px" /></figure>
            
            <p><br></p>
            <figure><img src="https://mail.google.com/mail/u/0/images/cleardot.gif" alt="" height="20px"
                    width="20px" /></figure>
            <p>to me</p>
            <p><br></p>
            <figure><img
                    src="https://mail.google.com/mail/u/0/images/cleardot.gif" alt="" height="20px" width="20px" /></figure>
            
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            
            <p><br></p>
            <p><br></p>
            <p><br>
            </p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <figure><img
                    src="https://ci5.googleusercontent.com/proxy/hHTqu_npX5VevPtEmjmx-WkeI0XPKQuWk5JkjtUnuTPL9G4Jfg1uGu0_FX4SXkxJsgggW9iCzf-pPVcI-K7MdSlNYWaXDeMrQVBLzopISi6_HCNTkWkvVC6DNbWlZ99j9S5gS8YCYZt4pwT-51213faZvOkKTiX9d4x06Gv9fJ6HGfA47y0kt2C_idrmpvIL9ZfFBg9TFlN3QcP0hQ=s0-d-e1-ft#https://docs.google.com/uc?export=download&amp;id=1BjFe-df7pNqbXruK7nII9yOv4frunAEK&amp;revid=0B8v14GUbkMQkYStwSldXOGpYV0c2RWJJZHBEK2tjNnpxeEN3PQ"
                    alt="" height="" width="" /></figure>
            <figure><img src="" alt="" height="" width="" /></figure>
            
            <figure><img src="" alt="" height="" width="" /></figure>
            <figure><img src="" alt="" height=""
                    width="" /><br>
            </figure>
            <p>Dr. B. Glenn Wilkerson, President&nbsp;<br>
            </p>
            <p><br></p>
            <p>The
                ARKGroup&nbsp;</p>
            <p>Lone Star College--University Park</p>
            <p>20515 SH 249, CB-127</p>
            <p>Houston, Texas
                77070&nbsp;</p>
            <p>(Work) 713-471-8922 (Cell) 832-878-7113</p>
            <p><a href="http://www.thearkgroup.org/"
                    title="www.thearkgroup.org&lt;br&gt;">www.thearkgroup.org</a><br>
            </p>
            <p><br></p>
            <p><br></p>
            <figure>
                <img src="https://ssl.gstatic.com/ui/v1/icons/mail/images/cleardot.gif" alt="" height="11px" width="24px" />
            </figure>
            <p><br></p>
            <p><br></p>
            <p><strong>3</strong> <strong>Attachments</strong></p>
            <p><br></p>
            <p><br></p>
            
            <p><br></p>
            <p><br></p>
            <p>&nbsp;</p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            
            <p>&nbsp;</p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <figure><img
                    src="https://mail.google.com/mail/u/0/images/cleardot.gif" alt="" height="20px" width="20px" /></figure>
            
            <p><br></p>
            <figure><img src="https://mail.google.com/mail/u/0/images/cleardot.gif" alt="" height="20px"
                    width="20px" /></figure>
            <p>to Glenn</p>
            <p><br></p>
            <figure><img
                    src="https://mail.google.com/mail/u/0/images/cleardot.gif" alt="" height="20px" width="20px" /></figure>
            
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            
            <p><br></p>
            <p>Looks great! I think the only other thing is if there’s anything you would like to add to the email...
                ARK or PEP links, book info...?<br>
            <br>
            </p>
            <p>Sent from my iPhone</p>
            <p><br>
            </p>
            <blockquote>On Aug 24,
                2021, at 2:32 PM, Glenn Wilkerson &lt;<a href="mailto:glennwilkerson@thearkgroup.org"
                    title="glennwilkerson@thearkgroup.&lt;wbr&gt;org">glennwilkerson@thearkgroup.org</a>&gt; wrote:</blockquote>
            
            <p><br></p>
            <p><br></p>
            <figure><img src="https://ssl.gstatic.com/ui/v1/icons/mail/images/cleardot.gif" alt=""
                    height="11px" width="24px" /></figure>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p>
                <br></p>
            <figure><img src="https://ssl.gstatic.com/ui/v1/icons/mail/no_photo.png" alt="" height="40px"
                    width="40px" /></figure>
            <p><br></p>
            <p>ReplyForward</p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p>
                <br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p>
                <br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p>
                <br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p>Page</p>
            
            <p>1/</p>
            <p>1</p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br>
            </p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <h2>Page 1 of 1</h2>
            <p>DR. B.
                GLENN WILKERSON</p>
            <p>VITAE</p>
            <p>Dr. B. Glenn Wilkerson is the President and Founder of the Children’s Center
                for Self-</p>
            <p>Esteem, Inc. (dba The ARKGroup).</p>
            <p>He is nationally-recognized for his work in elevating the
                social/emotional health and</p>
            <p>creating positive self-concepts in children. Wilkerson’s research in cooperation
                with late</p>
            <p>Dr. Ron Lorimar at the University of Texas’ School of Public Health established a new</p>
            <p>
                paradigm regarding the primacy of adult/child relationships characterized by</p>
            <p>unconditional love and care.
            </p>
            <p>Glenn is the author of Trekking: Searching for Love and Self-Esteem, If Jesus Had a</p>
            <p>Child, and the
                nationally-acclaimed ARK (Adults Relating to Kids) Program that</p>
            <p>incorporates best practices in parenting and
                teaching. His research-based materials are</p>
            <p>widely shared in schools, churches, prisons, and community
                service organizations.</p>
            <p>Glenn is the recipient of the State of Texas Board of Education’s Regional “Hero for
            </p>
            <p>Children” Award and was a participant in the White House Conference on Youth, Drug</p>
            <p>Use and Violence. He
                has been the keynote speaker and workshop leader at numerous</p>
            <p>conventions and convocations, including the
                State of Texas PTA Convention, the Texas</p>
            <p>Behavior Support Conference, the Texas State Conference on Rigor,
                Relevance, and</p>
            <p>Relationships, the PTAK “Pre-School Convention,” the Mental Health Association of</p>
            <p>
                Greater Dallas’ “Adolescent Symposium,” the Texas Education Agency’s “Parent</p>
            <p>Involvement Conference,” and
                the “Texas Superintendent’s Conference.”</p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p>
                <br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p>Wilkerson--Glenn's Vitae 2021--IMPORTANT (for speaking
                engagements).doc</p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p>
                <br></p>
            <p>Open with</p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br>
            </p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><br></p>
            <p><strong>Page 1 of
                    1</strong></p>
            <h2><img
                    src="https://firebasestorage.googleapis.com/v0/b/wild-ink-pages-prod.appspot.com/o/user-content%2F-MbO0HAtkIcYUIqusBSd%2FAuthor%20Photo%20200x200%20(1)_1628866700044.png?alt=media&amp;token=4d1fd361-3c68-42a6-94d0-5221d244c3aa" />Hosted
                by: Dori Durbin</h2>
            <p>Dori Durbin is a children's book author, illustrator, and former teacher with a Masters in
                Social Work with an emphasis on Community Services.</p>`;


    const v4 = `
                <h2>Conference Speakers:</h2>
<p><strong>Dr. Perry Bell, Psychologist. </strong>Dr. Perry Bell specializes in the
    treatment of children, adolescents and families. His focus includes depression, anxiety, parenting strategies,
    behavioral challenges in children, stress, life transitions, interpersonal relationships, obsessive- compulsive
    behavior, ADHD, developmental and social challenges related to the autism spectrum, learning challenges and time
    management.&nbsp;</p>
<p>Dr. Bell received his doctorate in Psychology from the Rutgers Graduate School of Applied
    and Professional Psychology. He also holds a masters in School Psychology and a New Jersey Certification as a School
    Psychologist from the same institution. Dr. Bell completed his undergraduate degree at Brandeis University. Prior to
    joining CCFD, Dr. Bell worked for public and private educational and clinical institutions.</p>
<p>Dr. Bell
    practices from a family-systems, cognitive-behavioral orientation. This means that his focus is on the teaching and
    acquisition of skills to manage the emotional, cognitive, behavioral and interpersonal demands of life.
    Additionally, Dr. Bell takes into account the family as the context in which children live and the important role of
    parents in the lives of children.</p>
<p>Dr. Bell’s professional interest is in the field of social- emotional
    learning (SEL) and character development or the avenues, strategies and methods by which children, parents and
    teachers can learn the skills of emotionally intelligent and morally intelligent living.</p>
<p>His writing,
    curricula and research has been published in the Journal of Education and Urban Policy, the Australian Handbook of
    School Psychology, the American Educational Research Organization as well as local newspaper outlets, state and
    professional organizations.</p>
<p>Outside of clinical work, Dr. Bell develops programs, trainings, consultations,
    talks and curricula on SEL, character development and stress management for private and public schools, athletic
    teams, parent organizations and teacher organizations.pariatur. Excepteur sint occaecat cupidatat non proident, sunt
    in culpa qui officia deserunt mollit anim id est laborum.</p>
<p><strong>Kathy Slattengren, Parent Educator and
        Coach. </strong>Kathy is the founder of Priceless Parenting. With over two decades of experience, Kathy
    specializes in helping parents build strong, loving relationships with their children. She has helped thousands of
    parents from across the United States to Australia through Priceless Parenting's online classes, presentations,
    coaching and books.&nbsp;</p>
<p>Kathy holds a bachelor's degree in Computer Science and Psychology from The
    University of Minnesota and a master's degree in Education and Instructional Design from The University of
    Washington.</p>
<p><strong>Dr. B. Glenn Wilkerson, President of th ARKGroup. &nbsp;</strong><br>
</p>
<p><br></p>

<p>Conversation opened. 5 messages. All messages read.</p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p>
    <br>
</p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br>
    <a href="https://mail.google.com/mail/u/0/" title="Skip to content">Skip to content</a><br>
    <a href="https://mail.google.com/mail/u/0/" title="Using Gmail with screen readers">Using Gmail with screen
        readers</a><br>
</p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>

<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<figure><img src="https://ssl.gstatic.com/ui/v1/icons/mail/rfr/logo_gmail_lockup_default_1x_r2.png" alt="" height="40px"
        width="109px" /></figure>
<p><br></p>
<p><br></p>
<p><br></p>
<h2>Search</h2>
<p><br></p>

<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>

<p><br></p>
<p><br></p>
<figure><img src="https://lh3.googleusercontent.com/ogw/ADea4I6PQxvieR8cy1UtrzVmYkTTkLmAKRtHJMqe0TI=s32-c-mo" alt=""
        height="32px" width="32px" /></figure>
<p><br></p>
<figure>&nbsp;</figure>

`;

    const v5 = `<figure>&nbsp;</figure>`;
    const v6 = `<div>f &nbsp;</div>`;
    const v7 = `<figure>f &nbsp;</figure>`;

    const v8 = `<h2>Conference Speakers:</h2>
<p><strong>Dr. Perry Bell, Psychologist. </strong>Dr. Perry Bell specializes in the
    treatment of children, adolescents and families. His focus includes depression, anxiety, parenting strategies,
    behavioral challenges in children, stress, life transitions, interpersonal relationships, obsessive- compulsive
    behavior, ADHD, developmental and social challenges related to the autism spectrum, learning challenges and time
    management.&nbsp;</p>
<p>Dr. Bell received his doctorate in Psychology from the Rutgers Graduate School of Applied
    and Professional Psychology. He also holds a masters in School Psychology and a New Jersey Certification as a School
    Psychologist from the same institution. Dr. Bell completed his undergraduate degree at Brandeis University. Prior to
    joining CCFD, Dr. Bell worked for public and private educational and clinical institutions.</p>
<p>Dr. Bell
    practices from a family-systems, cognitive-behavioral orientation. This means that his focus is on the teaching and
    acquisition of skills to manage the emotional, cognitive, behavioral and interpersonal demands of life.
    Additionally, Dr. Bell takes into account the family as the context in which children live and the important role of
    parents in the lives of children.</p>
<p>Dr. Bell’s professional interest is in the field of social- emotional
    learning (SEL) and character development or the avenues, strategies and methods by which children, parents and
    teachers can learn the skills of emotionally intelligent and morally intelligent living.</p>
<p>His writing,
    curricula and research has been published in the Journal of Education and Urban Policy, the Australian Handbook of
    School Psychology, the American Educational Research Organization as well as local newspaper outlets, state and
    professional organizations.</p>
<p>Outside of clinical work, Dr. Bell develops programs, trainings, consultations,
    talks and curricula on SEL, character development and stress management for private and public schools, athletic
    teams, parent organizations and teacher organizations.pariatur. Excepteur sint occaecat cupidatat non proident, sunt
    in culpa qui officia deserunt mollit anim id est laborum.</p>
<p><strong>Kathy Slattengren, Parent Educator and
        Coach. </strong>Kathy is the founder of Priceless Parenting. With over two decades of experience, Kathy
    specializes in helping parents build strong, loving relationships with their children. She has helped thousands of
    parents from across the United States to Australia through Priceless Parenting's online classes, presentations,
    coaching and books.&nbsp;</p>
<p>Kathy holds a bachelor's degree in Computer Science and Psychology from The
    University of Minnesota and a master's degree in Education and Instructional Design from The University of
    Washington.</p>
<p><strong>Dr. B. Glenn Wilkerson, President of th ARKGroup. &nbsp;</strong><br>
</p>
<p><br></p>

<p>Conversation opened. 5 messages. All messages read.</p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p>
    <br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br>
<a
        href="https://mail.google.com/mail/u/0/" title="Skip to content">Skip to content</a><br>
<a
        href="https://mail.google.com/mail/u/0/" title="Using Gmail with screen readers">Using Gmail with screen
        readers</a><br>
</p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>

<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<figure><img
        src="https://ssl.gstatic.com/ui/v1/icons/mail/rfr/logo_gmail_lockup_default_1x_r2.png" alt=""
        height="40px" width="109px" /></figure>
<p><br></p>
<p><br></p>
<p><br></p>
<h2>Search</h2>
<p><br></p>

<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>

<p><br></p>
<p><br></p>
<figure><img
        src="https://lh3.googleusercontent.com/ogw/ADea4I6PQxvieR8cy1UtrzVmYkTTkLmAKRtHJMqe0TI=s32-c-mo" alt=""
        height="32px" width="32px" /></figure>
<p><br></p>
<p><br></p>
<p><br></p>
<p>
    <br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>

<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p>Compose</p>

<p><br></p>
<p><br></p>
<h2>Labels</h2>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>

<p><br></p>
<p><br></p>
<p><a href="https://mail.google.com/mail/u/0/#inbox"
        title="Inbox"><strong>Inbox</strong></a></p>
<p>5,334</p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>

<p><br></p>
<p><a href="https://mail.google.com/mail/u/0/#starred" title="Starred">Starred</a></p>
<p><br></p>

<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><a href="https://mail.google.com/mail/u/0/#snoozed"
        title="Snoozed">Snoozed</a></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><a
        href="https://mail.google.com/mail/u/0/#sent" title="Sent">Sent</a></p>
<p><br></p>
<p><br></p>
<p><br>
</p>
<p><br></p>
<p><br></p>
<p><a href="https://mail.google.com/mail/u/0/#drafts"
        title="Drafts"><strong>Drafts</strong></a></p>
<p>7</p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>

<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><a href="https://mail.google.com/mail/u/0/#label/%5BGmail%5D"
        title="[Gmail]">[Gmail]</a></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>

<p><a href="https://mail.google.com/mail/u/0/#label/%5BGmail%5DAll+Mail" title="[Gmail]All Mail">[Gmail]All
        Mail</a></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><a
        href="https://mail.google.com/mail/u/0/#label/Notes" title="Notes">Notes</a></p>
<p><br></p>
<p>More</p>

<p>&nbsp;</p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p>Meet</p>
<p><br></p>
<p><br></p>
<p><br></p>

<p><br></p>
<p><br></p>
<p><br></p>
<p><a href="https://meet.google.com/getalink?hs=202&amp;authuser=0" title="New
        meeting">New meeting</a></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><a
        href="https://mail.google.com/mail/u/0/" title="Join a meeting">Join a meeting</a></p>
<p><br></p>
<p><br>
</p>
<p>Hangouts</p>
<p><br></p>
<p><br></p>
<p><br></p>
<figure><img
        src="https://mail.google.com/mail/u/0/images/cleardot.gif" alt="Collapse" height="12px" width="12px" />
</figure>
<h2><strong>Hangouts</strong></h2>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p>&nbsp;</p>
<p><br></p>
<p>&nbsp;</p>
<p><br></p>

<p>&nbsp;</p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>

<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p>
    <br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>

<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p>
    <br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p>More</p>
<p><br></p>
<p><br></p>

<p>1 of 5</p>
<p><br></p>
<figure><img src="https://mail.google.com/mail/u/0/images/cleardot.gif" alt=""
        height="20px" width="20px" /></figure>
<p><br></p>
<figure><img
        src="https://mail.google.com/mail/u/0/images/cleardot.gif" alt="" height="20px" width="20px" /></figure>

<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>

<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<figure><img
        src="https://mail.google.com/mail/u/0/images/cleardot.gif" alt="Collapse all" height="20px"
        width="20px" /></figure>
<p><br></p>
<figure><img
        src="https://mail.google.com/mail/u/0/images/cleardot.gif" alt="Print all" height="20px" width="20px" />
</figure>
<p><br></p>
<figure><img src="https://mail.google.com/mail/u/0/images/cleardot.gif" alt="In new window"
        height="20px" width="20px" /></figure>
<p><br></p>
<p><br></p>
<h2>Little Kids, Big Feelings Interview
</h2>
<p><br></p>
<p>Inbox</p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br>
</p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<figure>
    <img src="https://ssl.gstatic.com/ui/v1/icons/mail/profile_mask2.png" alt="" height="40px" width="40px" />
</figure>
<p><br></p>
<p><br></p>
<h3><strong>Dori Durbin</strong> &lt;artsybarncat@gmail.com&gt;</h3>
<p><br></p>

<figure><img src="https://mail.google.com/mail/u/0/images/cleardot.gif" alt="Attachments" height="20px"
        width="20px" />Tue, Aug 24, 12:15 PM (1 day ago)</figure>
<p><br></p>
<p><br></p>
<figure><img
        src="https://mail.google.com/mail/u/0/images/cleardot.gif" alt="" height="20px" width="20px" /></figure>

<p><br></p>
<figure><img src="https://mail.google.com/mail/u/0/images/cleardot.gif" alt="" height="20px"
        width="20px" /></figure>
<p>to Glenn</p>
<p><br></p>
<figure><img
        src="https://mail.google.com/mail/u/0/images/cleardot.gif" alt="" height="20px" width="20px" /></figure>

<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>

<p><br></p>
<p><br></p>
<p><br></p>
<p>&nbsp;</p>
<p>Hi Glenn!</p>
<p><br>
</p>
<p>Thank you so much for your
    time today! I am sending you everything&nbsp;you need (so far) here!</p>
<p><br>
</p>
<p>I don't think I
    remembered&nbsp;to mention the speaker release&nbsp;form-- it’s a contract to allow me to share your interview— I
    have included two formats-- please read through it, sign it and shoot it back to me (you can send an image&nbsp;from
    a phone if that's better). If you have any questions or concerns, please let me know?</p>
<p><br>
</p>
<p>Okay
    here's what I'd love to have before your interview:</p>
<p><br>
</p>
<p>1. Headshot/photo of you</p>
<p>2. Your
    biography</p>
<p>3. Speaker Agreement</p>
<p><br>
</p>
<p>Also.... is there anything you would like to promote
    or include for parents? For instance a link to your book, your PEP program... ARK? We will add to true emails you
    are a part of!&nbsp;</p>
<p><br>
</p>
<p>Here is the Thursday, September 2nd, 11:15-12:00pm (your time) Interview
    ZOOM link as well!</p>
<p><br>
</p>
<p><a
        href="https://us04web.zoom.us/j/77676257450?pwd=SjRFMG91a09Ia3NnTEZCQ0grNDdVUT09"
        title="https://us04web.zoom.us/j/&lt;wbr&gt;77676257450?pwd=&lt;wbr&gt;SjRFMG91a09Ia3NnTEZCQ0grNDdVUT&lt;wbr&gt;09">https://us04web.zoom.us/j/77676257450?pwd=SjRFMG91a09Ia3NnTEZCQ0grNDdVUT09</a>
</p>
<p><br>
</p>
<p>Thank you and please ask questions as they arise!</p>
<p>Dori Durbin</p>
<p><br>
</p>
<p>
    <br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p>
    <strong>2</strong> <strong>Attachments</strong></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p>&nbsp;
</p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p>&nbsp;</p>
<p><br></p>
<p><br>
</p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br>
</p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<figure><img
        src="https://ssl.gstatic.com/ui/v1/icons/mail/profile_mask2.png" alt="" height="40px" width="40px" />
</figure>
<p><br></p>
<p><br></p>
<h3><strong>Glenn Wilkerson</strong></h3>
<p><br></p>
<figure><img
        src="https://mail.google.com/mail/u/0/images/cleardot.gif" alt="Attachments" height="20px"
        width="20px" />Tue, Aug 24, 2:02 PM (1 day ago)</figure>
<p><br></p>
<p><br></p>
<figure><img
        src="https://mail.google.com/mail/u/0/images/cleardot.gif" alt="" height="20px" width="20px" /></figure>

<p><br></p>
<figure><img src="https://mail.google.com/mail/u/0/images/cleardot.gif" alt="" height="20px"
        width="20px" /></figure>
<p>to me</p>
<p><br></p>
<figure><img
        src="https://mail.google.com/mail/u/0/images/cleardot.gif" alt="" height="20px" width="20px" /></figure>

<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>

<p><br></p>
<p>Great visiting with you Dori!</p>
<p><br>
</p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>

<figure><img
        src="https://ci5.googleusercontent.com/proxy/hHTqu_npX5VevPtEmjmx-WkeI0XPKQuWk5JkjtUnuTPL9G4Jfg1uGu0_FX4SXkxJsgggW9iCzf-pPVcI-K7MdSlNYWaXDeMrQVBLzopISi6_HCNTkWkvVC6DNbWlZ99j9S5gS8YCYZt4pwT-51213faZvOkKTiX9d4x06Gv9fJ6HGfA47y0kt2C_idrmpvIL9ZfFBg9TFlN3QcP0hQ=s0-d-e1-ft#https://docs.google.com/uc?export=download&amp;id=1BjFe-df7pNqbXruK7nII9yOv4frunAEK&amp;revid=0B8v14GUbkMQkYStwSldXOGpYV0c2RWJJZHBEK2tjNnpxeEN3PQ"
        alt="" height="" width="" /></figure>
<figure><img src="" alt="" height="" width="" /></figure>

<figure><img src="" alt="" height="" width="" /></figure>
<figure><img src="" alt="" height=""
        width="" /><br>
</figure>
<p>Dr. B. Glenn Wilkerson, President&nbsp;<br>
</p>
<p><br></p>
<p>The
    ARKGroup&nbsp;</p>
<p>Lone Star College--University Park</p>
<p>20515 SH 249, CB-127</p>
<p>Houston, Texas
    77070&nbsp;</p>
<p>(Work) 713-471-8922 (Cell) 832-878-7113</p>
<p><a href="http://www.thearkgroup.org/"
        title="www.thearkgroup.org&lt;br&gt;">www.thearkgroup.org</a><br>
</p>
<p><br></p>
<p><br></p>
<figure>
    <img src="https://ssl.gstatic.com/ui/v1/icons/mail/images/cleardot.gif" alt="" height="11px" width="24px" />
</figure>
<p><br></p>
<p><br></p>
<p><strong>3</strong> <strong>Attachments</strong></p>
<p><br></p>
<p><br></p>

<p><br></p>
<p><br></p>
<p>&nbsp;</p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>

<p>&nbsp;</p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>

<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<figure><img
        src="https://ssl.gstatic.com/ui/v1/icons/mail/profile_mask2.png" alt="" height="40px" width="40px" />
</figure>
<p><br></p>
<p><br></p>
<h3><strong>Glenn Wilkerson</strong></h3>
<p><br></p>
<figure><img
        src="https://mail.google.com/mail/u/0/images/cleardot.gif" alt="Attachments" height="20px"
        width="20px" />Tue, Aug 24, 2:07 PM (1 day ago)</figure>
<p><br></p>
<p><br></p>
<figure><img
        src="https://mail.google.com/mail/u/0/images/cleardot.gif" alt="" height="20px" width="20px" /></figure>

<p><br></p>
<figure><img src="https://mail.google.com/mail/u/0/images/cleardot.gif" alt="" height="20px"
        width="20px" /></figure>
<p>to me</p>
<p><br></p>
<figure><img
        src="https://mail.google.com/mail/u/0/images/cleardot.gif" alt="" height="20px" width="20px" /></figure>

<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>

<p><br></p>
<p><br></p>
<p><br>
</p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<figure><img
        src="https://ci5.googleusercontent.com/proxy/hHTqu_npX5VevPtEmjmx-WkeI0XPKQuWk5JkjtUnuTPL9G4Jfg1uGu0_FX4SXkxJsgggW9iCzf-pPVcI-K7MdSlNYWaXDeMrQVBLzopISi6_HCNTkWkvVC6DNbWlZ99j9S5gS8YCYZt4pwT-51213faZvOkKTiX9d4x06Gv9fJ6HGfA47y0kt2C_idrmpvIL9ZfFBg9TFlN3QcP0hQ=s0-d-e1-ft#https://docs.google.com/uc?export=download&amp;id=1BjFe-df7pNqbXruK7nII9yOv4frunAEK&amp;revid=0B8v14GUbkMQkYStwSldXOGpYV0c2RWJJZHBEK2tjNnpxeEN3PQ"
        alt="" height="" width="" /></figure>
<figure><img src="" alt="" height="" width="" /></figure>

<figure><img src="" alt="" height="" width="" /></figure>
<figure><img src="" alt="" height=""
        width="" /><br>
</figure>
<p>Dr. B. Glenn Wilkerson, President&nbsp;<br>
</p>
<p><br></p>
<p>The
    ARKGroup&nbsp;</p>
<p>Lone Star College--University Park</p>
<p>20515 SH 249, CB-127</p>
<p>Houston, Texas
    77070&nbsp;</p>
<p>(Work) 713-471-8922 (Cell) 832-878-7113</p>
<p><a href="http://www.thearkgroup.org/"
        title="www.thearkgroup.org&lt;br&gt;">www.thearkgroup.org</a><br>
</p>
<p><br></p>
<p><br></p>
<figure>
    <img src="https://ssl.gstatic.com/ui/v1/icons/mail/images/cleardot.gif" alt="" height="11px" width="24px" />
</figure>
<p><br></p>
<p><br></p>
<p><strong>3</strong> <strong>Attachments</strong></p>
<p><br></p>
<p><br></p>

<p><br></p>
<p><br></p>
<p>&nbsp;</p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>

<p>&nbsp;</p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>

<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<figure><img
        src="https://mail.google.com/mail/u/0/images/cleardot.gif" alt="" height="20px" width="20px" /></figure>

<p><br></p>
<figure><img src="https://mail.google.com/mail/u/0/images/cleardot.gif" alt="" height="20px"
        width="20px" /></figure>
<p>to Glenn</p>
<p><br></p>
<figure><img
        src="https://mail.google.com/mail/u/0/images/cleardot.gif" alt="" height="20px" width="20px" /></figure>

<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>

<p><br></p>
<p>Looks great! I think the only other thing is if there’s anything you would like to add to the email...
    ARK or PEP links, book info...?<br>
<br>
</p>
<p>Sent from my iPhone</p>
<p><br>
</p>
<blockquote>On Aug 24,
    2021, at 2:32 PM, Glenn Wilkerson &lt;<a href="mailto:glennwilkerson@thearkgroup.org"
        title="glennwilkerson@thearkgroup.&lt;wbr&gt;org">glennwilkerson@thearkgroup.org</a>&gt; wrote:</blockquote>

<p><br></p>
<p><br></p>
<figure><img src="https://ssl.gstatic.com/ui/v1/icons/mail/images/cleardot.gif" alt=""
        height="11px" width="24px" /></figure>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p>
    <br></p>
<figure><img src="https://ssl.gstatic.com/ui/v1/icons/mail/no_photo.png" alt="" height="40px"
        width="40px" /></figure>
<p><br></p>
<p>ReplyForward</p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>

<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>

<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p>
    <br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>

<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p>
    <br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>

<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p>
    <br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p>Page</p>

<p>1/</p>
<p>1</p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br>
</p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<h2>Page 1 of 1</h2>
<p>DR. B.
    GLENN WILKERSON</p>
<p>VITAE</p>
<p>Dr. B. Glenn Wilkerson is the President and Founder of the Children’s Center
    for Self-</p>
<p>Esteem, Inc. (dba The ARKGroup).</p>
<p>He is nationally-recognized for his work in elevating the
    social/emotional health and</p>
<p>creating positive self-concepts in children. Wilkerson’s research in cooperation
    with late</p>
<p>Dr. Ron Lorimar at the University of Texas’ School of Public Health established a new</p>
<p>
    paradigm regarding the primacy of adult/child relationships characterized by</p>
<p>unconditional love and care.
</p>
<p>Glenn is the author of Trekking: Searching for Love and Self-Esteem, If Jesus Had a</p>
<p>Child, and the
    nationally-acclaimed ARK (Adults Relating to Kids) Program that</p>
<p>incorporates best practices in parenting and
    teaching. His research-based materials are</p>
<p>widely shared in schools, churches, prisons, and community
    service organizations.</p>
<p>Glenn is the recipient of the State of Texas Board of Education’s Regional “Hero for
</p>
<p>Children” Award and was a participant in the White House Conference on Youth, Drug</p>
<p>Use and Violence. He
    has been the keynote speaker and workshop leader at numerous</p>
<p>conventions and convocations, including the
    State of Texas PTA Convention, the Texas</p>
<p>Behavior Support Conference, the Texas State Conference on Rigor,
    Relevance, and</p>
<p>Relationships, the PTAK “Pre-School Convention,” the Mental Health Association of</p>
<p>
    Greater Dallas’ “Adolescent Symposium,” the Texas Education Agency’s “Parent</p>
<p>Involvement Conference,” and
    the “Texas Superintendent’s Conference.”</p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p>
    <br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>

<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p>Wilkerson--Glenn's Vitae 2021--IMPORTANT (for speaking
    engagements).doc</p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p>
    <br></p>
<p>Open with</p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br>
</p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><br></p>
<p><strong>Page 1 of
        1</strong></p>
<h2><img
        src="https://firebasestorage.googleapis.com/v0/b/wild-ink-pages-prod.appspot.com/o/user-content%2F-MbO0HAtkIcYUIqusBSd%2FAuthor%20Photo%20200x200%20(1)_1628866700044.png?alt=media&amp;token=4d1fd361-3c68-42a6-94d0-5221d244c3aa" />Hosted
    by: Dori Durbin</h2>
<p>Dori Durbin is a children's book author, illustrator, and former teacher with a Masters in
    Social Work with an emphasis on Community Services.</p>`;
    return v8;
}
