
// names MUST BE strings, not types, types are minified,etc at build time.

import { RuntimeWimlComponentCollection_v1_0_0 } from "../../../v1.0.0/theme-data/renderer/types";
import { wimlComponents_v1_2_0 } from "../../../v1.2.0/theme-data/renderer/wiml-components-v1.2.0";
import { Footer_v1_3_0 } from "./components/footer";

// todo replace and inherit v1.0.0 components
export const wimlComponents_v1_3_0: RuntimeWimlComponentCollection_v1_0_0 = {
    ...wimlComponents_v1_2_0,
    "Footer": Footer_v1_3_0,

};
