import React from 'react';
import { RuntimeWimlComponentProps_v1 } from "app/wiml/versions/v1/types";
import { getComponentClassName_v1_1_0 } from '../../compiler/components';
import { renderChildren_v1_0_0 } from '../../../../v1.0.0/theme-data/renderer/render-components';

export function Footer_v1_1_0(props: RuntimeWimlComponentProps_v1) {
  let footer;

  const className = getComponentClassName_v1_1_0(props, 'footer');
  const renderedChildren = renderChildren_v1_0_0(props.children);

  footer = (
    <footer className={className}>
      <div>{renderedChildren}</div>
      <div className="legal">
        <div className="colophon">
          <div className="copyright">
            © {`${props.extra.websiteData.about.firstName} ${props.extra.websiteData.about.lastName}`}. All Rights Reserved.
          </div>
          <div className="design-credits mt-1">
            Made with ❤ by{' '}
            <a href="//www.wildinkpages.com/" target="_blank">
              Wild Ink Pages
            </a>
            .
          </div>
        </div>
      </div>
      <style jsx>{`
        footer {
          background-color: var(--global-footer-bg-color);
          color: var(--global-footer-text-color);
        }
        footer a {
          color: var(--global-footer-link-color);
        }
        .legal {
          z-index: 1;
          position: relative;
          text-align: center;
          padding: 1.5rem 0;
          user-select: none;
        }

        .colophon {
          position: relative;
          z-index: 2;
        }

        .design-credits {
          font-size: 0.8rem;
        }

        .design-credits a {
          color: inherit;
        }
      `}</style>
    </footer>
  );

  return footer;
}
