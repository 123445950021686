import { IWiml_v1, ListInstanceComponentThemeData_v1 } from "../../../types";
import humps from 'humps';

export function getPageComponentThemeData_v1_0_0(pageId: string, wimlData: IWiml_v1) {
    return wimlData.themeData?.components.items?.pages?.items?.[pageId];
}

export function getPageChildComponentThemeData_v1_0_0(pageId: string, componentId: string, wimlData: IWiml_v1) {
    return getPageComponentThemeData_v1_0_0(pageId, wimlData)?.components?.items?.[componentId];
}

export function getListComponentThemeData_v1_0_0(listId: string, wimlData: IWiml_v1) {
    return wimlData.themeData?.components.items?.lists?.items?.[listId];
}

export function _getListChildComponentsThemeData_v1_0_0(listId: string, wimlData: IWiml_v1) {
    return getListComponentThemeData_v1_0_0(listId, wimlData)?.components?.items;
}

export function _getListChildComponentThemeData_v1_0_0(listId: string, componentId: string, wimlData: IWiml_v1) {
    return _getListChildComponentsThemeData_v1_0_0(listId, wimlData)?.[componentId];
}

export function getPageChildListInstanceComponentThemeData_v1_0_0(pageId: string, listInstanceComponentId: string, wimlData: IWiml_v1) {
    const component = getPageChildComponentThemeData_v1_0_0(pageId, listInstanceComponentId, wimlData);
    if (component.type != 'List') {
        throw new Error(`component ${listInstanceComponentId} on page ${pageId} is not a list`);
    }
    return component as ListInstanceComponentThemeData_v1;
}

export function getPageChildListInstanceChildComponentThemeData_v1_0_0(pageId: string, listInstanceComponentId: string, componentId: string, wimlData: IWiml_v1) {
    const component = getPageChildComponentThemeData_v1_0_0(pageId, listInstanceComponentId, wimlData);
    if (component.type != 'List') {
        throw new Error(`component ${listInstanceComponentId} on page ${pageId} is not a list`);
    }
    return component as ListInstanceComponentThemeData_v1;
}

export function getListInstanceComponentThemeDataId_v1_0_0(componentKey: string, componentType: string) {
    const newKey = componentKey || componentType;
    const componentId = componentType.toLowerCase() == newKey.toLowerCase() ? newKey : `${componentType}_${newKey}`;
    const camlizedComponentId = humps.camelize(componentId);
    return camlizedComponentId;
}
// todo turn this into a type consinder using "pluck"

export function getPageComponentThemeDataId_v1_0_0(pageId: string, componentKey: string, componentType: string) {
    const newPageId = humps.camelize(pageId);
    const newKey = componentKey || componentType;

    // this handles nested lists
    const split = newKey.split('.');
    const componentId = split.map((s) => s.toLowerCase() == componentType.toLowerCase() ? s : `${componentType}_${s}`).join('.');

    const camlizedComponentId = humps.camelize(componentId);

    return { pageId: newPageId, camlizedComponentId };
}
