import { establishPendingChangesListener, fetchPendingChanges, recordChanges, replayPendingChanges, replayPendingChangesSuccess } from "@wip/common/event-store/website";

const shouldRecordRegex = /^website\/(update|create|delete|add|remove|move|replace)/;

export const pendingChangesMiddleware = store => next => action => {
    const result = next(action);

    const state = store.getState();
    const { isReplaying } = state.website;

    if (!isReplaying) {
        if (typeof action == 'object') {
            if (shouldRecordRegex.test(action.type)) {
                store.dispatch(recordChanges(action));
            }
            else if (action.type == ('website/listenForDataChangesSuccess')) {
                store.dispatch(establishPendingChangesListener()).then(action => {
                    // store.dispatch(fetchWebsiteHistory());
                    // todo: we are temporariyl or permanently not displaying history. still capturing it on each save tho.
                    // this also causes the manage/index page to re-render the layout based on isLoading.
                });
            }
            else if (action.type == ('website/replayDataChangesSuccess')) {
                store.dispatch(replayPendingChanges());

                state.website.pendingChanges.queue.forEach(change => {
                    // this follows same convetion as websiteApi.listenForPendingChanges
                    // apps/common/event-store/website.js
                    store.dispatch(change.action);
                });

                store.dispatch(replayPendingChangesSuccess(state.website.pendingChanges.queue));
            } else {
                console.warn('pendingChangesMiddleware: unhandled action', action);
            }
            // else if (action.type == ('website/clearPendingChanges/fulfilled')) {
            //     window.location.reload();
            // }

            // TODO not moving this logic to middleware -- it's too complex. we need to use
            // redux saga or or redux rxjs
            // else if (action.type.startsWith('pendingChanges/commitPendingChanges/pending')) {
            //     store.dispatch(saveWebsiteData());
            // }
        }
    }


    return result
}
