import * as textUtils from "../lib/text-utils";
import { DataClass } from "../lib/object-utils";

export default class Post extends DataClass {

    constructor(postId, data) {
        super(data);

        this.id = postId;

        this.schedule = this.schedule || {};
        this.about = this.about || {};
        this.assets = this.assets || {};

        // todo - this is now just a string. about.tags = a comma-delimited string
        // this.tags = this.tags || [];

        const publishDate = this.schedule.publishDate;
        if (publishDate) {
            this.schedule.publishDate = new Date(publishDate);
        }
    }

    get tagCollection() {
        const retVal = (this.about.tags || "")
            // .split(/,\s?/)
            .split("\n")
            .filter(Boolean)
            .map(t => t.trim())
            .map(t => ({ key: textUtils.convertToSlug(t), value: t }));

        return retVal;
    }
}
