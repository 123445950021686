import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import {
  Card,
  CardHeader,
  CardBody
} from 'reactstrap';
import { useRef, useState, useEffect } from 'react';
import humps from 'humps';
import { makeSerialilzable } from '@wip/common/lib/object-utils';

export default function Preview(props) {
  const previewUrl = process.env.REACT_APP_PREVIEW_URL;
  const [isLoaded, setIsLoaded] = useState(false);

  const iframeRef = useRef();

  useEffect(() => {
    if (!isLoaded) {
      iframeRef.current.addEventListener('load', () => {
        setIsLoaded(true);
        window.addEventListener('message', function (e) {
          // this isn't used until wiml v1.2.0
          if (e.data.name == 'preview_update') {
            props.onPreviewChange(e.data.payload, props.websiteData);
          }
        });
      });
    }
  }, [isLoaded]);

  useEffect(() => {
    if (isLoaded) {
      const { websiteData } = props;
      // const camlized = humps.camelizeKeys(websiteData);
      // passed offline using serialization algorithm specified in spec (2.9.1)
      // ensure keys match the same case in preview mode as in live mode

      // because the redux selecto returns an actual website object, we need to convert it to a serializable object
      const serializedWebsiteData = makeSerialilzable(websiteData);

      iframeRef.current.contentWindow.postMessage({ name: 'update_website', payload: { websiteData: serializedWebsiteData } }, '*');
    }
  }, [isLoaded, props.pendingChanges.length]);

  const wimlVersion = props.websiteData.style.wiml.themeData.meta.version;
  let selectedTab;
  if (wimlVersion === '1.0.0' || wimlVersion === '1.1.0') {
    selectedTab = props.selectedTab;
  } else {
    selectedTab = props.selectedTab == 'home' ? 'page__home' : props.selectedTab;
  }

  useEffect(() => {
    if (isLoaded) {
      iframeRef.current.contentWindow.postMessage({ name: 'navigate_website', payload: { path: selectedTab } }, '*');
    }
  }, [selectedTab]);

  const additionalPreviewIframeProps = {};

  if (process.env.NODE_ENV === 'production') {
    // https://help.hotjar.com/hc/en-us/articles/115011624347-Can-I-Track-iframes-Inside-Heatmaps-and-Recordings#how-to-allow-an-iframe-within-hotjar
    additionalPreviewIframeProps['data-hj-allow-iframe'] = '';
  }

  const preview = (
    <section className="preview-wrapper flex-grow-1 p-3 d-none d-lg-block">
      <Card className="preview shadow h-100">
        <CardHeader className="preview-header">
          <FontAwesomeIcon size="xs" color={props.themeConfig.backgroundThirdColor} icon={faCircle} />
          <FontAwesomeIcon size="xs" className="ml-1" color={props.themeConfig.backgroundThirdColor} icon={faCircle} />
          <FontAwesomeIcon size="xs" className="ml-1" color={props.themeConfig.backgroundThirdColor} icon={faCircle} />
        </CardHeader>
        <CardBody className="preview-body">
          <div className="embed-responsive h-100" >
            <iframe ref={iframeRef} className="embed-responsive-item" src={previewUrl} {...additionalPreviewIframeProps} />
          </div>
        </CardBody>
      </Card>
      <style jsx>{`
        .preview-wrapper {
          background-color: ${props.themeConfig.backgroundSecondaryColor};
        }

        .preview-wrapper :global(.preview-header) {
          background-color: ${props.themeConfig.backgroundPrimaryColor};
          height: 40px;
        }

        .preview-wrapper :global(.preview-body) {
          padding:0;
        }
    `}</style>
    </section>
  );

  return preview;
}
