import React, { } from 'react';
import { getComponentClassName_v1_2_0 } from "../../compiler/components";
import { RuntimeWimlComponentProps_v1 } from "app/wiml/versions/v1/types";
import { V1_2_0_ComponentWrapper } from '../utils/component-wrapper';
import _ from 'lodash';
import { renderChildComponentSiteDataPropertyValue_v1_2_0 } from "../render-components";
import { WimlNumber_v1_0_0, _renderWimlNumber_v1_0_0 } from '../../../../v1.0.0/theme-data/renderer/components/number';

// note: before versionion, we called Number_*** WimlNumber to avoid conflict with the built-in Number type
// but now that we version, we can drop the Wiml prefix
export function Number_v1_2_0(props: RuntimeWimlComponentProps_v1) {
    let content = renderChildComponentSiteDataPropertyValue_v1_2_0<number>(props, 'number') || props.children as number;
    const className = getComponentClassName_v1_2_0(props, 'wiml-number');

    const numberValContent = _renderWimlNumber_v1_0_0(content, props);

    const retVal = <span className={className}>
        <V1_2_0_ComponentWrapper {...props} >
            {numberValContent}
        </V1_2_0_ComponentWrapper>
    </span>

    return retVal;
}

Number_v1_2_0.inputProps = {
    ...WimlNumber_v1_0_0.inputProps
};
