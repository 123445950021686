import { useRef } from 'react';
import { Editor as MceEditorV4 } from '@tinymce/tinymce-react-v4';

// todo remove along w/ env vars
const tinyMceApiKey = process.env.REACT_APP_TINY_MCE_API_KEY;

export function RichTextEditor_v2(props) {
    if (!props.onImageUpload) {
        throw new Error(`onImageUpload is required.`);
    }

    // todo remove along w/ env vars
    if (!tinyMceApiKey) {
        throw new Error(`tinyMceApiKey is required.`);
    }

    const editorRef = useRef(null);
    const prevVal = useRef();

    const inputBlur = () => {
        const newVal = getHTML();
        if (newVal != prevVal.current) {
            props.onDiff && props.onDiff(newVal);
        }
    };


    function getHTML() {
        const editorHtml = editorRef.current.getContent();

        return editorHtml
    }

    function inputFocus() {
        prevVal.current = getHTML();
    };

    // https://www.tiny.cloud/docs/configure/file-image-upload/#exampleusingimages_upload_handler
    async function uploadImageCallBack(blobInfo, success, failure, progress) {
        const file = blobInfo.blob();

        const fileUrl = await props.onImageUpload(file);


        success(fileUrl);
    }

    // todo self host tinymce not rely on cdn, see tiny-mce-v6 branch
    // https://www.tiny.cloud/docs/tinymce/6/react-pm-host/
    return <>
        <MceEditorV4
            tinymceScriptSrc="https://cdn.jsdelivr.net/npm/tinymce@6.8.5/tinymce.min.js"
            onInit={setRef}
            initialValue={props.defaultValue || ""}
            init={{
                body_class: 'rich-text-editor', /*todo does not work, have to use height*/
                height: 500,
                //    menubar: false,
                plugins: [
                    'advlist',
                    'autolink',
                    'lists',
                    'link',
                    'image',
                    'charmap',
                    'print',
                    'preview',
                    'anchor',
                    'searchreplace',
                    'visualblocks',
                    'code',
                    'fullscreen',
                    'insertdatetime',
                    'media',
                    'table',
                    'paste',
                    'help',
                    'wordcount'
                ],
                toolbar: 'undo redo | formatselect | ' +
                    'bold italic backcolor | alignleft aligncenter ' +
                    'alignright alignjustify | bullist numlist outdent indent | ' +
                    'removeformat | code | help',
                images_upload_handler: uploadImageCallBack,
                resize_img_proportional: true,
                extended_valid_elements: 'script[src|async|defer|type|charset]',
            }}
            onFocus={inputFocus}
            onBlur={inputBlur}
        />
        <style jsx global>{`
        .tox-promotion{
            display: none;
        }
        `}</style>
    </>;

    function setRef(evt, editor) {
        editorRef.current = editor;
    }
}
