import cheerio from "cheerio";
import { LoremIpsum } from "lorem-ipsum";
import htmlTruncate from 'html-truncate';
import truncateer from 'truncate-html';

// used in wiml 1.1
export function truncate_v2(str, limit = 240) {
    let retVal = str;

    // ensure limit is a number
    const numberLimit = parseInt(limit);

    if (str && str.length) {
        const options = {
            length: numberLimit,
            // set it to negetive number to remove the last word if cut in the middle.
            reserveLastWord: numberLimit / 10,
            decodeEntities: true,
        };


        retVal = truncateer(str, options);
    }

    return retVal;
}
export function truncate(str, limit = 240, filler = undefined, truncateLastWord = false, slop = undefined) {
    let retVal = str;

    if (str && str.length) {
        const options = {
            truncateLastWord: truncateLastWord,
            slop: slop,
        };
        if (filler) {
            options.ellipsis = filler;
        }
        retVal = htmlTruncate(str, limit, options);
        if (retVal.length < (limit / 2)) {
            // retVal = truncateHTML(str, limit, { ...options, slop: 20 });
            // retVal = truncateHTML(str, limit + 50, { ...options, slop: options.slop || 10 });
            // retVal = truncateHTML(str, limit + 50, options);
            retVal = htmlTruncate(str, limit / 2, options);
        }
    }

    return retVal;
}

function _truncate(str, limit, filler, truncateLastWord, slop) {
    let retVal = str;

    if (str && str.length) {
        const options = {
            truncateLastWord: truncateLastWord,
            slop: slop,
        };
        if (filler) {
            options.ellipsis = filler;
        }
        retVal = htmlTruncate(str, limit, options);
    }

    return retVal;
}
// https://stackoverflow.com/questions/4700226/i-want-to-truncate-a-text-or-line-with-ellipsis-using-javascript
// export function truncate(str, limit = 240) {
//     let retVal = str;

//     if (str.length > limit) {
//         retVal = str.substring(0, limit) + '...';
//      }

//      return retVal;
//}

// https://stackoverflow.com/questions/1053902/how-to-convert-a-title-to-a-url-slug-in-jquery
export function convertToSlug(str) {
    if (!str) throw new Error('str is empty.');

    const retVal = str
        .toLowerCase()
        .replace(/[^\w ]+/g, '')
        .replace(/ +/g, '-');

    return retVal;
}

export const slugify = convertToSlug;

// https://stackoverflow.com/questions/41000316/javascript-replace-all-non-alpha-numeric-characters-except-period
export function onlyAlphaNumeric(str, alsoAllow) {
    const stripRegex = new RegExp(`[^\\w${alsoAllow}]+`, 'g');

    const retVal = str.replace(stripRegex, "");
    return retVal;
}

// https://stackoverflow.com/a/5574446/173957
export function toTitleCase(str) {
    const retVal = str.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });

    return retVal;
}

export function generatePlaceholderText(length = 7) {
    const lorem = new LoremIpsum();

    // const lorem = new LoremIpsum({
    // sentencesPerParagraph: {
    //   max: 8,
    //   min: 4
    // },
    // wordsPerSentence: {
    //   max: 16,
    //   min: 4
    // }
    //   });

    //   lorem.generateWords(1);
    //   lorem.generateSentences(5);

    const retVal = lorem.generateParagraphs(length);

    return retVal;
}

// https://stackoverflow.com/questions/42235516/get-the-text-of-the-current-node-only
export function getTextFromHtml(html) {
    // https://github.com/cheeriojs/cheerio/issues/1031#issuecomment-748677236
    // https://stackoverflow.com/questions/31574127/node-js-cheerio-parser-breaks-utf-8-encoding
    const $ = cheerio.load(html, { decodeEntities: true }, false);

    const retVal = $.text();

    return retVal;
}



export const splitStringOnComma = (str) => str.split(/,\s*/g);
export const stringToBool = (str) => str?.toLowerCase() == "true";



// https://stackoverflow.com/questions/46155/whats-the-best-way-to-validate-an-email-address-in-javascript
export const emailValidationRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const validateEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(emailValidationRegex);
};

export const isFirstCharUpper = str => /^[A-Z]/.test(str);


export function convertNewLineToBr(value) {
    // replace newlines with <br>
    // https://stackoverflow.com/questions/784539/how-do-i-replace-all-line-breaks-in-a-string-with-br-elements
    const replaced = value.replace(/(\n)+/g, '<br />')

    return replaced;
}

export function convertBrToNewLine(value) {
    // replace <br> with newlines
    // https://stackoverflow.com/questions/784539/how-do-i-replace-all-line-breaks-in-a-string-with-br-elements
    const replaced = value.replace(/<br\s*[\/]?>/gi, "\n");

    return replaced
}


function isRelative(url) {
    const retVal = url.startsWith('/') || url.startsWith('#');

    return retVal;
}

export function getLinkTarget(url) {
    return isRelative(url) ? '' : '_blank';
}
export function convertStringToSemVer(version) {
    // handle default or empty minor and patch
    const [major = '0', minor = '0', patch = '0'] = version.split('.');

    return {
        major,
        minor,
        patch,
    };
}

export function searchText(searchTerm, content, snippetLength = 50) {
    const cleansedSearch = cleanseSearchString(searchTerm);
    const cleansedContent = cleanseSearchString(content);

    const index = cleansedContent.indexOf(cleansedSearch);
    if (index === -1) {
        const retVal = {
            found: false,
        }
        return retVal;
    } else {
        // Find the start of the snippet
        const start = Math.max(0, index - Math.floor(snippetLength / 2));
        // Get the full snippet from original content
        const snippet = content.slice(start, start + snippetLength).trim();

        const retVal = {
            found: true,
            index,
            snippet: snippet + (content.length > start + snippetLength ? '...' : '')
        }
        return retVal;
    };
}

function cleanseSearchString(input) {
    if (!input) return '';

    return input
        .toLowerCase()
        .replace(/[^a-z0-9\s]/g, '')
        .replace(/\s+/g, ' ')
        .trim();
}
