// https://dev.to/thatgalnatalie/how-to-get-started-with-redux-toolkit-41e
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { fetchContainerSnippetsData, fetchThemeData_v2 } from '../app/themes';

const initialState = {
  themes: [],
  containerSnippets: [], // todo put in own reducer or at least themes v3
  isLoading: false,
  hasErrors: false,
};

const theme = createSlice({
  name: "themes_v2",
  initialState,
  reducers: {
    getData: (state) => {
      state.isLoading = true;
    },

    getDataSuccess: (state, action) => {
      state.isLoading = false;
      state.themes = action.payload.themes;
      state.containerSnippets = action.payload.containerSnippets;
    },

    getDataFailure: (state) => {
      state.isLoading = false;
      state.hasErrors = true;
    },
  }
});

export const reducer = theme.reducer;

export const {
  getData, getDataSuccess, getDataFailure
} = theme.actions;

export const fetchThemes_v2 = createAsyncThunk(
  "theme/fetchThemes_v2",
  async (payload = {}, { dispatch }) => {
    // Set the loading state to true
    dispatch(getData());

    try {
      const retVal = {};
      retVal.themes = await fetchThemeData_v2();

      const { includeContainerSnippets } = payload;
      if (includeContainerSnippets) {
        const containerSnippets = await fetchContainerSnippetsData();
        retVal.containerSnippets = containerSnippets;
      }

      dispatch(getDataSuccess(retVal));
    } catch (error) {
      console.error('error', error)
      // Set any erros while trying to fetch
      dispatch(getDataFailure(error));
    }
  }
);
