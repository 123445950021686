import React, { useEffect, useContext, createContext, useState } from "react";
import firebase from '@wip/common/lib/firebase';

// https://stackoverflow.com/questions/55366320/how-do-i-use-the-firebase-onauthstatechange-with-the-new-react-hooks
// https://javascript.plainenglish.io/introduction-to-react-context-api-with-firebase-authentication-92a6a3cf116d
export const AuthContext = createContext();

export const AuthProvider = ({ children, onLogin, _firebase = firebase }) => {
    const [user, setUser] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        // listen for refresh token changes, signin, and sign out - https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth#onidtokenchanged
        // https://www.reddit.com/r/webdev/comments/us599i/what_is_the_difference_between_firebase/
        // https://www.reddit.com/r/Firebase/comments/qkpn78/firebase_auth_refresh_token/
        // https://stackoverflow.com/questions/38233687/how-to-use-the-firebase-refreshtoken-to-reauthenticate
        const unlisten = _firebase.auth().onIdTokenChanged(user => {
            setUser(user);
            user && onLogin && onLogin(user);
            setIsLoading(false);
            setIsLoaded(true);
        });

        return unlisten;
    }, []);

    return (
        <AuthContext.Provider value={{ isLoading, isLoaded, user, isAuthenticated: !!user }}>{children}</AuthContext.Provider>
    );
};
export const useAuth = () => useContext(AuthContext);
