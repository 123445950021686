import { RuntimeWimlComponentProps_v1 } from "app/wiml/versions/v1/types";
import { css } from "cheerio/lib/api/css";
import humps from "humps";
import React from "react";
import { breakpoints_v1_1_0 } from "../utils/breakpoints";
import { getInferredComponentDefaultType_v1_0_0 } from '../../../../v1.0.0/theme-data/compiler/components';
import { renderChildComponentSiteDataPropertyValue_v1_0_0 } from "../../../../v1.0.0/theme-data/renderer/render-components";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { iconOptions_v1_0_0 } from '../../../../v1.0.0/theme-data/renderer/components/icon';
import { Icon_v1_0_0 as Icon_v1_0_0 } from '../../../../v1.0.0/theme-data/renderer/components/icon';




// default md - height: 2rem !important;
const responsizeIconSizeLookupClassNames = {
    '': {
        '5': 'wiml-icon-size-5',
        '4': 'wiml-icon-size-4',
        '3': 'wiml-icon-size-3',
        '2': 'wiml-icon-size-2',
        '1': 'wiml-icon-size-1',
    },
    'lg': {
        '5': 'wiml-lg-icon-size-5',
        '4': 'wiml-lg-icon-size-4',
        '3': 'wiml-lg-icon-size-3',
        '2': 'wiml-lg-icon-size-2',
        '1': 'wiml-lg-icon-size-1',
    },
    'md': {
        '5': 'wiml-md-icon-size-5',
        '4': 'wiml-md-icon-size-4',
        '3': 'wiml-md-icon-size-3',
        '2': 'wiml-md-icon-size-2',
        '1': 'wiml-md-icon-size-1',
    },
    'sm': {
        '5': 'wiml-sm-icon-size-5',
        '4': 'wiml-sm-icon-size-4',
        '3': 'wiml-sm-icon-size-3',
        '2': 'wiml-sm-icon-size-2',
        '1': 'wiml-sm-icon-size-1',
    },
    'xs': {
        '5': 'wiml-xs-icon-size-5',
        '4': 'wiml-xs-icon-size-4',
        '3': 'wiml-xs-icon-size-3',
        '2': 'wiml-xs-icon-size-2',
        '1': 'wiml-xs-icon-size-1',

    }
} as const;


type IconSizeLookupClassNamesKey = keyof typeof responsizeIconSizeLookupClassNames;
type IconSizePositionKey = keyof typeof responsizeIconSizeLookupClassNames['']


const defaultIconSizeClass = responsizeIconSizeLookupClassNames['']['3'];

const iconSizeLookupClassNames = Object.keys(responsizeIconSizeLookupClassNames) as IconSizeLookupClassNamesKey[];
const iconSizeCamelizedPropNames = iconSizeLookupClassNames.reduce((acc, size) => {
    const alignPropName = size ? `size-${size}` : 'size';
    const camelizedPropName = humps.camelize(alignPropName);
    acc[size] = camelizedPropName;
    return acc;
}, {} as Record<IconSizeLookupClassNamesKey, string>);

export function Icon_v1_1_0(props: RuntimeWimlComponentProps_v1) {
    let retVal = null;

    const iconType = renderChildComponentSiteDataPropertyValue_v1_0_0(props, 'type', getInferredComponentDefaultType_v1_0_0('Icon'));
    if (iconType) {
        const icon = iconOptions_v1_0_0.find((option) => option.key === iconType);
        const className = _getIconClassNames_v1_1_0(props);

        retVal = <FontAwesomeIcon icon={icon.icon} className={className} />;
    }

    return retVal;
}
Icon_v1_1_0.inputProps = Icon_v1_0_0.inputProps;
export function _getIconClassNames_v1_1_0(props: RuntimeWimlComponentProps_v1) {
    const classNames = iconSizeLookupClassNames.map((size) => {
        const camelizedPropName = iconSizeCamelizedPropNames[size];
        const propValue = props[camelizedPropName] as IconSizePositionKey;
        if (propValue) {
            return [
                // 'iconsize',
                responsizeIconSizeLookupClassNames[size][propValue]
            ];
        }
    }).filter(Boolean);

    if (classNames.length === 0) {
        classNames.push([defaultIconSizeClass]);
    }

    classNames.unshift(['wiml-icon']);

    const className = classNames.flat().filter(Boolean).join(' ');
    return className;
}



















const responiveIconColorLookupClassNames = {
    '': {
        '5': '',
        '4': '',
        '3': '',
        '2': '',
        '1': '',
    },
    'lg': {
        '5': '',
        '4': '',
        '3': '',
        '2': '',
        '1': '',
    },
    'md': {
        '5': '',
        '4': '',
        '3': '',
        '2': '',
        '1': '',
    },
    'sm': {
        '5': '',
        '4': '',
        '3': '',
        '2': '',
        '1': '',
    },
    'xs': {
        '5': '',
        '4': '',
        '3': '',
        '2': '',
        '1': '',
    }
} as const;
type ResponsiveIconColorLookupClassNames = typeof responiveIconColorLookupClassNames;
type ResponiveIconColorLookupClassNamesKey = keyof ResponsiveIconColorLookupClassNames;

type IconColorPositionKeyNames = typeof responiveIconColorLookupClassNames[''];
type IconColorPositionKey = keyof IconColorPositionKeyNames

const iconColorLookupClassNames = Object.keys(responiveIconColorLookupClassNames) as ResponiveIconColorLookupClassNamesKey[];
const iconColorCamelizedPropNames = iconColorLookupClassNames.reduce((acc, iconColor) => {
    const alignPropName = iconColor ? `color-${iconColor}` : 'color';
    const camelizedPropName = humps.camelize(alignPropName);
    acc[iconColor] = camelizedPropName;
    return acc;
}, {} as Record<ResponiveIconColorLookupClassNamesKey, string>);

export function _getIconStyles_v1_1_0(props: RuntimeWimlComponentProps_v1) {
    const iconColorStyles = iconColorLookupClassNames.map((iconColorRepsoniveIconColorProp) => {
        const camelizedPropName = iconColorCamelizedPropNames[iconColorRepsoniveIconColorProp];
        const propValue = props[camelizedPropName] as IconColorPositionKey;
        if (propValue) {
            const cssIconColorValue = propValue;
            const cssRuleValue = cssIconColorValue.startsWith('--') ? `var(${cssIconColorValue})` : cssIconColorValue;
            let style;
            if (iconColorRepsoniveIconColorProp) {
                style = `.wiml-icon-color-dynamic__${cssIconColorValue} {
      color: ${cssRuleValue};
    }`;
                style = `@media ${breakpoints_v1_1_0[iconColorRepsoniveIconColorProp]} {
    ${style}
}`;
            } else {
                style = `.wiml-icon-color-dynamic__${cssIconColorValue} {
    color: ${cssRuleValue};
}`;
            }
            return [
                style
            ];
        }
    });


    const className = iconColorStyles.flat().filter(Boolean).join('\n\n');
    return className;
}
Icon_v1_1_0.inputProps = Icon_v1_0_0.inputProps;
