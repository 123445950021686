
export const listItemComponentCheckoutRules_v1_0_0 = {
    heading: { content: true },
    image: { url: true },
    number: { number: true },
    textConfirmationPage: { content: true },
    textConfirmationEmail: { content: true },
};

type ListItemComponentCheckoutRules = typeof listItemComponentCheckoutRules_v1_0_0;
export type ComponentKey = keyof ListItemComponentCheckoutRules;
