import humps from 'humps';
import { WimlNode_v1_0_0 } from './types';
import { ThemeDataPageComponent_v1, ThemeDataPageChildComponent_v1 } from "../../../types";
import {
    ThemeDataList_v1,
    ThemeDataWimlComponent_v1
} from "../../../types";

export function isThemeDataListComponent_v1_0_0(c: ThemeDataWimlComponent_v1): c is ThemeDataList_v1 {
    return c.type == 'List';
}

export function _getComponentId_v1_0_0(c: ThemeDataPageComponent_v1) {
    let retVal: string;

    const parts = c.key.split('.');
    const lastPart = parts.slice(-1)[0];

    if (c.type.toLocaleLowerCase() == lastPart.toLocaleLowerCase()) {
        retVal = lastPart;
    } else {
        const prefix = parts.slice(0, -1).join('.');
        const prefixWithPeriod = prefix ? `${prefix}.` : '';

        // List-book-series -> listBookSeries
        const camlizedId = humps.camelize(`${c.type}-${lastPart}`);
        retVal = `${prefixWithPeriod}${camlizedId}`;
    }

    return retVal;
}


import { _isListNode_v1_0_0 } from './nodes';



export function createComponent_v1_0_0(fullyQualifiedComponentId: string, node: WimlNode_v1_0_0, themeProps: { [key: string]: string; }, parentListId: string,): ThemeDataPageChildComponent_v1 {
    const isList = _isListNode_v1_0_0(node);

    return {
        key: fullyQualifiedComponentId,
        type: node.tag,
        themeProps,
        ...(isList && { listId: themeProps.listId || parentListId }),
        id: _getComponentId_v1_0_0({ key: fullyQualifiedComponentId, type: node.tag })
    };
}

// todo rename this to getInferredComponentType
export function getInferredComponentDefaultType_v1_0_0(lookup: string) {
    if (typeof lookup != 'string') throw new Error('lookup must be a string');
    return humps.decamelize(lookup, { separator: '-' });
}
