import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { Form, FormGroup, Label, Input } from 'reactstrap';
import { useDispatch } from "react-redux";
import { updateEventAbout, updateEventLocation, updateEventSchedule } from '@wip/common/event-store/website';
import { ReactstrapInputBlurChangeDetector, RichTextEditor } from '../../form/input';
import * as arrayUtils from '@wip/common/lib/array-utils';
import * as dateUtils from '@wip/common/lib/date-utils';
import { saveFile } from '@wip/common/event-store/website';

const headers = arrayUtils.range(6).map(i => ({ header: (i + 1).toString() }));

const EventDetail = (props) => {
    const dispatch = useDispatch();

    function uploadImage(file) {
        const workPromise = dispatch(saveFile(file)).then(action => action.payload);

        return workPromise;
    }

    const richTextConfig = useMemo(() => {
        const retVal = { onImageUpload: uploadImage };

        return retVal;
    }, [dispatch]);

    const eventDate = props.event.schedule.date;
    const eventDateFormat = eventDate ? dateUtils.dateYYYYMMDD(new Date(eventDate)) : null;
    const schedule = { ...props.event.schedule, date: eventDateFormat };
    const abc = { ...props.event, schedule };
    const { register, formState: { errors } } = useForm({
        mode: 'onBlur',
        defaultValues: abc
    });

    const { ref: titleRef, ...titleRest } = register('about.title', { required: true });
    const { ref: dateRef, ...dateRest } = register('schedule.date');
    const { ref: timeRef, ...timeRest } = register('schedule.time');
    const { ref: locationRef, ...locationRest } = register('location.title');
    const { ref: locationLinkRef, ...locationLinkRest } = register('location.link');
    const { ref: locationMapLinkRef, ...locationMapLinkRest } = register('location.mapLink');
    const { ref: bookshopLinkRef, ...bookshopLinkRest } = register('purchase.bookshopLink');
    const { ref: indieboundLinkRef, ...indieboundLinkRest } = register('purchase.indieboundLink');
    const { ref: custombookstore1NameRef, ...custombookstore1NameRest } = register('purchase.custombookstore1Name');
    const { ref: custombookstore1LinkRef, ...custombookstore1LinkRest } = register('purchase.custombookstore1Link');
    const { ref: awardsRef, ...awardsRest } = register('awards.content');

    // todo - this is duplicated throughout the app -- combine.
    const handleChangeEvent = (event, id, key, value) => {

        // for some reason isValid is always true even when not…
        if (errors && Object.keys(errors).length) {
            console.log('Skipping due to errors:', errors);
        } else {
            if (event) {
                id = event.target.dataset.id;
                key = event.target.name;

                if (event.target.type == 'checkbox') {
                    value = event.target.checked;

                } else {
                    value = event.target.value;
                }
            }

            if (!id) throw new Error('Handle change event `id` is missing.')
            if (!key) throw new Error('Handle change event `key` is missing.')
            if (value == undefined) throw new Error('Handle change event `value` must be set to a value or null.')

            const splitKeys = key.split('.');
            const path = splitKeys[0];
            const newKey = splitKeys[1];

            if (path == 'about') {
                dispatch(updateEventAbout({ id, key: newKey, value }));
            } else if (path == 'location') {
                dispatch(updateEventLocation({ id, key: newKey, value }));
            } else if (path == 'schedule') {
                dispatch(updateEventSchedule({ id, key: newKey, value }));
            }
        }
    };

    const handleEventDateChangeEvent = async (e) => {
        // todo use onChange in regsiter handler - react hook form new version has been added to project but this needs to be fixed.
        // note onChange is handled in react-hook-form 7.18

        dateRest.onChange && await dateRest.onChange(e);

        const id = e.target.dataset.id;
        const key = e.target.name;
        const value = dateUtils.parseDate(e.target.value);
        handleChangeEvent(null, id, key, value);
    };

    // todo - this is duplicated throughout the app -- combine.
    const handleRichEditorChangeEvent = (id, key, value) => {
        // for some reason isValid is always true even when not…
        if (errors && Object.keys(errors).length) {
            console.log('Skipping due to errors:', errors);
        } else {
            if (!id) throw new Error('Handle change event `id` is missing.')
            if (!key) throw new Error('Handle change event `key` is missing.')
            if (value == undefined) throw new Error('Handle change event `value` must be set to a value or null.')

            const splitKeys = key.split('.');
            const path = splitKeys[0];
            const newKey = splitKeys[1];

            if (path == 'about') {
                dispatch(updateEventAbout({ id, key: newKey, value }));
            }
        }
    };

    const bookViewElelement = (
        <Form>
            <FormGroup check>
                <Label check>
                    <Input type="checkbox" defaultChecked={props.event.schedule.draft} name="schedule.draft" id="schedule.draft" data-id={props.event.id} onChange={handleChangeEvent} /> {' '}
                    Draft?
                </Label>
            </FormGroup>
            <FormGroup>
                <Label for="about.title">Event Name</Label>
                <ReactstrapInputBlurChangeDetector id="about.title" invalid={!!errors.firstName} type="text" data-id={props.event.id} {...titleRest} innerRef={titleRef} onDiff={handleChangeEvent} />
            </FormGroup>
            <FormGroup>
                <Label for="schedule.date">Event Date</Label>
                <ReactstrapInputBlurChangeDetector id="schedule.date" type="date" data-id={props.event.id} {...dateRest} innerRef={dateRef} onChange={handleEventDateChangeEvent} />
            </FormGroup>
            <FormGroup>
                <Label for="schedule.amazonLink">Event Time</Label>
                <ReactstrapInputBlurChangeDetector id="schedule.time" type="text" data-id={props.event.id} {...timeRest} innerRef={timeRef} onDiff={handleChangeEvent} />
            </FormGroup>
            <FormGroup>
                <Label for="location.title">Event Location</Label>
                <ReactstrapInputBlurChangeDetector id="location.title" type="text" data-id={props.event.id} {...locationRest} innerRef={locationRef} onDiff={handleChangeEvent} />
            </FormGroup>
            <FormGroup>
                <Label for="location.link">Event Link</Label>
                <ReactstrapInputBlurChangeDetector id="location.link" type="text" data-id={props.event.id} {...locationLinkRest} innerRef={locationLinkRef} onDiff={handleChangeEvent} />
            </FormGroup>
            <FormGroup>
                <Label for="location.mapLink">Map Link</Label>
                <ReactstrapInputBlurChangeDetector id="location.mapLink" type="text" data-id={props.event.id} {...locationMapLinkRest} innerRef={locationMapLinkRef} onDiff={handleChangeEvent} />
            </FormGroup>
            <FormGroup>
                <Label for="about.description">Event Description</Label>
                <RichTextEditor defaultValue={props.event.about.description} onDiff={handleRichEditorChangeEvent.bind(null, props.event.id, 'about.description')} {...richTextConfig} />
            </FormGroup>
            <style jsx global>{`
            /* https://github.com/zenoamaro/react-quill/issues/118#issuecomment-263430862 */
            .ql-editor, .rich-text-editor{
                min-height: 100px !important;
                max-height: 300px;
                overflow: hidden;
                overflow-y: scroll;
                overflow-x: scroll;
              }
            `}</style>
        </Form>);

    return bookViewElelement;
}

export default EventDetail;
