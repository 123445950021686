import React from 'react';
import { Image_v1_0_0 } from '../../../../v1.0.0/theme-data/renderer/components/image';
import { getComponentClassName_v1_1_0 } from "../../compiler/components";
import { getInferredComponentDefaultType_v1_0_0 } from '../../../../v1.0.0/theme-data/compiler/components';
import { renderChildComponentSiteDataPropertyValue_v1_0_0 } from "../../../../v1.0.0/theme-data/renderer/render-components";
import { RuntimeWimlComponentProps_v1 } from "app/wiml/versions/v1/types";

export function Image_v1_1_0(props: RuntimeWimlComponentProps_v1) {
    let retVal = null;

    // rouded-circle is bootstrap
    const className = getComponentClassName_v1_1_0(props, props.circle != null && 'circle rounded-circle', props.square != null && 'square');

    const url = renderChildComponentSiteDataPropertyValue_v1_0_0(props, 'url', getInferredComponentDefaultType_v1_0_0('Image'));

    if (url) {
        const alt = renderChildComponentSiteDataPropertyValue_v1_0_0(props, 'alt', getInferredComponentDefaultType_v1_0_0('Image'));

        retVal = <img className={className} src={url} alt={alt} />;
    }
    return retVal;
}
Image_v1_1_0.inputProps = Image_v1_0_0.inputProps;
